import React, { useState } from "react";
import "../../styles/custom-bootstrap.scss";
import { Grid, TextField, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useFormikContext, getIn } from "formik";
import { zonedTimeToUtc } from 'date-fns-tz';
import DatePicker from 'react-datepicker';
import ContactDetailsAddress from "./ContactDetailsAddress";

const ContactDetails = (props) => {
  const {
    extendedWarrantyClaim,
    onAddressFormSubmit,
    entity,
    editableNewAddressFields,
    readOnlyFields,
    addAddressEnabled,
    selectAddressEnabled,
    sameAddressEnabled,
    isSameAddress
  } =
    props;

  const { t } = useTranslation();
  const customStyles = makeStyles({
    inputField: {
      marginTop: 10,
      marginBottom: 10
    },
    selectField: {
      marginBottom: 10
    },
    card: {
      width: '100%'
    },
    customStyleForSelect: {
      color: '#f44336',
      fontSize: '0.75rem',
      marginTop: '3px',
      marginLeft: '14px',
      marginRight: '14px'
    },
    selectedCard: {
      border: "2px solid #170f4f !important"
    }
  });
  const classes = customStyles();
  const formik = useFormikContext();
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [fieldToReveal, setFieldToReveal] = useState([])
  const [focusedField, setFocusedField] = useState(null)

  const handleFocus = (fieldName) => {
    setFocusedField(fieldName)
    setFieldToReveal((initialField) => [...initialField, fieldName])
  }

  const handleBlur = (fieldName) => {
    setFocusedField(null)
    setFieldToReveal((initialField) => initialField.filter(field => field !== fieldName))
  }

  const handleDateSelect = (newDate, field) => {
    const formattedDate = new Date(newDate);
    const dateInUsersLocalTime = zonedTimeToUtc(formattedDate, timeZone)
    formik.setFieldValue(field, dateInUsersLocalTime);
  }

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-sm-6">
          <TextField
            key={`${entity}.lastName`}
            label={t(`keys.extendedWarrantyClaim.${entity}.lastName`)}
            name={`${entity}.lastName`}
            variant="filled"
            autoComplete='off'
            type={fieldToReveal.includes(`${entity}.lastName`) ? "text" : "password"}
            fullWidth
            InputLabelProps={{
              shrink: true, // Example prop
              classes: {
                root: 'custom-label-root-class',
                focused: 'custom-label-focused-class',
              },
            }}
            InputProps={{
              disableUnderline: false,
              shrink: true
            }}
            className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
            onChange={formik.handleChange}
            onMouseEnter={() => setFieldToReveal((initialField) => [...initialField, `${entity}.lastName`])}
            onMouseLeave={() => { if (focusedField != `${entity}.lastName`) setFieldToReveal((initialField) => initialField.filter(field => field !== `${entity}.lastName`)) }}
            onFocus={() => handleFocus(`${entity}.lastName`)}
            onBlur={() => handleBlur(`${entity}.lastName`)}
            value={getIn(formik.values, `${entity}.lastName`)}
            disabled={readOnlyFields.includes('lastName')}
          />

          <TextField
            key={`${entity}.firstName`}
            label={t(`keys.extendedWarrantyClaim.${entity}.firstName`)}
            name={`${entity}.firstName`}
            variant="filled"
            autoComplete='off'
            fullWidth
            InputLabelProps={{
              shrink: true, // Example prop
              classes: {
                root: 'custom-label-root-class',
                focused: 'custom-label-focused-class',
              },
            }}
            InputProps={{
              disableUnderline: false,
              shrink: true
            }}
            className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
            onChange={formik.handleChange}
            value={getIn(formik.values, `${entity}.firstName`)}
            disabled={readOnlyFields.includes('firstName')}
          />

          <div className={` MuiFormControl-root MuiTextField-root makeStyles-inputField-65 margin-right-10 width-40`} style={{ width: '100%' }}>
            <Typography variant="subtitle2">{t('Gender')}</Typography>
            <select key={`${entity}.gender`}
              name={`${entity}.gender`}
              disabled={readOnlyFields.includes('gender')}
              onChange={formik.handleChange}
              value={getIn(formik.values, `${entity}.gender`)} className="select--small margin-bottom-10"
            >
              <option hidden value=''>{t('messages.selectYourOption')}</option>
              <option value='male'>{t('Male')}</option>
              <option value='female'>{t('Female')}</option>
            </select>
          </div>

          <div className={`${classes.inputField}`}>
            <Typography variant="subtitle2">{t('Date Of Birth')}</Typography>
            <DatePicker
              selected={getIn(formik.values, `${entity}.dateOfBirth`) ? new Date(getIn(formik.values, `${entity}.dateOfBirth`)) : null}
              onChange={(value) =>
                handleDateSelect(value, `${entity}.dateOfBirth`)
              }
              name={`${entity}.dateOfBirth`}
              className={`input-field`}
              placeholderText={t('messages.enterDateOfBirth')}
              timeInputLabel="Time:"
              dateFormat="dd/MM/yyyy HH:mm"
              showTimeInput
              fullWidth
              disabled={readOnlyFields.includes('dateOfBirth')}
            />

          </div>

          <TextField
            key={`${entity}.phoneNumber`}
            label={t(`keys.extendedWarrantyClaim.${entity}.phoneNumber`)}
            name={`${entity}.phoneNumber`}
            variant="filled"
            autoComplete='off'
            type={fieldToReveal.includes(`${entity}.phoneNumber`) ? "text" : "password"}
            fullWidth
            InputLabelProps={{
              shrink: true, // Example prop
              classes: {
                root: 'custom-label-root-class',
                focused: 'custom-label-focused-class',
              },
            }}
            InputProps={{
              disableUnderline: false,
              shrink: true
            }}
            className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
            onChange={formik.handleChange}
            onMouseEnter={() => setFieldToReveal((initialField) => [...initialField, `${entity}.phoneNumber`])}
            onMouseLeave={() => { if (focusedField != `${entity}.phoneNumber`) setFieldToReveal((initialField) => initialField.filter(field => field !== `${entity}.phoneNumber`)) }}
            onFocus={() => handleFocus(`${entity}.phoneNumber`)}
            onBlur={() => handleBlur(`${entity}.phoneNumber`)}
            value={getIn(formik.values, `${entity}.phoneNumber`)}
            disabled={readOnlyFields.includes('phoneNumber')}
          />

          <TextField
            key={`${entity}.email`}
            label={t(`keys.extendedWarrantyClaim.${entity}.email`)}
            name={`${entity}.email`}
            variant="filled"
            autoComplete='off'
            type={fieldToReveal.includes(`${entity}.email`) ? "text" : "password"}
            fullWidth
            InputLabelProps={{
              shrink: true, // Example prop
              classes: {
                root: 'custom-label-root-class',
                focused: 'custom-label-focused-class',
              },
            }}
            InputProps={{
              disableUnderline: false,
              shrink: true
            }}
            className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
            onChange={formik.handleChange}
            onMouseEnter={() => setFieldToReveal((initialField) => [...initialField, `${entity}.email`])}
            onMouseLeave={() => { if (focusedField != `${entity}.email`) setFieldToReveal((initialField) => initialField.filter(field => field !== `${entity}.email`)) }}
            onFocus={() => handleFocus(`${entity}.email`)}
            onBlur={() => handleBlur(`${entity}.email`)}
            value={getIn(formik.values, `${entity}.email`)}
            disabled={readOnlyFields.includes('email')}
          />

          {getIn(formik.values, `${entity}.ids`) && getIn(formik.values, `${entity}.ids`).length > 0 && getIn(formik.values, `${entity}.ids`).map((id, index) => {
            return (
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    key={`${entity}.ids[${index}].type`}
                    label={t(`keys.extendedWarrantyClaim.${entity}.ids.type`)}
                    name={`${entity}.ids[${index}].type`}
                    variant="filled"
                    autoComplete='off'
                    fullWidth
                    disabled={readOnlyFields.includes('ids.type')}
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class',
                        focused: 'custom-label-focused-class',
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={t(`keys.extendedWarrantyClaim.${entity}.ids.${getIn(formik.values, `${entity}.ids[${index}].type`)}`)}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    key={`${entity}.ids[${index}].number`}
                    label={t(`keys.extendedWarrantyClaim.${entity}.ids.number`)}
                    name={`${entity}.ids[${index}].number`}
                    variant="filled"
                    autoComplete='off'
                    type={fieldToReveal.includes(`${entity}.ids[${index}].number`) ? "text" : "password"}
                    fullWidth
                    disabled={readOnlyFields.includes('ids.type')}
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class',
                        focused: 'custom-label-focused-class',
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    onMouseEnter={() => setFieldToReveal((initialField) => [...initialField, `${entity}.ids[${index}].number`])}
                    onMouseLeave={() => { if (focusedField != `${entity}.ids[${index}].number`) setFieldToReveal((initialField) => initialField.filter(field => field !== `${entity}.ids[${index}].number`)) }}
                    onFocus={() => handleFocus(`${entity}.ids[${index}].number`)}
                    onBlur={() => handleBlur(`${entity}.ids[${index}].number`)}
                    value={getIn(formik.values, `${entity}.ids[${index}].number`)}
                  />
                </Grid>
              </Grid>
            )
          })}

          {getIn(formik.values, `${entity}.optIns`) && getIn(formik.values, `${entity}.optIns`).length > 0 && (
            <TextField
              key={`${entity}.optIns`}
              label={t(`keys.extendedWarrantyClaim.${entity}.optIns`)}
              name={`${entity}.optIns`}
              variant="filled"
              autoComplete='off'
              fullWidth
              disabled={readOnlyFields.includes('optIns')}
              InputLabelProps={{
                shrink: true, // Example prop
                classes: {
                  root: 'custom-label-root-class',
                  focused: 'custom-label-focused-class',
                },
              }}
              InputProps={{
                disableUnderline: false,
                shrink: true
              }}
              className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
              onChange={formik.handleChange}
              value={getIn(formik.values, `${entity}.optIns`)}
            />
          )}
        </div>
        <div className="col-md-6">
          <ContactDetailsAddress
            entity={entity} countryCode={extendedWarrantyClaim?.countryCode} contact={extendedWarrantyClaim[entity]}
            onAddressFormSubmit={onAddressFormSubmit} editableFields={editableNewAddressFields}
            addAddressEnabled={addAddressEnabled} selectAddressEnabled={selectAddressEnabled}
            sameAddressEnabled={sameAddressEnabled} isSameAddress={isSameAddress} />
        </div>
      </div>
    </div>
  );
};

export default ContactDetails;
