import axios from "./interceptor";
import {BASE_URL, SEARCH_INDEX} from "../constant";
import {handleError} from "../utils/utils";
import {SEARCH_GET_RESULT, SEARCH_SET_FACET_PARAM_VALUE, SEARCH_UNSET_STATE} from "./types";

export const getSearchResults = (facet, searchParams, languageCode, countryCode, carrierCode) => async dispatch => {
    axios.get(`${BASE_URL}/v1/search/${SEARCH_INDEX}/?facets[]=${facet.replace('**localeCode**', `${languageCode}_${countryCode}`)}&${searchParams}filter_field[]=systemConfiguration.availableForDirectToCustomer.${countryCode}.value.${carrierCode}&filter_value[]=true&filter_field[]=article.active&filter_value[]=true&limit=1`)
        .then(response => {
            dispatch({
                type: SEARCH_GET_RESULT,
                payload: {searchKey: facet, searchResult: response.data}
            })
        }).catch(error => {
        handleError(error, dispatch);
    })
}

export const setSearchParamValue = (searchKey, searchParamValue, nextSearch) => {
    return {
        type: SEARCH_SET_FACET_PARAM_VALUE,
        payload: {searchKey, searchParamValue, nextSearch}
    }
}

export const unsetSearchState = () => {
    return {
        type: SEARCH_UNSET_STATE
    }
}