import React, { Component } from "react";
import "../../styles/custom-bootstrap.scss";
import { useTranslation } from "react-i18next";
import DeviceDetailsView from "./DeviceDetailsView";
import ServiceLimitTable from "./ServiceLimitTable";
import QuotationDetailsView from "./QuotationDetailsView";
import ClaimDetailsView from "./ClaimDetailsView";
import ExcessFeeTable from "./ExcessFeeTable";
import { Formik } from "formik";
import RepairInvoiceView from "./RepairInvoiceView";
import ServiceCenterView from "./ServiceCenterView"
import UpdateStatusView from "./UpdateStatusView";
import PickUpDetailsView from "./PickUpDetailsView";
import DeliveryDetailsView from "./DeliveryDetailsView";
import AttachedFilesView from "./AttachedFilesView";
import { validateDisableAuthority } from "../../utils/utils";
import { AUTHORITY_PARTNER_AGENT } from "../../constant";

const attributesColorCircleStyle = (color) => ({
  backgroundColor: color,
  width: `24px`,
  height: `24px`,
  borderRadius: `50%`,
});

const quotationEnabledStatus = [
  "quotationReceivedAndUploaded",
  "quotationReviewInProgress",
  "quotationAwaitingCustomerResponse",
  "customerDeclinedQuotation",
  "customerDisputedQuotation",
  "customerAcceptedQuotation",
  "proceedWithRepair",
  "returnFromAsc",
  "returnDelivering",
  "returnedToCustomer",
  "repairInvoiceReceivedAndUploaded",
  "invoiceReviewInProgress",
  "customerDeclinedInvoice",
  "customerDisputedInvoice",
  "customerAcceptedInvoice",
  "finalReview",
  "reimbursementRequested",
  "paymentInitiated",
  "paymentInProgress",
  "paymentCompleted",
  "completed"
]

const invoiceEnabledStatus = [
  "repairInvoiceReceivedAndUploaded",
  "invoiceReviewInProgress",
  "invoiceAwaitingCustomerResponse",
  "customerDeclinedInvoice",
  "customerDisputedInvoice",
  "customerAcceptedInvoice",
  "finalReview",
  "reimbursementRequested",
  "paymentInitiated",
  "paymentInProgress",
  "paymentCompleted",
  "completed"
]

const serviceCenterDisabledStatus = [
  "new",
  "initiated"
]

const ExtendedWarrantyDetailsView = ({
  setSaveDisabled,
  onExtendedWarrantyClaimFormSubmit,
  extendedWarrantyClaim,
  editableFields,
  serviceLimit,
  subscription,
  businessEntities,
  countryCode,
  onExtendedWarrantyClaimUpdateStatus,
  onInitiateExtendedWarrantyClaim,
  onAddressFormSubmit,
  editableNewAddressFields,
  encodeFileAsURL,
  userAuthorities
}) => {
  const { t } = useTranslation();

  // if (!article) return null;
  return (
    <Formik
      enableReinitialize={true}
      initialValues={editableFields}
      onSubmit={(values, { setSubmitting }) => {
        onExtendedWarrantyClaimFormSubmit(values)
        setSaveDisabled(true);
        setTimeout(function () {
          setSaveDisabled(false);
          setSubmitting(false);
        }, 1000);
      }}
    >
      {(formik) => {
        const {
          values,
          handleChange,
          handleSubmit,
          errors,
          touched,
          handleBlur,
          isValid,
          onSubmit,
          dirty
        } = formik;
        return (
          <form id="extendedWarrantyClaimForm" onSubmit={formik.handleSubmit}>
            <div className="custom-bootstrap">
              <DeviceDetailsView
                extendedWarrantyClaim={extendedWarrantyClaim}
                subscription={subscription}
              />
              <ClaimDetailsView
                extendedWarrantyClaim={extendedWarrantyClaim}
                subscription={subscription}
              />
              <ServiceLimitTable
                serviceLimit={serviceLimit}
                extendedWarrantyClaim={extendedWarrantyClaim}
              />
              {extendedWarrantyClaim?.feeHashMap != null && Object.keys(extendedWarrantyClaim?.feeHashMap).length > 0 && (
                <ExcessFeeTable
                  extendedWarrantyClaim={extendedWarrantyClaim}
                />
              )}
              {quotationEnabledStatus.includes(extendedWarrantyClaim?.status) && (
                <QuotationDetailsView
                  extendedWarrantyClaim={extendedWarrantyClaim}
                  userCountryCode={countryCode}
                />
              )}
              {invoiceEnabledStatus.includes(extendedWarrantyClaim?.status) && (
                <RepairInvoiceView
                  serviceLimit={serviceLimit}
                  extendedWarrantyClaim={extendedWarrantyClaim}
                  userCountryCode={countryCode}
                />
              )}
              {!serviceCenterDisabledStatus.includes(extendedWarrantyClaim?.status) && (
                <ServiceCenterView
                  extendedWarrantyClaim={extendedWarrantyClaim}
                  businessEntities={businessEntities}
                />
              )}
              <PickUpDetailsView
                extendedWarrantyClaim={extendedWarrantyClaim}
                editableNewAddressFields={editableNewAddressFields}
              />
              <DeliveryDetailsView
                extendedWarrantyClaim={extendedWarrantyClaim}
                onAddressFormSubmit={onAddressFormSubmit}
                editableNewAddressFields={editableNewAddressFields}
              />
              <AttachedFilesView
                extendedWarrantyClaim={extendedWarrantyClaim}
                encodeFileAsURL={encodeFileAsURL}
                userCountryCode={countryCode}
              />
              {(extendedWarrantyClaim.status == "new" || extendedWarrantyClaim.status == "initiated" || extendedWarrantyClaim.status == "pendingInternal" || validateDisableAuthority(AUTHORITY_PARTNER_AGENT, userAuthorities)) && <UpdateStatusView
                extendedWarrantyClaim={extendedWarrantyClaim}
                onExtendedWarrantyClaimUpdateStatus={onExtendedWarrantyClaimUpdateStatus}
                onInitiateExtendedWarrantyClaim={onInitiateExtendedWarrantyClaim}
                userAuthorities={userAuthorities}
              />}
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

export default ExtendedWarrantyDetailsView;
