import React, {Component} from "react";
import { connect } from 'react-redux';
import PageTemplate from "../components/PageTemplate";
import * as yup from "yup";
import {createBusinessEntity} from "../actions/businessEntityActions";
import BusinessEntityForm from "../components/BusinessEntityForm";
import {addressFields} from "../utils/fields";
import {PICK_UP_DAYS} from "../constant";

class BusinessEntityCreate extends Component {
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.common.dialogType !== prevProps.common.dialogType &&
            this.props.common.dialogType === 'success'
        ) {
            this.props.history.push('/businessEntities');
        }
    }

    createBusinessEntity = (businessEntity) => {
        this.sanitiseData(businessEntity)
        this.props.createBusinessEntity(businessEntity);
    }

    sanitiseData = (businessEntity) => {
        let sanitisePickupDays =businessEntity.pickUpDays.filter(element => element);
        businessEntity.pickUpDays = sanitisePickupDays;   
    }
    renderForm = (countryCode) => {
        const editableFields = {
            code: '',
            name: '',
            channel: '',
            dealerCode: '',
            aggregatedPOSCode: '',
            email: '',
            personInChargeName: '',
            personInChargePhoneNumber: '',
            description: '',
            businessHours: '',
            pickUpDays: PICK_UP_DAYS[countryCode],
            address: {
                ...addressFields[countryCode].reduce((previousValue, currentValue) => ({...previousValue, [currentValue]: ''}), {}),
                countryCode: this.props.authentication.loggedInUser.countryCode
            }
        }

        const validationRules = yup.object({
            code: yup.string().required('messages.fieldRequired'),
            name: yup.string().required('messages.fieldRequired'),
            channel: yup.string().required('messages.fieldRequired'),
            address: yup.object({
                ...addressFields[countryCode].reduce((previousValue, currentValue) => ({...previousValue, [currentValue]: yup.string().required('messages.fieldRequired')}), {}),
            })
        });

            return (
            <BusinessEntityForm editableFields={editableFields}
                                validationRules={validationRules}
                                onFormSubmit={values => this.createBusinessEntity(values)}
                                loggedInUser={this.props.authentication.loggedInUser}
            />
        )
    }

    render() {
        return(
            <PageTemplate title="messages.businessEntityCreate">
                {this.renderForm(this.props.authentication.loggedInUser.countryCode)}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    authentication: state.authentication,
    businessEntities: state.businessEntities,
    common: state.common
})

export default connect(mapStateToProps, {
    createBusinessEntity
})(BusinessEntityCreate);