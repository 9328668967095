import React, { Component } from "react";
import { connect } from 'react-redux';
import PageTemplate from "../components/PageTemplate";
import { getBusinessEntityDetails, getSupportedBrandBusinessEntities } from "../actions/businessEntityActions";
import {
    getExtendedWarrantyClaimDetails,
    getExtendedWarrantyClaimServiceLimit,
    updateExtendedWarrantyClaim,
    saveAndUpdateExtendedWarrantyClaimStatus,
    saveAndInitiateExtendedWarrantyClaim,
    extendedWarrantyClaimCustomerAcceptedQuotation,
    extendedWarrantyClaimUploadDocument
} from "../actions/extendedWarrantyClaimActions";
import { withTranslation } from "react-i18next";
import { customerAddressFields } from "../utils/fields";
import ExtendedWarrantyDetailsView from "../components/extendedWarranty/ExtendedWarrantyDetailsView";
import { getSubscriptionWithPartnerAdditionalDetails } from "../actions/subscriptionActions";
import { convertToDimensional, getPropertyByString, validateDisableAuthority } from "../utils/utils";
import { getCustomerDetails, addCustomerAddress } from "../actions/customerActions";
import { showError } from "../actions/commonActions";
import { AUTHORITY_PARTNER_AGENT } from "../constant";

class ExtendedWarrantyDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {
            saveDisabled: false
        }
    }

    componentDidMount() {
        this.props.getExtendedWarrantyClaimDetails(this.props.match.params.id).then(() => {
            this.props.getExtendedWarrantyClaimServiceLimit(this.props.extendedWarrantyClaims.extendedWarrantyClaim.subscriptionId,
                this.props.extendedWarrantyClaims.extendedWarrantyClaim.id, this.props.extendedWarrantyClaims.extendedWarrantyClaim.quoteCurrencyCode);
            this.props.getSubscriptionWithPartnerAdditionalDetails(this.props.extendedWarrantyClaims.extendedWarrantyClaim.subscriptionId);
            this.props.getCustomerDetails(this.props.extendedWarrantyClaims.extendedWarrantyClaim.customerId);

            this.props.extendedWarrantyClaims.extendedWarrantyClaim.pickUpDetails.selectableAddresses = this.props.extendedWarrantyClaims.extendedWarrantyClaim.pickUpDetails?.addresses
            this.props.extendedWarrantyClaims.extendedWarrantyClaim.deliveryDetails.selectableAddresses = this.props.extendedWarrantyClaims.extendedWarrantyClaim.deliveryDetails?.addresses
        })
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.common.refresh !== prevProps.common.refresh && this.props.common.refresh
        ) {
            window.setTimeout(() => { window.location.reload(true); }, 2000)
        }
        
        if (this.props.subscriptions.subscriptionWithPartnerAdditionalDetails !== prevProps.subscriptions.subscriptionWithPartnerAdditionalDetails) {
            this.props.getSupportedBrandBusinessEntities(this.props.subscriptions.subscriptionWithPartnerAdditionalDetails.device.sku, this.props.extendedWarrantyClaims.extendedWarrantyClaim.countryCode)
        }
    }

    updateExtendedWarrantyClaim = (values) => {
        this.formatRequestPayload(values);
        this.props.updateExtendedWarrantyClaim(this.props.match.params.id, values);
    }

    updateExtendedWarrantyClaimStatus = ({ values, status, isSaveRequired }) => {
        this.formatRequestPayload(values);
        this.props.saveAndUpdateExtendedWarrantyClaimStatus(this.props.match.params.id, values, status, isSaveRequired);
    }

    saveAndInitiateExtendedWarrantyClaim = (values) => {
        this.formatRequestPayload(values)
        const initiatePayload = {
            extendedWarrantyClaim: values
        }
        this.props.saveAndInitiateExtendedWarrantyClaim(this.props.match.params.id, values, initiatePayload)
    }

    updateCustomerAddress = (values) => {
        var addressSize = this.props.customers.customer.contact.addresses == null || this.props.customers.customer.contact.addressesr == [] ? 0 : this.props.customers.customer.contact.addresses.length;

        var extendedWarrantyClaim = this.props.extendedWarrantyClaims.extendedWarrantyClaim;
        extendedWarrantyClaim.pickUpDetails.selectableAddresses = extendedWarrantyClaim.pickUpDetails.selectableAddresses ? extendedWarrantyClaim.pickUpDetails.selectableAddresses : [];
        extendedWarrantyClaim.deliveryDetails.selectableAddresses = extendedWarrantyClaim.deliveryDetails.selectableAddresses ? extendedWarrantyClaim.deliveryDetails.selectableAddresses : [];

        extendedWarrantyClaim.pickUpDetails.selectableAddresses.push(values);
        extendedWarrantyClaim.deliveryDetails.selectableAddresses.push(values);

        var data = [{
            'op': 'add',
            'path': `/contact/addresses/${addressSize} `,
            'value': values
        }]
        this.props.addCustomerAddress(extendedWarrantyClaim.customerId, data, false)
    }

    encodeFileAsURL = ({filesSelected, acceptedFileTypes, documentType}) => {
        if (filesSelected.length > 0) {
            if (acceptedFileTypes.includes(filesSelected[0].type)) {
                var fileToLoad = filesSelected[0];
                var mimeType = fileToLoad.type;
                var filename = fileToLoad.name;
                var fileReader = new FileReader();

                fileReader.onload = (fileLoadedEvent) => {
                    var base64EncryptedData = fileLoadedEvent.target.result;
                    this.uploadFile(mimeType, filename, base64EncryptedData, documentType);
                }
                fileReader.readAsDataURL(fileToLoad);
            } else {
                this.props.showError({ response: { data: { translationKey: `This file type is not supported.` } } });
                return false;
            }
        }
    }

    uploadFile = (mimeType, filename, base64EncryptedData, documentType) => {
        var fileData = {
            mimeType: mimeType,
            filename: filename,
            base64EncryptedData: btoa(base64EncryptedData)
        };

        this.props.extendedWarrantyClaimUploadDocument(this.props.match.params.id, documentType, fileData)
    }

    formatRequestPayload = (payload) => {
        if (payload.quotation.quotationDate) payload.quotation.quotationDate = new Date(payload.quotation.quotationDate).toISOString().replace('Z', '+0000');
        if (payload.invoice.invoiceDate) payload.invoice.invoiceDate = new Date(payload.invoice?.invoiceDate).toISOString().replace('Z', '+0000');
        this.insertCurrencyCode(payload);
    }

    insertCurrencyCode = (payload) => {
        const fields = [
            'quotation.ewAmount',
            'quotation.adldAmount',
            'quotation.replacementAmount',
            'quotation.inspectionFeeAmount',
            'quotation.otherAmount',
            'quotation.totalQuotationAmount',
            'quotation.approvedEwAmount',
            'quotation.approvedAdldAmount',
            'quotation.approvedReplacementAmount',
            'quotation.approvedInspectionFeeAmount',
            'quotation.approvedOtherAmount',
            'quotation.customerPayableAmount',
            'quotation.totalApprovedQuotationAmount',
            'invoice.totalInvoiceAmount',
            'invoice.ewAmount',
            'invoice.inspectionFeeAmount',
            'invoice.otherAmount',
            'invoice.adldAmount',
            'invoice.replacementAmount',
            'invoice.totalApprovedAmount',
            'invoice.totalRepairInvoiceAmount',
            'invoice.approvedEwAmount',
            'invoice.approvedInspectionFeeAmount',
            'invoice.approvedOtherAmount',
            'invoice.approvedAdldAmount',
            'invoice.approvedReplacementAmount',
            'invoice.customerPayableAmount'
        ]

        fields.map((field) => {
            if(getPropertyByString(payload, `${field}.amount`) != null) {
                payload[`${field}.currencyCode`] = this.props.extendedWarrantyClaims.extendedWarrantyClaim.quoteCurrencyCode
            }
        })

        convertToDimensional(payload)
    }

    renderDetails = (extendedWarrantyClaim, countryCode, serviceLimit, subscription, businessEntities) => {

        const formFields = [
            'quotation.serviceType',
            'quotation.replacement',
            'quotation.quotationDate',
            'quotation.ewAmount.amount',
            'quotation.adldAmount.amount',
            'quotation.replacementAmount.amount',
            'quotation.inspectionFeeAmount.amount',
            'quotation.otherAmount.amount',
            'quotation.totalQuotationAmount.amount',
            'quotation.approvedEwAmount.amount',
            'quotation.approvedAdldAmount.amount',
            'quotation.approvedReplacementAmount.amount',
            'quotation.approvedInspectionFeeAmount.amount',
            'quotation.approvedOtherAmount.amount',
            'quotation.customerPayableAmount.amount',
            'quotation.totalApprovedQuotationAmount.amount',
            'invoice.invoiceDate',
            'invoice.totalInvoiceAmount.amount',
            'invoice.ewAmount.amount',
            'invoice.inspectionFeeAmount.amount',
            'invoice.otherAmount.amount',
            'invoice.adldAmount.amount',
            'invoice.replacementAmount.amount',
            'invoice.totalApprovedAmount.amount',
            'invoice.totalRepairInvoiceAmount.amount',
            'invoice.approvedEwAmount.amount',
            'invoice.approvedInspectionFeeAmount.amount',
            'invoice.approvedOtherAmount.amount',
            'invoice.approvedAdldAmount.amount',
            'invoice.approvedReplacementAmount.amount',
            'invoice.customerPayableAmount.amount',
            'businessEntityCode',
            'pickUpDetails.lastName',
            'pickUpDetails.firstName',
            'pickUpDetails.dateOfBirth',
            'pickUpDetails.gender',
            'pickUpDetails.phoneNumber',
            'pickUpDetails.email',
            'pickUpDetails.ids',
            'pickUpDetails.optIns',
            'pickUpDetails.addresses',
            'deliveryDetails.lastName',
            'deliveryDetails.firstName',
            'deliveryDetails.dateOfBirth',
            'deliveryDetails.gender',
            'deliveryDetails.phoneNumber',
            'deliveryDetails.email',
            'deliveryDetails.ids',
            'deliveryDetails.optIns',
            'deliveryDetails.addresses'
        ];

        const editableFields = convertToDimensional(formFields.map(formField => formField)
            .reduce((obj, key) => ({
                ...obj, [key]: getPropertyByString(extendedWarrantyClaim, key)
            }), {}));
        

        if (serviceLimit.details?.extendedWarranty?.frequencyRemaining <= 0 || serviceLimit.details?.extendedWarranty?.amountRemaining?.amount <= 0) {
            editableFields['invoice']['approvedEwAmount']['amount'] = 0;
        }

        if (serviceLimit.details?.otherCoverage?.frequencyRemaining <= 0 || serviceLimit.details?.otherCoverage?.amountRemaining?.amount <= 0) {
            editableFields['invoice']['approvedOtherAmount']['amount'] = 0;
        }

        if (serviceLimit.details?.accidentalDamageAndLiquidDamage?.frequencyRemaining <= 0 || serviceLimit.details?.accidentalDamageAndLiquidDamage?.amountRemaining?.amount <= 0) {
            editableFields['invoice']['approvedAdldAmount']['amount'] = 0;
        }

        if (serviceLimit.details?.replacement?.frequencyRemaining <= 0 || serviceLimit.details?.replacement?.amountRemaining?.amount <= 0) {
            editableFields['invoice']['approvedReplacementAmount']['amount'] = 0;
        }


        const addressFields = customerAddressFields[extendedWarrantyClaim.countryCode];

        const editableNewAddressFields = addressFields?.map(formField => formField)
            .reduce((obj, key) => ({
                ...obj, [key]: ''
            }), {});

        if (editableNewAddressFields != null) {
            editableNewAddressFields["countryCode"] = extendedWarrantyClaim?.countryCode;
            editableNewAddressFields["type"] = "billingSubscription";
        }

        return (
            <ExtendedWarrantyDetailsView extendedWarrantyClaim={extendedWarrantyClaim} serviceLimit={serviceLimit} editableFields={editableFields}
                subscription={subscription} onExtendedWarrantyClaimFormSubmit={values => this.updateExtendedWarrantyClaim(values)}
                setSaveDisabled={(value) => this.setState({ saveDisabled: value })} businessEntities={businessEntities}
                onExtendedWarrantyClaimUpdateStatus={payload => this.updateExtendedWarrantyClaimStatus(payload)}
                onInitiateExtendedWarrantyClaim={data => this.saveAndInitiateExtendedWarrantyClaim(data)}
                onAddressFormSubmit={values => this.updateCustomerAddress(values)} editableNewAddressFields={editableNewAddressFields}
                encodeFileAsURL={payload => this.encodeFileAsURL(payload)}
                userAuthorities={this.props.authentication.loggedInUser.authorities}> 
            </ExtendedWarrantyDetailsView>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <PageTemplate title="Extended Warranty Claim Details" saveForm="extendedWarrantyClaimForm" saveDisabled={validateDisableAuthority(AUTHORITY_PARTNER_AGENT, this.props.authentication.loggedInUser.authorities) ? this.state.saveDisabled : true}>
                {
                    this.renderDetails(this.props.extendedWarrantyClaims.extendedWarrantyClaim, this.props.authentication.loggedInUser.countryCode,
                        this.props.extendedWarrantyClaims.serviceLimit, this.props.subscriptions.subscriptionWithPartnerAdditionalDetails,
                        this.props.businessEntities.businessEntities)

                }
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    businessEntities: state.businessEntities,
    customers: state.customers,
    extendedWarrantyClaims: state.extendedWarrantyClaims,
    subscriptions: state.subscriptions,
    common: state.common
})

export default connect(mapStateToProps, {
    getExtendedWarrantyClaimDetails,
    getExtendedWarrantyClaimServiceLimit,
    getSubscriptionWithPartnerAdditionalDetails,
    getBusinessEntityDetails,
    updateExtendedWarrantyClaim,
    getSupportedBrandBusinessEntities,
    saveAndUpdateExtendedWarrantyClaimStatus,
    saveAndInitiateExtendedWarrantyClaim,
    extendedWarrantyClaimCustomerAcceptedQuotation,
    getCustomerDetails,
    addCustomerAddress,
    showError,
    extendedWarrantyClaimUploadDocument
})(withTranslation()(ExtendedWarrantyDetails));