import Axios from "axios";
import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    LOG_IN,
    LOG_OUT,
    ERROR,
    SET_RESET_PASSWORD_TOKEN,
    REQUEST_START,
    REQUEST_SUCCESS, REQUEST_FAILURE, OTP_GET,
} from "./types";

const handleError = (error, dispatch) => {
    dispatch({
        type: ERROR,
        payload: error.response
    })
}

export const logIn = (data) => async dispatch => {
    Axios({
        method: 'POST',
        data: data,
        headers: {
            'Content-type': 'application/json'
        },
        url: `${BASE_URL}/v1/merchantUsers/authenticate`
    }).then(response => {
        dispatch({
            type: LOG_IN,
            payload: response
        })
    }).catch(error => {
        handleError(error, dispatch);
    })
}

export const logOut = () => {
    return({
        type: LOG_OUT
    })
}

export const sendForgotPasswordEmail = (data) => async dispatch => {
    dispatch({
        type: REQUEST_START
    })
    Axios({
        method: 'POST',
        data: data,
        headers: {
            'Content-type': 'application/json'
        },
        url: `${BASE_URL}/v1/merchantUsers/forgotPassword`
    }).then(() => {
        dispatch({
            type: REQUEST_SUCCESS
        })
    }).catch(error => {
        dispatch({
            type: REQUEST_FAILURE
        })
        handleError(error, dispatch);
    })
}

export const setResetPasswordToken = (token) => {
    return({
        type: SET_RESET_PASSWORD_TOKEN,
        payload: token
    })
}

export const getOtp = (data) => async dispatch => {
    Axios({
        method: 'POST',
        data: data,
        headers: {
            'Content-type': 'application/json'
        },
        url: `${BASE_URL}/v1/merchantUsers/forgotPasswordOtp`
    }).then(response => {
        dispatch({
            type: OTP_GET,
            payload: response
        })
    }).catch(error => {
        handleError(error, dispatch);
    })
}

export const submitForgotPasswordConfirmation = (data) => async  dispatch => {
    const {newPasswordConfirmation, ...sanitizedData} = data;
    dispatch({
        type: REQUEST_START
    })
    Axios({
        method: 'POST',
        data: sanitizedData,
        headers: {
            'Content-type': 'application/json'
        },
        url: `${BASE_URL}/v1/merchantUsers/forgotPasswordConfirmation`
    }).then(() => {
        dispatch({
            type: REQUEST_SUCCESS
        })
    }).catch(error => {
        dispatch({
            type: REQUEST_FAILURE
        })
        handleError(error, dispatch);
    })
}

export const changePassword = (data) => async dispatch => {
    const {newPasswordConfirmation, ...sanitizedData} = data;
    dispatch({
        type: REQUEST_START
    })
    axios.post(`${BASE_URL}/v1/merchantUsers/changePassword`, sanitizedData)
        .then(() => {
            dispatch({
                type: LOG_OUT
            })
            dispatch({
                type: REQUEST_SUCCESS
            })
        }).catch(error => {
            dispatch({
                type: REQUEST_FAILURE
            })
            handleError(error, dispatch);
        })
}

export const setNewPasswordRequest = (data) => async dispatch => {
    const {newPasswordConfirmation, ...sanitizedData} = data;
    dispatch({
        type: REQUEST_START
    })
    axios.post(`${BASE_URL}/v1/merchantUsers/setNewPassword`, sanitizedData)
        .then(() => {
            dispatch({
                type: LOG_OUT
            })
            dispatch({
                type: REQUEST_SUCCESS
            })
        }).catch(error => {
        dispatch({
            type: REQUEST_FAILURE
        })
        handleError(error, dispatch);
    })
}