import React, { useState } from "react";
import "../../styles/custom-bootstrap.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
    calculateDayDifference,
    fullDateWithIndicator,
    formatCurrency,
} from "../../utils/utils";
import ConfirmationDialogSimple from "../ConfirmationDialogSimple";

const SubscriptionInfo = (props) => {
    const { subscription, userCountryCode, userLanguageCode, isFromCustomerPage, isBlacklisted, onRequestExtendedWarrantyClaim } =
        props;
    const { t } = useTranslation();
    const customStyles = makeStyles({
        customBackground: {
            background: "#eceff1",
        },
    });
    const [isShowUsageFee, setIsShowUsageFee] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const planUsageTypes = [
        "switch",
        "replace",
        "repair",
        "screenRepair",
        "batteryReplacement"
    ]
    const localeCode = userLanguageCode + "_" + userCountryCode

    const getSubscriptionEndDate = (activationDate, durationInMonths, extendedDurationInMonths) => {
        const newDate = new Date(activationDate);

        let month = newDate.getMonth();
        let year = newDate.getFullYear();

        month = month + durationInMonths + extendedDurationInMonths;
        year += Math.floor(month / 12);
        month %= 12;

        newDate.setMonth(month);
        newDate.setFullYear(year);

        return newDate;
    }

    const getRemainingMonths = (activationDate, durationInMonths, extendedDurationInMonths) => {
        let endDate = getSubscriptionEndDate(activationDate, durationInMonths, extendedDurationInMonths);
        let currentDate = new Date();

        var timeDiff = Math.abs(endDate.getTime() - currentDate.getTime());
        return Math.trunc(timeDiff / (2e3 * 3600 * 365.25));
    }

    const handleFocus = (usageType) => {
        setIsShowUsageFee(usageType);
    };

    const handleBlur = () => {
        setIsShowUsageFee(null);
    };

    const handleRequestExtendedWarrantyClaim = () => {
        if (isBlacklisted) {
            setIsDialogOpen(true);
        } else {
            onRequestExtendedWarrantyClaim(subscription?.id);
        }
    }

    const handleDialogConfirm = () => {
        setIsDialogOpen(false);
        onRequestExtendedWarrantyClaim(subscription?.id);
    }

    const customStyleClass = customStyles();

    return (
        <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div>
                    <strong> {`Subscription id: ${subscription?.id}`}</strong>
                </div>
                <div>
                    {isFromCustomerPage && subscription?.plan?.extendedWarranty && (
                        <button className="float-right btn btn-sm btn-primary mr-2" onClick={handleRequestExtendedWarrantyClaim} >
                            <i className="fa fa-exchange" aria-hidden="true"></i>
                            {" Request EW Claim"}
                        </button>
                    )}
                </div>

            </div>
            <div className="card-block">
                <div className="card-body">
                    <div>
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label>Status</label>
                                    <div>
                                        <label className="font-weight-bold">{subscription?.status}</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Manually Verified</label>
                                    <div>
                                        <label className="font-weight-bold">{subscription?.manuallyVerified}</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Swap Restricted</label>
                                    <div>
                                        <label className="font-weight-bold">{subscription?.swapRestricted}</label>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Plan</label>
                                    <div>
                                        <label className="font-weight-bold">{subscription?.plan?.description["it_IT"]}</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Carrier Code</label><br></br>
                                            <label className="font-weight-bold">{subscription?.carrierCode}</label>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label>Brand</label><br></br>
                                            <label className="font-weight-bold">{subscription?.brand}</label>
                                        </div>
                                    </div>

                                </div>
                                <div className="form-group">
                                    <label>Shop Code</label>
                                    <div>
                                        <label className="font-weight-bold">{subscription?.shopCode}</label>

                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Agent Display Name</label>
                                    <div>
                                        <label className="font-weight-bold">{subscription?.agent?.displayName}</label>

                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>Activation Date</label>
                                    <div>
                                        <label className="font-weight-bold">{fullDateWithIndicator(subscription?.activationDate, userCountryCode)}</label>

                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>Status History</label>
                                    <div className="form-group">
                                        <table className="table table-sm table-striped">
                                            <thead>
                                                <tr>
                                                    <th>{t(`Status`)}</th>
                                                    <th>{t(`Created At`)}</th>
                                                    <th>{t(`Reason`)}</th>
                                                    <th>{t(`Days since Activation Date`)}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    subscription?.history?.map((history) => {
                                                        return (
                                                            <tr>
                                                                <td>{history.status}</td>
                                                                <td>{fullDateWithIndicator(history.createdAt, userCountryCode)}</td>
                                                                <td>{history.reason}</td>
                                                                <td>{subscription?.activationDate != null && history?.createdAt != null ?
                                                                    calculateDayDifference(subscription?.activationDate, history?.createdAt)
                                                                    : ''}</td>
                                                            </tr>

                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <div>
                                        <label>{subscription?.phoneNumber}</label>
                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>IMEI/SN</label>
                                    <div>
                                        <label className="font-weight-bold">{subscription?.device?.imei}</label>

                                    </div>
                                </div>

                                <div className="form-group">
                                    <label>Suggested Retail Price</label>
                                    <div>
                                        <label className="font-weight-bold">{`(${subscription?.device?.suggestedRetailPrice?.currencyCode})${subscription?.device?.suggestedRetailPrice?.amount} `}</label>

                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Purchase Price</label>
                                    <div>
                                        <label className="font-weight-bold">{`(${subscription?.device?.purchasePrice?.currencyCode})${subscription?.device?.purchasePrice?.amount} `}</label>
                                    </div>
                                </div>
                                {subscription?.availableSwaps != null && (
                                    <div className="form-group">
                                        <label>Available Swaps</label>
                                        <div className="form-group">
                                            <table className="table table-sm table-striped">
                                                <thead>

                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <th>{t(`Quantity`)}</th>
                                                        <td>{subscription?.availableSwaps?.numberOfAvailableSwaps}</td>
                                                        {subscription.availableSwaps?.numberOfAvailableSwaps != null && subscription?.availableSwaps?.numberOfAvailableSwaps > 0 ? (
                                                            <td><label class="badge badge-success mb-0"> Available</label></td>
                                                        ) : (
                                                            <td><label class="badge badge-success mb-0">Not Available</label></td>
                                                        )
                                                        }

                                                    </tr>
                                                    <tr>
                                                        <th>{t(`Switch`)}</th>
                                                        <td>{subscription.availableSwaps?.numberOfAvailableSwitches}</td>
                                                        {subscription.availableSwaps?.numberOfAvailableSwitches != null && subscription.availableSwaps?.numberOfAvailableSwitches > 0 ? (
                                                            <td><label class="badge badge-success mb-0"> Available</label></td>
                                                        ) : (
                                                            <td><label class="badge badge-success mb-0">Not Available</label></td>
                                                        )
                                                        }

                                                    </tr>
                                                    <tr>
                                                        <th>{t(`Replace`)}</th>
                                                        <td>{subscription.availableSwaps?.numberOfAvailableReplaces}</td>
                                                        {subscription.availableSwaps?.numberOfAvailableReplaces != null && subscription.availableSwaps?.numberOfAvailableReplaces > 0 ? (
                                                            <td><label class="badge badge-success mb-0"> Available</label></td>
                                                        ) : (
                                                            <td><label class="badge badge-success mb-0">Not Available</label></td>
                                                        )
                                                        }

                                                    </tr>
                                                    <tr>
                                                        <th>{t(`Repair`)}</th>
                                                        <td>{subscription.availableSwaps?.numberOfAvailableRepairs}</td>
                                                        {subscription.availableSwaps?.numberOfAvailableRepairs != null && subscription.availableSwaps?.numberOfAvailableRepairs > 0 ? (
                                                            <td><label class="badge badge-success mb-0"> Available</label></td>
                                                        ) : (
                                                            <td><label class="badge badge-success mb-0">Not Available</label></td>
                                                        )
                                                        }

                                                    </tr>
                                                    <tr>
                                                        <th>{t(`Screen Repair`)}</th>
                                                        <td>{subscription.availableSwaps?.numberOfAvailableScreenRepairs}</td>
                                                        {subscription.availableSwaps?.numberOfAvailableScreenRepairs != null && subscription.availableSwaps?.numberOfAvailableScreenRepairs > 0 ? (
                                                            <td><label class="badge badge-success mb-0"> Available</label></td>
                                                        ) : (
                                                            <td><label class="badge badge-success mb-0">Not Available</label></td>
                                                        )
                                                        }

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>

                                )

                                }

                                {!subscription?.plan?.calculateFeeByReplacementDevice && subscription?.status == "active" && subscription?.calculateUsageFees != null && planUsageTypes.map(planUsageType => {
                                    return (planUsageType in subscription?.calculateUsageFees) && (
                                        <div class="form-group" >
                                            <label>{planUsageType}</label><br />
                                            <label>Total Fee:</label>
                                            {' '}
                                            <label>{subscription?.calculateUsageFees[planUsageType]?.totalFee?.currencyCode}</label>
                                            {' '}
                                            <label>{formatCurrency(subscription?.calculateUsageFees[planUsageType]?.totalFee?.amount)}</label>
                                            <div style={{ position: 'relative', display: 'initial' }}>
                                                <button onFocus={() => handleFocus(planUsageType)} onBlur={handleBlur} class="btn btn-sm btn-link">
                                                    <i class="fa fa-question-circle" aria-hidden="true"></i>
                                                </button>
                                                {isShowUsageFee === planUsageType && (
                                                    <div class="card" style={{ position: 'absolute', top: '0', left: '100%', width: 'max-content', boxShadow: '0 4px 6px rgba(50,50,93,.11), 0 1px 3px rgba(0,0,0,.08)', zIndex: '1' }}>
                                                        <table class="table table-striped mb-0">
                                                            <tbody>
                                                                <tr>
                                                                    <td>{planUsageType} Fee</td>
                                                                    <td>{formatCurrency(subscription?.calculateUsageFees[planUsageType]?.usageFee?.amount)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>VAT</td>
                                                                    <td>{formatCurrency(subscription?.calculateUsageFees[planUsageType]?.vat?.amount)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Deposit Fee</td>
                                                                    <td>{formatCurrency(subscription?.calculateUsageFees[planUsageType]?.depositFee?.amount)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Delivery Fee</td>
                                                                    <td>{formatCurrency(subscription?.calculateUsageFees[planUsageType]?.deliveryFee?.amount)}</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>Total Fee</td>
                                                                    <td>{formatCurrency(subscription?.calculateUsageFees[planUsageType]?.totalFee?.amount)}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    )
                                })}

                                {subscription?.plan?.extendedWarranty && (
                                    <div class="form-group" >
                                        <label>Service Limit</label>
                                        <div>
                                            <table class="table table-striped">
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th class="text-right">Currency</th>
                                                        <th class="text-right">Amount</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            EW Claims Frequency
                                                        </td>
                                                        <td class="text-right">
                                                        </td>
                                                        <td class="text-right">
                                                            {subscription?.serviceLimitFee?.details?.extendedWarranty?.frequencyRemaining}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            EW Claim Amount Limit
                                                        </td>
                                                        <td class="text-right">
                                                            {subscription?.serviceLimitFee?.details?.extendedWarranty?.amountRemaining?.currencyCode}
                                                        </td>
                                                        <td class="text-right">
                                                            {formatCurrency(subscription?.serviceLimitFee?.details?.extendedWarranty?.amountRemaining?.amount)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            ADLD Frequency
                                                        </td>
                                                        <td class="text-right">
                                                        </td>
                                                        <td class="text-right">
                                                            {subscription?.serviceLimitFee?.details?.accidentalDamageAndLiquidDamage?.frequencyRemaining}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            ADLD Amount Limit
                                                        </td>
                                                        <td class="text-right">
                                                            {subscription?.serviceLimitFee?.details?.accidentalDamageAndLiquidDamage?.amountLimit?.currencyCode}
                                                        </td>
                                                        <td class="text-right">
                                                            {formatCurrency(subscription?.serviceLimitFee?.details?.accidentalDamageAndLiquidDamage?.amountLimit?.amount)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Replacement Frequency
                                                        </td>
                                                        <td class="text-right">
                                                        </td>
                                                        <td class="text-right">
                                                            {subscription?.serviceLimitFee?.details?.replacement?.frequencyRemaining}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Replacement Amount Limit
                                                        </td>
                                                        <td class="text-right">
                                                            {subscription?.serviceLimitFee?.details?.replacement?.amountLimit?.currencyCode}
                                                        </td>
                                                        <td class="text-right">
                                                            {formatCurrency(subscription?.serviceLimitFee?.details?.replacement?.amountLimit?.amount)}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Other Coverage Frequency
                                                        </td>
                                                        <td class="text-right">
                                                        </td>
                                                        <td class="text-right">
                                                            {subscription?.serviceLimitFee?.details?.otherCoverage?.frequencyRemaining}
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            Other Coverage Amount Limit
                                                        </td>
                                                        <td class="text-right">
                                                            {subscription?.serviceLimitFee?.details?.otherCoverage?.amountLimit?.currencyCode}
                                                        </td>
                                                        <td class="text-right">
                                                            {formatCurrency(subscription?.serviceLimitFee?.details?.otherCoverage?.amountLimit?.amount)}
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                )}

                                <div className="form-group">
                                    <label>Purchase Date</label>
                                    <div>
                                        <label className="font-weight-bold">{fullDateWithIndicator(subscription?.device?.purchaseDate, userCountryCode)}</label>
                                    </div>
                                </div>

                                {subscription?.partialRefund && subscription?.simplifiedBillRefundResponse?.isRefundRequired && (
                                    <div className="form-group">
                                        <label>Partial Refund</label>
                                        <div>
                                            <label className="font-weight-bold">{`Total Fee: ${subscription?.simplifiedBillRefundResponse?.refundAmount?.currencyCode} ${subscription?.simplifiedBillRefundResponse?.refundAmount?.amount}`}</label>
                                        </div>
                                    </div>
                                )}

                                {subscription?.displayExtendedDurationStatus && (
                                    <div className="form-group">
                                        <label>Extended Duration</label>
                                        <div>
                                            <label className="font-weight-bold">{subscription?.durationExtended.toString()}</label>
                                        </div>
                                    </div>
                                )}

                                {subscription?.termination && subscription?.allowPendingTerminationStatusAndPenaltyFee && (
                                    <div className="form-group">
                                        <label>Penalty Fee For Early Termination</label>
                                        <div>
                                            <label className="font-weight-bold">{`Total Fee: ${subscription?.subscriptionPenaltyFeeResponse?.penaltyFee?.currencyCode} ${subscription?.subscriptionPenaltyFeeResponse?.penaltyFee?.amount}`}</label>
                                        </div>
                                    </div>
                                )}

                                {subscription?.subscriptionPartnerAdditionalDetails != null && (
                                    <div>
                                        {subscription?.subscriptionPartnerAdditionalDetails['utmSource'] != null && (
                                            <div class="form-group">
                                                <label>Partner additional details utmSource</label><br />
                                                <label>{subscription?.subscriptionPartnerAdditionalDetails['utmSource']}</label>
                                            </div>
                                        )}
                                        {subscription?.plan?.extendedWarranty && (
                                            <div>
                                                <div class="form-group" >
                                                    <label>Partner additional details device make</label><br />
                                                    {subscription?.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails['deviceMake'] != null &&
                                                        <label class="font-weight-bold">{subscription?.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails['deviceMake']}</label>
                                                    }
                                                </div>
                                                <div class="form-group" >
                                                    <label>Partner additional details device model</label><br />
                                                    {subscription?.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails['deviceModel'] != null &&
                                                        <label class="font-weight-bold">{subscription?.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails['deviceModel']}</label>
                                                    }
                                                </div>
                                                <div class="form-group" >
                                                    <label>Partner additional details external Id</label><br />
                                                    {subscription?.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails['externalId'] != null &&
                                                        <label class="font-weight-bold">{subscription?.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails['externalId']}</label>
                                                    }
                                                </div>
                                                <div class="form-group" >
                                                    <label>Partner additional details external plan description</label><br />
                                                    {subscription?.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails['externalPlanDescription'] != null &&
                                                        <label class="font-weight-bold">{subscription?.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails['externalPlanDescription']}</label>
                                                    }
                                                </div>
                                                <div class="form-group">
                                                    <label>Partner additional details ew cost</label><br />
                                                    {subscription?.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails['ewCost'] != null &&
                                                        <label class="font-weight-bold">{subscription?.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails['ewCost']}</label>
                                                    }
                                                </div>
                                                <div class="form-group" >
                                                    <label>Partner additional details external remarks</label><br />
                                                    {subscription?.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails['externalRemarks'] != null &&
                                                        <label class="font-weight-bold">{subscription?.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails['externalRemarks']}</label>
                                                    }
                                                </div>
                                            </div>
                                        )}
                                        <div class="form-group" >
                                            <label>Partner additional details plan details</label><br />
                                            {subscription?.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails['externalPlanDetails'] != null &&
                                                <label class="font-weight-bold">{subscription?.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails['externalPlanDetails']}</label>
                                            }
                                        </div>

                                    </div>
                                )}
                            </div>

                            <div className="col-sm-4 text-center">
                                <label>Manufacturer Name:</label>
                                {' '}
                                <label>{subscription?.article?.manufacturer?.name[localeCode]}</label>
                                <br />
                                <label>Series:</label>
                                {' '}
                                <label>{subscription?.article?.attributes?.series?.value[localeCode]}</label>
                                <br />
                                <label>Storage Size:</label>
                                {' '}
                                <label>{subscription?.article?.attributes?.storageSize?.value}</label>
                                <br />
                                <label>Color:</label>
                                {' '}
                                <label>{subscription?.article?.attributes?.color?.value[localeCode]}</label>
                                <br />
                                <label>Network Technology:</label>
                                {' '}
                                <label>{subscription?.article?.attributes?.networkTechnology?.value}</label>
                                <br />
                                <label>Model Number:</label>
                                {' '}
                                <label>{subscription?.article?.attributes?.modelNumber?.value[userCountryCode]}</label>
                                <br />
                                <label>SKU:</label>
                                {' '}
                                <label>{subscription?.article?.sku}</label>
                                <br />
                                {subscription?.device?.itemDetails != null && (
                                    <div class="card d-block pt-2">
                                        <div style={{ display: 'inline-block' }}>
                                            <label>Manufacturer Serial Number:</label>
                                            {' '}
                                            <label>{subscription?.device?.itemDetails?.manufacturerSerialNumber}</label>
                                            <br />
                                            <label>Manufacturer Id:</label>
                                            {' '}
                                            <label>{subscription?.device?.itemDetails?.manufacturerId}</label>
                                            <br />
                                            <label>Color:</label>
                                            {' '}
                                            <label>{subscription?.device?.itemDetails?.color}</label>
                                            <br />
                                            <label>Storage Size:</label>
                                            {' '}
                                            <label>{subscription?.device?.itemDetails?.storageSize}</label>
                                        </div>
                                    </div>
                                )}
                                {subscription?.endOfLifeConfiguration && (
                                    <label style={{ color: "red" }}>The device is no longer supported, therefore, Like-for-Like devices will be supplemented with similar versions</label>
                                )}
                                <div class="form-group">
                                    <label>Subscribed Device History</label>
                                    <table class="table table-striped table-sm">
                                        <thead>
                                            <tr>
                                                <th>SKU</th>
                                                <th>Model Number</th>
                                                <th>Color</th>
                                                <th>Stock Location</th>
                                                <th>Update Reason</th>
                                                <th>Created At</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {subscription?.deviceHistory?.map((deviceHistory) => {
                                                return (
                                                    <tr>
                                                        <td>{deviceHistory?.device?.sku}</td>
                                                        <td>{deviceHistory?.article?.attributes?.modelNumber?.value[userCountryCode]}</td>
                                                        <td>{deviceHistory?.article?.attributes?.color?.value[localeCode]}</td>
                                                        <td>{deviceHistory?.device?.stockLocation}</td>
                                                        <td>{deviceHistory?.reason}</td>
                                                        <td>{fullDateWithIndicator(deviceHistory?.createdAt, userCountryCode)}</td>
                                                    </tr>
                                                )
                                            })

                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                        {subscription?.adldEnabled && (
                            <>
                                <hr />
                                <div class="row">
                                    <div class="col-sm-12">
                                        <div class="form-group">
                                            <label>ADLD</label><br />
                                            <label class="font-weight-bold">{subscription?.subscriptionAdld?.status}</label>
                                        </div>
                                        <div class="form-group">
                                            <label>ADLD Request History</label>
                                            <table class="table table-striped table-sm">
                                                <thead>
                                                    <tr>
                                                        <th>Case Number</th>
                                                        <th>Partner Case Number</th>
                                                        <th>Partner Case Sequence</th>
                                                        <th>Request Date</th>
                                                        <th>Transaction Date</th>
                                                        <th>Transaction Type</th>
                                                        <th>Transaction Reason</th>
                                                        <th>Reimburse Amount</th>
                                                        <th>Total Repair Cost</th>
                                                        <th>Created At</th>
                                                        <th>Updated At</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {subscription?.subscriptionAdld?.requestHistory?.map((requestHistory) => {
                                                        return (
                                                            <tr>
                                                                <td>{requestHistory?.caseNumber}</td>
                                                                <td>{requestHistory?.partnerCaseNumber}</td>
                                                                <td>{requestHistory?.partnerCaseSequence}</td>
                                                                <td>{fullDateWithIndicator(requestHistory?.requestDate)}</td>
                                                                <td>{fullDateWithIndicator(requestHistory?.transactionDate)}</td>
                                                                <td>{requestHistory?.transactionType}</td>
                                                                <td>{requestHistory?.transactionReason}</td>
                                                                <td>{requestHistory?.reimburseAmount?.amount == null ? '' : `${requestHistory?.reimburseAmount?.currencyCode} ${formatCurrency(requestHistory?.reimburseAmount?.amount)}`}</td>
                                                                <td>{requestHistory?.totalRepairCost?.amount == null ? '' : `${requestHistory?.totalRepairCost?.currencyCode} ${formatCurrency(requestHistory?.totalRepairCost?.amount)}`}</td>
                                                                <td>{fullDateWithIndicator(requestHistory?.createdAt, userCountryCode)}</td>
                                                                <td>{fullDateWithIndicator(requestHistory?.updatedAt, userCountryCode)}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </>
                        )}
                        <hr />
                        <div className="row">
                            <div className="col-sm-4">
                                <div className="form-group">
                                    <label>Net Subscription Fee</label>
                                    <div>
                                        <label>{`(${subscription?.plan?.netSubscriptionFee?.currencyCode}) ${subscription?.plan?.netSubscriptionFee?.amount} ${subscription?.plan?.subscriptionFeeType}`}</label>
                                    </div>
                                </div>
                            </div>

                            {subscription?.activationDate != null &&
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Subscription End Date</label>
                                        <div>
                                            <label>{fullDateWithIndicator((getSubscriptionEndDate(subscription?.activationDate, subscription?.plan?.durationInMonths, 0)).toISOString(), userCountryCode)}</label>

                                        </div>
                                    </div>
                                </div>
                            }

                            {subscription?.status == 'active' &&
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Number of Remaining Months</label>
                                        <div>
                                            <label>{getRemainingMonths(subscription?.activationDate, subscription?.plan?.durationInMonths, subscription?.durationExtended && subscription?.plan?.extendedDurationInMonths != null ? subscription?.plan?.extendedDurationInMonths : 0)}</label>

                                        </div>
                                    </div>
                                </div>
                            }

                            {subscription?.activationDate != null &&
                                <div className="col-sm-4">
                                    <div className="form-group">
                                        <label>Subscription End Date (Extended)</label>
                                        <div>
                                            <label>{!subscription?.plan?.extendedWarranty ? "null" : fullDateWithIndicator((getSubscriptionEndDate(subscription?.activationDate, subscription?.plan?.durationInMonths, subscription?.plan?.extendedDurationInMonths != null ? subscription?.plan?.extendedDurationInMonths : 0)).toISOString(), userCountryCode)}</label>
                                        </div>
                                    </div>
                                </div>
                            }

                            {subscription?.displayTriggerEventDurationEnabled &&
                                <>
                                    <div class="col-sm-4" >
                                        <div class="form-group">
                                            <label>Calculated Cancellation Date</label><br />
                                            <label>{fullDateWithIndicator(subscription?.subscriptionTriggerEventCancellation?.actionAt, userCountryCode)}</label>
                                        </div>
                                    </div>
                                    <div class="col-sm-4" >
                                        <div class="form-group">
                                            <label>Calculated Termination Date</label><br />
                                            <label>{fullDateWithIndicator(subscription?.subscriptionTriggerEventTermination?.actionAt, userCountryCode)}</label>
                                        </div>
                                    </div>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <ConfirmationDialogSimple onAccept={handleDialogConfirm}
                onReject={() => setIsDialogOpen(false)}
                open={isDialogOpen}
                action="blacklisted"
                entity="user"
            />
        </div>
    );
};

export default SubscriptionInfo;
