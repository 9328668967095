import React, { useState } from "react";
import "../../styles/custom-bootstrap.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, Grid, InputLabel, MenuItem, NativeSelect, Select, TextField, Typography } from "@material-ui/core";
import { Formik, useFormikContext, getIn } from "formik";
import { customerAddressFields } from "../../utils/fields";

const ContactDetailsAddress = (props) => {
  const {
    contact,
    onAddressFormSubmit,
    editableFields,
    entity,
    countryCode,
    addAddressEnabled,
    selectAddressEnabled,
    sameAddressEnabled,
    isSameAddress
  } =
    props;

  const { t } = useTranslation();
  const [rowExpanded, setRowExpanded] = React.useState(false);

  const customStyles = makeStyles({
    inputField: {
      marginTop: 10,
      marginBottom: 10
    },
    selectField: {
      marginBottom: 10
    },
    card: {
      width: '100%'
    },
    customStyleForSelect: {
      color: '#f44336',
      fontSize: '0.75rem',
      marginTop: '3px',
      marginLeft: '14px',
      marginRight: '14px'
    },
    selectedCard: {
      border: "1px solid #170f4f !important"
    }
  })
  
  const [selectedAddressIndex, setSelectedAddressIndex] = useState(0)
  const [isSameAddressAsPickUp, setIsSameAddressAsPickUp] = useState(isSameAddress)

  const classes = customStyles();

  const formik = useFormikContext();

  const handleSameAddressChange = (value) => {    
    setIsSameAddressAsPickUp(value);
    customerAddressFields[countryCode]?.filter((addressField) => addressField !== "countryCode" && addressField !== "type").forEach(addressField => {
      if (value) {
        formik.setFieldValue(`deliveryDetails.addresses[0].${addressField}`, getIn(formik.values, `pickUpDetails.addresses[0].${addressField}`))
      } else {
        formik.setFieldValue(`deliveryDetails.addresses[0].${addressField}`, '')
      }
    })
  }

  const handleSelectAddress = (address, index) => {
    getIn(formik.values, `${entity}.addresses`).map((originalAddress, index) => {
      formik.setFieldValue(`${entity}.addresses[${index}]`, address)
    })
    setSelectedAddressIndex(index)
  }

  const handleInputChange = (event) => {
    setIsSameAddressAsPickUp(false);
    formik.handleChange(event);
  }


  return (
    contact != null && editableFields != null && (
      <>
        <div className="card">
          <div className="card-header">
            <span>Edit Addresses</span>
          </div>
          <div className="card-body">
            {
              getIn(formik.values, `${entity}.addresses`)?.map((address, index) => {
                return (
                  <div>
                    {
                      customerAddressFields[countryCode]?.filter((addressField) => addressField !== "countryCode" && addressField !== "type").map(addressField => {
                        return (
                          <TextField
                            key={`${entity}.addresses[${index}].${addressField}`}
                            label={t(`keys.address.${addressField}`)}
                            name={`${entity}.addresses[${index}].${addressField}`}
                            onChange={(e) => handleInputChange(e)}
                            value={getIn(formik.values, `${entity}.addresses[${index}].${addressField}`)}
                            autoComplete='off'
                            InputProps={{
                              disableUnderline: false,
                            }}
                            className={`${classes.inputField} margin-right-10 width-40`}
                          // value={formik.values['address'][addressField]}
                          // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                          // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                          />
                        )
                      })
                    }
                  </div>
                )
              })
            }
            {/* {
            customerAddressFields[countryCode]?.filter((addressField) => addressField !== "countryCode" && addressField !== "type").map(addressField => {
              return (
                <TextField
                  key={`address.${addressField}`}
                  label={t(`keys.address.${addressField}`)}
                  name={`${addressField}`}
                  onChange={formik.handleChange}
                  value={formik?.values?.[addressField]}
                  autoComplete='off'
                  InputProps={{
                    disableUnderline: false, 
                  }}
                  className={`${classes.inputField} margin-right-10 width-40`}
                // onChange={formik.handleChange}
                // value={formik.values['address'][addressField]}
                // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                />
              )
            })
          } */}
          </div>
          <Formik
            enableReinitialize={true}
            initialValues={editableFields}
            onSubmit={(values, { setSubmitting }) => {
              onAddressFormSubmit(values)
              setTimeout(function () {
                setSubmitting(false);
              }, 1000);
            }}
          >
            {(addressFormik) => {
              const {
                values,
                handleChange,
                handleSubmit,
                errors,
                touched,
                handleBlur,
                isValid,
                onSubmit,
                dirty
              } = addressFormik;
              return (
                <>
                  <div class="card-header">
                    <span >Saved Addresses</span>
                    {addAddressEnabled && (
                      < button
                        className="float-right btn btn-sm btn-primary"
                        style={rowExpanded ? { backgroundColor: 'red' } : { backgroundColor: '' }}
                        onClick={() => { setRowExpanded(!rowExpanded) }}
                        type="button"
                      >
                        {
                          !rowExpanded ? (
                            <>
                              <i class="fa fa-plus"></i>
                              <span> Add address</span>
                            </>
                          ) :
                            (
                              <>
                                <i class="fa fa-times"></i>
                                <span> Cancel</span>
                              </>
                            )
                        }
                      </button>
                    )}
                  </div >
                  <Accordion expanded={rowExpanded}>
                    <AccordionSummary style={{ display: 'none' }}>
                      <Typography>{t('messages.addRemark')}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <form id="addressForm" onSubmit={handleSubmit}>
                        <Grid container>
                          {
                            customerAddressFields[countryCode]?.map(addressField => {

                              switch (addressField) {
                                case "countryCode":
                                  return (
                                    <TextField
                                      disabled
                                      key={`address.${addressField}`}
                                      label={t(`keys.address.${addressField}`)}
                                      name={`${addressField}`}
                                      autoComplete='off'
                                      InputProps={{
                                        disableUnderline: false,
                                      }}
                                      onChange={addressFormik.handleChange}
                                      value={addressFormik.values?.countryCode}
                                      className={`${classes.inputField} margin-right-10 width-40`}
                                    />
                                  )
                                  break;
                                case "type":

                                  return (
                                    <div className="MuiFormControl-root MuiTextField-root makeStyles-inputField-60 margin-right-10 width-40">
                                      <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                      <NativeSelect
                                        defaultValue={'billingSubscription'}
                                        onChange={(e) => addressFormik.setFieldValue(addressField, e.target.value)}
                                        value={addressFormik.values?.[addressField]}
                                        inputProps={{
                                          name: 'type',
                                          id: 'uncontrolled-native',
                                        }}
                                      >
                                        <option value={'billingSubscription'}>Billing Address</option>
                                        <option value={'correspondence'}>Correspondence Address</option>
                                      </NativeSelect>

                                    </div>

                                  )
                                  break;

                                default:
                                  return (
                                    <TextField
                                      key={`address.${addressField}`}
                                      label={t(`keys.address.${addressField}`)}
                                      name={`${addressField}`}
                                      onChange={addressFormik.handleChange}
                                      value={addressFormik?.values?.[addressField]}
                                      autoComplete='off'
                                      InputProps={{
                                        disableUnderline: false, // <== added this
                                      }}
                                      className={`${classes.inputField} margin-right-10 width-40`}
                                    // onChange={formik.handleChange}
                                    // value={formik.values['address'][addressField]}
                                    // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                                    // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                                    />
                                  )
                                  break;
                              }
                            })
                          }
                          <Grid item xs={12}>
                            <div style={{ textAlign: 'right' }}>
                              <button
                                className="btn btn-sm btn-primary"
                                form="addressForm"
                                type="button"
                                onClick={() => addressFormik.submitForm()}
                              >
                                <i className="fa fa-plus"></i>
                                <span> Save</span>
                              </button>

                            </div>

                          </Grid>

                        </Grid>
                      </form>
                    </AccordionDetails>
                  </Accordion>
                  <div class="card-body">
                    {
                      contact?.selectableAddresses?.map((address, index) => {
                        return (
                          <div>
                            <label 
                            className={selectAddressEnabled && selectedAddressIndex == index ? classes.selectedCard : ''}
                            style={{
                              float: "left",
                              padding: "10px",
                              width: "calc(50% - 10px)",
                              margin: "5px",
                              border: "1px solid #ddd",
                              borderRadius: "5px",
                              cursor: selectAddressEnabled ? 'pointer' : 'default'
                            }} onClick={() => selectAddressEnabled ? handleSelectAddress(address, index) : null}>
                              <span>

                                {
                                  customerAddressFields[countryCode]?.filter((addressField) => addressField !== "countryCode" && addressField !== "type").map(customerAddressField => {
                                    return (
                                      <>
                                        <span>
                                          {address?.[customerAddressField]}
                                        </span><br></br>

                                      </>

                                    )
                                  }
                                  )

                                }
                              </span>
                            </label>
                          </div>
                        )
                      })
                    }
                  </div>
                </>
              );
            }}
          </Formik>
        </div >
        {sameAddressEnabled && (
          <div class="form-group">
            <label>Same as Pick up Address</label>
            <select value={isSameAddressAsPickUp} class="form-control" onChange={(event) => handleSameAddressChange(event.target.value == "true")}>
              <option value={true}>Yes</option>
              <option value={false}>No</option>
            </select>
          </div>
        )}
      </>
    )

  );
};

export default ContactDetailsAddress;
