import {
    SUBSCRIPTION_CLEAR_FILTER,
    SUBSCRIPTION_FILTER,
    SUBSCRIPTION_GET_DETAILS,
    SUBSCRIPTION_GET_LIST,
    SUBSCRIPTION_SORT,
    SUBSCRIPTION_GET_PARTNER_ADDITIONAL_DETAILS
} from "../actions/types";

const INITIAL_STATE = {
    subscription: {},
    subscriptions: [],
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    subscriptionsFilters: {
        _id: '',
        phoneNumber: '',
        'device.imei': '',
        carrierCode: '',
        brand: '',
        activationDate: {
            from: null,
            to: null
        },
        swapRestricted: [],
        status: [],
        planCode: '',
        shopCode: '',
        manuallyVerified: [],
        createdAt: {
            from: null,
            to: null
        },
        updatedAt: {
            from: null,
            to: null
        }
    },
    subscription: {},
    subscriptionWithPartnerAdditionalDetails: {},
}

const subscriptionReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SUBSCRIPTION_GET_LIST:
            return {
                ...state,
                subscriptions: action.payload.data
            }
        case SUBSCRIPTION_FILTER:
            return {
                ...state,
                subscriptionsFilters: {
                    ...state.subscriptionsFilters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }

        case SUBSCRIPTION_GET_DETAILS:
            return {
                ...state,
                subscription: action.payload.data,
            }
        case SUBSCRIPTION_CLEAR_FILTER:
            return {
                ...state,
                subscriptionsFilters: {
                    _id: '',
                    phoneNumber: '',
                    'device.imei': '',
                    carrierCode: '',
                    brand: '',
                    activationDate: {
                        from: null,
                        to: null
                    },
                    swapRestricted: [],
                    status: [],
                    planCode: '',
                    shopCode: '',
                    manuallyVerified: [],
                    createdAt: {
                        from: null,
                        to: null
                    },
                    updatedAt: {
                        from: null,
                        to: null
                    }
                }
            }
        case SUBSCRIPTION_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        case SUBSCRIPTION_GET_PARTNER_ADDITIONAL_DETAILS:
            return {
                ...state,
                subscriptionWithPartnerAdditionalDetails: action.payload.data
            }
        default:
            return state;
    }
}

export default subscriptionReducer;