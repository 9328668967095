import {
    VOUCHER_CODE_CLEAR_FILTER,
    VOUCHER_CODE_FILTER,
    VOUCHER_CODE_GET_DETAILS,
    VOUCHER_CODE_GET_LIST, VOUCHER_CODE_SORT,
    VOUCHER_CODE_UPDATE
} from "../actions/types";

const INITIAL_STATE = {
    voucherCodes: [],
    voucherCode: {},
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    filters: {
        serialNumber: '',
        createdAt: {
            from: null,
            to: null
        }
    }
}

const voucherCodeReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case VOUCHER_CODE_GET_LIST:
            return {
                ...state,
                voucherCodes: action.payload.data
            }
        case VOUCHER_CODE_GET_DETAILS:
            return {
                ...state,
                voucherCode: action.payload.data
            }
        case VOUCHER_CODE_UPDATE:
            return {
                ...state,
                voucherCode: action.payload.data
            }
        case VOUCHER_CODE_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case VOUCHER_CODE_CLEAR_FILTER:
            return {
                ...state,
                filters: INITIAL_STATE.filters
            }
        case VOUCHER_CODE_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        default:
            return state;
    }
}

export default voucherCodeReducer;