import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    PICK_UP_ITEM_GET_LIST,
    PICK_UP_ITEM_CLEAR_FILTER,
    PICK_UP_ITEM_FILTER,
    PICK_UP_ITEM_SORT,
    SHOW_SUCCESS, PICK_UP_ITEM_UPDATE
} from "./types";
import { handleError, showContentLoader } from "../utils/utils";

export const getPickUpItemList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/pickUpItems/?${path}`)
        .then(response => {
            dispatch({
                type: PICK_UP_ITEM_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
    })
}

export const updatePickUpItemSingleField = (id, fieldName, fieldValue) => async dispatch => {
    console.log("fieldName", fieldName);
    console.log("fieldValue", fieldValue);

    axios.patch(`${BASE_URL}/v1/pickUpItems/${id}`, {[fieldName]: fieldValue})
        .then(response => {
            dispatch({
                type: PICK_UP_ITEM_UPDATE,
                payload: {
                    id: id,
                    fieldName: fieldName,
                    fieldValue: fieldValue
                }
            })
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.pickUpItem.updatePickUpItemSuccessful',
                    dialogType: 'success'
                }
            })
        }).catch(error => {
            handleError(error, dispatch)
    })
}

export const updateFiltersForPickUpItemList = (filterKey, filterValue) => {
    return ({
        type: PICK_UP_ITEM_FILTER,
        payload: {filterKey, filterValue}
    })
}

export const clearPickUpItemFilters = () => {
    return ({
        type: PICK_UP_ITEM_CLEAR_FILTER
    })
}

export const sortPickUpItems = (orderBy, sort) => {
    return ({
        type: PICK_UP_ITEM_SORT,
        payload: {orderBy, sort}
    })
}