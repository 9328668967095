import React, { useState } from "react";
import "../../styles/custom-bootstrap.scss";
import { Grid, TextField, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useFormikContext, getIn } from "formik";
import { zonedTimeToUtc } from 'date-fns-tz';
import DatePicker from 'react-datepicker';
import ContactDetails from "./ContactDetails";

const DeliveryDetailsView = (props) => {
  const { extendedWarrantyClaim, onAddressFormSubmit, editableNewAddressFields } =
    props;

  const { t } = useTranslation();

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <i class="fa fa-truck" aria-hidden="true"></i>
              <strong> {'Delivery Details'}</strong>
            </div>
            <ContactDetails
              extendedWarrantyClaim={extendedWarrantyClaim}
              entity="deliveryDetails"
              onAddressFormSubmit={onAddressFormSubmit}
              editableNewAddressFields={editableNewAddressFields}
              readOnlyFields={extendedWarrantyClaim?.deliveryDetailsReadOnlyFields ? extendedWarrantyClaim?.deliveryDetailsReadOnlyFields : ""}
              addAddressEnabled={extendedWarrantyClaim?.status == 'new'}
              selectAddressEnabled={extendedWarrantyClaim?.status == 'new'}
              sameAddressEnabled={false}
            />
          </div>
        </div>
      </div>

    </>
  );
};

export default DeliveryDetailsView;
