import React, {Component} from "react";
import { connect } from 'react-redux';
import {
    clearCustomerFilters,
    getCustomerList, sortCustomers,
    updateFiltersForCustomerList
} from '../actions/customerActions';
import DataTable from "../components/DataTable";
import PageTemplate from "../components/PageTemplate";
import {
    parseObjectToString,
    validateAcceptedFields,
    validateEmail,
    validatePhoneNumber,
    validateRequiredFields
} from "../utils/utils";
import Box from "@material-ui/core/Box";
import FileUpload from "../components/FileUpload";
import {showError} from "../actions/commonActions";
import {fileUploadAcceptedFields, fileUploadRequiredFields} from "../utils/fields";

class CustomerList extends Component {
    componentDidMount() {
        this.props.getCustomerList('orderBy=createdAt&sort=desc');
        this.props.clearCustomerFilters();
        this.props.sortCustomers('createdAt', 'desc');
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let customerFilterState = this.props.customers.customerFilters[filterKey];
            customerFilterState.includes(filterValue) ? customerFilterState = customerFilterState.filter(item => item !== filterValue) : customerFilterState.push(filterValue);
            filterValue = customerFilterState;
        }
        this.props.updateFiltersForCustomerList(filterKey, filterValue)
    }

    handleFilterReset() {
        this.props.clearCustomerFilters();
        this.props.getCustomerList('orderBy=createdAt&sort=desc');
    }

    handleSorting(orderBy, sort) {
        this.props.sortCustomers(orderBy, sort)
        this.props.getCustomerList(parseObjectToString(this.props.customers.filters, {orderBy, sort}))
    }

    renderCustomers(customers, showContentLoader) {
        const fields = [
            {
                name: 'id',
                label: 'customer.id'
            },
            {
                name: 'number',
                label: 'customer.number'
            },
            {
                name: 'contact.lastName',
                label: 'customer.lastName'
            },
            {
                name: 'contact.firstName',
                label: 'customer.firstName'
            },
            {
                name: 'contact.ids[0].number',
                label: 'customer.documentNumber'
            },
            {
                name: 'contact.ids[0].type',
                label: 'customer.documentType'
            },
            {
                name: 'contact.gender',
                label: 'customer.gender'
            }, 
            {
                name: 'contact.phoneNumber',
                label: 'customer.phoneNumber'
            },
            {
                name: 'contact.email',
                label: 'customer.email'
            },
            {
                name: 'company.name',
                label: 'customer.companyName'
            },
            {
                name: 'contact.optIns',
                type: 'arrayString',
                label: 'customer.optIns'
            },
            {
                name: 'blacklisted',
                type: 'translatedString',
                label: 'customer.blacklisted'
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }];

        const filterKeys = [
            {
                name: '_id',
                type: 'textField',
                label: 'customer.id'
            },
            {
                name: 'number',
                type: 'textField',
                label: 'customer.number'
            },
            {
                name: 'contact.lastName',
                type: 'textField',
                label: 'customer.lastName'
            },
            {
                name: 'contact.firstName',
                type: 'textField',
                label: 'customer.firstName'
            },
            {
                name: 'contact.ids.number',
                type: 'textField',
                label: 'customer.documentNumber'
            },
            {
                name: 'contact.phoneNumber',
                type: 'textField',
                label: 'customer.phoneNumber'
            },
            {
                name: 'contact.email',
                type: 'textField',
                label: 'customer.email'
            },
            {
                name: 'company.name',
                type: 'textField',
                label: 'customer.companyName'
            },
            {
                name: 'blacklisted',
                type: 'checkBoxes',
                options: ['true', 'false'],
                label: 'customer.blacklisted',
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            }
        ];

        return(
            <DataTable fields={fields}
                       items={customers}
                       entity="customers"
                       filterKeys={filterKeys}
                       filterValues={this.props.customers.customerFilters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getCustomerList(parseObjectToString(this.props.customers.customerFilters))}
                       sorting={this.props.customers.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
            />
        )
    }

    render() {

        return(
            <PageTemplate title="messages.customerList">
                {this.renderCustomers(this.props.customers.customers, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    customers: state.customers,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getCustomerList,
    updateFiltersForCustomerList,
    clearCustomerFilters,
    sortCustomers,
    showError
})(CustomerList);