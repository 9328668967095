import React, {Component} from "react";
import { connect } from 'react-redux';
import DataTable from "../components/DataTable";
import PageTemplate from "../components/PageTemplate";
import {
    clearSubscriptionFilters,
    getSubscriptionList, sortSubscriptions,
    updateFilterForSubscriptionList
} from "../actions/subscriptionActions";
import {
    parseObjectToString,
    parseSortingToString,
} from "../utils/utils";
import {showError} from "../actions/commonActions";

class SubscriptionList extends Component {
    componentDidMount() {
        this.props.getSubscriptionList('orderBy=createdAt&sort=desc');
        this.props.clearSubscriptionFilters();
        this.props.sortSubscriptions('createdAt', 'desc');
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.common.refresh !== prevProps.common.refresh && this.props.common.refresh
        ) {
            this.props.getSubscriptionList('orderBy=createdAt&sort=desc');
        }
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let filterState = this.props.subscriptions.subscriptionsFilters[filterKey];
            filterState.includes(filterValue) ? filterState = filterState.filter(item => item !== filterValue) : filterState.push(filterValue);
            filterValue = filterState;
        }
        this.props.updateFilterForSubscriptionList(filterKey, filterValue)
    }

    handleFilterReset() {
        this.props.clearSubscriptionFilters();
        this.props.getSubscriptionList(parseSortingToString(this.props.subscriptions.sorting));
    }

    handleSorting(orderBy, sort) {
        this.props.sortSubscriptions(orderBy, sort)
        this.props.getSubscriptionList(parseObjectToString(this.props.subscriptions.subscriptionsFilters, {orderBy, sort}))
    }

    renderSubscriptions(subscriptions, showContentLoader) {
        const fields = [
            {
                name: 'id',
                label: 'subscription.id'
            },
            {
                name: 'phoneNumber',
                label: 'subscription.phoneNumber'

            },
            {
                name: 'device.imei',
                label: 'subscription.imei'
            },
            {
                name: 'carrierCode',
            },
            {
                name: 'brand',
            },
            {
                name: 'activationDate',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
            {
                name: 'swapRestricted',
                type: 'translatedString',
                label: 'subscription.swapRestricted'
            },
            {
                name: 'status',
            },
            {
                name: 'planCode',
                label: 'subscription.planCode'
            },
            {
                name: 'shopCode',
            },
            {
                name: 'subscriptionPartnerAdditionalDetails.partnerAdditionalDetails.externalId',
                label: 'subscription.partnerAdditionalDetailsExternalId'
            },
            {
                name: 'subscriptionPartnerAdditionalDetails.partnerAdditionalDetails.externalPlanDescription',
                label: 'subscription.partnerAdditionalDetailsExternalPlanDetails'
            },
            {
                name: 'shopCode',
            },
            {
                name: 'manuallyVerified',
                type: 'translatedString',
                label: 'subscription.manuallyVerified'
            },
            {
                name: 'agent.displayName',
                label: 'subscription.agent'
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
            {
                name: 'updatedAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
            ];

        const filterKeys = [
            {
                name: '_id',
                type: 'textField',
                label: 'subscription.id'
            },
            {
                name: 'phoneNumber',
                type: 'textField',
            },
            {
                name: 'device.imei',
                type: 'textField',
                label: 'subscription.imei'
            },
            {
                name: 'carrierCode',
                type: 'textField',
            },
            {
                name: 'brand',
                type: 'textField',
            },
            {
                name: 'swapRestricted',
                type: 'checkBoxes',
                options: ['true', 'false'],
                label: 'subscription.swapRestricted'

            },
            {
                name: 'status',
                type: 'checkBoxes',
                options: ['active', 'inactive', 'suspended', 'pendingForTermination', 'terminated', 'canceled'],
            },
            {
                name: 'planCode',
                type: 'textField',
                label: 'subscription.planCode'

            },
            {
                name: 'shopCode',
                type: 'textField',
            },
            {
                name: 'manuallyVerified',
                type: 'checkBoxes',
                options: ['true', 'false'],
                label: 'subscription.manuallyVerified'
            },
            {
                name: 'activationDate',
                type: 'dateRangePicker'
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            },
            {
                name: 'updatedAt',
                type: 'dateRangePicker'
            }
        ]
        return(
            <DataTable fields={fields}
                       items={subscriptions}
                       entity="subscriptions"
                       filterKeys={filterKeys}
                       filterValues={this.props.subscriptions.subscriptionsFilters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getSubscriptionList(parseObjectToString(this.props.subscriptions.subscriptionsFilters, this.props.subscriptions.sorting))}
                       sorting={this.props.subscriptions.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
            />
        )
    }

    render() {
        return(
            <PageTemplate title="messages.subscriptionList">
                {this.renderSubscriptions(this.props.subscriptions.subscriptions, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    authentication: state.authentication,
    subscriptions: state.subscriptions,
    common: state.common
})

export default connect(mapStateToProps, {
    getSubscriptionList,
    updateFilterForSubscriptionList,
    clearSubscriptionFilters,
    sortSubscriptions,
    showError
})(SubscriptionList);