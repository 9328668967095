import React from 'react';
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as yup from "yup";
import {CircularProgress, Paper} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {useFormik} from "formik";
import DoneIcon from "@material-ui/icons/Done";
import logo from "../assets/images/logo_bolttech.png";

const customStyles = makeStyles({
    forgotPasswordLink: {
        textAlign: 'right',
        marginTop: 20
    },
    success: {
        color: '#4caf50'
    },
    inputFields: {
        marginBottom: 20
    }
})

const validationSchema = (t) => {
    return yup.object({
        oldPassword: yup
            .string()
            .required(t('messages.fieldRequired', {field: t(`keys.oldPassword`)})),
        newPassword: yup
            .string()
            .required(t('messages.fieldRequired', {field: t(`keys.newPassword`)})),
        newPasswordConfirmation: yup
            .string()
            .required(t('messages.fieldRequired', {field: t(`keys.newPasswordConfirmation`)}))
            .when("newPassword", {
            is: val => (val && val.length > 0),
            then: yup.string().oneOf(
                [yup.ref("newPassword")],
               t('messages.passwordsMustMatch')
            )
        })
    });
}

const ChangePassword = ({ onFormSubmit, onLogOut, errorMessage, requestInProgress, requestSuccess }) => {
    const { t } = useTranslation();
    const classes = customStyles();

    const formik = useFormik({
        initialValues: {
            oldPassword: '',
            newPassword: '',
            newPasswordConfirmation: ''
        },
        validationSchema: validationSchema(t),
        onSubmit: (values) => {
            onFormSubmit(values);
        },
    });

    if (requestSuccess) {
        setTimeout(() => { window.location.reload();}, 2000)
    }

    return (
        <div className="authentication-cover">
            <Paper className="authentication-form">
                <img src={logo} alt="bolttech"  style={{width: '50%'}} />
                <div className="display-flex justify-right">
                    <Button size="small" onClick={onLogOut}>{t('messages.backToLogin')}</Button>
                </div>
                <p>{t('messages.changePassword')}</p>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        fullWidth
                        id="oldPassword"
                        name="oldPassword"
                        label={t('keys.oldPassword')}
                        type="password"
                        autoComplete="off"
                        variant="outlined"
                        value={formik.values.oldPassword}
                        onChange={formik.handleChange}
                        error={formik.touched.oldPassword && Boolean(formik.errors.oldPassword)}
                        helperText={formik.touched.oldPassword && formik.errors.oldPassword}
                        className={classes.inputFields}
                    />
                    <TextField
                        fullWidth
                        id="newPassword"
                        name="newPassword"
                        label={t('keys.newPassword')}
                        type="password"
                        autoComplete="off"
                        variant="outlined"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
                        helperText={formik.touched.newPassword && formik.errors.newPassword}
                        className={classes.inputFields}
                    />
                    <TextField
                        fullWidth
                        id="newPasswordConfirmation"
                        name="newPasswordConfirmation"
                        label={t('keys.newPasswordConfirmation')}
                        type="password"
                        autoComplete="off"
                        variant="outlined"
                        value={formik.values.newPasswordConfirmation}
                        onChange={formik.handleChange}
                        error={formik.touched.newPasswordConfirmation && Boolean(formik.errors.newPasswordConfirmation)}
                        helperText={formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation}
                        className={classes.inputFields}
                    />
                    {errorMessage !== undefined &&
                    <Paper className="authentication-error-message" elevation={3}>{t(errorMessage)}</Paper>
                    }
                    <Button color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            disabled={(requestInProgress || requestSuccess)}
                    >
                        {requestInProgress && <CircularProgress />}
                        {requestSuccess && <DoneIcon className={classes.success}/>}
                        {!requestInProgress && !requestSuccess && t('messages.submit')}
                    </Button>
                </form>
            </Paper>
        </div>
    )
}

export default ChangePassword;