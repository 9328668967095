import React from "react";
import "../../styles/custom-bootstrap.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  fullDateWithIndicator,
} from "../../utils/utils";

const ServiceLimitTable = (props) => {
  const { extendedWarrantyClaim, userCountryCode, serviceLimit } =
    props;

  const { t } = useTranslation();
  const customStyles = makeStyles({
    customBackground: {
      background: "#eceff1",
    },
  });
  const customStyleClass = customStyles();

  return (
    <>
    <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
            <i class="fa fa-history" aria-hidden="true"></i>
              <strong> {'Service Limit'}</strong>
            </div>
            <div className="card-body">
            

              <div className="row">
              <div className="col-md-6">
        <div className="form-group">
          <table className="table table-sm table-striped">
            <thead>
              <tr>
                <th>{}</th>
                <th>{t(`Currency`)}</th>
                <th>{t(`Amount`)}</th>
              </tr>
            </thead>
            <tbody>
                  <tr>
                    <td>{'EW Claim Frequency'}</td>
                    <td>
                      {''}
                    </td>
                    <td>
                      {serviceLimit?.details?.extendedWarranty?.frequencyRemaining}
                    </td>
                  </tr>

                  <tr>
                    <td>{'EW Claim Amount Limit'}</td>
                    <td>
                      {serviceLimit?.details?.extendedWarranty?.amountRemaining.currencyCode}
                    </td>
                    <td>
                      {serviceLimit?.details?.extendedWarranty?.amountRemaining.amount}
                    </td>
                  </tr>
                  <tr>
                    <td>{'ADLD Frequency'}</td>
                    <td>
                      {''}
                    </td>
                    <td>
                      {serviceLimit?.details?.accidentalDamageAndLiquidDamage?.frequencyLimit}
                    </td>
                  </tr>

                  <tr>
                    <td>{'ADLD Amount Limit'}</td>
                    <td>
                      {serviceLimit?.details?.accidentalDamageAndLiquidDamage?.amountLimit.currencyCode}
                    </td>
                    <td>
                      {serviceLimit?.details?.accidentalDamageAndLiquidDamage?.amountLimit.amount}
                    </td>
                  </tr>

                  <tr>
                    <td>{'Replacement Frequency'}</td>
                    <td>
                      {''}
                    </td>
                    <td>
                      {serviceLimit?.details?.replacement?.frequencyLimit}
                    </td>
                  </tr>

                  <tr>
                    <td>{'Replacement Amount Limit'}</td>
                    <td>
                      {serviceLimit?.details?.replacement?.amountLimit.currencyCode}
                    </td>
                    <td>
                      {serviceLimit?.details?.replacement?.amountLimit.amount}
                    </td>
                  </tr>
                  <tr>
                    <td>{'Other Coverage Frequency'}</td>
                    <td>
                      {''}
                    </td>
                    <td>
                      {serviceLimit?.details?.otherCoverage?.frequencyLimit}
                    </td>
                  </tr>

                  <tr>
                    <td>{'Other Coverage Frequency'}</td>
                    <td>
                      {serviceLimit?.details?.otherCoverage?.amountLimit.currencyCode}
                    </td>
                    <td>
                      {serviceLimit?.details?.otherCoverage?.amountLimit.amount}
                    </td>
                  </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-md-3">
                <div className="form-group">
                      <label>{'Quote Currency'}</label>
                      <br />
                      <label className="font-weight-bold">
                      {extendedWarrantyClaim?.quoteCurrencyCode}
                      </label>
                      <br />
                </div>
            </div>
            <div className="col-md-3">
                <div className="form-group">
                      <label>{'Exchange Rate'}</label>
                      <br />
                      <label className="font-weight-bold">
                        {serviceLimit?.exchangeRate}
                      </label>
                      <br />
                </div>
            </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </>
  );
};

export default ServiceLimitTable;
