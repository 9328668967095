import React, { Component, useState } from "react";
import "../../styles/custom-bootstrap.scss";
import { useTranslation } from "react-i18next";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import {
  fullDateWithIndicator,
} from "../../utils/utils";
import ConfirmationDialogSimple from "../ConfirmationDialogSimple";

const attributesColorCircleStyle = (color) => ({
  backgroundColor: color,
  width: `24px`,
  height: `24px`,
  borderRadius: `50%`,
});

const TradeInTransactionDetailsView = ({ tradeInTransaction, userAuthorities, onCancelTradeInTransaction }) => {
  const { t } = useTranslation();
  
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleDialogAccept = (id) => {
    setIsDialogOpen(false);
    onCancelTradeInTransaction(id);
  }

  return (
    <div className="custom-bootstrap">
       <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div>
                    <i className="fa fa-level-up" aria-hidden="true"></i>
                    <strong>{`Trade In - ${tradeInTransaction?.id}`}</strong>
                </div>
                {tradeInTransaction?.status == 'pendingPickUp' && (
                    <div>
                        <Link to={`/tradeInTransactions/${tradeInTransaction?.id}/edit`}>
                            <Button size="small" variant="contained" color="primary" className="margin-right-10">
                                {"Edit"}
                            </Button>
                        </Link>
                            <Button size="small" variant="outlined" color="primary" onClick={() => setIsDialogOpen(true)}>
                                {"Cancel order"}
                            </Button>
                    </div>
                )}
            </div>
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>Status</label><br />
                            <label className="font-weight-bold">{t(`keys.tradeInTransaction.status.${tradeInTransaction?.status}`)}</label>
                        </div>
                        <div className="form-group">
                            <label>IMEI/SN</label><br />
                            <label className="font-weight-bold">{tradeInTransaction?.imei}</label>
                        </div>
                        <div className="form-group">
                            <label>Partner additional details utmSource</label><br />
                            <label className="font-weight-bold">{tradeInTransaction?.utmSource}</label>
                        </div>
                        <div className="form-group">
                            <label>Grade</label><br />
                            <label className="font-weight-bold">{tradeInTransaction?.grade}</label>
                        </div>
                        <div className="form-group">
                            <label>Values</label><br />
                            <label className="font-weight-bold">{tradeInTransaction?.values ? `${tradeInTransaction?.values[0].value.amount} ${tradeInTransaction?.values[0].value.currencyCode}` : "-"}</label>
                        </div>
                        <div className="form-group">
                            <label>Test Results</label><br />
                            {
                                tradeInTransaction?.testResults?.map((testResult) => (
                                    <div>
                                        <code>{testResult.testId}</code>
                                        <span className="font-weight-bold ml-2">{testResult.result}</span>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="form-group">
                            <label>SKU</label><br />
                            <label className="font-weight-bold">{tradeInTransaction?.tradeInDevice?.sku}</label>
                        </div>
                        <div className="form-group">
                            <label>Device Make</label><br />
                            <label className="font-weight-bold">{tradeInTransaction?.tradeInDevice?.make}</label>
                        </div>
                        <div className="form-group">
                            <label>Series</label><br />
                            <label className="font-weight-bold">{tradeInTransaction?.tradeInDevice?.nickname ? tradeInTransaction?.tradeInDevice?.nickname : '-'}</label>
                        </div>
                        <div className="form-group">
                            <label>Created At</label><br />
                            <label className="font-weight-bold">{fullDateWithIndicator(tradeInTransaction?.createdAt)}</label>
                        </div>
                        <div className="form-group">
                            <label>Store ID</label><br />
                            <label className="font-weight-bold">{tradeInTransaction?.businessEntity ? tradeInTransaction?.businessEntity?.code : "-"}</label>
                        </div>
                        <div className="form-group">
                            <label>Store City</label><br />
                            <label className="font-weight-bold">{tradeInTransaction?.businessEntity ? tradeInTransaction?.businessEntity?.address.city : "-"}</label>
                        </div>
                        <div className="form-group">
                            <label>Store Province Code</label><br />
                            <label className="font-weight-bold">{tradeInTransaction?.businessEntity ? tradeInTransaction?.businessEntity?.address.provinceCode : "-"}</label>
                        </div>
                        <div className="form-group">
                            <label>Zip Code</label><br />
                            <label className="font-weight-bold">{tradeInTransaction?.businessEntity ? tradeInTransaction?.businessEntity?.address.zipCode : "-"}</label>
                        </div>
                        <div className="form-group">
                            <label>Staff First Name</label><br />
                            <label className="font-weight-bold">{tradeInTransaction?.staffInfo?.firstName ? tradeInTransaction?.staffInfo?.firstName : "-"}</label>
                        </div>
                        <div className="form-group">
                            <label>Staff Last Name</label><br />
                            <label className="font-weight-bold">{tradeInTransaction?.staffInfo?.lastName ? tradeInTransaction?.staffInfo?.lastName : "-"}</label>
                        </div>
                        <div className="form-group">
                            <label>Staff Phone Number</label><br />
                            <label className="font-weight-bold">{tradeInTransaction?.staffInfo?.phoneNumber ? tradeInTransaction?.staffInfo?.phoneNumber : "-"}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <ConfirmationDialogSimple onAccept={() => handleDialogAccept(tradeInTransaction?.id)}
            onReject={() => setIsDialogOpen(false)}
            open={isDialogOpen}
            action="cancel"
            entity="tradeIn"
            title={t('messages.tradeIn.cancel.title')}
        />
    </div>
  );
};

export default TradeInTransactionDetailsView;
