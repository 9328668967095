import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    CUSTOMER_FILTER,
    CUSTOMER_GET_LIST,
    CUSTOMER_CLEAR_FILTER,
    CUSTOMER_SORT,
    SHOW_SUCCESS,
    CUSTOMER_GET_DETAILS
} from "./types";
import { handleError, showContentLoader } from "../utils/utils";

export const getCustomerList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/customers/?${path}`)
        .then(response => {
            dispatch({
                type: CUSTOMER_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
        })
}

export const getCustomerDetails = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/customers/${id}`)
        .then(response => {
            dispatch({
                type: CUSTOMER_GET_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
    })
}

export const addCustomerAddress = (id, data, isRefresh) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.patch(`${BASE_URL}/v1/customers/${id}`, data)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.customer.updateSuccessful',
                    dialogType: 'success',
                    refresh: isRefresh
                }
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
    })
}



export const updateFiltersForCustomerList = (filterKey, filterValue) => {
    return({
        type: CUSTOMER_FILTER,
        payload: {filterKey, filterValue}
    })
}

export const clearCustomerFilters = () => {
    return ({
        type: CUSTOMER_CLEAR_FILTER
    })
}

export const sortCustomers = (orderBy, sort) => {
    return ({
        type: CUSTOMER_SORT,
        payload: {orderBy, sort}
    })
}