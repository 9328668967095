import React, { Component, useState, useRef, useEffect } from "react";
import "../../styles/custom-bootstrap.scss";
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { Grid, Typography, Button } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import {
  shortDate
} from "../../utils/utils";
import ConfirmationDialogSimple from "../ConfirmationDialogSimple";

const customStyles = makeStyles({
  questionnaireContainer: {
    marginTop: 20,
    marginBottom: 40
  },
  questionContainer: {
    marginTop: 5,
    marginBottom: 5
  },
  detailsContainer: {
    marginTop: 20,
    marginBottom: 20
  },
  inputContainer:{
    position: "relative",
    display: "inline-block",
    width: "100%"
  },
  textInput: {
    width: "100%",
    padding: "12px",
    paddingRight: "48px",
    border: "1px solid #ddd",
    borderRadius: "4px",
    boxSizing: "absolute"
  },
  icon: {
    position: "absolute",
    right: "12px",
    top: "50%",
    transform: "translateY(-50%)",
    pointerEvents: "none"
  },
  dropdownContainer: {
    position: "relative",
    display: "inline-block",
    width: "100%"
  },
  dropdown: {
    position: "absolute",
    top: "100%",
    left: "0",
    width: "100%",
    border: "1px solid #ccc",
    borderRadius: "4px",
    backgroundColor: "#fff",
    zIndex: "1000",
  },
  filterInput: {
    width: "100%",
    padding: "8px",
    border: "none",
    borderBottom: "1px solid #ccc"
  },
  dropdownList: {
    listStyleType: "none",
    padding: "0",
  },
  dropdownItem: {
    padding: "8px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#f0f0f0"
    }
  },
  orderedList: {
    padding: "0", 
    listStylePosition: "inside"
  }
})
const TradeInTransactionEditView = ({ tradeInTransaction, questionnaire, eligibleArticles, userAuthorities, userCountryCode, onCancelTradeInTransaction, onConfirmTradeInTransaction, onEvaluateTradeInPrice }) => {
  const { t } = useTranslation();
  const classes = customStyles();

  eligibleArticles = eligibleArticles ? eligibleArticles : [];

  const isEvaluated = tradeInTransaction?.riderAssessmentValue != null;
  const transactionDate = (new Date()).toISOString()

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setSelectedItem({sku: tradeInTransaction?.tradeInDevice?.sku, description: tradeInTransaction?.tradeInDevice?.nickname})
  }, [eligibleArticles])

  useEffect(() => {
    generateQuestionnaire()
  }, [questionnaire, tradeInTransaction])

  
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const [selectedItem, setSelectedItem] = useState({});
  const [questions, setQuestions] = useState([]);
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const dropdownRef = useRef(null);

  const handleFocus = () => setIsDropdownOpen(true);

  const handleFilterChange = (e) => setFilter(e.target.value);
  
  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsDropdownOpen(false);
  };

  const handleQuestionnaireChange = (key, newResult) => {
    const updatedQuestions = questions.map((question) =>
      question.key === key
        ? { ...question, result: newResult }  
        : question                           
    );

    setQuestions(updatedQuestions);
  }

  const handleDialogAccept = (id) => {
    setIsDialogOpen(false);
    evaluateTradeInPrice(id);
  }

  const generateQuestionnaire = () => {
    let questionnaireWithResult = []; 
    questionnaire?.questions?.map((question) => {
      const filteredTestResult = tradeInTransaction?.testResults?.filter(testResult => testResult.testId === question.key);
      const testResult = filteredTestResult?.length > 0 ? filteredTestResult[0].result : (question?.answers ? question?.answers[0]?.value : undefined );
      question["result"] = testResult;
      questionnaireWithResult.push(question)
    })
    setQuestions(questionnaireWithResult)
  }

  const evaluateTradeInPrice = (id) => {
    const payload = {};
    payload["transactionDate"] = transactionDate.replace('Z', '+0000');;
    payload["tradeInDevice"] = {}
    payload["tradeInDevice"]["sku"] = selectedItem?.sku;
    const formattedTestResult = questions.map(({ key: testId, result }) => ({ testId, result }));
    payload["testResults"] = formattedTestResult;
    payload["countryCode"] = tradeInTransaction?.countryCode;
    payload["carrierCode"] = tradeInTransaction?.carrierCode;
    onEvaluateTradeInPrice(id, payload);
  }

  const filteredItems = eligibleArticles.filter(item => item.description.toLowerCase().includes(filter.toLowerCase()));

  return (
    <div className="custom-bootstrap">
       <div className="card">
            <div className="card-header d-flex justify-content-between align-items-center">
                <div>
                    <i className="fa fa-level-up" aria-hidden="true"></i>
                    <strong>{" Operation Use"}</strong>
                </div>
                {isEvaluated && (
                  <div>
                    <Button variant="contained" color="primary" className="margin-right-10" startIcon={<SaveIcon />} onClick={() => onConfirmTradeInTransaction(tradeInTransaction.id, tradeInTransaction)}>
                      {"Confirm order"}
                    </Button>
                    <Button variant="outlined" color="primary"  startIcon={<CloseIcon />} onClick={() => onCancelTradeInTransaction(tradeInTransaction.id)}>
                      {"Cancel order"}
                    </Button>
                  </div>
                )}
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <Typography variant="subtitle2" className={classes.headerText}>{t('Diagnostic Result')}</Typography>
                  <div className={classes.questionnaireContainer}>
                    <ol>
                    {
                      questions?.map((item) => {
                        return (
                          <li>
                            <div className={`d-flex justify-content-between align-items-center ${classes.questionContainer}`}>
                              <span style={{marginRight: "180px"}}>{t(item.question)}</span>
                              <select 
                                className="select--small" 
                                style={{minWidth: "calc(30% - 10px)"}} 
                                value={item.result}
                                disabled={isEvaluated}
                                onChange={(event) => handleQuestionnaireChange(item.key, event.target.value)}>
                                {
                                  item?.answers?.map((answer) => (
                                    <option value={answer?.value}>{answer?.value}</option>
                                  ))
                                }
                              </select>
                            </div>
                          </li>
                        )
                      })
                    }
                    </ol>
                  </div>
                </div>
                {isEvaluated && (
                  <div className="col-md-6">
                    <div style={{width:"60%"}} className="float-right">
                      <Typography variant="subtitle2" className="text-danger" style={{fontWeight: "bold"}}>{`Notes`}</Typography>
                      <span className="text-danger">
                        <ol className={classes.orderedList}>
                          <li className="margin-bottom-10">By clicking "Confirm Order", you acknowledge that the remaining balance has been paid and the device's condition is as described.</li>
                          <li>By clicking "Cancel Order", you acknowledge customer would like to cancel the order, resulting in order cancellation.</li>
                        </ol>
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-md-6">
                  <Typography variant="subtitle2" className={classes.headerText}>{t('Trade In Details')}</Typography>
                  <div className={classes.detailsContainer}>
                    <Grid item xs={6}>
                      <div className="form-group">
                        <label>Transaction Date</label><br />
                        <input 
                          type="text"
                          className={`form-control`} 
                          value={shortDate(transactionDate, userCountryCode)} 
                          readOnly
                          disabled
                        />
                      </div>
                      <label>Device Make Model Capacity</label><br />
                      <div className={`form-group ${classes.dropdownContainer}`} ref={dropdownRef}>
                        <div className={classes.inputContainer}>
                          <input 
                            type="text"
                            className={`form-control ${classes.textInput}`} 
                            value={selectedItem?.description} 
                            readOnly
                            onFocus={handleFocus}
                            disabled={isEvaluated}
                          />
                          <KeyboardArrowDownIcon className={classes.icon} />
                        </div>
                        {isDropdownOpen && (
                          <div className={classes.dropdown}>
                            <input
                              type="text"
                              placeholder="Filter..."
                              value={filter}
                              onChange={handleFilterChange}
                              className={classes.filterInput}
                            />
                            <ul className={classes.dropdownList} style={{margin:"0"}}>
                              {filteredItems.length > 0 ? (
                                filteredItems.map((item, index) => (
                                  <li key={index.description} onClick={() => handleItemClick(item)} className={classes.dropdownItem}>
                                    {item.description}
                                  </li>
                                ))
                              ) : (
                                <li className={classes.dropdownItem}>No items found</li>
                              )}
                            </ul>
                          </div>
                        )}
                      </div>
                      <div className="form-group">
                        <label>Trade-In Value</label><br />
                        <label className="font-weight-bold">{tradeInTransaction?.values ? tradeInTransaction?.values[0].value.amount :  ""}</label>
                      </div>
                      {isEvaluated && (
                        <div className="form-group">
                          <label>New Trade-In Value</label><br />
                          <label className="font-weight-bold">{tradeInTransaction?.riderAssessmentValue ? tradeInTransaction?.riderAssessmentValue?.amount :  ""}</label>
                        </div>
                      )}
                      {isEvaluated && (
                        <div className="form-group">
                          <label>Amount Difference</label><br />
                          <label className="font-weight-bold">{tradeInTransaction?.values && tradeInTransaction?.riderAssessmentValue ? (tradeInTransaction?.values[0].value.amount - tradeInTransaction?.riderAssessmentValue?.amount) : ""}</label>
                        </div>
                      )}
                    </Grid>
                    {!isEvaluated && (
                      <div className="d-flex align-items-center">
                        <Button size="large" variant="contained" color="primary" onClick={() => setIsDialogOpen(true)}>
                          {"Confirm Phone Condition"}
                        </Button>
                        <label className="text-danger ml-3">
                          ** I declare that the above accurately reflects the phone condition
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
        </div>
        <ConfirmationDialogSimple onAccept={() => handleDialogAccept(tradeInTransaction?.id)}
            onReject={() => setIsDialogOpen(false)}
            open={isDialogOpen}
            action="confirmPhoneCondition"
            entity="tradeIn"
        />
    </div>
  );
};

export default TradeInTransactionEditView;
