import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    PICK_UP_GET_DETAILS,
    HIDE_CONFIRMATION_DIALOG,
    PICK_UP_UPDATE_PICK_UP_DATE,
    PICK_UP_GET_LIST,
    PICK_UP_CLEAR_FILTER,
    PICK_UP_FILTER,
    SHOW_SUCCESS,
    PICK_UP_GET_DOCUMENTS,
    PICK_UP_CLEAR_DOCUMENTS,
    PICK_UP_GET_DOCUMENTS_IN_PROGRESS,
    PICK_UP_UPDATED_DETAILS,
    PICK_UP_UPDATE_PARCEL_CONDITION,
    PICK_UP_UPDATE_COMPLIANCE_BREACHED,
    PICK_UP_SORT
} from "./types";
import { handleError, showContentLoader } from "../utils/utils";

export const getPickUpList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/pickUps/?${path}`)
        .then(response => {
            dispatch({
                type: PICK_UP_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
    })
}

export const getPickUpDetails = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/pickUps/${id}/withPickupItems`)
        .then(response => {
            dispatch({
                type: PICK_UP_GET_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
    })
}

export const updateComplianceBreached = (id, complianceBreached) => async dispatch => {
    axios.patch(`${BASE_URL}/v1/pickUps/${id}`, {complianceBreached})
        .then(() => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.pickUp.updateComplianceBreachedSuccessful',
                    dialogType: 'success'
                }
            })
            dispatch({
                type: PICK_UP_UPDATE_COMPLIANCE_BREACHED,
                payload: {
                    complianceBreached: complianceBreached
                }
            })
        }).catch(error => {
            handleError(error, dispatch);
    })
}

export const updatePickUpParcelCondition = (id, parcelCondition) => async dispatch => {
    axios.patch(`${BASE_URL}/v1/pickUps/${id}`, {parcelCondition})
        .then(() => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.pickUp.updateParcelConditionSuccessful',
                    dialogType: 'success'
                }
            })
            dispatch({
                type: PICK_UP_UPDATE_PARCEL_CONDITION,
                payload: {
                    parcelCondition: parcelCondition
                }
            })
        }).catch(error => {
            handleError(error, dispatch);
    })
}

export const updatePickUpDate = (id, pickUpDate) => async dispatch => {
    axios.patch(`${BASE_URL}/v1/pickUps/${id}`, {pickUpDate})
        .then(response => {
            dispatch({
                type: HIDE_CONFIRMATION_DIALOG
            })
            dispatch({
                type: PICK_UP_UPDATE_PICK_UP_DATE,
                payload: {
                    pickUpDate: pickUpDate
                }
            })
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.pickUp.updatePickUpDateSuccessful',
                    dialogType: 'success'
                }
            })
            dispatch({
                type: PICK_UP_UPDATED_DETAILS,
                payload: response
            })
        }).catch(error => {
        handleError(error, dispatch);
    })
}

export const updateFilterForPickUpList = (filterKey, filterValue) => {
    return ({
        type: PICK_UP_FILTER,
        payload: {filterKey, filterValue}
    })
}

export const clearPickUpFilters = () => {
    return ({
        type: PICK_UP_CLEAR_FILTER
    })
}

export const clearDocumentTemplates = () => {
    return ({
        type: PICK_UP_CLEAR_DOCUMENTS
    })
}

export const getPickUpDocumentTemplates = (id) => async dispatch => {
    dispatch({
        type: PICK_UP_GET_DOCUMENTS_IN_PROGRESS,
        payload: true
    })
    axios.get(`${BASE_URL}/v1/pickUps/${id}/pdfTemplates`)
        .then(response => {
            dispatch({
                type: PICK_UP_GET_DOCUMENTS,
                payload: response
            })
            dispatch({
                type: PICK_UP_GET_DOCUMENTS_IN_PROGRESS,
                payload: false
            })
        }).catch(error => {
        handleError(error, dispatch);
    })
}

export const sortPickUps = (orderBy, sort) => {
    return ({
        type: PICK_UP_SORT,
        payload: {orderBy, sort}
    })
}