import React from "react";
import { useTranslation } from 'react-i18next';
import Box from "@material-ui/core/Box";
import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import { Link, useHistory } from "react-router-dom";
import Button from "@material-ui/core/Button";
import GetAppIcon from '@material-ui/icons/GetApp';
import SaveIcon from '@material-ui/icons/Save';

const styles = {
    title: {
        fontSize: 22,
        fontWeight: 500,
        marginBottom: 0,
    },
    main: {
        paddingTop: 30,
        paddingLeft: 30,
        paddingRight: 30
    },
    mainPaper: {
        padding: 10,
    },
    mainHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingBottom: 10
    },
    mainWrapper: {
        paddingTop: 10
    },
    button: {
        marginLeft: 5
    }
}

const PageTemplate = ({ title, children, createPath, backButton, saveForm, downloadAction, saveDisabled }) => {
    const { t } = useTranslation();
    const history = useHistory();
    return (
        <div style={styles.main}>
            <Paper style={styles.mainPaper}>
                <Box style={styles.mainHeader}>
                    <h3 style={styles.title}>{t(title)}</h3>
                    <Box>
                        {saveForm &&
                            <Button disabled={saveDisabled} style={styles.button} size="small" variant="contained" color="primary" type="submit" form={saveForm}>
                                <SaveIcon />
                                {t('messages.save')}
                            </Button>
                        }
                        {backButton !== false &&
                            <Button style={styles.button} size="small" variant="outlined" color="primary" onClick={() => history.goBack()}>
                                <ArrowBackIcon />
                                {t('messages.back')}
                            </Button>
                        }
                        {createPath &&
                            <Link to={`/${createPath}`} seleniumselector={createPath}>
                                <Button size="small" variant="contained" color="secondary" className="margin-left-10">
                                    <AddIcon />
                                    {t('messages.create')}
                                </Button>
                            </Link>
                        }
                        {downloadAction &&
                            <Button size="small" variant="contained" color="secondary" onClick={() => downloadAction()} className="margin-left-10">
                                <GetAppIcon />
                                {t('messages.download')}
                            </Button>
                        }

                    </Box>
                </Box>
                <Divider />
                <Box style={styles.mainWrapper}>
                    {children}
                </Box>
                <div />
            </Paper>
        </div>
    )
}

export default PageTemplate;