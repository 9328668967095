import React, {Component} from "react";
import { connect } from 'react-redux';
import {getDeliveryDetails} from "../actions/logisticsActions";
import {withTranslation} from "react-i18next";
import PageTemplate from "../components/PageTemplate";
import SectionTemplate from "../components/SectionTemplate";
import DataTableLoader from "../components/DataTableLoader";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import {fullDate} from "../utils/utils";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {TableCell} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";

class BoxDeliveryDetails extends Component {
    componentDidMount() {
        this.props.getDeliveryDetails(this.props.match.params.id);
    }

    renderDetails = (boxDelivery, countryCode, t) => {
        if (boxDelivery) {
            console.log(boxDelivery);
            return (
                <React.Fragment>
                    <Grid container>
                        <Grid item md={3}>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.salesOrderNumber')}</Typography>
                                <p className="display-value">{boxDelivery.salesOrderNumber}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.quantity')}</Typography>
                                <p className="display-value">{boxDelivery.deliveryItems[0].quantity}</p>
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.createdAt')}</Typography>
                                <p className="display-value">{fullDate(boxDelivery.createdAt, countryCode)}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.updatedAt')}</Typography>
                                <p className="display-value">{fullDate(boxDelivery.updatedAt, countryCode)}</p>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Table size="small">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>{t('keys.sku')}</TableCell>
                                        <TableCell>{t('keys.description')}</TableCell>
                                        <TableCell>{t('keys.status')}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {boxDelivery['deliveryItems'].map((deliveryItem) => (
                                        <TableRow key={deliveryItem.id}>
                                            <TableCell>{deliveryItem['article']['sku']}</TableCell>
                                            <TableCell>{deliveryItem['article']['description']}</TableCell>
                                            <TableCell>{t(`values.status.${deliveryItem.status}`)}</TableCell>
                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </Grid>
                    </Grid>
                </React.Fragment>
            )
        }
    }

    render() {
        const { t } = this.props;
        return (
            <PageTemplate title="messages.boxDeliveryDetails">
                <SectionTemplate title="boxDeliveryDetails">
                    {this.props.common.showContentLoader ? <DataTableLoader /> : this.renderDetails(this.props.logistics.delivery, this.props.authentication.loggedInUser.countryCode, t)}
                </SectionTemplate>
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    authentication: state.authentication,
    logistics: state.logistics,
    common: state.common
})

export default connect(mapStateToProps, {
    getDeliveryDetails
})(withTranslation() (BoxDeliveryDetails));