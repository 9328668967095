import React, {Component} from "react";
import { connect } from 'react-redux';
import {
    getBoxDeliveries,
    updateFiltersForBoxDeliveryList,
    clearBoxDeliveryFilters, sortBoxDeliveryList, createBoxDelivery
} from '../actions/logisticsActions';
import DataTable from "../components/DataTable";
import PageTemplate from "../components/PageTemplate";
import {
    parseObjectToString,
} from "../utils/utils";
import {showError} from "../actions/commonActions";
import BoxDeliveryCreateForm from "../components/BoxDeliveryCreateForm";
import Grid from "@material-ui/core/Grid";
import {boxDeliveriesExport} from "../utils/exportUtils";
import {exportFile} from "../actions/exportActions";

class BoxDeliveryList extends Component {
    componentDidMount() {
        this.props.getBoxDeliveries('orderBy=createdAt&sort=desc');
        this.props.clearBoxDeliveryFilters();
        this.props.sortBoxDeliveryList('createdAt', 'desc');
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let filterState = this.props.logistics.filters[filterKey];
            filterState.includes(filterValue) ? filterState = filterState.filter(item => item !== filterValue) : filterState.push(filterValue);
            filterValue = filterState;
        }
        this.props.updateFiltersForBoxDeliveryList(filterKey, filterValue);
    }

    handleFilterReset() {
        this.props.clearBoxDeliveryFilters();
        this.props.getBoxDeliveries(this.props.logistics.sorting);
    }

    handleSorting(orderBy, sort) {
        this.props.sortBoxDeliveryList(orderBy, sort)
        this.props.getBoxDeliveries(parseObjectToString(this.props.logistics.filters, {orderBy, sort}))
    }

    renderBoxDeliveries(boxDeliveries, showContentLoader) {
        const fields = [
            {
                name: 'salesOrderNumber',
            },
            {
                name: 'deliveryItems[0].quantity',
                label: 'quantity',
                sorting: false
            },
            {
                name: 'deliveryItems[0].status',
                translationKey: 'status',
                label: 'status',
                type: 'translatedStringTranslationKey',
                sorting: false
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }];

        const filterKeys = [
            {
                name: 'salesOrderNumber',
                type: 'textField'
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            }
        ];

        return(
            <DataTable fields={fields}
                       items={boxDeliveries}
                       entity="boxDeliveries"
                       filterKeys={filterKeys}
                       filterValues={this.props.logistics.filters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getBoxDeliveries(parseObjectToString(this.props.logistics.filters))}
                       sorting={this.props.logistics.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
            />
        )
    }

    onDocumentDownload() {
        this.props.exportFile(boxDeliveriesExport());
    }

    render() {

        return(
            <PageTemplate title="messages.boxDeliveries" downloadAction={() => this.onDocumentDownload()}>
                <Grid container justify="flex-end">
                    <Grid item md={2}>
                        <BoxDeliveryCreateForm onFormSubmit={(quantity => this.props.createBoxDelivery(quantity))} />
                    </Grid>
                </Grid>
                {this.renderBoxDeliveries(this.props.logistics.logisticsBoxDeliveries, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    merchantUsers: state.merchantUsers,
    authentication: state.authentication,
    logistics: state.logistics,
    common: state.common,
    export: state.export
})

export default connect(mapStateToProps, {
    getBoxDeliveries,
    updateFiltersForBoxDeliveryList,
    createBoxDelivery,
    clearBoxDeliveryFilters,
    sortBoxDeliveryList,
    showError,
    exportFile
})(BoxDeliveryList);