import React, { Component } from "react";
import { connect } from 'react-redux';
import isEqual from 'lodash/isEqual'
import PageTemplate from "../components/PageTemplate";
import {
    clearDocumentTemplates,
    getPickUpDetails, getPickUpDocumentTemplates,
    updatePickUpDate,
    updatePickUpParcelCondition,
    updateComplianceBreached
} from "../actions/pickUpActions";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { withTranslation } from 'react-i18next';
import HistoryTable from "../components/HistoryTable";
import { fullDate, shortDate, validateAuthority } from "../utils/utils";
import Box from "@material-ui/core/Box";
import TableCell from "@material-ui/core/TableCell";
import { updatePickUpItemSingleField } from "../actions/pickUpItemActions";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableBody from "@material-ui/core/TableBody";
import { createdRemark, getRemarks } from "../actions/remarkActions";
import Remarks from "../components/Remarks";
import { getDelivery, unsetDelivery } from "../actions/logisticsActions";
import AddressCard from "../components/AddressCard";
import SectionTemplate from "../components/SectionTemplate";
import { AUTHORITY_PICK_UP_MANAGER, AUTHORITY_PICK_UP_OFFICER, AUTHORITY_VIDEO_CONTROLLER, AUTHORITY_READ_ONLY } from "../constant";
import { showConfirmationDialog, showError } from "../actions/commonActions";
import DataTableLoader from "../components/DataTableLoader";
import Button from "@material-ui/core/Button";
import GetAppIcon from '@material-ui/icons/GetApp';
import CircularProgress from "@material-ui/core/CircularProgress";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { TextField } from "@material-ui/core";

class PickUpDetails extends Component {
    componentDidMount() {
        this.props.clearDocumentTemplates();
        this.props.unsetDelivery();
        this.props.getPickUpDetails(this.props.match.params.id);
        if (validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_VIDEO_CONTROLLER, AUTHORITY_READ_ONLY], this.props.authentication.loggedInUser.authorities)) {
            this.props.getRemarks('pickUp', this.props.match.params.id);
        }
        if (validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_PICK_UP_OFFICER], this.props.authentication.loggedInUser.authorities)) {
            this.props.getPickUpDocumentTemplates(this.props.match.params.id);
        }
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.pickUpItems.pickUpItems !== prevProps.pickUpItems.pickUpItems && this.props.pickUpItems.pickUpItems.length
        ) {
            this.props.pickUpItems.pickUpItems.forEach(pickUpItem => {
                if (validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_VIDEO_CONTROLLER, AUTHORITY_READ_ONLY], this.props.authentication.loggedInUser.authorities)) {
                    this.props.getRemarks('pickUpItem', pickUpItem.id)
                }
            })
        }

        if (!isEqual(this.props.pickUps.pickUp['boxDeliveryIds'], prevProps.pickUps.pickUp['boxDeliveryIds']) && this.props.pickUps.pickUp['boxDeliveryIds'] !== undefined && this.props.pickUps.pickUp['boxDeliveryIds'].length) {
            this.props.pickUps.pickUp['boxDeliveryIds'].map(boxDeliveryId => {
                return this.props.getDelivery(boxDeliveryId, 'boxOrder');
            })
        }

        if (this.props.pickUps.pickUp.deliveryId !== prevProps.pickUps.pickUp.deliveryId) {
            if (this.props.pickUps.pickUp.deliveryId) {
                this.props.getDelivery(this.props.pickUps.pickUp.deliveryId);
            } else {
                this.props.unsetDelivery();
            }
        }
    }

    onDocumentDownload = () => {
        const pdfDocuments = this.props.pickUps.pdfDocuments;
        if (this.props.pickUps.pickUp['pickUpDocuments'] != null && this.props.pickUps.pickUp['pickUpDocuments'].length > 0) {
            this.props.pickUps.pickUp['pickUpDocuments'].forEach(pickUpDocument => {
                if (!pdfDocuments.includes(pickUpDocument)) {
                    pdfDocuments.push(pickUpDocument)
                }
            })
        }

        pdfDocuments.forEach(pdfDocument => {
            let pdf = `data:application/pdf;base64,${pdfDocument.pdfContent || pdfDocument.content}`;
            let a = document.createElement("a");
            a.href = pdf;
            a.download = `${pdfDocument.fileName || pdfDocument.name}`;
            a.click();
        })
    }

    renderDetails = (pickUp, availablePickUpDates, t) => {
        return (
            <SectionTemplate title='pickUpOrder'>
                <Grid container item spacing={6} md={12} style={{ position: 'relative' }}>
                    {
                        validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_PICK_UP_OFFICER], this.props.authentication.loggedInUser.authorities) &&
                        <Button disabled={this.props.pickUps.pdfDocuments.length === 0 || pickUp['pickUpDocuments'] === undefined || pickUp['pickUpDocuments'].length === 0} variant="contained" size="small" color="secondary" style={{ position: 'absolute', right: '0', top: '-30px' }} onClick={() => this.onDocumentDownload()}>
                            {this.props.pickUps.getPdfDocumentsInProgress ? <CircularProgress size={20} /> : <GetAppIcon />}
                            <Box ml={1}>{t('messages.download')}</Box>
                        </Button>
                    }
                    <Grid item md={6}>
                        <Typography variant="h6" className="grouping-header">{t(`messages.generalInformation`)}</Typography>
                        <div className="grouping-content">
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.number')}</Typography>
                                <p className="display-value">{pickUp.number}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.businessEntityCode')}</Typography>
                                <p className="display-value">{pickUp.businessEntityCode}</p>
                            </Box>
                        </div>
                        <Typography variant="h6" className="grouping-header">{t(`keys.pickUpDate`)}</Typography>
                        <div className="grouping-content">
                            <Box mb={2}>
                                {!pickUp.pickUpDate && validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_PICK_UP_OFFICER], this.props.authentication.loggedInUser.authorities) ?
                                    <select onChange={(value) => this.props.showConfirmationDialog(pickUp.id, pickUp.pickUpDate, value.target.value, 'pickUp', 'pickUpDate', 'update', 'shortDate', 'updatePickUpDate')} className="select" style={{ borderColor: 'red' }}>
                                        <option hidden value=''>{t('messages.selectYourOption')}</option>
                                        {
                                            availablePickUpDates.length > 0 && availablePickUpDates.map(availablePickUpDate => {
                                                return (
                                                    <option value={availablePickUpDate}
                                                        key={availablePickUpDate}
                                                    >{shortDate(availablePickUpDate, pickUp.countryCode)}</option>
                                                )
                                            })
                                        }
                                    </select>
                                    : <p className="display-value">{pickUp.pickUpDate ? shortDate(pickUp.pickUpDate, pickUp.countryCode) : 'N/A'}</p>
                                }
                            </Box>
                        </div>
                        {
                            validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_VIDEO_CONTROLLER, AUTHORITY_READ_ONLY], this.props.authentication.loggedInUser.authorities) && <React.Fragment>
                                <Typography variant="h6" className="grouping-header">{t(`keys.complianceBreached`)}</Typography>
                                <div className="grouping-content">
                                    <Box mb={2}>
                                        {
                                            validateAuthority([AUTHORITY_VIDEO_CONTROLLER, AUTHORITY_PICK_UP_MANAGER], this.props.authentication.loggedInUser.authorities) ?
                                                this.renderComplianceBreached(pickUp.id, pickUp.complianceBreached, t) :
                                                <p className="display-value">{pickUp.complianceBreached ? t(`values.complianceBreached.${pickUp.complianceBreached}`) : 'N/A'}</p>
                                        }
                                    </Box>
                                </div>
                            </React.Fragment>
                        }
                        {
                            validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_VIDEO_CONTROLLER, AUTHORITY_READ_ONLY], this.props.authentication.loggedInUser.authorities) && <React.Fragment>
                                <Typography variant="h6" className="grouping-header">{t(`keys.parcelCondition`)}</Typography>
                                <div className="grouping-content">
                                    <Box mb={2}>
                                        {
                                            validateAuthority(AUTHORITY_VIDEO_CONTROLLER, this.props.authentication.loggedInUser.authorities) ?
                                                this.renderParcelCondition(pickUp.id, pickUp.parcelCondition, pickUp.status, t) :
                                                <p className="display-value">{pickUp.parcelCondition ? t(`values.parcelCondition.${pickUp.parcelCondition}`) : 'N/A'}</p>
                                        }
                                    </Box>
                                </div>
                            </React.Fragment>
                        }
                    </Grid>
                    <Grid item md={6}>
                        <Typography variant="h6" className="grouping-header">{t(`keys.status`)}</Typography>
                        <div className="grouping-content">
                            <Box mb={2}>
                                <HistoryTable history={pickUp.history} countryCode={this.props.authentication.loggedInUser.countryCode} />
                            </Box>
                        </div>
                        <Typography variant="h6" className="grouping-header">{t(`messages.additionalInformation`)}</Typography>
                        <div className="grouping-content">
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.updatedAt')}</Typography>
                                <p className="display-value">{fullDate(pickUp.updatedAt, this.props.authentication.loggedInUser.countryCode)}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.createdAt')}</Typography>
                                <p className="display-value">{fullDate(pickUp.createdAt, this.props.authentication.loggedInUser.countryCode)}</p>
                            </Box>
                        </div>
                    </Grid>
                </Grid>
            </SectionTemplate>
        )
    }

    renderComplianceBreached = (id, complianceBreached, t) => {
        const statuses = [true, false];
        const handleSelection = (selectedOption) => {
            this.props.updateComplianceBreached(id, selectedOption.target.value);
        }
        return (
            <select onChange={(selectedOption) => handleSelection(selectedOption)}
                value={complianceBreached}
                className="select"
            >
                <option hidden value=''>{t('messages.selectYourOption')}</option>
                {
                    statuses.map(status => {
                        return (
                            <option value={status}
                                key={status}
                            >{t(`values.${status}`)}</option>
                        )
                    })
                }

            </select>
        )
    }

    renderParcelCondition = (id, parcelCondition, pickUpStatus, t) => {
        const parcelConditions = ['accepted', 'unknown', 'lost', 'packOpen'];
        const handleSelection = (selectedOption) => {
            this.props.updatePickUpParcelCondition(id, selectedOption.target.value);
        }
        return (
            <select onChange={(selectedOption) => handleSelection(selectedOption)}
                value={parcelCondition}
                className="select"
                disabled={pickUpStatus === 'new'}
            >
                <option hidden value=''>{t('messages.selectYourOption')}</option>
                {
                    parcelConditions.map(parcelConditionOption => {
                        return (
                            <option value={parcelConditionOption}
                                key={parcelConditionOption}
                            >{t(`values.parcelCondition.${parcelConditionOption}`)}</option>
                        )
                    })
                }

            </select>
        )
    }

    renderReceiptImei = (receiptImei, pickUpItemId, pickUpStatus, parcelCondition, t) => {
        var inputValue = receiptImei;
        const handleInput = (input) => {
            inputValue = input.target.value;
            console.log('value is:', inputValue);
        }
        const handleSubmit = () => {
            console.log(inputValue);
            if (inputValue.length > 15) {
                this.props.showError({response: {data: {translationKey: `Receipt IMEI should not be more than 15 characters.`}}});
                return;
            }
            if (inputValue) {
                this.props.updatePickUpItemSingleField(pickUpItemId, 'receiptImei', inputValue);
            }
        }
        return (
            <div>
                <TextField
                    type="number"
                    onChange={(input) => handleInput(input)}
                    disabled={!validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_VIDEO_CONTROLLER], this.props.authentication.loggedInUser.authorities) || pickUpStatus === 'new' || parcelCondition === undefined}
                    defaultValue={receiptImei}
                    className="margin-bottom-20"
                    variant="outlined"
                    inputProps={{ maxLength: 15 }}
                >
                </TextField>
                <Button color="primary" disabled={!validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_VIDEO_CONTROLLER], this.props.authentication.loggedInUser.authorities) || pickUpStatus === 'new' || parcelCondition === undefined}
                    onClick={() => handleSubmit()}>
                    {t('messages.submit')}
                </Button>
            </div>
        )
    }

    renderDeviceCondition = (deviceCondition, pickUpItemId, pickUpStatus, parcelCondition, t) => {
        const deviceConditions = [
            'ok',
            'okMajorAnomalies',
            'okMinorAnomalies',
            'lost',
            'unknownQuarantine',
            'fmiActive',
            'doesNotSwitchOn',
            'itemNoPresent',
            'deliveredSeparately',
            'requestCancelled'
        ];
        const handleSelection = (selectedOption) => {
            this.props.updatePickUpItemSingleField(pickUpItemId, 'deviceCondition', selectedOption.target.value);
        }
        return (
            <select onChange={(selectedOption) => handleSelection(selectedOption)}
                value={deviceCondition}
                className="select"
                disabled={!validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_VIDEO_CONTROLLER], this.props.authentication.loggedInUser.authorities) || pickUpStatus === 'new' || parcelCondition === undefined}
            >
                <option hidden value=''>{t('messages.selectYourOption')}</option>
                {
                    deviceConditions.map(deviceConditionOption => {
                        return (
                            <option value={deviceConditionOption}
                                key={deviceConditionOption}
                            >{t(`values.deviceCondition.${deviceConditionOption}`)}</option>
                        )
                    })
                }

            </select>
        )
    }

    renderGrade = (grade, pickUpItemId, pickUpStatus, parcelCondition, t) => {
        const grades = [
            'gradeA',
            'gradeB',
            'gradeC',
            'gradeD',
            'gradeE',
            'ber',
            'nonGrade'
        ];
        const handleSelection = (selectedOption) => {
            this.props.updatePickUpItemSingleField(pickUpItemId, 'grade', selectedOption.target.value);
        }
        return (
            <select onChange={(selectedOption) => handleSelection(selectedOption)}
                value={grade}
                className="select"
                disabled={!validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_VIDEO_CONTROLLER], this.props.authentication.loggedInUser.authorities) || pickUpStatus === 'new' || parcelCondition === undefined}
            >
                <option hidden value=''>{t('messages.selectYourOption')}</option>
                {
                    grades.map(grade => {
                        return (
                            <option value={grade}
                                key={grade}
                            >{t(`values.grade.${grade}`)}</option>
                        )
                    })
                }

            </select>
        )
    }

    renderAdditionalNotes = (additionalNotes, pickUpItemId, pickUpStatus, parcelCondition, t) => {
        const additionalNotesOptions = [
            'deviceNotRestored',
            'deviceHasSimMemoryCard'
        ]
        const handleSelection = (selectedOption) => {
            let selectedAdditionalNotes = additionalNotes;
            if (!selectedAdditionalNotes) {
                selectedAdditionalNotes = [];
            }
            selectedAdditionalNotes = selectedAdditionalNotes.includes(selectedOption) ? selectedAdditionalNotes.filter(e => e !== selectedOption) : [...selectedAdditionalNotes, selectedOption];
            this.props.updatePickUpItemSingleField(pickUpItemId, 'additionalNotes', selectedAdditionalNotes);
        }
        return (
            additionalNotesOptions.map(additionalNotesOption => {
                return (
                    <FormControlLabel
                        className="MuiTypography-root MuiTypography-subtitle2"
                        key={additionalNotesOption}
                        control={
                            <Checkbox
                                checked={additionalNotes && additionalNotes.includes(additionalNotesOption)}
                                value={additionalNotesOption}
                                onChange={() => handleSelection(additionalNotesOption)}
                                name="additionalNotes"
                                color="primary"
                                disabled={!validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_VIDEO_CONTROLLER], this.props.authentication.loggedInUser.authorities) || pickUpStatus === 'new' || parcelCondition === undefined}
                            />
                        }
                        label={t(`values.additionalNotes.${additionalNotesOption}`)}
                    />
                )
            })
        )
    }

    renderBoxDeliveryDetails = (boxDeliveries, t) => {
        return (
            <SectionTemplate title='boxDeliveries'>
                <div style={{ overflow: 'auto' }}>
                    <Table size="small">
                        <TableHead className="background-transparent">
                            <TableRow>
                                <TableCell component="th">{t('keys.sku')}</TableCell>
                                <TableCell component="th">{t('keys.description')}</TableCell>
                                <TableCell component="th">{t('keys.status')}</TableCell>
                                <TableCell component="th">{t('keys.statusChangeHappenedAt')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {boxDeliveries.map(boxDelivery => {
                                return (
                                    <TableRow key={boxDelivery.id}>
                                        <TableCell>{boxDelivery.deliveryItems[0].article.sku}</TableCell>
                                        <TableCell>{boxDelivery.deliveryItems[0].article.description}</TableCell>
                                        <TableCell>{t(`values.status.${boxDelivery.deliveryItems[0].status}`)}</TableCell>
                                        <TableCell>{fullDate(boxDelivery.deliveryItems[0].history[boxDelivery.deliveryItems[0].history.length - 1].statusChangeHappenedAt, this.props.pickUps.pickUp.countryCode)}</TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </div>
            </SectionTemplate>
        )
    }

    renderPickUpItems = (pickUpItems, t) => {
        return (
            <SectionTemplate title='pickUpItems'>
                <div style={{ overflow: 'auto' }}>
                    <Table>
                        <TableHead className="background-transparent">
                            <TableRow>
                                <TableCell component="th">{t('keys.imei')}</TableCell>
                                <TableCell component="th">{t('keys.receiptImei')}</TableCell>
                                <TableCell component="th">{t('keys.description')}</TableCell>
                                <TableCell component="th">{t('keys.type')}</TableCell>
                                {
                                    validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_VIDEO_CONTROLLER, AUTHORITY_READ_ONLY], this.props.authentication.loggedInUser.authorities) &&
                                    <React.Fragment>
                                        <TableCell component="th">{t('keys.deviceCondition')}</TableCell>
                                        <TableCell component="th">{t('keys.grade')}</TableCell>
                                        <TableCell component="th">{t('keys.additionalNotes')}</TableCell>
                                        <TableCell component="th">{t('keys.content')}</TableCell>
                                    </React.Fragment>
                                }
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {pickUpItems.map(pickUpItem => {
                                return (
                                    <TableRow key={pickUpItem.id}>
                                        <TableCell className="vertical-align-top">{pickUpItem.imei}</TableCell>
                                        <TableCell className="vertical-align-top">{this.renderReceiptImei(pickUpItem.receiptImei, pickUpItem.id, this.props.pickUps.pickUp.status, this.props.pickUps.pickUp.parcelCondition, t)}</TableCell>
                                        <TableCell className="vertical-align-top">{pickUpItem.articleDescription}</TableCell>
                                        <TableCell className="vertical-align-top">{t('values.type.' + pickUpItem.type)}</TableCell>
                                        {
                                            validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_VIDEO_CONTROLLER, AUTHORITY_READ_ONLY], this.props.authentication.loggedInUser.authorities) &&
                                            <React.Fragment>
                                                <TableCell
                                                    className="vertical-align-top">{this.renderDeviceCondition(pickUpItem.deviceCondition, pickUpItem.id, this.props.pickUps.pickUp.status, this.props.pickUps.pickUp.parcelCondition, t)}</TableCell>
                                                <TableCell
                                                    className="vertical-align-top">{this.renderGrade(pickUpItem.grade, pickUpItem.id, this.props.pickUps.pickUp.status, this.props.pickUps.pickUp.parcelCondition, t)}</TableCell>
                                                <TableCell
                                                    className="vertical-align-top">{this.renderAdditionalNotes(pickUpItem.additionalNotes, pickUpItem.id, this.props.pickUps.pickUp.status, this.props.pickUps.pickUp.parcelCondition, t)}</TableCell>
                                                <TableCell>
                                                    <Box width={500}>
                                                        {this.renderRemarks(this.props.remarks.remarks[pickUpItem.id] || [], pickUpItem.id, 'pickUpItem', true)}
                                                    </Box>
                                                </TableCell>
                                            </React.Fragment>
                                        }
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table>
                </div>
            </SectionTemplate>
        )
    }

    renderDeliveryDetails = (delivery, t) => {
        if (delivery) {
            return (
                <SectionTemplate title="delivery">
                    <Grid container item spacing={6} md={12}>
                        <Grid item md={6}>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.courierAdapterCode')}</Typography>
                                <p className="display-value">{delivery.courierAdapterCode || 'N/A'}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.trackingNumber')}</Typography>
                                <p className="display-value">{delivery.tracking.trackingNumber || 'N/A'}</p>
                            </Box>
                        </Grid>
                        <Grid item md={6}>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.warehouseAdapterCode')}</Typography>
                                <p className="display-value">{delivery.warehouseAdapterCode || 'N/A'}</p>
                            </Box>
                        </Grid>
                    </Grid>
                    <Grid container item spacing={6} md={12}>
                        <Grid item md={6}>
                            {
                                delivery.from && <Box mb={2}>
                                    <Typography variant="subtitle2">{t('keys.from')}</Typography>
                                    {delivery.from.addresses.map((address, index) => (
                                        <AddressCard countryCode={this.props.authentication.loggedInUser.countryCode} address={address} key={address.street + index} />
                                    ))}
                                </Box>
                            }
                        </Grid>
                        <Grid item md={6}>
                            {
                                delivery.to && <Box mb={2}>
                                    <Typography variant="subtitle2">{t('keys.to')}</Typography>
                                    {delivery.to.addresses.map((address, index) => (
                                        <AddressCard countryCode={this.props.authentication.loggedInUser.countryCode} address={address} key={address.street + index} />
                                    ))}
                                </Box>
                            }
                        </Grid>

                    </Grid>
                </SectionTemplate>
            )
        }
    }

    renderRemarks = (remarks, entityId, entity, extendable) => {
        return (
            <Remarks remarks={remarks}
                entityId={entityId}
                entityName={entity}
                extendable={extendable}
                onFormSubmit={(data) => this.props.createdRemark(data, entityId)}
                toDisabled={validateAuthority([AUTHORITY_PICK_UP_MANAGER,AUTHORITY_VIDEO_CONTROLLER ], this.props.authentication.loggedInUser.authorities)}
            />
        )
    }

    render() {
        const { t } = this.props;
        return (
            <PageTemplate title="messages.pickUpDetails">
                {this.props.common.showContentLoader ? <DataTableLoader /> : this.renderDetails(this.props.pickUps.pickUp, this.props.pickUps.availablePickUpDates, t)}
                {this.props.logistics.boxDeliveries.length ? this.renderBoxDeliveryDetails(this.props.logistics.boxDeliveries, t) : ''}
                {this.props.common.showContentLoader ? <DataTableLoader /> : this.renderPickUpItems(this.props.pickUpItems.pickUpItems, t)}
                {this.renderDeliveryDetails(this.props.logistics.delivery, t)}
                {
                    validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_VIDEO_CONTROLLER, AUTHORITY_READ_ONLY], this.props.authentication.loggedInUser.authorities) &&
                    <SectionTemplate title="remarks">
                        {this.renderRemarks(this.props.remarks.remarks[this.props.pickUps.pickUp.id] || [], this.props.pickUps.pickUp.id, 'pickUp')}
                    </SectionTemplate>
                }
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    pickUps: state.pickUps,
    authentication: state.authentication,
    pickUpItems: state.pickUpItems,
    remarks: state.remarks,
    logistics: state.logistics,
    common: state.common
})

export default connect(mapStateToProps, {
    clearDocumentTemplates,
    createdRemark,
    getPickUpDocumentTemplates,
    getDelivery,
    getPickUpDetails,
    getRemarks,
    showConfirmationDialog,
    unsetDelivery,
    updatePickUpDate,
    updatePickUpItemSingleField,
    updatePickUpParcelCondition,
    updateComplianceBreached,
    showError
})(withTranslation()(PickUpDetails));
