import React, {Component} from "react";
import {connect} from "react-redux";
import PageTemplate from "../components/PageTemplate";
import ReloadPlusPickup from "../components/dashboard/ReloadPlusPickup";
import Default from "../components/dashboard/Default";
import {getProduct} from "../actions/merchantUserActions";

class Dashboard extends Component {
    componentDidMount() {
        this.props.getProduct();
    }

    renderDashboard = (productCode) => {
        switch (productCode) {
            case "reload-plus-pickup":
                return <ReloadPlusPickup authorities={this.props.authentication.loggedInUser.authorities} />
            default:
                return <Default />
        }

    }
    render() {
        return(
            <PageTemplate title="messages.welcome" backButton={false}>
                {this.renderDashboard(this.props.authentication.loggedInUser.productCode)}
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
})

export default connect(mapStateToProps, {
    getProduct
})(Dashboard);
