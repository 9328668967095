import React from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";

const customStyles = makeStyles({
    inputField: {
        marginTop: 10,
        marginBottom: 10
    },
    card: {
        width: '100%'
    },
    cardDanger: {
        borderColor: '#f44336'
    }
})

const BoxDeliveryCreateForm = ({ onFormSubmit }) => {
    const { t } = useTranslation();
    const [displayConfirmButton, setDisplayConfirmButton] = React.useState(false);
    const classes = customStyles();

    const validationRules = yup.object({
        quantity: yup.number().required(t('messages.fieldRequired', {field: t('keys.quantity')})).min(1, t('messages.minimumValue', {value: 1})).max(3, t('messages.maximumValue', {value: 3})),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            quantity: ''
        },
        validationSchema: validationRules,
        onSubmit: (values, { setSubmitting }) => {
            onFormSubmit(values);
            values['quantity'] = '';
            setDisplayConfirmButton(false);
            setTimeout(function(){
                setSubmitting(false)
            }, 1000);
        }
    })

    const onFormReject = () => {
        formik.handleReset();
        setDisplayConfirmButton(false)
    }

    return (
        <form onSubmit={formik.handleSubmit} className="padding-bottom-10">
            <div className="grouping-content">
                <TextField
                    type="number"
                    variant="outlined"
                    fullWidth
                    size="small"
                    step="1"
                    label={t('keys.quantity')}
                    name="quantity"
                    autoComplete='off'
                    className={classes.inputField}
                    onChange={formik.handleChange}
                    value={formik.values['quantity']}
                    error={formik.touched['quantity'] && Boolean(formik.errors['quantity'])}
                    helperText={formik.touched['quantity'] && formik.errors['quantity']}
                />
            </div>
            <div className="text-right">
                <div className={displayConfirmButton ? 'display-none' : 'display-block'}>
                    <Button disabled={!formik.values['quantity']} onClick={() => setDisplayConfirmButton(true)} variant="contained" color="primary" size="small">
                        {t('messages.boxDelivery.create')}
                    </Button>
                </div>
                <div className={displayConfirmButton ? 'display-flex justify-right' : 'display-none'}>
                    {t('confirmationMessages.areYouSure')}
                    <Button disabled={formik.isSubmitting} variant="contained" color="primary" type="submit" size="small" className="margin-left-10 margin-right-10">
                        {t('confirmationMessages.yes')}
                    </Button>
                    <Button onClick={() => onFormReject()} variant="contained" size="small">
                        {t('confirmationMessages.cancel')}
                    </Button>
                </div>
            </div>
        </form>
    )


}

export default BoxDeliveryCreateForm;