import React, {Component} from "react";
import { connect } from 'react-redux';
import {withTranslation} from "react-i18next";
import PageTemplate from "../components/PageTemplate";
import DataTableLoader from "../components/DataTableLoader";
import SectionTemplate from "../components/SectionTemplate";
import {showConfirmationDialog} from "../actions/commonActions";
import {getVoucherCodeDetails, updateVoucherCode} from "../actions/voucherCodeActions";
import {getPlanList} from "../actions/planActions";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import VoucherCodeForm from "../components/VoucherCodeForm";

class VoucherCodeDetails extends Component {
    componentDidMount() {
        this.props.getVoucherCodeDetails(this.props.match.params.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.voucherCodes.voucherCode.planCodes !== prevProps.voucherCodes.voucherCode.planCodes && this.props.voucherCodes.voucherCode.planCodes.length) {
            this.props.getPlanList(`${this.props.voucherCodes.voucherCode.planCodes.map(planCode => {return 'filterFields[]=code&filterValues[]=' + planCode}).join('&')}`)
        }
    }

    renderPlans = (plans, user, t) => {
        return (
            plans.map(plan => {
                return (
                    <Grid container key={plan.id}>
                        <Grid item md={3}>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.code')}</Typography>
                                <p className="display-value">{plan.code}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.description')}</Typography>
                                <p className="display-value">{plan.description[`${user.languageCode}_${user.countryCode}`]}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.durationInMonths')}</Typography>
                                <p className="display-value">{plan.durationInMonths}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.netSubscriptionFee')}</Typography>
                                <p className="display-value">{plan.netSubscriptionFee.amount} {plan.netSubscriptionFee.currencyCode}</p>
                            </Box>
                        </Grid>
                        <Grid item md={3}>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.usageTimeFrame')}</Typography>
                                <p className="display-value">{plan.usageTimeFrame}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.usageLimit')}</Typography>
                                <p className="display-value">{plan.usageLimit}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.usageTypeLimits')}</Typography>
                                {
                                    Object.keys(plan.usageTypeLimits).map((keyName, keyIndex) =>
                                        <p className="display-value" key={keyName}>{t(`keys.usageTypeLimitKeys.${keyName}`)}: {plan.usageTypeLimits[keyName]}</p>
                                    )
                                }
                            </Box>
                        </Grid>
                    </Grid>
                )
            })
        )
    }

    renderVoucherCodeDetails = (voucherCode, loggedInUser) => {
        return (
            <VoucherCodeForm voucherCode={voucherCode}
                             onFormSubmit={(values) => this.props.updateVoucherCode(this.props.match.params.id, values, loggedInUser)}
                             loggedInUser={loggedInUser} />
        )
    }

    render() {
        const { t } = this.props;
        return (
            <PageTemplate title="messages.voucherCodeDetails">
                {this.props.common.showContentLoader ? <DataTableLoader/> :
                    <React.Fragment>
                        <SectionTemplate title="plans">
                            {this.renderPlans(this.props.plans.plans, this.props.authentication.loggedInUser, t)}
                        </SectionTemplate>
                        <SectionTemplate title="voucherCodeDetails">
                            {this.renderVoucherCodeDetails(this.props.voucherCodes.voucherCode, this.props.authentication.loggedInUser)}
                        </SectionTemplate>
                    </React.Fragment>
                }
            </PageTemplate>
        )

    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    common: state.common,
    voucherCodes: state.voucherCodes,
    plans: state.plans
})

export default connect(mapStateToProps, {
    getVoucherCodeDetails,
    getPlanList,
    updateVoucherCode,
    showConfirmationDialog
})(withTranslation() (VoucherCodeDetails));