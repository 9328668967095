import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    BUSINESS_ENTITY_CHECK_EXISTENCE, BUSINESS_ENTITY_CHECK_EXISTENCE_RESULT,
    BUSINESS_ENTITY_CLEAR_FILTER,
    BUSINESS_ENTITY_FILTER,
    BUSINESS_ENTITY_GET_DETAILS,
    BUSINESS_ENTITY_GET_LIST, BUSINESS_ENTITY_RESET_EXISTENCE, BUSINESS_ENTITY_SORT,
    SHOW_SUCCESS,
} from "./types";
import { handleError, showContentLoader } from "../utils/utils";

export const getBusinessEntityList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/businessEntities/?${path}`)
        .then(response => {
            dispatch({
                type: BUSINESS_ENTITY_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const getBusinessEntityDetails = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/businessEntities/${id}`)
        .then(response => {
            dispatch({
                type: BUSINESS_ENTITY_GET_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const getSupportedBrandBusinessEntities = (sku, countryCode) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/businessEntities/getSupportedBrandBusinessEntities?sku=${sku}&countryCode=${countryCode}`)
        .then(response => {
            dispatch({
                type: BUSINESS_ENTITY_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const updateBusinessEntity = (businessEntity, id) => async dispatch => {
    axios.patch(`${BASE_URL}/v1/businessEntities/${id}`, businessEntity)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.businessEntity.updateSuccessful',
                    dialogType: 'success'
                }
            })
        }).catch(error => {
            handleError(error, dispatch);
        })
}

export const createBusinessEntity = (businessEntity) => async dispatch => {
    axios.post(`${BASE_URL}/v1/businessEntities`, businessEntity)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.businessEntity.createSuccessful',
                    dialogType: 'success'
                }
            })
        }).catch(error => {
            handleError(error, dispatch)
        })
}

export const batchCreateBusinessEntity = (businessEntities) => async dispatch => {
    axios.post(`${BASE_URL}/v1/businessEntities/batch`, businessEntities)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.businessEntity.fileUploaded',
                    entity: 'businessEntity',
                    dialogType: 'info',
                    uploadResponse: response,
                    refresh: true
                }
            })
        }).catch(error => {
            handleError(error, dispatch)
        })
}

export const checkIfBusinessEntityExists = (businessEntityCode) => async dispatch => {
    dispatch({
        type: BUSINESS_ENTITY_CHECK_EXISTENCE,
        payload: true
    })
    axios.get(`${BASE_URL}/v1/businessEntities/?filterFields[]=code&filterValues[]=${businessEntityCode}`)
        .then(response => {
            dispatch({
                type: BUSINESS_ENTITY_CHECK_EXISTENCE_RESULT,
                payload: response
            })
        }).catch(error => {
            dispatch({
                type: BUSINESS_ENTITY_CHECK_EXISTENCE,
                payload: false
            })
            handleError(error, dispatch);
        })
}

export const resetBusinessEntityExistence = () => {
    return ({
        type: BUSINESS_ENTITY_RESET_EXISTENCE
    })
}

export const updateFilterForBusinessEntityList = (filterKey, filterValue) => {
    return ({
        type: BUSINESS_ENTITY_FILTER,
        payload: { filterKey, filterValue }
    })
}

export const clearBusinessEntityFilters = () => {
    return ({
        type: BUSINESS_ENTITY_CLEAR_FILTER
    })
}

export const sortBusinessEntities = (orderBy, sort) => {
    return ({
        type: BUSINESS_ENTITY_SORT,
        payload: { orderBy, sort }
    })
}