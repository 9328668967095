import {
    LOG_IN,
    LOG_OUT,
    ERROR,
    SET_RESET_PASSWORD_TOKEN,
    OTP_GET
} from "../actions/types";

const isAuthenticated = () => {
    const token = localStorage.getItem("merchant-portal-token")
    return !!token
}

const getUser = () => {
    const user = localStorage.getItem("merchant-portal-user")
    return user ? JSON.parse(user) : {}
}

const getToken = () => {
    const token = localStorage.getItem("merchant-portal-token")
    return token ? token : undefined
}

const getMerchantProduct = () => {
    const merchantProduct = localStorage.getItem("merchant-portal-product")
    return merchantProduct ? JSON.parse(merchantProduct) : {}
}

const getPasswordExpired = () => {
    const passwordExpired = localStorage.getItem("merchant-portal-password-expired")
    return passwordExpired ? passwordExpired : undefined
}

const setAuthentication = (token, user, merchantProduct) => {
    localStorage.setItem("merchant-portal-token", token);
    localStorage.setItem("merchant-portal-user", JSON.stringify(user));
    localStorage.setItem("merchant-portal-product", JSON.stringify(merchantProduct));
    localStorage.setItem("i18nextLng", user.languageCode)
    localStorage.setItem("merchant-portal-password-expired", (new Date(user.passwordValidUntil) < new Date()).toString())
}

const removeAuthentication = () => {
    localStorage.removeItem("merchant-portal-token");
    localStorage.removeItem("merchant-portal-product")
    localStorage.removeItem("merchant-portal-user");
    localStorage.removeItem("i18nextLng");
    localStorage.removeItem("merchant-portal-password-expired");
}

const INITIAL_STATE = {
    authenticated: isAuthenticated(),
    loggedInUser: getUser(),
    token: getToken(),
    merchantProduct: getMerchantProduct(),
    errorCode: undefined,
    errorMessage: undefined,
    forgetPasswordConfirmationRequest: {
        token: '',
        otpId: ''
    },
    passwordExpired: getPasswordExpired()
}

const authenticationReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case LOG_IN:
            if (action.payload.data.token && action.payload.data.merchantUser) {
                setAuthentication(action.payload.data.token, action.payload.data.merchantUser, action.payload.data.merchantProduct);
                return {
                    ...state,
                    authenticated: true,
                    loggedInUser: action.payload.data.merchantUser,
                    token: action.payload.data.token,
                    merchantProduct: action.payload.data.merchantProduct,
                    passwordExpired: (new Date(action.payload.data.merchantUser.passwordValidUntil) < new Date()).toString()
                }
            } else {
                return {
                    ...state,
                    authenticated: false,
                    user: undefined,
                    token: undefined,
                    merchantProduct: undefined
                }
            }
        case SET_RESET_PASSWORD_TOKEN:
            return {
                ...state,
                forgetPasswordConfirmationRequest: {
                    ...state.forgetPasswordConfirmationRequest,
                    token: action.payload.token
                }
            }
        case OTP_GET:
            return {
                ...state,
                forgetPasswordConfirmationRequest: {
                    ...state.forgetPasswordConfirmationRequest,
                    otpId: action.payload.data.otpId
                }
            }
        case LOG_OUT:
            removeAuthentication();
            return {
                ...state,
                authenticated: false,
                user: undefined,
                token: undefined,
                merchantProduct: undefined,
                passwordExpired: undefined,
                errorMessage: action.payload ? action.payload.data.translationKey : undefined
            }
        case ERROR:
            return {
                ...state,
                errorCode: action.payload.data.errorCode,
                errorMessage: action.payload.data.translationKey
            }
        default:
            return state;
    }
}

export default authenticationReducer;