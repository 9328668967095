import React from 'react';
import {useTranslation} from "react-i18next";
import {Formik} from "formik";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Typography from "@material-ui/core/Typography";
import {fullDate, shortDate} from "../utils/utils";
import TextField from "@material-ui/core/TextField";
import DatePickerField from "./DatePickerField";
import Button from "@material-ui/core/Button";
import ElasticSearch from "./ElasticSearch";
import ConfirmationDialogSimple from "./ConfirmationDialogSimple";

const customStyles = makeStyles({
    inputField: {
        marginTop: 10,
        marginBottom: 10
    }
})

const VoucherCodeForm = ({voucherCode, onFormSubmit, loggedInUser}) => {
    const { t } = useTranslation();
    const currentTime = new Date();
    const [skuAttributeValue, setSkuAttributeValue] = React.useState(undefined);
    const [dialogOpen, setDialogOpen] = React.useState(false);
    const classes = customStyles();

    const initialValues = {
        attributes: {
            shopCode: {
                value: loggedInUser.email,
                type: 'StringAttribute'
            },
            salesPersonCode: {
                value: '',
                type: 'StringAttribute'
            },
            dateOfPurchase: {
                value: currentTime,
                type: 'DateAttribute'
            },
            imei: {
                value: '',
                type: 'StringAttribute'
            },
            sku: {
                value: '',
                type: 'StringAttribute'
            }
        }
    }

    return (
        <Grid container>
            <Grid item md={3}>
                <Box mb={2}>
                    <Typography variant="subtitle2">{t('keys.serialNumber')}</Typography>
                    <p className="display-value">{voucherCode['serialNumber']}</p>
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle2">{t('keys.voucherType')}</Typography>
                    <p className="display-value">{voucherCode['voucherType']}</p>
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle2">{t('keys.productCode')}</Typography>
                    <p className="display-value">{voucherCode['productCode']}</p>
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle2">{t('keys.planCodes')}</Typography>
                    <p className="display-value">{voucherCode['planCodes']}</p>
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle2">{t('keys.brand')}</Typography>
                    <p className="display-value">{voucherCode['brand']}</p>
                </Box>
                {
                    voucherCode['description'] && <Box mb={2}>
                        <Typography variant="subtitle2">{t('keys.description')}</Typography>
                        {
                            Object.keys(voucherCode['description']).map((keyName, keyIndex) =>
                                <p className="display-value" key={keyName}>{t(`keys.locale.${keyName}`)}: {voucherCode['description'][keyName]}</p>
                            )
                        }
                    </Box>
                }
                <Box mb={2}>
                    <Typography variant="subtitle2">{t('keys.carrierCode')}</Typography>
                    <p className="display-value">{voucherCode['carrierCode']}</p>
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle2">{t('keys.maxCount')}</Typography>
                    <p className="display-value">{voucherCode['maxCount']} ({t('messages.currentCount', {count: voucherCode['currentCount']})})</p>
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle2">{t('keys.expiryDate')}</Typography>
                    <p className="display-value">{fullDate(voucherCode['expiryDate'], loggedInUser.countryCode) }</p>
                </Box>
                <Box mb={2}>
                    <Typography variant="subtitle2">{t('keys.active')}</Typography>
                    <p className="display-value">{t(`values.active.${voucherCode['active']}`)}</p>
                </Box>
                {
                    voucherCode['attributes'] !== undefined && voucherCode['attributes'].hasOwnProperty('subscriptionActivationDelayInMonths') &&
                    <Box mb={2}>
                        <Typography variant="subtitle2">{t('keys.subscriptionActivationDelayInMonths')}</Typography>
                        <p className="display-value">{voucherCode['attributes']['subscriptionActivationDelayInMonths']['value']}</p>
                    </Box>
                }
            </Grid>
            <Grid item md={3}>
                {
                    voucherCode['active'] ? <div>
                        <Box mb={2}>
                            <Typography variant="subtitle2">{t('keys.shopCode')}</Typography>
                            <p className="display-value">{voucherCode['attributes']['shopCode']['value']}</p>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="subtitle2">{t('keys.salesPersonCode')}</Typography>
                            <p className="display-value">{voucherCode['attributes']['salesPersonCode']['value'] || 'N/A'}</p>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="subtitle2">{t('keys.attributes.dateOfPurchase.value')}</Typography>
                            <p className="display-value">{shortDate(voucherCode['attributes']['dateOfPurchase']['value'], voucherCode['countryCode'])}</p>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="subtitle2">{t('keys.imei')}</Typography>
                            <p className="display-value">{voucherCode['attributes']['imei']['value'] || 'N/A'}</p>
                        </Box>
                        <Box mb={2}>
                            <Typography variant="subtitle2">{t('keys.sku')}</Typography>
                            <p className="display-value">{voucherCode['attributes']['sku']['value']}</p>
                        </Box>
                    </div> :
                    <Formik initialValues={initialValues}
                            validator={() => ({})}
                            onSubmit={(values, { setSubmitting }) => {
                                values['attributes']['sku']['value'] = skuAttributeValue;
                                onFormSubmit(values)
                            }}>
                        {props => {
                            const {
                                values,
                                handleChange,
                                handleSubmit,
                            } = props;
                            return (
                                <React.Fragment>
                                    <form>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            label={t('keys.shopCode')}
                                            name="attributes.shopCode.value"
                                            autoComplete='off'
                                            disabled
                                            className={classes.inputField}
                                            onChange={handleChange}
                                            value={values['attributes']['shopCode']['value']}
                                        />
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            label={t('keys.salesPersonCode')}
                                            name="attributes.salesPersonCode.value"
                                            autoComplete='off'
                                            className={classes.inputField}
                                            onChange={handleChange}
                                            value={values['attributes']['salesPersonCode']['value']}
                                        />
                                        <Typography variant="subtitle2">{t('keys.attributes.dateOfPurchase.value')}</Typography>
                                        <DatePickerField
                                            name="attributes.dateOfPurchase.value"
                                            className="input-field width-100 margin-bottom-10"
                                            placeholderText={t('messages.selectDateOfPurchase')}
                                            dateFormat="dd/MM/yyyy"
                                            timeZone={loggedInUser.timeZone}
                                        />
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            label={t('keys.imei')}
                                            name="attributes.imei.value"
                                            autoComplete='off'
                                            className={classes.inputField}
                                            onChange={handleChange}
                                            value={values['attributes']['imei']['value']}
                                        />
                                        {
                                            voucherCode['carrierCode'] !== undefined && <ElasticSearch carrierCode={voucherCode['carrierCode']} onSkuChange={(value) => setSkuAttributeValue(value)} />
                                        }
                                        <Button disabled={skuAttributeValue === undefined} onClick={() => setDialogOpen(true)} variant="contained" color="primary" className="float-right">
                                            {t('messages.activate')}
                                        </Button>
                                    </form>
                                    <ConfirmationDialogSimple onAccept={() => handleSubmit()}
                                                              onReject={() => setDialogOpen(false)}
                                                              open={dialogOpen}
                                                              action="activate"
                                                              entity="voucherCode"
                                    />
                                </React.Fragment>
                            );
                        }}
                    </Formik>
                }
            </Grid>
        </Grid>
    )
}

export default VoucherCodeForm;