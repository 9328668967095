import React, { Component } from "react";
import { connect } from 'react-redux';
import * as yup from 'yup';
import PageTemplate from "../components/PageTemplate";
import { getBusinessEntityDetails, updateBusinessEntity } from "../actions/businessEntityActions";
import { getMerchantUserList } from "../actions/merchantUserActions";
import { withTranslation } from "react-i18next";
import { addressFields, customerAddressFields } from "../utils/fields";
import ExtendedWarrantyDetailsView from "../components/extendedWarranty/ExtendedWarrantyDetailsView";
import CustomerDetailsView from "../components/customer/CustomerDetailsView";
import SubscriptionDetails from "../components/customer/SubscriptionDetailsPage";
import { addCustomerAddress, getCustomerDetails } from "../actions/customerActions";
import { getSubscriptionListByCustomer } from "../actions/subscriptionActions";
import { getExtendedWarrantyClaimListByCustomer, createExtendedWarrantyClaim } from "../actions/extendedWarrantyClaimActions";
import ExtendedWarrantyClaimService from "../components/customer/ExtendedWarrantyClaimService";

class CustomerDetails extends Component {
    componentDidMount() {
        this.props.getCustomerDetails(this.props.match.params.id);
        this.props.getSubscriptionListByCustomer(this.props.match.params.id);
        this.props.getExtendedWarrantyClaimListByCustomer(this.props.match.params.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.common.refresh !== prevProps.common.refresh && this.props.common.refresh
        ) {
            window.setTimeout(() => { window.location.reload(true); }, 2000)
        }

        if (this.props.extendedWarrantyClaims.extendedWarrantyClaim !== prevProps.extendedWarrantyClaims.extendedWarrantyClaim) {
            this.props.history.push(`/extendedWarrantyClaims/${this.props.extendedWarrantyClaims.extendedWarrantyClaim.id}`);
        }
    }

    updateCustomerAddress = (values) => {
        var addressSize = this.props.customers.customer.contact.addresses == null || this.props.customers.customer.contact.addressesr == [] ? 0 : this.props.customers.customer.contact.addresses.length;
        console.log("values submitted", values)
        var initialCustomer = this.props.customers.customer;
        if (initialCustomer.contact.addresses == null) {
            initialCustomer.contact.addresses = [];
        }

        initialCustomer.contact.addresses.push(values);

        var data = [{
            'op': 'add',
            'path': `/contact/addresses/${addressSize} `,
            'value': values
        }]
        this.props.addCustomerAddress(this.props.customers.customer.id, data, true)
    }

    createExtendedWarrantyClaim = (subscriptionId) => {
        var customer = this.props.customers.customer;
        var requestPayload = {
            'subscriptionId': subscriptionId,
            'customerId': customer?.id,
            'countryCode': customer?.countryCode,
            'status': 'new'
        }

        this.props.createExtendedWarrantyClaim(requestPayload);
    }

    renderDetails = (customer, subscriptions, extendedWarrantyClaims, countryCode, languageCode) => {

        const formFields = customerAddressFields[customer.countryCode];

        const editableFields = formFields?.map(formField => formField)
            .reduce((obj, key) => ({

                ...obj, [key]: ''

            }), {});
        if (editableFields != null) {
            editableFields["countryCode"] = customer?.countryCode;
            editableFields["type"] = "billingSubscription";

        }

        return (
            <>
                <CustomerDetailsView customer={customer} editableFields={editableFields} onAddressFormSubmit={values => this.updateCustomerAddress(values)} ></CustomerDetailsView>
                <SubscriptionDetails subscriptions={subscriptions} countryCode={countryCode} languageCode={languageCode} isBlacklisted={customer?.blacklisted} onRequestExtendedWarrantyClaim={subscriptionId => this.createExtendedWarrantyClaim(subscriptionId)}></SubscriptionDetails>
                {extendedWarrantyClaims != null && (
                    <ExtendedWarrantyClaimService extendedWarrantyClaims={extendedWarrantyClaims}></ExtendedWarrantyClaimService>
                )}

            </>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <PageTemplate title="Customer Details">
                {
                    this.renderDetails(this.props.customers.customer, this.props.subscriptions.subscriptions, this.props.extendedWarrantyClaims.extendedWarrantyClaims, this.props.authentication.loggedInUser.countryCode, this.props.authentication.loggedInUser.languageCode)
                }
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    customers: state.customers,
    merchantUsers: state.merchantUsers,
    common: state.common,
    subscriptions: state.subscriptions,
    extendedWarrantyClaims: state.extendedWarrantyClaims,
})

export default connect(mapStateToProps, {
    getCustomerDetails,
    getBusinessEntityDetails,
    updateBusinessEntity,
    getMerchantUserList,
    addCustomerAddress,
    getSubscriptionListByCustomer,
    getExtendedWarrantyClaimListByCustomer,
    createExtendedWarrantyClaim
})(withTranslation()(CustomerDetails));