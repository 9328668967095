import React from "react";
import "../../styles/custom-bootstrap.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  fullDateWithIndicator,
} from "../../utils/utils";
import ContactDetailsAddress from "./ContactDetailsAddress";

const ContactDetails = (props) => {
  const { customer, onAddressFormSubmit, editableFields } =
    props;
  const { t } = useTranslation();
  const customStyles = makeStyles({
    customBackground: {
      background: "#eceff1",
    },
  });
  
  const customStyleClass = customStyles();

  return (
    <>
        <div className="row">
            <div className="col-sm-6">
                    <div className="form-group">
                        <label>Last Name</label>
                        <div>
                            <label>{customer.contact?.lastName}</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>First Name</label>
                        <div>
                            <label>{customer.contact?.firstName}</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Gender</label>
                        <div>
                            <label>{customer.contact?.gender}</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Date of birth</label>
                        <div>
                        <label>{customer.contact?.dateOfBirth}</label>

                        </div>
                    </div>
                    <div className="form-group">
                        <label>Phone Number</label>
                        <div>
                        <label>{customer.contact?.phoneNumber}</label>

                        </div>
                    </div>
                    <div className="form-group">
                        <label>Email</label>
                        <div>
                        <label>{customer.contact?.email}</label>
                        </div>
                    </div>

                    <div className="form-group">
                        <label>Opt-Ins</label>
                        <div>
                        <label>{customer.contact?.optIns}</label>

                        </div>
                    </div>

                    <div className="form-group">
                        <label>Billing Key</label>
                        <div>
                    <label>{customer.tokenId != null ? 'true' : 'false'}</label>

                        </div>
                    </div>

                    <div className="form-group">
                        <label>Black listed</label>
                        <div>
                        <label>{customer.blacklisted != null ? customer.blacklisted : 'false'}</label>

                        </div>
                    </div>
                            
                    <div className="row form-group">
                        <div className="col-md-6">
                            <label>Document Type</label>
                            <div>
                                <label>{customer.contact?.ids[0]?.type}</label> 
                            </div>
                        </div>
                        <div className="col-md-6">
                            <label>Document Number</label>
                            <div>
                                <label>{customer.contact?.ids[0]?.number}</label>
                            </div>
                        </div>
                    </div>
            </div>
            
            <div className="col-md-6">
                  <ContactDetailsAddress customer={customer} onAddressFormSubmit={onAddressFormSubmit} editableFields={editableFields}/>
            </div>
        </div>
    </>
  );
};

export default ContactDetails;
