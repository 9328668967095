import React, {Component} from "react";
import { connect } from 'react-redux';
import PageTemplate from "../components/PageTemplate";
import {getSwapDetails} from "../actions/swapActions";
import {withTranslation} from "react-i18next";
import DataTableLoader from "../components/DataTableLoader";
import SectionTemplate from "../components/SectionTemplate";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import AddressCard from "../components/AddressCard";
import {fullDate} from "../utils/utils";
import Remarks from "../components/Remarks";
import HistoryTable from "../components/HistoryTable";
import {createdRemark, getRemarks} from "../actions/remarkActions";
import RepairForm from "../components/RepairForm";
import {showConfirmationDialog} from "../actions/commonActions";

class RepairDetails extends Component {
    componentDidMount() {
        this.props.getSwapDetails(this.props.match.params.id);
        this.props.getRemarks('swap', this.props.match.params.id);
    }

    renderDetails = (swap, t) => {
        return (
            <SectionTemplate title="deviceRepairs">
                <Grid container item spacing={6} md={12} style={{position: 'relative'}}>
                    <Grid item md={6}>
                        <div className="grouping-content">
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.number')}</Typography>
                                <p className="display-value">{swap.swap.number}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.lastName')}</Typography>
                                <p className="display-value">{swap.customer.contact.lastName}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.firstName')}</Typography>
                                <p className="display-value">{swap.customer.contact.firstName}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.usageType')}</Typography>
                                <p className="display-value">{t(`values.usageType.${swap.swap.usageType}`)}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.imei')}</Typography>
                                <p className="display-value">{swap['swap'].coveredDevice.imei}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.replacementDevice')}</Typography>
                                <p className="display-value">{swap['swap'].replacementDevice ? t('values.true') : t('values.false')}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.manufacturerName')}</Typography>
                                <p className="display-value">{swap.coveredArticle.manufacturer.name[`${this.props.authentication.loggedInUser.languageCode}_${this.props.authentication.loggedInUser.countryCode}`]}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.series')}</Typography>
                                <p className="display-value">{swap.coveredArticle.attributes.series.value[`${this.props.authentication.loggedInUser.languageCode}_${this.props.authentication.loggedInUser.countryCode}`]}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.storageSize')}</Typography>
                                <p className="display-value">{swap.coveredArticle.attributes.storageSize.value}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.color')}</Typography>
                                <p className="display-value">{swap.coveredArticle.attributes.color.value[`${this.props.authentication.loggedInUser.languageCode}_${this.props.authentication.loggedInUser.countryCode}`]}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.networkTechnology')}</Typography>
                                <p className="display-value">{swap.coveredArticle.attributes.networkTechnology.value}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.modelNumber')}</Typography>
                                <p className="display-value">{swap.coveredArticle.attributes.modelNumber.value[this.props.authentication.loggedInUser.countryCode]}</p>
                            </Box>
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        <RepairForm fields={swap['swap']}
                                    updateRepairParts={(value) => this.props.showConfirmationDialog(swap['swap'].id, 'N/A', value, 'swap', 'repairParts', 'update', 'string', 'updateRepairParts')}
                                    updateRepairCost={(value) => this.props.showConfirmationDialog(swap['swap'].id, swap['swap'].repairDetail.repairCost.amount, value, 'swap', 'repairCost', 'update', 'string', 'updateRepairCost')}
                                    updateStatus={(value) => this.props.showConfirmationDialog(swap['swap'].id, swap['swap'].status, value, 'swap', 'status', 'update', 'nestedTranslatedString', 'updateStatus')}
                        />
                    </Grid>
                </Grid>
            </SectionTemplate>
        )
    }

    renderDeliveryDetails = (swap, t) => {
        return(
            <SectionTemplate title="deliveryDetails">
                <Grid container item spacing={6} md={12} style={{position: 'relative'}}>
                    <Grid item md={6}>
                        <div className="grouping-content">
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.lastName')}</Typography>
                                <p className="display-value">{swap['swap'].deliveryDetails.lastName}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.firstName')}</Typography>
                                <p className="display-value">{swap['swap'].deliveryDetails.firstName}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.gender')}</Typography>
                                <p className="display-value">{swap['swap'].deliveryDetails.gender}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.phoneNumber')}</Typography>
                                <p className="display-value">{swap['swap'].deliveryDetails.phoneNumber}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.email')}</Typography>
                                <p className="display-value">{swap['swap'].deliveryDetails.email}</p>
                            </Box>
                        </div>
                    </Grid>
                    <Grid item md={6}>
                        {swap?.['swap']?.deliveryDetails?.addresses?.map((address, index) => (
                            <AddressCard countryCode={this.props.authentication.loggedInUser.countryCode} address={address} key={address.street + index } />
                        ))}
                    </Grid>
                </Grid>
            </SectionTemplate>
            )
    }

    renderCourierInformation = (swap, t) => {
        return (
            <SectionTemplate title="courierInformation">
                <Grid container item spacing={6} md={12} style={{position: 'relative'}}>
                    <Grid item md={6}>
                        <div className="grouping-content">
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.courierArrangedTime')}</Typography>
                                <p className="display-value">{fullDate(swap['swap'].repairDetail.courierArrangedTimeFrom, this.props.authentication.loggedInUser.countryCode)} - {fullDate(swap['swap'].repairDetail.courierArrangedTimeTo, this.props.authentication.loggedInUser.countryCode)}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.courierUsed')}</Typography>
                                <p className="display-value">{swap['swap'].repairDetail.courierUsed}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.trackingNumber')}</Typography>
                                <p className="display-value">{swap['swap'].repairDetail.trackingNumber}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.driverLastName')}</Typography>
                                <p className="display-value">{swap['swap'].repairDetail.driverLastName}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.driverFirstName')}</Typography>
                                <p className="display-value">{swap['swap'].repairDetail.driverFirstName}</p>
                            </Box>

                        </div>
                    </Grid>
                </Grid>
            </SectionTemplate>
        )
    }

    renderHistory = (swap) => {
        return (
            <SectionTemplate title="history">
                <HistoryTable history={swap.history} countryCode={this.props.authentication.loggedInUser.countryCode} />
            </SectionTemplate>
        )
    }

    renderRemarks = (remarks, entityId, entity, extendable) => {
        return (
            <SectionTemplate title="remarks">
                <Remarks remarks={remarks}
                         entityId={entityId}
                         entityName={entity}
                         extendable={extendable}
                         onFormSubmit={(data) => this.props.createdRemark(data, entityId)}
                />
            </SectionTemplate>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <PageTemplate title="messages.repairDetails">
                {this.props.common.showContentLoader || this.props.swaps.swapObject.customer == null ? <DataTableLoader /> : this.renderDetails(this.props.swaps.swapObject, t)}
                {this.props.common.showContentLoader || this.props.swaps.swapObject.customer == null ? <DataTableLoader /> : this.renderDeliveryDetails(this.props.swaps.swapObject, t)}
                {this.props.common.showContentLoader || this.props.swaps.swapObject.customer == null ? <DataTableLoader /> : this.renderCourierInformation(this.props.swaps.swapObject, t)}
                {this.props.common.showContentLoader || this.props.swaps.swapObject.customer == null ? <DataTableLoader /> : this.renderHistory(this.props.swaps.swapObject['swap'])}
                {this.props.common.showContentLoader || this.props.swaps.swapObject.customer == null ? <DataTableLoader /> : this.renderRemarks(this.props.remarks.remarks[this.props.swaps.swapObject['swap'].id] || [], this.props.swaps.swapObject['swap'].id, 'swap', false)}
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    common: state.common,
    swaps: state.swaps,
    remarks: state.remarks,
})

export default connect(mapStateToProps, {
    getSwapDetails,
    createdRemark,
    getRemarks,
    showConfirmationDialog,
})(withTranslation() (RepairDetails));