import {createMuiTheme} from '@material-ui/core/styles';

const themeBolttech = createMuiTheme({
    palette: {
        primary: {
            main: '#170F4F',
        },
        secondary: {
            main: '#00BAC7',
        }
    },
    overrides: {
        MuiTableCell: {
            head: {
                fontWeight: 'bold'
            }
        },
        MuiToolbar: {
            regular: {
                minHeight: 'unset !important'
            }
        },
        MuiRadio: {
            root: {
                color: '#170F4F',
            },
            colorSecondary: {
                '&$checked': {
                    color: '#170F4F',
                }
            }
        },
        MuiMenuItem: {
            root: {
                paddingTop: '10px',
                paddingBottom: '10px'
            }
        },
        MuiDialog: {
            paperWidthSm: {
                minWidth: 300,
                minHeight: 150
            }
        },
        MuiTableSortLabel: {
            root: {
                color: '#FFFFFF',
                "&:hover": {
                    color: '#FFFFFF',

                    '&& $icon': {
                        opacity: 1,
                        color: '#00BAC7'
                    },
                },
                "&$active": {
                    color: '#FFFFFF',

                    // && instead of & is a workaround for https://github.com/cssinjs/jss/issues/1045
                    '&& $icon': {
                        opacity: 1,
                        color: '#00BAC7'
                    },
                },
            },
        }
    }

});

export default themeBolttech;