import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    DOWNLOAD_REQUEST_CLEAR_FILTER,
    DOWNLOAD_REQUEST_FILTER,
    DOWNLOAD_REQUEST_GET_LIST, DOWNLOAD_REQUEST_SORT
} from "./types";
import {handleError, showContentLoader} from "../utils/utils";

export const getDownloadRequestList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/export/getDownloadRequests/?${path}`)
        .then(response => {
            dispatch({
                type: DOWNLOAD_REQUEST_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
    })
}

export const updateFilterForDownloadRequestList = (filterKey, filterValue) => {
    return ({
        type: DOWNLOAD_REQUEST_FILTER,
        payload: {filterKey, filterValue}
    })
}

export const clearDownloadRequestFilters = () => {
    return ({
        type: DOWNLOAD_REQUEST_CLEAR_FILTER
    })
}

export const sortDownloadRequests = (orderBy, sort) => {
    return ({
        type: DOWNLOAD_REQUEST_SORT,
        payload: {orderBy, sort}
    })
}