import React, {Component} from "react";
import { connect } from 'react-redux';
import PageTemplate from "../components/PageTemplate";
import {
    createMerchantUser
} from "../actions/merchantUserActions";
import {checkIfBusinessEntityExists, resetBusinessEntityExistence} from "../actions/businessEntityActions";
import UserForm from "../components/UserForm";

class UserCreate extends Component {
    componentDidMount() {
        this.props.resetBusinessEntityExistence();
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (
            this.props.common.dialogType !== prevProps.common.dialogType &&
            this.props.common.dialogType === 'success'
        ) {
            this.props.history.push('/users');
        }
    }

    createUser = (merchantUser) => {
        this.props.createMerchantUser(merchantUser);
    }

    checkBusinessEntity = (value) => {
        this.props.checkIfBusinessEntityExists(value)
    }

    renderForm = () => {
        const user = {
            displayName: '',
            email: '',
            secondaryEmail: '',
            phoneNumber: '',
            secondaryPhoneNumber: '',
            languageCode: '',
            authorities: [],
            businessEntityCode: ''
        }

        return (
        <UserForm editableFields={user}
                  loggedInUser={this.props.authentication.loggedInUser}
                  authorities={this.props.authentication.merchantProduct.authorities}
                  onBusinessEntityCheck={value => this.checkBusinessEntity(value)}
                  businessEntityExists={this.props.businessEntities.businessEntityExists}
                  businessEntityLoading={this.props.businessEntities.businessEntityExistenceChecking}
                  onFormSubmit={values => this.createUser(values)} />
        )
    }

    render() {
        return(
            <PageTemplate title="messages.userCreate">
                {this.renderForm()}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    authentication: state.authentication,
    businessEntities: state.businessEntities,
    common: state.common
})

export default connect(mapStateToProps, {
    createMerchantUser,
    checkIfBusinessEntityExists,
    resetBusinessEntityExistence
})(UserCreate);