import React, {Component} from "react";
import PageTemplate from "../components/PageTemplate";
import {connect} from "react-redux";
import {
    clearPickUpItemFilters,
    getPickUpItemList,
    sortPickUpItems,
    updateFiltersForPickUpItemList
} from "../actions/pickUpItemActions";
import DataTable from "../components/DataTable";
import {parseObjectToString, validateAuthority} from "../utils/utils";
import {AUTHORITY_PICK_UP_MANAGER, AUTHORITY_READ_ONLY, AUTHORITY_VIDEO_CONTROLLER} from "../constant";
import {pickUpItemsExportParams, remarksExport} from "../utils/exportUtils";
import Button from "@material-ui/core/Button";
import {exportFile} from "../actions/exportActions";

class PickUpItemList extends Component {
    componentDidMount() {
        this.props.getPickUpItemList('orderBy=createdAt&sort=desc');
        this.props.clearPickUpItemFilters();
        this.props.sortPickUpItems('createdAt', 'desc');
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let pickUpItemFilterState = this.props.pickUpItems.filters[filterKey];
            pickUpItemFilterState.includes(filterValue) ? pickUpItemFilterState = pickUpItemFilterState.filter(item => item !== filterValue) : pickUpItemFilterState.push(filterValue);
            filterValue = pickUpItemFilterState;
        }
        this.props.updateFiltersForPickUpItemList(filterKey, filterValue)
    }

    handleFilterReset() {
        this.props.clearPickUpItemFilters();
        this.props.getPickUpItemList('orderBy=createdAt&sort=desc');
        this.props.sortPickUpItems('createdAt', 'desc');
    }

    handleSorting(orderBy, sort) {
        this.props.sortPickUpItems(orderBy, sort)
        this.props.getPickUpItemList(parseObjectToString(this.props.pickUpItems.filters, {orderBy, sort}));
    }

    renderPickUpList = (pickUpItems, showContentLoader) => {
        const fields = [
            {
                name: 'imei'
            },
            {
                name: 'businessEntityCode'
            },
            {
                name: 'type',
                type: 'translatedString'
            },
            {
                name: 'isBooked',
                type: 'translatedString'
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }];

        const filterKeys = [
            {
                name: 'imei',
                type: 'textField'
            },
            {
                name: 'businessEntityCode',
                type: 'textField'
            },
            {
                name: 'type',
                type: 'checkBoxes',
                options: ['upgrade', 'tradeIn']
            },
            {
                name: 'isBooked',
                type: 'checkBoxes',
                options: ['true', 'false']
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            }
        ]

        if (validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_VIDEO_CONTROLLER, AUTHORITY_READ_ONLY], this.props.authentication.loggedInUser.authorities)) {
            fields.splice(3,0,
                {
                    name: 'deviceCondition',
                    type: 'translatedString'
                }
            )
            filterKeys.splice(3, 0,
                {
                    name: 'deviceCondition',
                    type: 'checkBoxes',
                    options: ['ok',
                        'okMajorAnomalies',
                        'okMinorAnomalies',
                        'lost',
                        'unknownQuarantine',
                        'itemNoPresent',
                        'deliveredSeparately',
                        'requestCancelled'
                    ]
                })
        }
        return(
            <DataTable fields={fields}
                       items={pickUpItems}
                       entity="pickUps"
                       redirectId="pickUpId"
                       filterKeys={filterKeys}
                       filterValues={this.props.pickUpItems.filters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getPickUpItemList(parseObjectToString(this.props.pickUpItems.filters))}
                       sorting={this.props.pickUpItems.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
            />
        )
    }

    onDocumentDownload() {
        this.props.exportFile(pickUpItemsExportParams(this.props.authentication.loggedInUser.authorities));
        this.props.exportFile(remarksExport);
    }

    render() {
        return (
            <PageTemplate title="messages.pickUpItemList">
                <div className="display-flex justify-space-between align-items-center margin-bottom-10">
                    <p/>
                    {
                        !validateAuthority([AUTHORITY_READ_ONLY], this.props.authentication.loggedInUser.authorities) &&
                        <Button variant="contained" size="small" color="secondary" onClick={() => this.onDocumentDownload()}>download</Button>
                    }
                </div>
                {this.renderPickUpList(this.props.pickUpItems.pickUpItems, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    pickUpItems: state.pickUpItems,
    authentication: state.authentication,
    common: state.common,
    export: state.export
})

export default connect(mapStateToProps, {
    getPickUpItemList,
    updateFiltersForPickUpItemList,
    clearPickUpItemFilters,
    sortPickUpItems,
    exportFile
})(PickUpItemList);