import React, { useState } from "react";
import "../../styles/custom-bootstrap.scss";
import "../../styles/custom-react-date-picker.css";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { Grid, TextField, Typography } from "@material-ui/core";
import { useFormikContext, getIn } from "formik";
import { addressFields } from "../../utils/fields";
import { setBusinessEntity } from "../../actions/businessEntityActions"

const ServiceCenterView = (props) => {
  const { extendedWarrantyClaim, businessEntities } =
    props;

  const { t } = useTranslation();
  const customStyles = makeStyles({
    inputField: {
      marginTop: 10,
      marginBottom: 10
    },
    selectField: {
      marginBottom: 10
    },
    card: {
      width: '100%'
    },
    customStyleForSelect: {
      color: '#f44336',
      fontSize: '0.75rem',
      marginTop: '3px',
      marginLeft: '14px',
      marginRight: '14px'
    },
    selectedCard: {
      border: "2px solid #170f4f !important"
    }
  })
  const classes = customStyles();

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const formik = useFormikContext();

  const [fieldToReveal, setFieldToReveal] = useState(null)
  const [selectedBusinessEntity, setSelectedBusinessEntity] = useState(null)

  const handleSelectBusinessEntity = (businessEntity) => {
    setSelectedBusinessEntity(businessEntity);
    formik.setFieldValue('businessEntityCode', businessEntity.code)
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <i className="fa fa-phone" aria-hidden="true"></i>
              <strong> {'Service Center Information'}</strong>
            </div>
            <div className="card-body">
              {extendedWarrantyClaim?.status == "pendingInternal" && (
                <div className="row" style={{ borderBottom: '1px solid silver', marginBottom: '16px', overflowY: 'scroll', maxHeight: '360px' }}>
                  {businessEntities.map((supportedBusinessEntity) => {
                    return (
                      <div key={supportedBusinessEntity.id} className="col-sm-3">
                        <div className={`card  ${selectedBusinessEntity?.id == supportedBusinessEntity.id ? classes.selectedCard : ''}`} style={{ cursor: 'pointer' }} onClick={() => handleSelectBusinessEntity(supportedBusinessEntity)}>
                          <div className="card-header">
                            <strong>{"Business Entity Code"}</strong><br />
                            <label>{supportedBusinessEntity.code}</label>
                          </div>
                          <div className="card-body">
                            <strong>{"Business Entity Name"}</strong><br />
                            <label>{supportedBusinessEntity.name}</label><br />
                            <strong>{"Address"}</strong><br />
                            <div>
                              {supportedBusinessEntity.address?.homeNumber && <span>{supportedBusinessEntity.address?.homeNumber + ", "}</span>}
                              {supportedBusinessEntity.address?.flat && <span>{supportedBusinessEntity.address?.flat + ", "}</span>}
                              {supportedBusinessEntity.address?.floor && <span>{supportedBusinessEntity.address?.floor + ", "}</span>}
                              {supportedBusinessEntity.address?.building && <span>{supportedBusinessEntity.address?.building + ", "}</span>}
                              {supportedBusinessEntity.address?.lane && <span>{supportedBusinessEntity.address?.lane + ", "}</span>}
                              {supportedBusinessEntity.address?.number && <span>{supportedBusinessEntity.address?.number + ", "}</span>}
                              {supportedBusinessEntity.address?.street && <span>{supportedBusinessEntity.address?.street + ", "}</span>}
                              {supportedBusinessEntity.address?.district && <span>{supportedBusinessEntity.address?.district + ", "}</span>}
                              {supportedBusinessEntity.address?.zipCode && <span>{supportedBusinessEntity.address?.zipCode + ", "}</span>}
                              {supportedBusinessEntity.address?.city && <span>{supportedBusinessEntity.address?.city + ", "}</span>}
                              {supportedBusinessEntity.address?.provinceCode && <span>{supportedBusinessEntity.address?.provinceCode + ", "}</span>}
                            </div>
                          </div>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )}
              <div>

              </div>
              <div className="card">
                <div className="card-header">
                  <i className="fa fa-building" style={{ marginRight: '3px' }} aria-hidden="true"></i>
                  {extendedWarrantyClaim?.businessEntity ? (
                    <a href={`/businessEntities/${extendedWarrantyClaim?.businessEntity?.id}`}>{`Business Entity Details - ${extendedWarrantyClaim?.businessEntity?.id}`}</a>
                  ) : (
                    <span>{t('Business Entity Details')}</span>
                  )}
                </div>
                <div className="card-body">
                  <Grid container spacing={2}>
                    <Grid item xs={4}>
                      <TextField
                        key={`businessEntityCode`}
                        label={t(`keys.businessEntity.code`)}
                        name="businessEntityCode"
                        variant="filled"
                        autoComplete='off'
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true, // Example prop
                          classes: {
                            root: 'custom-label-root-class',
                            focused: 'custom-label-focused-class',
                          },
                        }}
                        InputProps={{
                          disableUnderline: false,
                          shrink: true
                        }}
                        className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                        onChange={formik.handleChange}
                        value={selectedBusinessEntity ? selectedBusinessEntity.code : (extendedWarrantyClaim?.businessEntity?.code || '')}
                      />

                      <TextField
                        key={`businessEntity.name`}
                        label={t(`keys.businessEntity.name`)}
                        name="businessEntity.name"
                        variant="filled"
                        autoComplete='off'
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true, // Example prop
                          classes: {
                            root: 'custom-label-root-class',
                            focused: 'custom-label-focused-class',
                          },
                        }}
                        InputProps={{
                          disableUnderline: false,
                          shrink: true
                        }}
                        className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                        onChange={formik.handleChange}
                        value={selectedBusinessEntity ? selectedBusinessEntity.name : (extendedWarrantyClaim?.businessEntity?.name || '')}
                      />

                      <TextField
                        key={`businessEntity.channel`}
                        label={t(`keys.businessEntity.channel`)}
                        name="businessEntity.channel"
                        variant="filled"
                        autoComplete='off'
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true, // Example prop
                          classes: {
                            root: 'custom-label-root-class',
                            focused: 'custom-label-focused-class',
                          },
                        }}
                        InputProps={{
                          disableUnderline: false,
                          shrink: true
                        }}
                        className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                        onChange={formik.handleChange}
                        value={selectedBusinessEntity ? selectedBusinessEntity.channel : (extendedWarrantyClaim?.businessEntity?.channel || '')}
                      />

                      <TextField
                        key={`businessEntity.dealerCode`}
                        label={t(`keys.businessEntity.dealerCode`)}
                        name="businessEntity.dealerCode"
                        variant="filled"
                        autoComplete='off'
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true, // Example prop
                          classes: {
                            root: 'custom-label-root-class',
                            focused: 'custom-label-focused-class',
                          },
                        }}
                        InputProps={{
                          disableUnderline: false,
                          shrink: true
                        }}
                        className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                        onChange={formik.handleChange}
                        value={selectedBusinessEntity ? selectedBusinessEntity.dealerCode : (extendedWarrantyClaim?.businessEntity?.dealerCode || '')}
                      />

                      <TextField
                        key={`businessEntity.aggregatedPOSCode`}
                        label={t(`keys.businessEntity.aggregatedPOSCode`)}
                        name="businessEntity.aggregatedPOSCode"
                        variant="filled"
                        autoComplete='off'
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true, // Example prop
                          classes: {
                            root: 'custom-label-root-class',
                            focused: 'custom-label-focused-class',
                          },
                        }}
                        InputProps={{
                          disableUnderline: false,
                          shrink: true
                        }}
                        className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                        onChange={formik.handleChange}
                        value={selectedBusinessEntity ? selectedBusinessEntity.aggregatedPOSCode : (extendedWarrantyClaim?.businessEntity?.aggregatedPOSCode || '')}
                      />

                      <TextField
                        key={`businessEntity.countryCode`}
                        label={t(`keys.businessEntity.countryCode`)}
                        name="businessEntity.countryCode"
                        variant="filled"
                        autoComplete='off'
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true, // Example prop
                          classes: {
                            root: 'custom-label-root-class',
                            focused: 'custom-label-focused-class',
                          },
                        }}
                        InputProps={{
                          disableUnderline: false,
                          shrink: true
                        }}
                        className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                        onChange={formik.handleChange}
                        value={selectedBusinessEntity ? selectedBusinessEntity.countryCode : (extendedWarrantyClaim?.businessEntity?.countryCode || '')}
                      />

                      <TextField
                        key={`businessEntity.personInChargeName`}
                        label={t(`keys.businessEntity.personInChargeName`)}
                        name="businessEntity.personInChargeName"
                        variant="filled"
                        autoComplete='off'
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true, // Example prop
                          classes: {
                            root: 'custom-label-root-class',
                            focused: 'custom-label-focused-class',
                          },
                        }}
                        InputProps={{
                          disableUnderline: false,
                          shrink: true
                        }}
                        className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                        onChange={formik.handleChange}
                        value={selectedBusinessEntity ? selectedBusinessEntity.personInChargeName : (extendedWarrantyClaim?.businessEntity?.personInChargeName || '')}
                      />

                      <TextField
                        key={`businessEntity.personInChargePhoneNumber`}
                        label={t(`keys.businessEntity.personInChargePhoneNumber`)}
                        name="businessEntity.personInChargePhoneNumber"
                        variant="filled"
                        autoComplete='off'
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true, // Example prop
                          classes: {
                            root: 'custom-label-root-class',
                            focused: 'custom-label-focused-class',
                          },
                        }}
                        InputProps={{
                          disableUnderline: false,
                          shrink: true
                        }}
                        className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                        onChange={formik.handleChange}
                        value={selectedBusinessEntity ? selectedBusinessEntity.personInChargePhoneNumber : (extendedWarrantyClaim?.businessEntity?.personInChargePhoneNumber || '')}
                      />

                    </Grid>

                    <Grid item xs={4}>
                      <TextField
                        key={`businessEntity.description`}
                        label={t(`keys.businessEntity.description`)}
                        name="businessEntity.description"
                        variant="filled"
                        autoComplete='off'
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true, // Example prop
                          classes: {
                            root: 'custom-label-root-class',
                            focused: 'custom-label-focused-class',
                          },
                        }}
                        InputProps={{
                          disableUnderline: false,
                          shrink: true
                        }}
                        className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                        onChange={formik.handleChange}
                        value={selectedBusinessEntity ? selectedBusinessEntity.description : (extendedWarrantyClaim?.businessEntity?.description || '')}
                      />

                      <TextField
                        key={`businessEntity.businessHours`}
                        label={t(`keys.businessEntity.businessHours`)}
                        name="businessEntity.businessHours"
                        variant="filled"
                        autoComplete='off'
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true, // Example prop
                          classes: {
                            root: 'custom-label-root-class',
                            focused: 'custom-label-focused-class',
                          },
                        }}
                        InputProps={{
                          disableUnderline: false,
                          shrink: true
                        }}
                        className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                        onChange={formik.handleChange}
                        value={selectedBusinessEntity ? selectedBusinessEntity.businessHours : (extendedWarrantyClaim?.businessEntity?.businessHours || '')}
                      />

                      <TextField
                        key={`businessEntity.email`}
                        label={t(`keys.businessEntity.email`)}
                        name="businessEntity.email"
                        variant="filled"
                        autoComplete='off'
                        fullWidth
                        disabled
                        InputLabelProps={{
                          shrink: true, // Example prop
                          classes: {
                            root: 'custom-label-root-class',
                            focused: 'custom-label-focused-class',
                          },
                        }}
                        InputProps={{
                          disableUnderline: false,
                          shrink: true
                        }}
                        className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                        onChange={formik.handleChange}
                        value={selectedBusinessEntity ? selectedBusinessEntity.channel : (extendedWarrantyClaim?.businessEntity?.email || '')}
                      />
                      <div>
                        <Grid container>
                          {
                            extendedWarrantyClaim?.countryCode && addressFields[extendedWarrantyClaim.countryCode].map(addressField => {
                              return <TextField
                                key={`address.${addressField}`}
                                variant="filled"
                                disabled
                                label={t(`keys.address.${addressField}`)}
                                name={`address.${addressField}`}
                                autoComplete='off'
                                type={fieldToReveal == `address.${addressField}` ? "text" : "password"}
                                InputLabelProps={{
                                  shrink: true, // Example prop
                                  classes: {
                                    root: 'custom-label-root-class',
                                    focused: 'custom-label-focused-class',
                                  },
                                }}
                                InputProps={{
                                  disableUnderline: false,
                                  shrink: true
                                }}
                                className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                                onChange={formik.handleChange}
                                onMouseEnter={() => setFieldToReveal(`address.${addressField}`)}
                                onMouseLeave={() => setFieldToReveal(null)}
                                value={selectedBusinessEntity ? selectedBusinessEntity.address[addressField] : (extendedWarrantyClaim?.businessEntity?.address || '')[addressField]}
                              />
                            })
                          }

                          <TextField
                            key={`businessEntity.coordinateX`}
                            label={t(`keys.businessEntity.coordinateX`)}
                            name={`businessEntity.coordinateX`}
                            variant="filled"
                            disabled
                            autoComplete='off'
                            InputLabelProps={{
                              shrink: true, // Example prop
                              classes: {
                                root: 'custom-label-root-class',
                                focused: 'custom-label-focused-class',
                              },
                            }}
                            InputProps={{
                              disableUnderline: false,
                              shrink: true
                            }}
                            className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                            onChange={formik.handleChange}
                            value={selectedBusinessEntity ? selectedBusinessEntity.coordinateX : (extendedWarrantyClaim?.businessEntity?.coordinateX || '')}
                          />

                          <TextField
                            key={`businessEntity.coordinateY`}
                            label={t(`keys.businessEntity.coordinateY`)}
                            name={`businessEntity.coordinateY`}
                            variant="filled"
                            disabled
                            autoComplete='off'
                            InputLabelProps={{
                              shrink: true, // Example prop
                              classes: {
                                root: 'custom-label-root-class',
                                focused: 'custom-label-focused-class',
                              },
                            }}
                            InputProps={{
                              disableUnderline: false,
                              shrink: true
                            }}
                            className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                            onChange={formik.handleChange}
                            value={selectedBusinessEntity ? selectedBusinessEntity.coordinateY : (extendedWarrantyClaim?.businessEntity?.coordinateY || '')}
                          />

                          <TextField
                            key={`businessEntity.shopPhoneNumber`}
                            label={t(`keys.businessEntity.shopPhoneNumber`)}
                            name="businessEntity.shopPhoneNumber"
                            variant="filled"
                            autoComplete='off'
                            fullWidth
                            disabled
                            InputLabelProps={{
                              shrink: true, // Example prop
                              classes: {
                                root: 'custom-label-root-class',
                                focused: 'custom-label-focused-class',
                              },
                            }}
                            InputProps={{
                              disableUnderline: false,
                              shrink: true
                            }}
                            className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                            onChange={formik.handleChange}
                            value={selectedBusinessEntity ? selectedBusinessEntity.shopPhoneNumber : (extendedWarrantyClaim?.businessEntity?.shopPhoneNumber || '')}
                          />
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};


export default ServiceCenterView;
