import React from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import makeStyles from "@material-ui/core/styles/makeStyles";
import * as yup from "yup";
import TextField from "@material-ui/core/TextField";
import {Chip, IconButton, InputAdornment} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Tooltip from "@material-ui/core/Tooltip";
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from "@material-ui/icons/Add";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

const customStyles = makeStyles({
    inputField: {
        marginTop: 10,
        marginBottom: 10
    },
    card: {
        width: '100%'
    },
    cardDanger: {
        borderColor: '#f44336'
    }
})

const RepairForm = ({ fields, updateRepairParts, updateStatus, updateRepairCost }) => {
    const { t } = useTranslation();
    const classes = customStyles();

    const validationRules = yup.object({
        repairParts: yup.string(),
        'repairCost.amount': yup.number(),
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            status: fields.status,
            repairDetailCostAmount: fields.repairDetail.repairCost?.amount,
            additionalRepairPart: ''
        },
        validationSchema: validationRules,
    })

    const addRepairPart = (value) => {
        updateRepairParts(value)
        formik.values['additionalRepairPart'] = '';
    }

    const updateCost = (value) => {
        updateRepairCost(value);
    }

    return (
        <form onSubmit={formik.handleSubmit} className="padding-bottom-10">
            <Grid container item md={12} spacing={5}>
                <Grid item md={12}>
                    <div className="grouping-content">
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t('keys.repairParts')}
                            name="additionalRepairPart"
                            autoComplete='off'
                            className={classes.inputField}
                            onChange={formik.handleChange}
                            value={formik.values['additionalRepairPart']}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton disabled={formik.values['additionalRepairPart'] === formik.initialValues['additionalRepairPart'] || formik.values['additionalRepairPart'] === ''} size="small" onClick={() => addRepairPart(formik.values['additionalRepairPart'])}>
                                        <Tooltip title={t('messages.add')}>
                                            <AddIcon />
                                        </Tooltip>
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />

                        <div className="display-flex">
                            {
                                fields.repairDetail.repairParts && fields.repairDetail.repairParts.map(repairPart => {
                                    return (
                                        <Chip
                                            className="margin-right-10"
                                            key={repairPart}
                                            variant="outlined"
                                            label={repairPart}
                                        />
                                    )
                                })
                            }
                        </div>

                    </div>
                    <div className="grouping-content margin-top-20">
                        <TextField
                            variant="outlined"
                            fullWidth
                            type="number"
                            label={t('keys.repairCost')}
                            name="repairDetailCostAmount"
                            autoComplete='off'
                            className={classes.inputField}
                            onChange={formik.handleChange}
                            value={formik.values['repairDetailCostAmount']}
                            InputProps={{
                                endAdornment: <InputAdornment position="end">
                                    <IconButton disabled={formik.values['repairDetailCostAmount'] === formik.initialValues['repairDetailCostAmount'] || formik.values['repairDetailCostAmount'] === ''} size="small" onClick={() => updateCost(formik.values['repairDetailCostAmount'])}>
                                        <Tooltip title={t('messages.save')}>
                                            <SaveIcon />
                                        </Tooltip>
                                    </IconButton>
                                </InputAdornment>
                            }}
                        />
                    </div>
                    <div className="grouping-content margin-top-20">
                        {
                            fields.status !== 'rejected' &&
                            <Typography variant="subtitle2">{t('messages.updateStatus')}</Typography>
                        }
                        {
                            fields.status === 'rejected' && !fields.replacementDevice &&
                            <Typography variant="subtitle2">{t('messages.updateStatus')}</Typography>
                        }
                        {
                            fields.status === 'availableForProcess' &&
                            <Button onClick={() => updateStatus('setToReceived')} variant="contained" color="primary">{t('values.status.received')}</Button>

                        }
                        {
                            fields.status === 'received' &&
                            <div className="display-flex">
                                <Button className="margin-right-10" onClick={() => updateStatus('setToProcessingDevice')} variant="contained" color="primary">{t('values.status.processingDevice')}</Button>
                                <Button className="margin-left-10" onClick={() => updateStatus('setToRejected')} variant="contained" color="secondary">{t('values.status.rejected')}</Button>
                            </div>
                        }
                        {
                            fields.status === 'processingDevice' &&
                            <div className="display-flex">
                                <Button onClick={() => updateStatus('setToPendingDelivery')} variant="contained" color="secondary">{t('values.status.pendingDelivery')}</Button>
                            </div>
                        }
                        {
                            fields.status === 'pendingDelivery' &&
                            <div className="display-flex">
                                <Button onClick={() => updateStatus('return')} variant="contained" color="secondary">{t('values.status.returned')}</Button>
                            </div>
                        }
                        {
                            fields.status === 'rejected' && !fields.replacementDevice &&
                            <div className="display-flex">
                                <Button onClick={() => updateStatus('return')} variant="contained" color="secondary">{t('values.status.returned')}</Button>
                            </div>
                        }
                    </div>
                </Grid>
            </Grid>

        </form>
    )


}

export default RepairForm;