import React from "react";
import "../../styles/custom-bootstrap.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  fullDateWithIndicator,
} from "../../utils/utils";

const ClaimDetailsView = (props) => {
  const { extendedWarrantyClaim, subscription, userCountryCode } =
    props;
  const { t } = useTranslation();
  const customStyles = makeStyles({
    customBackground: {
      background: "#eceff1",
    },
  });
  const customStyleClass = customStyles();

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <i class="fa fa-history" aria-hidden="true"></i>
              <strong> {'Claim Details'}</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>{'EW Claim Number'}</label>
                    <br />
                    <label className="font-weight-bold">
                      {extendedWarrantyClaim?.number}
                    </label>
                    <br />
                  </div>
                </div><div className="col-md-3">
                  <div className="form-group">
                    <label>{'Subscription IMEI / SN'}</label>
                    <br />
                    <label className="font-weight-bold">
                      {subscription?.device?.imei}
                    </label>
                    <br />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>{'Subscription Shop Code'}</label>
                    <br />
                    <label className="font-weight-bold">
                      {subscription?.shopCode}
                    </label>
                    <br />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>{'External ID'}</label>
                    <br />
                    <label className="font-weight-bold">
                      {extendedWarrantyClaim.beneficiary?.externalId}
                    </label>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ClaimDetailsView;
