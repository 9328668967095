import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    DELIVERY_BOX_CLEAR_FILTER,
    DELIVERY_BOX_FILTER, DELIVERY_BOX_SORT, DELIVERY_CREATED_BOX_ORDER,
    DELIVERY_GET,
    DELIVERY_GET_BOX_ORDER,
    DELIVERY_GET_BOX_ORDERS,
    DELIVERY_UNSET, SHOW_SUCCESS,
} from "./types";
import {handleError, showContentLoader} from "../utils/utils";

export const getDelivery = (id, usageType) =>  async dispatch =>  {
    console.log('getting delivery', id);
    axios.get(`${BASE_URL}/v1/logistics/${id}`)
        .then(response => {
            switch (usageType) {
                case 'boxOrder':
                    dispatch({
                        type: DELIVERY_GET_BOX_ORDER,
                        payload: response
                    })
                    break;
                default:
                    dispatch({
                        type: DELIVERY_GET,
                        payload: response
                    })
            }
        }).catch(error => {
        handleError(error, dispatch);
    })
}

export const getDeliveryDetails = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/logistics/${id}`)
        .then(response => {
            dispatch({
                type: DELIVERY_GET,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
    })
}

export const getBoxDeliveries = (path) =>  async dispatch =>  {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/logistics/boxDelivery/?${path}`)
        .then(response => {
                dispatch({
                    type: DELIVERY_GET_BOX_ORDERS,
                    payload: response
                })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
    })
}

export const createBoxDelivery = (createBoxDeliveryRequest) => async dispatch => {
    console.log('crearing')
    showContentLoader(true, dispatch);
    axios.post(`${BASE_URL}/v1/logistics/boxDelivery`, createBoxDeliveryRequest)
        .then(response => {
            showContentLoader(false, dispatch);
            console.log('response', response);
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.boxDelivery.createSuccessful',
                    dialogType: 'success'
                }
            })
            dispatch({
                type: DELIVERY_CREATED_BOX_ORDER,
                payload: response
            })
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch)
    })
}

export const updateFiltersForBoxDeliveryList = (filterKey, filterValue) => {
    return({
        type: DELIVERY_BOX_FILTER,
        payload: {filterKey, filterValue}
    })
}

export const clearBoxDeliveryFilters = () => {
    return ({
        type: DELIVERY_BOX_CLEAR_FILTER
    })
}

export const sortBoxDeliveryList = (orderBy, sort) => {
    return ({
        type: DELIVERY_BOX_SORT,
        payload: {orderBy, sort}
    })
}

export const unsetDelivery = () => {
    return {
        type: DELIVERY_UNSET
    }
}