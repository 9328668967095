import {REMARK_CREATE, REMARK_GET_LIST} from "../actions/types";

const INITIAL_STATE = {
    remarks: {}
}

const remarkReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case REMARK_GET_LIST:
            return {
                ...state,
                remarks: {...state.remarks, [action.payload.entityId]: action.payload.response.data}
            }
        case REMARK_CREATE:
            return {
                ...state,
                remarks: {...state.remarks, [action.payload.entityId]: [action.payload.response.data, ...state.remarks[action.payload.entityId]]}
            }
        default:
            return state;
    }
}

export default remarkReducer;