import React from 'react';
import Papa from "papaparse";
import Button from "@material-ui/core/Button";
import {Card} from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import GetAppIcon from '@material-ui/icons/GetApp';
import {trimData} from "../utils/utils";

const customStyles = makeStyles({
    fileUpload: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10
    }
})

const FileUpload = ({ defaultFields, templateFile, onUpload }) => {
    const [file, setFile] = React.useState(undefined);
    const { t } = useTranslation();
    const classes = customStyles();

    const handleChange = event => {
        setFile(event.target.files[0])
    };

    const importCSV = () => {
        Papa.parse(file, {
            complete: updateData,
            header: true
        });
    };

    const updateData = (result) => {
        const data = result.data;
        trimData(data);
        const updatedData = data.map(obj=> ({ ...obj, ...defaultFields }));
        const convertedToDimensional = updatedData.map(obj => convertToDimensional(obj));
        setFile(undefined)
        document.getElementById('fileUpload').value = '';
        onUpload(convertedToDimensional);
    }

    const convertToDimensional = (data) => {
        for (const key in data) {
            if (data.hasOwnProperty(key)) {
                if (key.indexOf(".") !== -1)
                {
                    parseDotNotation(key, data[key], data);
                }
            }
        }
        return data;
    }

    const parseDotNotation = (originalKey, val, obj) => {
        var currentObj = obj,
            keys = originalKey.split("."),
            i, l = Math.max(1, keys.length - 1), key;

        for (i = 0; i < l; ++i) {
            key = keys[i];
            currentObj[key] = currentObj[key] || {};
            currentObj = currentObj[key];
        }

        currentObj[keys[i]] = val;
        delete obj[originalKey];
    }
    return (
        <Card variant="outlined" className={classes.fileUpload}>
            <div>
                <input
                    id="fileUpload"
                    type="file"
                    name="file"
                    onChange={handleChange}
                    seleniumselector="fileUpload"
                />
                <Button size="small" disabled={!file} onClick={importCSV} variant="contained" color="primary" seleniumselector="fileSubmit">{t('messages.upload')}</Button>
            </div>
            <a href={`${process.env.PUBLIC_URL}/assets/resources/${templateFile}`} target='_blank' rel="noreferrer" download title={t('messages.downloadTemplateFile')} className="float-right"><GetAppIcon /></a>
        </Card>
    )
}

export default FileUpload;