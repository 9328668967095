import {
    DOWNLOAD_REQUEST_CLEAR_FILTER,
    DOWNLOAD_REQUEST_FILTER,
    DOWNLOAD_REQUEST_GET_LIST,
    DOWNLOAD_REQUEST_SORT
} from "../actions/types";

const INITIAL_STATE = {
    downloadRequests: [],
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    filters: {
        createdAt: {
            from: null,
            to: null
        }
    }
}

const downloadRequestReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DOWNLOAD_REQUEST_GET_LIST:
            return {
                ...state,
                downloadRequests: action.payload.data
            }
        case DOWNLOAD_REQUEST_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case DOWNLOAD_REQUEST_CLEAR_FILTER:
            return {
                ...state,
                filters: INITIAL_STATE.filters
            }
        case DOWNLOAD_REQUEST_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        default:
            return state;
    }
}

export default downloadRequestReducer;