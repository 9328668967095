import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {handleError, parseDate, showContentLoader} from "../utils/utils";
import {
    SHOW_SUCCESS, VOUCHER_CODE_CLEAR_FILTER,
    VOUCHER_CODE_FILTER,
    VOUCHER_CODE_GET_DETAILS,
    VOUCHER_CODE_GET_LIST, VOUCHER_CODE_SORT,
    VOUCHER_CODE_UPDATE
} from "./types";

export const getVoucherCodeList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/voucherCodes/?${path}`)
        .then(response => {
            dispatch({
                type: VOUCHER_CODE_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
    })
}

export const getVoucherCodeDetails = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/voucherCodes/${id}`)
        .then(response => {
            dispatch({
                type: VOUCHER_CODE_GET_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
    })
}

export const updateVoucherCode = (id, values, merchantUser) => async  dispatch => {
    const voucherCode = values;
    voucherCode['active'] = true;
    voucherCode['activationDate'] = parseDate(new Date(), merchantUser.timeZone);
    voucherCode['attributes']['dateOfPurchase']['value'] = parseDate(values['attributes']['dateOfPurchase']['value'], merchantUser.timeZone);
    axios.patch(`${BASE_URL}/v1/voucherCodes/${id}`, values)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.voucherCode.updateSuccessful',
                    dialogType: 'success'
                }
            })
            dispatch({
                type: VOUCHER_CODE_UPDATE,
                payload: response
            })
        }).catch(error => {
            handleError(error, dispatch);
    })
}

export const updateFiltersForVoucherCodeList = (filterKey, filterValue) => {
    return ({
        type: VOUCHER_CODE_FILTER,
        payload: {filterKey, filterValue}
    })
}

export const clearVoucherCodeFilters = () => {
    return({
        type: VOUCHER_CODE_CLEAR_FILTER
    })
}

export const sortVoucherCodes = (orderBy, sort) => {
    return ({
        type: VOUCHER_CODE_SORT,
        payload: {orderBy, sort}
    })
}