import {
    PICK_UP_GET_DETAILS,
    PICK_UP_GET_LIST,
    PICK_UP_UPDATE_PICK_UP_DATE,
    PICK_UP_CLEAR_FILTER,
    PICK_UP_FILTER,
    PICK_UP_GET_DOCUMENTS,
    PICK_UP_CLEAR_DOCUMENTS,
    PICK_UP_GET_DOCUMENTS_IN_PROGRESS,
    PICK_UP_UPDATED_DETAILS,
    PICK_UP_UPDATE_PARCEL_CONDITION, PICK_UP_SORT,
} from "../actions/types";

const INITIAL_STATE = {
    pickUps: [],
    pickUp: {},
    availablePickUpDates: [],
    pdfDocuments: [],
    getPdfDocumentsInProgress: false,
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    filters: {
        number: '',
        businessEntityCode: '',
        status: [],
        parcelCondition: [],
        pickUpDate: {
            from: null,
            to: null
        },
        createdAt: {
            from: null,
            to: null
        }
    }
}

const pickUpReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PICK_UP_GET_LIST:
            return {
                ...state,
                pickUps: action.payload.data
            }
        case PICK_UP_GET_DETAILS:
            return {
                ...state,
                pickUp: action.payload.data.pickup,
                availablePickUpDates: action.payload.data.availablePickUpDates
            }
        case PICK_UP_UPDATED_DETAILS:
            return {
                ...state,
                pickUp: action.payload.data
            }
        case PICK_UP_UPDATE_PICK_UP_DATE:
            return {
                ...state,
                pickUp: {
                    ...state.pickUp,
                    pickUpDate: action.payload.pickUpDate
                }
            }
        case PICK_UP_UPDATE_PARCEL_CONDITION:
            return {
                ...state,
                pickUp: {
                    ...state.pickUp,
                    parcelCondition: action.payload.parcelCondition
                }
            }
        case PICK_UP_CLEAR_DOCUMENTS:
            return {
                ...state,
                pdfDocuments: []
            }
        case PICK_UP_GET_DOCUMENTS_IN_PROGRESS:
            return {
                ...state,
                getPdfDocumentsInProgress: action.payload
            }
        case PICK_UP_GET_DOCUMENTS:
            return {
                ...state,
                pdfDocuments: action.payload.data
            }
        case PICK_UP_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
        }
        case PICK_UP_CLEAR_FILTER:
            return {
                ...state,
                filters: {
                    number: '',
                    businessEntityCode: '',
                    status: [],
                    parcelCondition: [],
                    pickUpDate: {
                        from: null,
                        to: null
                    },
                    createdAt: {
                        from: null,
                        to: null
                    }
                }
            }
        case PICK_UP_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        default:
            return state;
    }
}

export default pickUpReducer;