import React from 'react';
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Button from "@material-ui/core/Button";
import DialogContentText from "@material-ui/core/DialogContentText";
import {useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";

const customStyles = makeStyles({
    message: {
       fontWeight: 'bold'
    },
    success: {
        background: '#4caf50',
        width: '100%',
        height: 10
    },
    danger: {
        background: '#f44336',
        width: '100%',
        height: 10
    },
    info: {
        background: '#00BAC7',
        width: '100%',
        height: 10
    }
})

const SimpleDialog = ({ handleDialogClosing, open, message, dialogType, entity, uploadResponseMessage }) => {
    const { t } = useTranslation();
    const classes = customStyles();

    return(
        <Dialog onClose={() => handleDialogClosing}
                open={open}
                transitionDuration={{
                    enter: 1,
                    exit: 1
                }}
        >
            <div className={classes[dialogType]}/>
            <DialogContent>
                <DialogContentText className={classes.message}>
                    {t(`messages.dialogType.${dialogType ? dialogType : 'undefined'}`)}
                </DialogContentText>
                <DialogContentText>
                    {message && t(`${message}`)}
                </DialogContentText>
                {
                    uploadResponseMessage.recordsProcessed != null &&
                        <React.Fragment>
                            <Divider />
                            <Box mt={1}>
                                <DialogContentText>
                                    {t('messages.recordsProcessed')}: {uploadResponseMessage.recordsProcessed}
                                </DialogContentText>
                            </Box>
                        </React.Fragment>
                }
                {
                    uploadResponseMessage.recordsProcessed != null && uploadResponseMessage.recordsProcessed > 0 &&
                        <Box mt={1}>
                            <DialogContentText>
                                {t(`messages.${entity}.uploadSuccess`)}
                            </DialogContentText>
                        </Box>
                }
                {
                    uploadResponseMessage.failedRecords.length > 0 &&
                        <div>
                            <Divider />
                            <Box mt={1}>
                                <DialogContentText>
                                    {t('messages.recordsFailed')}: {uploadResponseMessage.failedRecords.length}
                                </DialogContentText>
                            </Box>
                            {
                                uploadResponseMessage.failedRecords.map(failedRecord => (
                                    <DialogContentText key={failedRecord}>
                                        {failedRecord}
                                    </DialogContentText>
                                ))

                            }
                        </div>
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={() => handleDialogClosing()} variant="outlined" color="primary">
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default SimpleDialog;