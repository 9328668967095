import React from "react";
import "../../styles/custom-bootstrap.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
    fullDateWithIndicator,
} from "../../utils/utils";
import { Accordion, AccordionDetails, AccordionSummary, Button, FormControl, Grid, InputLabel, MenuItem, NativeSelect, Select, TextField, Typography } from "@material-ui/core";
import { getIn, useFormik } from "formik";
import { addressFields, customerAddressFields } from "../../utils/fields";

const ContactDetailsAddress = (props) => {
    const { customer, onAddressFormSubmit, editableFields } =
        props;

    const { t } = useTranslation();
    const [rowExpanded, setRowExpanded] = React.useState(false);

    const customStyles = makeStyles({
        inputField: {
            marginTop: 10,
            marginBottom: 10
        },
        selectField: {
            marginBottom: 10
        },
        card: {
            width: '100%'
        },
        customStyleForSelect: {
            color: '#f44336',
            fontSize: '0.75rem',
            marginTop: '3px',
            marginLeft: '14px',
            marginRight: '14px'
        }
    })





    const formik = useFormik({
        enableReinitialize: true,
        initialValues: editableFields,
        // validationSchema: validationRules,
        onSubmit: (values, { setSubmitting }) => {
            onAddressFormSubmit(values)
            setTimeout(function () {
                setSubmitting(false)
            }, 1000);
        }
    })

    const classes = customStyles();

    return (
        customer != null && editableFields != null && (
            <>
                <div className="card">
                    <div class="card-header">
                        <span >Saved Addresses</span>
                        <button
                            className="float-right btn btn-sm btn-primary"
                            style={rowExpanded ? { backgroundColor: 'red' } : { backgroundColor: '' }}
                            onClick={() => { setRowExpanded(!rowExpanded) }
                            }
                        >
                            {
                                !rowExpanded ? (
                                    <>
                                        <i class="fa fa-plus"></i>
                                        <span> Add address</span>
                                    </>

                                ) :
                                    (
                                        <>
                                            <i class="fa fa-times"></i>
                                            <span> Cancel</span>
                                        </>

                                    )

                            }
                        </button>
                    </div>
                    <Accordion expanded={rowExpanded}>
                        <AccordionSummary style={{ display: 'none' }}>
                            <Typography>{t('messages.addRemark')}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <form onSubmit={formik.handleSubmit}>
                                <Grid container>
                                    {
                                        customerAddressFields[customer.countryCode]?.map(addressField => {

                                            switch (addressField) {
                                                case "countryCode":
                                                    return (
                                                        <TextField
                                                            disabled
                                                            key={`address.${addressField}`}
                                                            label={t(`keys.address.${addressField}`)}
                                                            name={`${addressField}`}
                                                            autoComplete='off'
                                                            InputProps={{
                                                                disableUnderline: false,
                                                            }}
                                                            onChange={formik.handleChange}
                                                            value={customer.countryCode}
                                                            className={`${classes.inputField} margin-right-10 width-40`}
                                                        />
                                                    )
                                                    break;
                                                case "type":

                                                    return (
                                                        <div className="MuiFormControl-root MuiTextField-root makeStyles-inputField-60 margin-right-10 width-40">
                                                            <InputLabel id="demo-simple-select-label">Type</InputLabel>
                                                            <NativeSelect
                                                                defaultValue={'billingSubscription'}
                                                                onChange={(e) => formik.setFieldValue(addressField, e.target.value)}
                                                                value={formik.values?.[addressField]}
                                                                inputProps={{
                                                                    name: 'type',
                                                                    id: 'uncontrolled-native',
                                                                }}
                                                            >
                                                                <option value={'billingSubscription'}>Billing Address</option>
                                                                <option value={'correspondence'}>Correspondence Address</option>
                                                            </NativeSelect>

                                                        </div>

                                                    )
                                                    break;

                                                default:
                                                    return (
                                                        <TextField
                                                            key={`address.${addressField}`}
                                                            label={t(`keys.address.${addressField}`)}
                                                            name={`${addressField}`}
                                                            onChange={formik.handleChange}
                                                            value={formik?.values?.[addressField]}
                                                            autoComplete='off'
                                                            InputProps={{
                                                                disableUnderline: false, // <== added this
                                                            }}
                                                            className={`${classes.inputField} margin-right-10 width-40`}
                                                        // onChange={formik.handleChange}
                                                        // value={formik.values['address'][addressField]}
                                                        // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                                                        // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                                                        />
                                                    )
                                                    break;


                                            }

                                        })
                                    }
                                    <Grid item xs={12}>
                                        <div style={{ textAlign: 'right' }}>
                                            <button
                                                className="btn btn-sm btn-primary"
                                                type="submit"
                                            >
                                                <i className="fa fa-plus"></i>
                                                <span> Save</span>
                                            </button>

                                        </div>

                                    </Grid>

                                </Grid>
                            </form>



                            {/* <div  >
                        
                    </div> */}

                        </AccordionDetails>

                    </Accordion>
                    <div class="card-body">
                        {
                            customer.contact?.addresses?.map(address => {
                                return (
                                    <div>
                                        <label style={{
                                            float: "left",
                                            padding: "10px",
                                            width: "calc(50% - 10px)",
                                            margin: "5px",
                                            border: "1px solid #ddd",
                                            borderRadius: "5px"
                                        }}>
                                            <span>

                                                {
                                                    customerAddressFields[customer?.countryCode]?.map(customerAddressField => {
                                                        return (
                                                            <>
                                                                <span>
                                                                    {address?.[customerAddressField]}
                                                                </span><br></br>

                                                            </>

                                                        )
                                                    }
                                                    )

                                                }
                                            </span>
                                        </label>
                                    </div>

                                )

                            })
                        }

                    </div>

                </div>
            </>

        )

    );
};

export default ContactDetailsAddress;
