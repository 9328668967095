import React, {Component} from "react";
import { connect } from 'react-redux';
import PageTemplate from "../components/PageTemplate";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import {withTranslation} from "react-i18next";
import {getBusinessEntityList} from "../actions/businessEntityActions";

class MyAccount extends Component {
    componentDidMount() {
        if (this.props.authentication.loggedInUser.businessEntityCode !== undefined) {
            this.props.getBusinessEntityList(`filterFields[]=code&filterValues[]=${this.props.authentication.loggedInUser.businessEntityCode}`);
        }
    }

    render() {
        const { t } = this.props;
        const user = this.props.authentication.loggedInUser;
        return (
            <PageTemplate title="messages.myAccount">
                <Grid container item md={12} spacing={5}>
                    <Grid item md={3}>
                        <Typography variant="h6" className="grouping-header">{t(`messages.generalInformation`)}</Typography>
                        <div className="grouping-content">
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.displayName')}</Typography>
                                <p className="display-value">{user.displayName}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.phoneNumber')}</Typography>
                                <p className="display-value">{user.phoneNumber}</p>
                            </Box>
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.email')}</Typography>
                                <p className="display-value">{user.email}</p>
                            </Box>
                        </div>
                    </Grid>
                    <Grid item md={3}>
                        <Typography variant="h6" className="grouping-header">{t(`keys.authorities`)}</Typography>
                        <div className="grouping-content">
                            <Box mb={2}>
                                {
                                    user.authorities.map(authority => {
                                        return (<p key={authority} className="display-value">{t(`values.authorities.${authority}`)}</p>)
                                    })
                                }
                            </Box>
                        </div>
                    </Grid>
                </Grid>
                <Grid container item md={12} spacing={5}>
                    <Grid item md={3}>
                        <Typography variant="h6" className="grouping-header">{t(`messages.other`)}</Typography>
                        <div className="grouping-content">
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.businessEntityCode')}</Typography>
                                <p className="display-value">{user.businessEntityCode || 'N/A'}</p>
                            </Box>
                        </div>
                    </Grid>
                    <Grid item md={3}>
                        {
                            this.props.authentication.loggedInUser.businessEntityCode !== undefined && this.props.businessEntities.businessEntities.length > 0 &&
                            <React.Fragment>
                                <Typography variant="h6" className="grouping-header">{t(`messages.address`)}</Typography>
                                <div className="grouping-content">
                                    <Grid container item md={12} spacing={2}>
                                        <Grid item md={6}>
                                            <Box mb={2}>
                                                <Typography variant="subtitle2">{t('keys.address.street')}</Typography>
                                                <p className="display-value">{this.props.businessEntities.businessEntities[0].address.street}</p>
                                            </Box>
                                            <Box mb={2}>
                                                <Typography variant="subtitle2">{t('keys.address.zipCode')}</Typography>
                                                <p className="display-value">{this.props.businessEntities.businessEntities[0].address.zipCode}</p>
                                            </Box>
                                            <Box mb={2}>
                                                <Typography variant="subtitle2">{t('keys.address.provinceCode')}</Typography>
                                                <p className="display-value">{this.props.businessEntities.businessEntities[0].address.provinceCode}</p>
                                            </Box>
                                        </Grid>
                                        <Grid item md={6}>
                                            <Box mb={2}>
                                                <Typography variant="subtitle2">{t('keys.address.number')}</Typography>
                                                <p className="display-value">{this.props.businessEntities.businessEntities[0].address.number}</p>
                                            </Box>
                                            <Box mb={2}>
                                                <Typography variant="subtitle2">{t('keys.address.city')}</Typography>
                                                <p className="display-value">{this.props.businessEntities.businessEntities[0].address.city}</p>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </div>
                            </React.Fragment>
                        }
                    </Grid>
                </Grid>
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    businessEntities: state.businessEntities,
})

export default connect(mapStateToProps, {
    getBusinessEntityList
})(withTranslation()(MyAccount));