import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    MERCHANT_USER_FILTER,
    MERCHANT_USER_GET_DETAILS,
    MERCHANT_USER_GET_LIST,
    MERCHANT_USER_CLEAR_FILTER,
    MERCHANT_USER_SORT,
    SHOW_SUCCESS, HIDE_CONFIRMATION_DIALOG
} from "./types";
import { handleError, showContentLoader } from "../utils/utils";

export const getMerchantUserList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/merchantUsers/?${path}`)
        .then(response => {
            dispatch({
                type: MERCHANT_USER_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
        })
}

export const updateFiltersForUserMerchantList = (filterKey, filterValue) => {
    return({
        type: MERCHANT_USER_FILTER,
        payload: {filterKey, filterValue}
    })
}

export const getMerchantUserDetails = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/merchantUsers/${id}`)
        .then(response => {
            dispatch({
                type: MERCHANT_USER_GET_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
        })
}

export const updateMerchantUser = (merchantUser, id) => async dispatch => {
    axios.patch(`${BASE_URL}/v1/merchantUsers/${id}`, merchantUser)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.merchantUser.updateSuccessful',
                    dialogType: 'success'
                }
            })
        }).catch(error => {
            handleError(error, dispatch);
        })
}

export const createMerchantUser = (merchantUser) => async dispatch => {
    axios.post(`${BASE_URL}/v1/merchantUsers`, merchantUser)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.merchantUser.createSuccessful',
                    dialogType: 'success'
                }
            })
        }).catch(error => {
            handleError(error, dispatch)
    })
}

export const batchCreateMerchantUser = (merchantUsers) => async dispatch => {
    axios.post(`${BASE_URL}/v1/merchantUsersManagement/batch`, merchantUsers)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.merchantUser.fileUploaded',
                    entity: 'merchantUser',
                    dialogType: 'info',
                    uploadResponse: response,
                    refresh: false
                }
            })
        }).catch(error => {
            handleError(error, dispatch)
    })
}

export const clearMerchantUserFilters = () => {
    return ({
        type: MERCHANT_USER_CLEAR_FILTER
    })
}

export const getProduct = () => async dispatch => {
    axios.get(`${BASE_URL}/v1/merchantUsers/product`)
        .then(() => {})
        .catch(error => {
            handleError(error, dispatch);
        })
}

export const sortMerchantUsers = (orderBy, sort) => {
    return ({
        type: MERCHANT_USER_SORT,
        payload: {orderBy, sort}
    })
}

export const resetMerchantUserPassword = (id) => async dispatch => {
    axios.post(`${BASE_URL}/v1/merchantUsers/${id}/resetPassword`)
        .then(() => {
            dispatch({
                type: HIDE_CONFIRMATION_DIALOG
            })
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.merchantUser.passwordReset',
                    entity: 'merchantUser',
                    dialogType: 'success',
                    refresh: false
                }
            })
        }).catch(error => {
        handleError(error, dispatch)
    })

}