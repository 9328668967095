import React from "react";
import "../../styles/custom-bootstrap.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  fullDateWithIndicator,
} from "../../utils/utils";

const DeviceDetailsView = (props) => {
  const { extendedWarrantyClaim, userCountryCode, subscription, subscriptionPartnerAdditionalDetails } =
    props;
  const { t } = useTranslation();
  const customStyles = makeStyles({
    customBackground: {
      background: "#eceff1",
    },
  });
  const customStyleClass = customStyles();

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <i class="fa fa-history" aria-hidden="true"></i>
              <strong> {'Device Details'}</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-3">
                  <div className="form-group">
                    <label>{'Device Make'}</label>
                    <br />
                    <label className="font-weight-bold">
                      {subscription.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails?.deviceMake}
                    </label>
                    <br />
                  </div>
                  <div className="form-group">
                    <label>{'Device Model'}</label>
                    <br />
                    <label className="font-weight-bold">
                      {subscription.subscriptionPartnerAdditionalDetails?.partnerAdditionalDetails?.deviceModel}
                    </label>
                    <br />
                  </div>
                </div><div className="col-md-3">
                  <div className="form-group">
                    <label>{'Product Official Warranty'}</label>
                    <br />
                    <label className="font-weight-bold">
                      {'TBC'}
                    </label>
                    <br />
                  </div>
                  <div className="form-group">
                    <label>{'SKU Description'}</label>
                    <br />
                    <label className="font-weight-bold">
                      {subscription.article?.description}
                    </label>
                    <br />
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="form-group">
                    <label>{'Device Selling Price'}</label>
                    <br />
                    <label className="font-weight-bold">
                    ({subscription.device?.purchasePrice?.currencyCode}) {subscription.device?.purchasePrice?.amount}
                    </label>
                    <br />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default DeviceDetailsView;
