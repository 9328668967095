export const BASE_URL = process.env.REACT_APP_BASE_API_URL;
export const TIME_ZONES = JSON.parse(process.env.REACT_APP_TIME_ZONES);
export const TIME_FORMAT_LONG = process.env.REACT_APP_TIME_FORMAT_LONG;
export const TIME_FORMAT_LONG_WITH_INDICATOR = process.env.REACT_APP_TIME_FORMAT_LONG_WITH_INDICATOR;
export const PICK_UP_DAYS = JSON.parse(process.env.REACT_APP_PICK_UP_DAYS);
export const TIME_FORMAT_SHORT = process.env.REACT_APP_TIME_FORMAT_SHORT;
export const FALLBACK_LANGUAGE = process.env.REACT_APP_FALLBACK_LANGUAGE;
export const SUPPORTED_LANGUAGES = process.env.REACT_APP_SUPPORTED_LANGUAGES.split(',');
export const PRIVACY_POLICY_URL = process.env.REACT_APP_PRIVACY_POLICY_URL;
export const SEARCH_INDEX = process.env.REACT_APP_SEARCH_INDEX;
export const AUTHORITY_IDENTITY_MANAGER = 'IDENTITY_MANAGER';
export const AUTHORITY_PICK_UP_MANAGER = 'PICK_UP_MANAGER';
export const AUTHORITY_PICK_UP_OFFICER = 'PICK_UP_OFFICER';
export const AUTHORITY_VIDEO_CONTROLLER = 'VIDEO_CONTROLLER';
export const AUTHORITY_VOUCHER_CODE_AGENT = 'VOUCHER_CODE_AGENT';
export const AUTHORITY_REPAIR_MANAGER = 'REPAIR_MANAGER';
export const AUTHORITY_READ_ONLY = 'READ_ONLY';
export const AUTHORITY_AGENT = 'AGENT';
export const AUTHORITY_MANAGER = 'MANAGER';
export const AUTHORITY_TRADE_IN_MANAGER = 'TRADE_IN_MANAGER';
export const AUTHORITY_TRADE_IN_STORE_AGENT = 'TRADE_IN_STORE_AGENT';
export const AUTHORITY_TRADE_IN_AGENT = 'TRADE_IN_AGENT';
export const AUTHORITY_PARTNER_AGENT = 'PARTNER_AGENT';
export const SEARCH_RESULT_KEYS = [
    'article.manufacturer.name.**localeCode**.keyword',
    'article.attributes.series.value.**localeCode**.keyword',
    'article.attributes.color.value.**localeCode**.keyword',
    'article.attributes.storageSize.value.keyword',
    'article.attributes.networkTechnology.value.keyword',
    'article.sku.keyword'
]

export const MERCHANT_USER_CHANNEL_OPTIONS = {
    'reload-plus-pickup': [
        'Shop in shop',
        'Top Dealer',
        'Formula 3',
        'Franchising Soft',
        'Master Dealer',
        'Master Dealer PC',
        'Negozi di gruppo',
        '3 Corner'
    ]
}