import React from 'react';
import { useTranslation } from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import FilterListIcon from '@material-ui/icons/FilterList';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import { zonedTimeToUtc, format } from 'date-fns-tz';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

const customStyles = makeStyles({
    fab: {
        top: 'auto',
        right: 100,
        bottom: 20,
        left: 'auto',
        position: 'fixed',
        marginRight: 20,
        backgroundColor: 'lightblue',
    },
    inputField: {
        margin: 10,
    },
    fieldSet: {
        borderRadius: '4px',
        border: '1px solid rgba(0, 0, 0, 0.23)'
    },
    legend: {
        fontSize: '13px',
        padding: '0 8px',
        color: 'rgba(0, 0, 0, 0.53)'
    }
})

const renderFilterInputField = (filterKey, filterValues, onInputChange, onValueSubmit, classes, t) => {
    switch (filterKey.type) {
        case 'checkBoxes':
            const onCheckBoxChange = (name, value, multipleValues) => {
                onInputChange(name, value, multipleValues);
                window.setTimeout(() => {onValueSubmit()}, 100 )
            }
            return (
                <fieldset key={filterKey.name} className={`${classes.fieldSet} ${classes.inputField}`}>
                    <legend className={classes.legend}>{t(`keys.${filterKey.label ? filterKey.label : filterKey.name}`)}</legend>
                    {filterKey.options.map((option) => {
                        return (<FormControlLabel
                            key={`${filterValues[filterKey.name]}.${option}`}
                            control={
                                <Checkbox
                                    checked={filterValues[filterKey.name].includes(option)}
                                    value={option}
                                    onChange={value => onCheckBoxChange(filterKey.name, value, true)}
                                    name={filterKey.name}
                                    color="primary"
                                />
                            }
                            label={t(`values.${filterKey.name}.${option}`)}
                        />)
                    })}
                </fieldset>
            )
        case 'dateRangePicker':
            const selectionRange = {
                from: filterValues[filterKey.name].from,
                to: filterValues[filterKey.name].to
            }

            const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

            const handleSelect = (range, rangeKey) => {
                const rangeToDate = new Date(range);
                const dateInUsersLocalTime = zonedTimeToUtc(rangeToDate, timeZone)
                selectionRange[rangeKey] = format(dateInUsersLocalTime, "yyyy-MM-dd'T'HH:mm:ssXXX");
                onInputChange(filterKey.name, {target: {value: {from: selectionRange['from'], to: selectionRange['to']}}}, false)
            }
            return (
                <fieldset key={filterKey.name} className={`${classes.fieldSet} ${classes.inputField}`}>
                    <legend className={classes.legend}>{t(`keys.${filterKey.name}`)}</legend>

                    <form autoComplete="off" className="display-flex">
                        <div>
                            <DatePicker
                                selected={selectionRange.from ? new Date(selectionRange.from) : null}
                                onChange={(value) => handleSelect(value, 'from')}
                                name={`${filterKey.name}.from`}
                                className={`${classes.inputField} input-field`}
                                placeholderText={t('messages.from')}
                                timeInputLabel="Time:"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeInput
                            />
                        </div>
                        <div>
                            <DatePicker
                                selected={selectionRange.to ? new Date(selectionRange.to) : null}
                                onChange={(value) => handleSelect(value, 'to')}
                                name={`${filterKey.name}.from`}
                                className={`${classes.inputField} input-field`}
                                placeholderText={t('messages.to')}
                                timeInputLabel="Time:"
                                dateFormat="dd/MM/yyyy HH:mm"
                                showTimeInput
                            />
                        </div>
                    </form>
                </fieldset>
            )
        default:
            return (
                <TextField
                    key={filterKey.name}
                    variant="outlined"
                    label={t(`keys.${filterKey.label ? filterKey.label : filterKey.name}`)}
                    name={filterKey.name}
                    autoComplete='off'
                    className={classes.inputField}
                    onChange={value => onInputChange(filterKey.name, value, false)}
                    value={filterValues[filterKey.name]}
                    size="small"
                />
            )
    }
}

const FilterDrawer = ({ filterKeys, filterValues, onInputChange, onValueSubmit, onCancel }) => {
    const { t } = useTranslation();
    const classes = customStyles();

    return (
        <fieldset className={classes.fieldSet}>
            {filterKeys.map((filterKey) => (
                renderFilterInputField(filterKey, filterValues, onInputChange, onValueSubmit, classes, t)
            ))}
            <Box display="flex" m={1}>
                <Box mr={2}>
                    <Button onClick={() => onCancel()}
                            variant="outlined"
                            startIcon={<RotateLeftIcon />}
                    >
                        {t('messages.reset')}
                    </Button>
                </Box>
                <Button onClick={() => onValueSubmit()}
                        variant="contained"
                        color="primary"
                        startIcon={<FilterListIcon />}
                >
                    {t('messages.ok')}
                </Button>
            </Box>
        </fieldset>
    )

}

export default FilterDrawer;