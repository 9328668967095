import React from "react";
import _ from 'lodash';
import { Link } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import makeStyles from "@material-ui/core/styles/makeStyles";
import Box from "@material-ui/core/Box";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import {fallbackValue, fullDate, shortDate} from "../utils/utils";
import Tooltip from "@material-ui/core/Tooltip";
import FilterListIcon from '@material-ui/icons/FilterList';
import WarningIcon from '@material-ui/icons/Warning';
import FilterDrawer from "./FilterDrawer";
import DataTableLoader from "./DataTableLoader";
import {IconButton} from "@material-ui/core";

const customStyles = makeStyles({
    tableHead: {
        background: '#170F4F'
    },
    tableHeadFilterContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    tableHeadFilter: {
        width: '40px',
        textAlign: 'center',
        padding: '0px !important'
    },
    headerCell: {
        color: '#ffffff'
    },
    headerCellBox: {
      display: "flex"
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
});

const CustomTableHead = ({ classes, order, orderBy, sortingAvailable, onRequestSort, fields, onFilterClick, filterOpen, emptyString, t }) => {
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHead}>
            <TableRow>
                {fields.length > 0 &&
                fields.map((field) => (
                    <TableCell
                        className={classes.headerCell}
                        key={field.name}
                        component="th"
                    >
                        <Box className={classes.headerCellBox}>
                            { sortingAvailable && field.sorting !== false ?
                                <TableSortLabel
                                    active={orderBy === field.name}
                                    direction={orderBy === field.name ? order : 'asc'}
                                    onClick={createSortHandler(field.name)}
                                >
                                    {t(`keys.${field.label ? field.label : field.name}`)}
                                    {orderBy === field.name ? (
                                        <span className={classes.visuallyHidden}>
                                        {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                    </span>
                                    ) : null}
                                </TableSortLabel>
                                : <label>{t(`keys.${field.label ? field.label : field.name}`)}</label>
                            }
                        </Box>
                    </TableCell>
                ))}
                <TableCell component="th" className={classes.tableHeadFilter} style={filterOpen ? {background: '#E3D900'} : {background: '#00BAC7'}}>
                    <div className={classes.tableHeadFilterContainer} >
                        <IconButton size="small" onClick={onFilterClick}>
                            <Tooltip title={t('messages.filter')}>
                                <FilterListIcon />
                            </Tooltip>
                        </IconButton>
                    </div>
                </TableCell>
            </TableRow>
        </TableHead>
    )
}

const renderCellItem = (redirectId, item, entity, field, t, disableCell, emptyString) => {
    switch (field.tag) {
        case 'downloadLink':
            return (
                <Link to={{ pathname: _.get(item, field.name) }} target="_blank"
                style={_.get(item, field.name) === undefined ? {color: 'red'} : {}}
                >
                {renderCell(field, _.get(item, field.name), t, emptyString)}
                </Link>
            );
        case 'multipleValues':
            return (            
                <Link to={redirectId ? (item[redirectId] ? `/${entity}/${item[redirectId]}` : '#') : `/${entity}/${item.id}`}
                className={`${redirectId && !item[redirectId] ? 'link-disabled ' : ''} ${disableCell ? ' cellLink-disabled' : ''}`}
                style={_.get(item, field.name[0]) === undefined ? {color: 'red'} : {}}
                >
                {renderCell(field, field.name.map((name) => _.get(item, name)).join(' '), t, emptyString)}
                </Link>
            );
        default:
            return (            
                <Link to={redirectId ? (item[redirectId] ? `/${entity}/${item[redirectId]}` : '#') : `/${entity}/${item.id}`}
                className={`${redirectId && !item[redirectId] ? 'link-disabled ' : ''} ${disableCell ? ' cellLink-disabled' : ''}`}
                style={_.get(item, field.name) === undefined ? {color: 'red'} : {}}
                >
                {renderCell(field, _.get(item, field.name), t, emptyString)}
                </Link>
            );
    }
}

const renderCell = (field, item, t, emptyString) => {
    switch (field.type) {
        case 'string':
            return item;
        case 'translatedString':
            return t(`values.${field.name}.${item}`);
        case 'translatedStringTranslationKey':
            return t(`values.${field.translationKey}.${item}`);
        case 'length':
            return item.length;
        case 'translatedArray':
            let translatedArrayString = '';
            const itemLength = item.length;
            item.forEach((itemValue, index) => {
                translatedArrayString = translatedArrayString + t(`values.${field.name}.${itemValue}`) + (index === itemLength - 1 ? '' : ' | ');
            })
            return translatedArrayString;
        case 'arrayString':
            let arrayString = '';
            if(item != null) {
                const length = item.length;
                item.forEach((itemValue, index) => {
                    arrayString = arrayString + itemValue + (index === length - 1 ? '' : ' | ');
                })
            }
            return arrayString;
        case 'fullDate':
            return item ? fullDate(item, field.countryCode) : t(`messages.${fallbackValue[field.name]}`);
        case 'shortDate':
            return item ? shortDate(item, field.countryCode) : t(`messages.${fallbackValue[field.name]}`);
        case 'expiryDate':
            return item ? (new Date(item) < new Date() ? <span className="color-danger display-flex align-items-center"><WarningIcon className="font-size-1 margin-right-10" /> {fullDate(item, field.countryCode)}</span> : fullDate(item, field.countryCode)) : t(`messages.${fallbackValue[field.name]}`);
        case 'isFieldEmpty':
            return item ? t('values.true') : t('values.false');
        case 'downloadLink':
            return "Download"
        case 'keyValueArray':
            let keyValueArrayString = '';
            if(item != null) {
                const length = item.length;
                item.forEach((itemValue, index) => {
                    keyValueArrayString = keyValueArrayString + "{" + itemValue[field.key] + '|' + itemValue[field.value] + '}';
                })
            }
            return keyValueArrayString;
        default:
            if (emptyString)  {
                return item == undefined || item.trim() == "" ? "-" : item;
            } else {
                return item
            }
    }
}

const DataTable = ({ fields, items, entity, redirectId, filterKeys, filterValues, onInputChange, onValueSubmit, sorting, onSort, sortingAvailable, onCancel, showContentLoader, disableCell, isFilterOpen, emptyString }) => {
    const { t } = useTranslation();
    const [filterOpen, setFilterOpen] = React.useState(isFilterOpen ? isFilterOpen : false);
    const [filter] = React.useState(false);
    const classes = customStyles();

    const handleRequestSort = (event, columnName) => {
        const sortingDirection = sorting.sort === 'desc' ? 'asc' : 'desc';
        onSort(columnName, sortingDirection)
    };

    const handleFilterClick = () => {
        setFilterOpen(!filterOpen)
    }

    return (
        <div style={{overflow: 'auto'}}>
            <Table size="small">
                <CustomTableHead classes={classes}
                                 order={sorting.sort}
                                 orderBy={sorting.orderBy}
                                 sortingAvailable={sortingAvailable}
                                 onRequestSort={handleRequestSort}
                                 filter={filter}
                                 fields={fields}
                                 onFilterClick={handleFilterClick}
                                 filterOpen={filterOpen}
                                 t={t}
                />
                <TableBody>
                    {
                        filterOpen &&
                        <TableRow>
                            <TableCell colSpan={fields.length + 1}>
                                <FilterDrawer
                                    filterKeys={filterKeys}
                                    filterValues={filterValues}
                                    onInputChange={(filterKey, filterValue, multipleSelection) => onInputChange(filterKey, filterValue.target.value, multipleSelection)}
                                    onValueSubmit={() => onValueSubmit()}
                                    onCancel={() => onCancel()}
                                />
                            </TableCell>
                        </TableRow>
                    }
                    {showContentLoader ? <TableRow>
                        <TableCell colSpan={fields.length +1}>
                            <DataTableLoader />
                        </TableCell>
                    </TableRow> : items.length > 0 ? (
                        items.map((item) => (
                            <TableRow hover key={item.id}>
                                {fields.map((field) => (
                                    <TableCell
                                        key={item.id + field.name + item[field.name]}
                                        component="th"
                                    >
                                        {renderCellItem(redirectId, item, entity, field, t, disableCell, emptyString)}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={fields.length}>
                                <p style={{ textAlign: "center" }}> {t('messages.noDataFound')}</p>
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>
        </div>
    )

}

export default DataTable;