import React from "react";
import "../../styles/custom-bootstrap.scss";
import "../../styles/custom-react-date-picker.css";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { zonedTimeToUtc, format } from 'date-fns-tz';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { FormControl, Grid, InputLabel, MenuItem, NativeSelect, Select, TextField, Typography } from "@material-ui/core";
import { useFormikContext, getIn } from "formik";

const QuotationDetailsView = (props) => {
  const { extendedWarrantyClaim, userCountryCode } =
    props;


  const { t } = useTranslation();
  const customStyles = makeStyles({
    inputField: {
      marginTop: 10,
      marginBottom: 10
    },
    selectField: {
      marginBottom: 10
    },
    card: {
      width: '100%'
    },
    customStyleForSelect: {
      color: '#f44336',
      fontSize: '0.75rem',
      marginTop: '3px',
      marginLeft: '14px',
      marginRight: '14px'
    }
  })
  const classes = customStyles();

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleDateSelect = (newDate, field) => {
    const formattedDate = new Date(newDate);
    const dateInUsersLocalTime = zonedTimeToUtc(formattedDate, timeZone)
    formik.setFieldValue(field, dateInUsersLocalTime);
  }

  const formik = useFormikContext();

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <i class="fa fa-history" aria-hidden="true"></i>
              <strong> {'Quotation Partial'}</strong>
            </div>
            <div className="card-body">
                <Grid container spacing={2}>
                  <Grid item xs={4}>
                    <div className="">
                      <Typography variant="subtitle2">{t('Quotation Date & Time')}</Typography>

                      <DatePicker
                        selected={getIn(formik.values, 'quotation.quotationDate') ? new Date(getIn(formik.values, 'quotation.quotationDate')) : null}
                        onChange={(value) =>
                          handleDateSelect(value, 'quotation.quotationDate')
                        }
                        name={`quotation.quotationDate`}
                        className={`${classes.inputField} input-field`}
                        placeholderText={t('messages.from')}
                        timeInputLabel="Time:"
                        dateFormat="dd/MM/yyyy HH:mm"
                        showTimeInput
                        fullWidth
                      />

                    </div>

                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      key={`quotation.totalQuotationAmount.amount`}
                      label={t(`keys.extendedWarrantyClaim.totalQuotationAmount`)}
                      name="quotation.totalQuotationAmount.amount"
                      variant="filled"
                      autoComplete='off'
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Example prop
                        classes: {
                          root: 'custom-label-root-class',
                          focused: 'custom-label-focused-class',
                        },
                      }}
                      InputProps={{
                        disableUnderline: false,
                        shrink: true
                      }}
                      className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                      onChange={formik.handleChange}
                      value={getIn(formik.values, 'quotation.totalQuotationAmount.amount')}
                    // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                    // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                    />

                  </Grid>

                  <Grid item xs={4}></Grid>

                  <Grid item xs={4}>
                    <TextField
                      key={`quotation.ewAmount.amount`}
                      label={t(`keys.extendedWarrantyClaim.ewAmount`)}
                      name={`quotation.ewAmount.amount`}
                      variant="filled"
                      autoComplete='off'
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Example prop
                        classes: {
                          root: 'custom-label-root-class', // Example custom class
                          focused: 'custom-label-focused-class', // Example custom class for focused state
                        },
                        // Any other props you want to pass to InputLabel
                      }}
                      InputProps={{
                        disableUnderline: false,
                        shrink: true
                      }}
                      className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                      onChange={formik.handleChange}
                      value={getIn(formik.values, 'quotation.ewAmount.amount')}
                    // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                    // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                    />

                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      key={`quotation.inspectionFeeAmount.amount`}
                      label={t(`keys.extendedWarrantyClaim.inspectionFeeAmount`)}
                      name={`quotation.inspectionFeeAmount.amount`}
                      variant="filled"
                      autoComplete='off'
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Example prop
                        classes: {
                          root: 'custom-label-root-class', // Example custom class
                          focused: 'custom-label-focused-class', // Example custom class for focused state
                        },
                        // Any other props you want to pass to InputLabel
                      }}
                      InputProps={{
                        disableUnderline: false,
                        shrink: true
                      }}
                      className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                      onChange={formik.handleChange}
                      value={getIn(formik.values, 'quotation.inspectionFeeAmount.amount')}
                    // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                    // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                    />

                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      key={`quotation.otherAmount.amount`}
                      label={t(`keys.extendedWarrantyClaim.otherAmount`)}
                      name={`quotation.otherAmount.amount`}
                      variant="filled"
                      autoComplete='off'
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Example prop
                        classes: {
                          root: 'custom-label-root-class', // Example custom class
                          focused: 'custom-label-focused-class', // Example custom class for focused state
                        },
                        // Any other props you want to pass to InputLabel
                      }}
                      InputProps={{
                        disableUnderline: false,
                        shrink: true
                      }}
                      className={`${classes.inputField} margin-top-20 margin-right-10 width-40 Mui-focused`}
                      onChange={formik.handleChange}
                      value={getIn(formik.values, 'quotation.otherAmount.amount')}
                    // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                    // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                    />
                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      key={`quotation.adldAmount.amount`}
                      label={t(`keys.extendedWarrantyClaim.adldAmount`)}
                      name={`quotation.adldAmount.amount`}
                      variant="filled"
                      autoComplete='off'
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Example prop
                        classes: {
                          root: 'custom-label-root-class', // Example custom class
                          focused: 'custom-label-focused-class', // Example custom class for focused state
                        },
                        // Any other props you want to pass to InputLabel
                      }}
                      InputProps={{
                        disableUnderline: false,
                        shrink: true
                      }}
                      className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                      onChange={formik.handleChange}
                      value={getIn(formik.values, 'quotation.adldAmount.amount')}
                    // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                    // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                    />

                  </Grid>

                  <Grid item xs={4}>
                    <TextField
                      key={`quotation.replacementAmount.amount`}
                      label={t(`keys.extendedWarrantyClaim.replacementAmount`)}
                      name={`quotation.replacementAmount.amount`}
                      variant="filled"
                      autoComplete='off'
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Example prop
                        classes: {
                          root: 'custom-label-root-class', // Example custom class
                          focused: 'custom-label-focused-class', // Example custom class for focused state
                        },
                        // Any other props you want to pass to InputLabel
                      }}
                      InputProps={{
                        disableUnderline: false,
                        shrink: true
                      }}
                      className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                      onChange={formik.handleChange}
                      value={getIn(formik.values, 'quotation.replacementAmount.amount')}
                    />

                  </Grid>

                  <Grid item xs={4}></Grid>

                  <Grid item xs={12}>
                    <hr></hr>

                  </Grid>
                  <Grid item xs={4}>
                    <div className={` MuiFormControl-root MuiTextField-root makeStyles-inputField-65 margin-right-10 width-40`} style={{ width: '100%' }}>
                      <Typography variant="subtitle2">{t('Service Type')}</Typography>
                      <select key={`quotation.serviceType`}
                        name={`quotation.serviceType`}
                        onChange={formik.handleChange}
                        value={getIn(formik.values, 'quotation.serviceType')} className="select margin-bottom-10"
                      >
                        <option hidden value=''>{t('messages.selectYourOption')}</option>
                        <option value='extendedWarranty'>{t('Extended Warranty')}</option>
                        <option value='replacement'>{t('Replacement')}</option>
                        <option value='otherCoverage'>{t('Other Coverage')}</option>
                      </select>
                    </div>
                  </Grid>

                  <Grid item xs={4}>
                    <div className={` MuiFormControl-root MuiTextField-root makeStyles-inputField-65 margin-right-10 width-40`} style={{ width: '100%' }}>
                      <Typography variant="subtitle2">{t('Replacement Device?')}</Typography>
                      <select key={`quotation.replacement`}
                        name={`quotation.replacement`}
                        onChange={formik.handleChange}
                        value={getIn(formik.values, 'quotation.replacement')} className="select margin-bottom-10"
                      >
                        <option hidden value=''>{t('messages.selectYourOption')}</option>
                        <option value='true'>{t('True')}</option>
                        <option value='false'>{t('False')}</option>
                      </select>
                    </div>

                  </Grid>

                  <Grid item xs={4}>

                  </Grid >

                  <Grid item xs={4}>
                    <TextField
                      key={`quotation.totalApprovedQuotationAmount.amount`}
                      label={t(`keys.extendedWarrantyClaim.totalApprovedQuotationAmount`)}
                      name={`quotation.totalApprovedQuotationAmount.amount`}
                      variant="filled"
                      autoComplete='off'
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Example prop
                        classes: {
                          root: 'custom-label-root-class', // Example custom class
                          focused: 'custom-label-focused-class', // Example custom class for focused state
                        },
                        // Any other props you want to pass to InputLabel
                      }}
                      InputProps={{
                        disableUnderline: false,
                        shrink: true
                      }}
                      className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                      onChange={formik.handleChange}
                      value={getIn(formik.values, 'quotation.totalApprovedQuotationAmount.amount')}
                    // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                    // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                    />
                  </Grid>

                  <Grid item xs={4}>

                    <TextField
                      key={`quotation.approvedEwAmount.amount`}
                      label={t(`keys.extendedWarrantyClaim.approvedEwAmount`)}
                      name={`quotation.approvedEwAmount.amount`}
                      variant="filled"
                      autoComplete='off'
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Example prop
                        classes: {
                          root: 'custom-label-root-class', // Example custom class
                          focused: 'custom-label-focused-class', // Example custom class for focused state
                        },
                        // Any other props you want to pass to InputLabel
                      }}
                      InputProps={{
                        disableUnderline: false,
                        shrink: true
                      }}
                      className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                      onChange={formik.handleChange}
                      value={getIn(formik.values, 'quotation.approvedEwAmount.amount')}
                    // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                    // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                    />

                  </Grid>

                  <Grid item xs={4}>

                    <TextField
                      key={`quotation.approvedInspectionFeeAmount.amount`}
                      label={t(`keys.extendedWarrantyClaim.approvedInspectionFeeAmount`)}
                      name={`quotation.approvedInspectionFeeAmount.amount`}
                      variant="filled"
                      autoComplete='off'
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Example prop
                        classes: {
                          root: 'custom-label-root-class', // Example custom class
                          focused: 'custom-label-focused-class', // Example custom class for focused state
                        },
                        // Any other props you want to pass to InputLabel
                      }}
                      InputProps={{
                        disableUnderline: false,
                        shrink: true
                      }}
                      className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                      onChange={formik.handleChange}
                      value={getIn(formik.values, 'quotation.approvedInspectionFeeAmount.amount')}
                    // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                    // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                    />

                  </Grid>

                  <Grid item xs={4}>

                    <TextField
                      key={`quotation.approvedOtherAmount.amount`}
                      label={t(`keys.extendedWarrantyClaim.approvedOtherAmount`)}
                      name={`quotation.approvedOtherAmount.amount`}
                      variant="filled"
                      autoComplete='off'
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Example prop
                        classes: {
                          root: 'custom-label-root-class', // Example custom class
                          focused: 'custom-label-focused-class', // Example custom class for focused state
                        },
                        // Any other props you want to pass to InputLabel
                      }}
                      InputProps={{
                        disableUnderline: false,
                        shrink: true
                      }}
                      className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                      onChange={formik.handleChange}
                      value={getIn(formik.values, 'quotation.approvedOtherAmount.amount')}
                    // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                    // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                    />

                  </Grid>

                  <Grid item xs={4}>

                    <TextField
                      key={`quotation.approvedAdldAmount.amount`}
                      label={t(`keys.extendedWarrantyClaim.approvedAdldAmount`)}
                      name={`quotation.approvedAdldAmount.amount`}
                      variant="filled"
                      autoComplete='off'
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Example prop
                        classes: {
                          root: 'custom-label-root-class', // Example custom class
                          focused: 'custom-label-focused-class', // Example custom class for focused state
                        },
                        // Any other props you want to pass to InputLabel
                      }}
                      InputProps={{
                        disableUnderline: false,
                        shrink: true
                      }}
                      className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                      onChange={formik.handleChange}
                      value={getIn(formik.values, 'quotation.approvedAdldAmount.amount')}
                    // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                    // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                    />

                  </Grid>

                  <Grid item xs={4}>

                    <TextField
                      key={`quotation.approvedReplacementAmount.amount`}
                      label={t(`keys.extendedWarrantyClaim.approvedReplacementAmount`)}
                      name={`quotation.approvedReplacementAmount.amount`}
                      variant="filled"
                      autoComplete='off'
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Example prop
                        classes: {
                          root: 'custom-label-root-class', // Example custom class
                          focused: 'custom-label-focused-class', // Example custom class for focused state
                        },
                        // Any other props you want to pass to InputLabel
                      }}
                      InputProps={{
                        disableUnderline: false,
                        shrink: true
                      }}
                      className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                      onChange={formik.handleChange}
                      value={getIn(formik.values, 'quotation.approvedReplacementAmount.amount')}
                    // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                    // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                    />

                  </Grid>

                  <Grid item xs={4}>

                    <TextField
                      key={`quotation.customerPayableAmount.amount`}
                      label={t(`keys.extendedWarrantyClaim.customerPayableAmount`)}
                      name={`quotation.customerPayableAmount.amount`}
                      variant="filled"
                      autoComplete='off'
                      fullWidth
                      InputLabelProps={{
                        shrink: true, // Example prop
                        classes: {
                          root: 'custom-label-root-class', // Example custom class
                          focused: 'custom-label-focused-class', // Example custom class for focused state
                        },
                      }}
                      InputProps={{
                        disableUnderline: false,
                        shrink: true
                      }}
                      className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                      onChange={formik.handleChange}
                      value={getIn(formik.values, 'quotation.customerPayableAmount.amount')}
                    // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                    // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                    />

                  </Grid>
                </Grid>
            </div>

          </div>
        </div>
      </div>

    </>
  );
};

export default QuotationDetailsView;
