import React, {Component} from "react";
import { connect } from 'react-redux';
import {
    clearVoucherCodeFilters,
    getVoucherCodeList, sortVoucherCodes,
    updateFiltersForVoucherCodeList
} from "../actions/voucherCodeActions";
import {parseObjectToString, parseSortingToString} from "../utils/utils";
import DataTable from "../components/DataTable";
import PageTemplate from "../components/PageTemplate";

class VoucherCodeList extends Component {
    componentDidMount() {
        this.props.getVoucherCodeList('orderBy=createdAt&sort=desc');
        this.props.clearVoucherCodeFilters();
        this.props.sortVoucherCodes('createdAt', 'desc');
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let filterState = this.props.voucherCodes.filters[filterKey];
            filterState.includes(filterValue) ? filterState = filterState.filter(item => item !== filterValue) : filterState.push(filterValue);
            filterValue = filterState;
        }
        this.props.updateFiltersForVoucherCodeList(filterKey, filterValue);
    }

    handleFilterReset() {
        this.props.clearVoucherCodeFilters();
        this.props.getVoucherCodeList(parseSortingToString(this.props.voucherCodes.sorting));
    }

    handleSorting(orderBy, sort) {
        this.props.sortVoucherCodes(orderBy, sort);
        this.props.getVoucherCodeList(parseObjectToString(this.props.voucherCodes.filters, {orderBy, sort}))
    }

    renderVoucherCodes(voucherCodes, showContentLoader) {
        const fields = [
            {
                name: 'serialNumber'
            },
            {
                name: 'planCodes'
            },
            {
                name: 'attributes.dateOfPurchase.value',
                type: 'shortDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
            {
                name: 'active',
                type: 'translatedString'
            },
            {
                name: 'activationDate',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
            {
                name: 'expiryDate',
                type: 'expiryDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }];

        const filterKeys = [
            {
                name: 'serialNumber'
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            }
        ]
        return(
            <DataTable fields={fields}
                       items={voucherCodes}
                       entity="voucherCodes"
                       filterKeys={filterKeys}
                       filterValues={this.props.voucherCodes.filters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getVoucherCodeList(parseObjectToString(this.props.voucherCodes.filters, this.props.voucherCodes.sorting))}
                       sorting={this.props.voucherCodes.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
            />
        )
    }

    render() {
        return (
            <PageTemplate title="messages.voucherCodeList">
                {this.renderVoucherCodes(this.props.voucherCodes.voucherCodes, this.props.common.showContentLoader)}
            </PageTemplate>
            )
    }

}

const mapStateToProps = state => ({
    authentication: state.authentication,
    voucherCodes: state.voucherCodes,
    common: state.common
})

export default connect(mapStateToProps, {
    getVoucherCodeList,
    updateFiltersForVoucherCodeList,
    clearVoucherCodeFilters,
    sortVoucherCodes
})(VoucherCodeList);