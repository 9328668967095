import {
    EXTENDED_WARRANTY_CLAIM_GET_LIST,
    EXTENDED_WARRANTY_CLAIM_GET_DETAILS,
    EXTENDED_WARRANTY_CLAIM_GET_SERVICE_LIMIT,
    EXTENDED_WARRANTY_CLAIM_FILTER,
    EXTENDED_WARRANTY_CLAIM_CLEAR_FILTER,
    EXTENDED_WARRANTY_CLAIM_SORT
} from "../actions/types";

const INITIAL_STATE = {
    extendedWarrantyClaims: [],
    extendedWarrantyClaim: {},
    serviceLimit: {},
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    extendedWarrantyClaimFilters: {
        _id: '',
        number: '',
        status: [],
        createdAt: {
            from: null,
            to: null
        },
        updatedAt: {
            from: null,
            to: null
        }
    },
}

const extendedWarrantyClaimReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EXTENDED_WARRANTY_CLAIM_GET_LIST:
            return {
                ...state,
                extendedWarrantyClaims: action.payload.data
            }
        case EXTENDED_WARRANTY_CLAIM_FILTER:
            return {
                ...state,
                extendedWarrantyClaimFilters: {
                    ...state.extendedWarrantyClaimFilters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case EXTENDED_WARRANTY_CLAIM_CLEAR_FILTER:
            return {
                ...state,
                extendedWarrantyClaimFilters: {
                    _id: '',
                    number: '',
                    status: [],
                    createdAt: {
                        from: null,
                        to: null
                    },
                    updatedAt: {
                        from: null,
                        to: null
                    }
                }
            }
        case EXTENDED_WARRANTY_CLAIM_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        case EXTENDED_WARRANTY_CLAIM_GET_DETAILS:
            return {
                ...state,
                extendedWarrantyClaim: action.payload.data,
            }
        case EXTENDED_WARRANTY_CLAIM_GET_SERVICE_LIMIT:
            return {
                ...state,
                serviceLimit: action.payload.data,
            }
        default:
            return state;
    }
}

export default extendedWarrantyClaimReducer;