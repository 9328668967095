import React, {Component} from "react";
import { connect } from 'react-redux';
import DataTable from "../components/DataTable";
import PageTemplate from "../components/PageTemplate";
import {clearSwapFilters, getSwapList, sortSwaps, updateFiltersForSwapList} from "../actions/swapActions";
import {parseObjectToString, parseSortingToString} from "../utils/utils";

class RepairList extends Component {
    componentDidMount() {
        this.props.getSwapList('orderBy=createdAt&sort=desc');
        this.props.clearSwapFilters();
        this.props.sortSwaps('createdAt', 'desc');
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let filterState = this.props.swaps.filters[filterKey];
            filterState.includes(filterValue) ? filterState = filterState.filter(item => item !== filterValue) : filterState.push(filterValue);
            filterValue = filterState;
        }
        this.props.updateFiltersForSwapList(filterKey, filterValue);
    }

    handleFilterReset() {
        this.props.clearSwapFilters();
        this.props.getSwapList('orderBy=createdAt&sort=desc');
    }

    handleSorting(orderBy, sort) {
        this.props.sortSwaps(orderBy, sort);
        this.props.getSwapList(parseObjectToString(this.props.swaps.filters, {orderBy, sort}))
    }

    renderRepairs(swaps, showContentLoader) {
        const fields = [
            {
                name: 'number'
            },
            {
                name: 'replacementDevice',
                type: 'isFieldEmpty'
            },
            {
                name: 'usageType',
                type: 'translatedString'
            },
            {
                name: 'status',
                type: 'translatedString'
            },
            {
                name: 'coveredDevice.imei',
            },
            {
                name: 'repairDetail.courierArrangedTimeFrom',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
            {
                name: 'repairDetail.courierArrangedTimeTo',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
            {
                name: 'updatedAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }
        ];

        const filterKeys = [
            {
                name: 'number'
            },
            {
                name: 'status',
                type: 'checkBoxes',
                options: ['new', 'received', 'processingDevice', 'pendingDelivery', 'declined' ,'rejected']
            },
            {
                name: 'coveredDevice.imei'
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            },
            {
                name: 'updatedAt',
                type: 'dateRangePicker'
            }
        ];

        return(
            <DataTable fields={fields}
                       items={swaps}
                       entity="repairs"
                       filterKeys={filterKeys}
                       filterValues={this.props.swaps.filters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getSwapList(parseObjectToString(this.props.swaps.filters, this.props.swaps.sorting))}
                       sorting={this.props.swaps.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
            />

        )

    }

    render() {
        return (
            <PageTemplate title="messages.repairList">
                {this.renderRepairs(this.props.swaps.swaps, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    swaps: state.swaps,
    common: state.common
})

export default connect(mapStateToProps, {
    getSwapList,
    updateFiltersForSwapList,
    clearSwapFilters,
    sortSwaps
})(RepairList);