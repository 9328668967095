import React, {Component} from "react";
import PageTemplate from "../components/PageTemplate";
import {connect} from "react-redux";
import {
    getTradeInDetailList,
    updateFiltersForTradeInDetailList,
    clearTradeInTradeInDetailList,
    sortTradeInDetailList
} from "../actions/tradeInAction";
import DataTable from "../components/DataTable";
import {parseObjectToString, validateAuthority} from "../utils/utils";
import Button from "@material-ui/core/Button";
import {exportFile} from "../actions/exportActions";
import {tradeInDeatilListExport} from "../utils/exportUtils";

class TradeInDashboard extends Component {
    componentDidMount() {
        this.props.getTradeInDetailList('orderBy=createdAt&sort=desc');
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let tradeInDetailListFilterState = this.props.tradeIns.tradeInDetailListFilters[filterKey];
            tradeInDetailListFilterState.includes(filterValue) ? tradeInDetailListFilterState = tradeInDetailListFilterState.filter(item => item !== filterValue) : tradeInDetailListFilterState.push(filterValue);
            filterValue = tradeInDetailListFilterState;
        }
        this.props.updateFiltersForTradeInDetailList(filterKey, filterValue)
    }

    handleFilterReset() {
        this.props.clearTradeInTradeInDetailList();
        this.props.getTradeInDetailList('orderBy=createdAt&sort=desc');
        this.props.sortTradeInDetailList('createdAt', 'desc');
    }

    handleSorting(orderBy, sort) {
        const orderByKey = {
            "tradeUp.imei": "tradeUps.imei",
            "tradeUp.tradeUpDevice.make": "tradeUps.tradeUpDevice.make",
            "tradeUp.tradeUpDevice.nickname": "tradeUps.tradeUpDevice.nickname",
            "tradeUp.tradeUpDevice.capacityDescription": "tradeUps.tradeUpDevice.capacityDescription",
            "businessEntityName": "tradeIns_diagnostic.businessEntity.name",
            "staffFirstName": "tradeIns_diagnostic.staffInfo.firstName",
            "staffLastName": " tradeIns_diagnostic.staffInfo.lastName"
        }
        let modifiedOrderBy = orderByKey[orderBy] ? orderByKey[orderBy] : orderBy;
        this.props.sortTradeInDetailList(orderBy, sort)
        this.props.getTradeInDetailList(parseObjectToString(this.props.tradeIns.tradeInDetailListFilters, {orderBy: modifiedOrderBy, sort: sort}));
    }

    onDocumentDownload() {
        this.props.exportFile(tradeInDeatilListExport(this.props.tradeIns.tradeInDetailListFilters.createdAt.from, this.props.tradeIns.tradeInDetailListFilters.createdAt.to));
    }

    renderTradeInDetailList = (tradeInDetailList, showContentLoader) => {
        const fields = [
            {
                name: 'id',
                label: 'tradeInDetailList.id'
            },
            {
                name: 'countryCode',
                label: 'tradeInDetailList.countryCode'
            },
            {
                name: 'carrierCode',
                label: 'tradeInDetailList.carrierCode'
            },
            {
                name: 'imei',
                label: 'tradeInDetailList.tradeIn.imei'
            },
            {
                name: 'tradeInDevice.make',
                label: 'tradeInDetailList.tradeIn.make'
            },
            {
                name: 'tradeInDevice.nickname',
                label: 'tradeInDetailList.tradeIn.model'
            },
            {
                name: 'tradeInDevice.capacityDescription',
                label: 'tradeInDetailList.tradeIn.capacity'
            },
            {
                name: ['values[0].value.currencyCode', 'values[0].value.amount'],
                label: 'tradeInDetailList.tradeIn.tradeInValue',
                tag: "multipleValues",
                sorting: false
            },
            {
                name: ['bonus.currencyCode', 'bonus.amount'],
                label: 'tradeInDetailList.tradeIn.bonusValue',
                tag: "multipleValues",
                sorting: false
            },
            {
                name: ['tradeInPlusBonus.currencyCode', 'tradeInPlusBonus.amount'],
                label: 'tradeInDetailList.tradeIn.tradeInPlusBonusValue',
                tag: "multipleValues",
                sorting: false
            },
            {
                name: 'testResults',
                label: 'tradeInDetailList.tradeIn.testResults',
                key: 'testId',
                value: 'result',
                type: 'keyValueArray',
                sorting: false
            },
            {
                name: 'tradeUp.imei',
                label: 'tradeInDetailList.tradeUp.imei'
            },
            {
                name: 'tradeUp.tradeUpDevice.make',
                label: 'tradeInDetailList.tradeUp.make'
            },
            {
                name: 'tradeUp.tradeUpDevice.nickname',
                label: 'tradeInDetailList.tradeUp.model'
            },
            {
                name: 'tradeUp.tradeUpDevice.capacityDescription',
                label: 'tradeInDetailList.tradeUp.capacity'
            },
            {
                name: ['tradeUp.tradeUpValue.currencyCode', 'tradeUp.tradeUpValue.amount'],
                label: 'tradeInDetailList.tradeUp.srp',
                tag: "multipleValues",
                sorting: false
            },
            {
                name: ['tradeUp.eligibleTopUpValue.currencyCode', 'tradeUp.eligibleTopUpValue.amount'],
                label: 'tradeInDetailList.tradeUp.token',
                tag: "multipleValues",
                sorting: false
            },
            {
                name: 'businessEntityName',
                label: 'tradeInDetailList.businessEntityName'
            },
            {
                name: 'staffFirstName',
                label: 'tradeInDetailList.staffFirstName'
            },
            {
                name: 'staffLastName',
                label: 'tradeInDetailList.staffLastName'
            },
            {
                name: 'tradeUp.invoiceNumber',
                label: 'tradeInDetailList.invoiceNumber'
            },
            {
                name: 'status'
            },
            {
                name: 'updatedAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }
        ];

        const filterKeys = [
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            }
        ]

        return(
            <DataTable fields={fields}
                       items={tradeInDetailList}
                       entity="tradeInDetail"
                       filterKeys={filterKeys}
                       filterValues={this.props.tradeIns.tradeInDetailListFilters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getTradeInDetailList(parseObjectToString(this.props.tradeIns.tradeInDetailListFilters))}
                       sorting={this.props.tradeIns.tradeInDetailSorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
                       disableCell={true}
                       isFilterOpen={true}
                       emptyString={"-"}
            />
        )
    }

    render() {
        return (
            <PageTemplate title="messages.tradeInDetailList">
                <div className="display-flex justify-space-between align-items-center margin-bottom-10">
                    <p/>
                    {
                        <Button variant="contained" size="small" color="secondary" onClick={() => this.onDocumentDownload()}>download</Button>
                    }
                </div>
                {this.renderTradeInDetailList(this.props.tradeIns.tradeInDetailList, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    tradeIns: state.tradeIns,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getTradeInDetailList,
    updateFiltersForTradeInDetailList,
    clearTradeInTradeInDetailList,
    sortTradeInDetailList,
    exportFile
})(TradeInDashboard);