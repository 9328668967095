export const addressFields = {
    'IT': ['street', 'number', 'zipCode', 'city', 'provinceCode'],
    'HK': ['flat', 'floor', 'building', 'number', 'street', 'district', 'city'],
    'TH': ['homeNumber', 'area', 'building', 'floor', 'lane', 'street', 'district', 'subDistrict', 'city', 'zipCode'],
    'SG': ['homeNumber', 'block', 'building', 'street', 'zipCode'],
    'PH': ['homeNumber', 'street', 'building', 'provinceCode', 'city', 'neighbourhood', 'zipCode'],
    'MY': ['addressLine1', 'addressLine2', 'addressLine3', 'city', 'state', 'zipCode'],
}

export const customerAddressFields = {
    'AT': [ 'street', 'number', 'flat', 'zipCode', 'city', 'countryCode', 'type'],
    'IT': ['street', 'number', 'zipCode', 'city', 'provinceCode', 'countryCode', 'type'],
    'IE': ['street', 'number', 'district', 'city', , 'zipCode', 'countryCode', 'type'],

    'HK': ['flat', 'floor', 'building', 'number', 'street', 'district', 'city', 'countryCode', 'type'],
    'TH': ['building', 'floor', 'homeNumber', 'area', 'lane', 'street', 'district', 'subDistrict', 'city', 'zipCode', 'countryCode', 'type'],
    'MO': ['flat', 'floor', 'building', 'street', 'district', 'zipCode', 'city', 'countryCode', 'type'],
    'PH': ['street', 'floor', 'flat', 'building', 'provinceCode', 'city', 'district', 'zipCode', 'countryCode', 'type'],
    'SG': ['homeNumber', 'building', 'street',  'block', 'zipCode', 'countryCode', 'type'],
    'MY': ['addressLine1', 'addressLine2', 'addressLine3', 'city', 'state', 'zipCode', 'countryCode', 'type'],
    'ID': ['homeNumber', 'building', 'street',  'block', 'provinceCode', 'city', 'zipCode', 'countryCode', 'type'],
    
    'KR': ['street', 'building', 'zipCode', 'homeNumber', 'flat', 'countryCode', 'type'],
    'JP': ['street', 'city', 'prefecture', 'building', 'zipCode', 'flat', 'countryCode', 'type'],
    'TW': ['city', 'zipCode', 'district', 'street',  'countryCode', 'type'],
}

export const fileUploadAcceptedFields = {
    merchantUser: [
        'email',
        'displayName',
        'phoneNumber',
        'languageCode',
        'authorities',
        'businessEntityCode'
    ],
    businessEntity: [
        'code',
        'name',
        'channel',
        'dealerCode',
        'aggregatedPOSCode',
        'email'
    ]
}

export const fileUploadRequiredFields = {
    merchantUser: [
        'email',
        'displayName',
        'phoneNumber',
        'languageCode',
        'authorities'
    ],
    businessEntity: [
        'code',
        'name',
        'channel',
        'dealerCode',
        'aggregatedPOSCode'
    ]
}