import React, {Component} from "react";
import PageTemplate from "../components/PageTemplate";
import {connect} from "react-redux";
import {
    getTradeInTransactions,
    clearTradeInTransactionsFilters,
    sortTradeInTransactions,
    updateFiltersForTradeInTransactions
} from "../actions/tradeInAction";
import DataTable from "../components/DataTable";
import {parseObjectToString, validateAuthority} from "../utils/utils";

class TradeInTransactionsList extends Component {
    componentDidMount() {
        this.props.getTradeInTransactions('orderBy=createdAt&sort=desc');
        this.props.clearTradeInTransactionsFilters();
        this.props.sortTradeInTransactions('createdAt', 'desc');
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let tradeInTransactionFilterState = this.props.tradeIns.filters[filterKey];
            tradeInTransactionFilterState.includes(filterValue) ? tradeInTransactionFilterState = tradeInTransactionFilterState.filter(item => item !== filterValue) : tradeInTransactionFilterState.push(filterValue);
            filterValue = tradeInTransactionFilterState;
        }
        this.props.updateFiltersForTradeInTransactions(filterKey, filterValue)
    }

    handleFilterReset() {
        this.props.clearTradeInTransactionsFilters();
        this.props.getTradeInTransactions('orderBy=createdAt&sort=desc');
        this.props.sortTradeInTransactions('createdAt', 'desc');
    }

    handleSorting(orderBy, sort) {
        this.props.sortTradeInTransactions(orderBy, sort)
        this.props.getTradeInTransactions(parseObjectToString(this.props.tradeIns.filters, {orderBy, sort}));
    }

    renderTradeInTransactionList = (tradeInTransactions, showContentLoader) => {
        const fields = [
            {
                name: 'imei'
            },
            {
                name: 'grade',
                label: 'tradeInTransaction.grading'
            },
            {
                name: ['values[0].value.currencyCode', 'values[0].value.amount'],
                label: 'tradeInTransaction.tradeInValues',
                tag: "multipleValues",
                sorting: false
            },
            {
                name: ['tradeInDevice.make', 'tradeInDevice.nickname', 'tradeInDevice.capacityDescription'],
                label: 'tradeInTransaction.tradeInDevice',
                tag: "multipleValues",
                sorting: false
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }];

        const filterKeys = [
            {
                name: 'imei',
                type: 'textField'
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            }
        ]

        return(
            <DataTable fields={fields}
                       items={tradeInTransactions}
                       entity="tradeInTransactions"
                       filterKeys={filterKeys}
                       filterValues={this.props.tradeIns.filters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getTradeInTransactions(parseObjectToString(this.props.tradeIns.filters))}
                       sorting={this.props.tradeIns.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
            />
        )
    }

    render() {
        return (
            <PageTemplate title="messages.tradeInTransactionList">
                {this.renderTradeInTransactionList(this.props.tradeIns.tradeInTransactions, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    tradeIns: state.tradeIns,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getTradeInTransactions,
    clearTradeInTransactionsFilters,
    sortTradeInTransactions,
    updateFiltersForTradeInTransactions
})(TradeInTransactionsList);