import {applyMiddleware, combineReducers, createStore} from "redux";
import thunk from "redux-thunk";
import {composeWithDevTools} from "redux-devtools-extension";
import merchantUserReducer from "../reducers/merchantUserReducer";
import authenticationReducer from "../reducers/authenticationReducer";
import commonReducer from "../reducers/commonReducer";
import pickUpReducer from "../reducers/pickUpReducer";
import downloadRequestReducer from "../reducers/downloadRequestReducer";
import pickUpItemReducer from "../reducers/pickUpItemReducer";
import remarkReducer from "../reducers/remarkReducer";
import logisticsReducer from "../reducers/logisticsReducer";
import businessEntityReducer from "../reducers/businessEntityReducer";
import exportReducer from "../reducers/exportReducer";
import voucherCodeReducer from "../reducers/voucherCodeReducer";
import planReducer from "../reducers/planReducer";
import searchReducer from "../reducers/searchReducer";
import swapReducer from "../reducers/swapReducer";
import subscriptionReducer from "../reducers/subscriptionReducer";
import customerReducer from "../reducers/customerReducer";
import extendedWarrantyClaimReducer from "../reducers/extendedWarrantyClaimReducer";
import tradeInReducer from "../reducers/tradeInReducer";

const rootReducer = combineReducers({
    authentication: authenticationReducer,
    businessEntities: businessEntityReducer,
    customers: customerReducer,
    common: commonReducer,
    export: exportReducer,
    extendedWarrantyClaims: extendedWarrantyClaimReducer,
    logistics: logisticsReducer,
    merchantUsers: merchantUserReducer,
    pickUpItems: pickUpItemReducer,
    pickUps: pickUpReducer,
    downloadRequests: downloadRequestReducer,
    subscriptions: subscriptionReducer,
    plans: planReducer,
    remarks: remarkReducer,
    search: searchReducer,
    swaps: swapReducer,
    tradeIns: tradeInReducer,
    voucherCodes: voucherCodeReducer
});

export default function configureStore() {
    const middlewares = [thunk];
    const middleWareEnhancer = applyMiddleware(...middlewares);

    return createStore(
        rootReducer,
        composeWithDevTools(middleWareEnhancer)
    );
}