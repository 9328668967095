import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    SUBSCRIPTION_CLEAR_FILTER,
    SUBSCRIPTION_FILTER,
    SUBSCRIPTION_GET_DETAILS,
    SUBSCRIPTION_GET_LIST, SUBSCRIPTION_SORT,
    SUBSCRIPTION_GET_PARTNER_ADDITIONAL_DETAILS
} from "./types";
import { handleError, showContentLoader } from "../utils/utils";

export const getSubscriptionList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/subscriptions/?${path}`)
        .then(response => {
            dispatch({
                type: SUBSCRIPTION_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const getSubscriptionDetails = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/subscriptions/${id}`)
        .then(response => {
            dispatch({
                type: SUBSCRIPTION_GET_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const getSubscriptionWithPartnerAdditionalDetails = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/subscriptions/getSubscriptionWithPartnerAdditionalDetails/${id}`)
        .then(response => {
            dispatch({
                type: SUBSCRIPTION_GET_PARTNER_ADDITIONAL_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const getSubscriptionListByCustomer = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/subscriptions/byCustomer/${id}`)
        .then(response => {
            dispatch({
                type: SUBSCRIPTION_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const updateFilterForSubscriptionList = (filterKey, filterValue) => {
    return ({
        type: SUBSCRIPTION_FILTER,
        payload: { filterKey, filterValue }
    })
}

export const clearSubscriptionFilters = () => {
    return ({
        type: SUBSCRIPTION_CLEAR_FILTER
    })
}

export const sortSubscriptions = (orderBy, sort) => {
    return ({
        type: SUBSCRIPTION_SORT,
        payload: { orderBy, sort }
    })
}