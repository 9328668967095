import React from "react";
import "../../styles/custom-bootstrap.scss";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import {
  formatCurrency,
} from "../../utils/utils";

const ExcessFeeTable = (props) => {
  const { extendedWarrantyClaim } =
    props;

  const { t } = useTranslation();
  const customStyles = makeStyles({
    customBackground: {
      background: "#eceff1",
    },
  });
  const customStyleClass = customStyles();

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <i class="fa fa-history" aria-hidden="true"></i>
              <strong> {'Excess Fee'}</strong>
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <table className="table table-sm table-striped">
                      <thead>
                        <tr>
                          <th>{ }</th>
                          <th class="text-right">{t(`Currency`)}</th>
                          <th class="text-right">{t(`Amount`)}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {Object.keys(extendedWarrantyClaim?.feeHashMap).map((key) => {
                          return (
                            <tr>
                              <td>{t(`keys.extendedWarrantyClaim.${key}.excessFee`)}</td>
                              <td className="text-right">
                                {extendedWarrantyClaim?.feeHashMap[key]?.totalFee?.currencyCode}
                              </td>
                              <td className="text-right">
                                {formatCurrency(extendedWarrantyClaim?.feeHashMap[key]?.totalFee?.amount)}
                              </td>
                            </tr>
                          )
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default ExcessFeeTable;
