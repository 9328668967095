import {
    MERCHANT_USER_FILTER,
    MERCHANT_USER_GET_DETAILS,
    MERCHANT_USER_GET_LIST,
    MERCHANT_USER_UPDATE,
    MERCHANT_USER_CREATE, MERCHANT_USER_CLEAR_FILTER, MERCHANT_USER_SORT
} from "../actions/types";

const INITIAL_STATE = {
    merchantUsers: [],
    merchantUser: {},
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    merchantFilters: {
        email: '',
        displayName: '',
        businessEntityCode: '',
        locked: [],
        active: [],
        authorities: [],
        createdAt: {
            from: null,
            to: null
        }
    },
}

const merchantUserReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MERCHANT_USER_GET_LIST:
            return {
                ...state,
                merchantUsers: action.payload.data
            }
        case MERCHANT_USER_FILTER:
            return {
                ...state,
                merchantFilters: {
                    ...state.merchantFilters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case MERCHANT_USER_GET_DETAILS:
            return {
                ...state,
                merchantUser: action.payload.data,
            }
        case MERCHANT_USER_CLEAR_FILTER:
            return {
                ...state,
                merchantFilters: {
                    email: '',
                    displayName: '',
                    businessEntityCode: '',
                    locked: [],
                    active: [],
                    authorities: [],
                    createdAt: {
                        from: null,
                        to: null
                    }
                }
            }
        case MERCHANT_USER_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        case MERCHANT_USER_UPDATE:
        case MERCHANT_USER_CREATE:
            return {
                ...state
            }
        default:
            return state;
    }
}

export default merchantUserReducer;