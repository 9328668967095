import axios from "./interceptor";
import { BASE_URL } from "../constant";
import { handleError, showContentLoader } from "../utils/utils";
import {
    HIDE_CONFIRMATION_DIALOG,
    SHOW_SUCCESS,
    SWAP_CLEAR_FILTER, SWAP_FILTER,
    SWAP_GET_DETAILS,
    SWAP_GET_LIST, SWAP_SORT,
} from "./types";

export const getSwapList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/swaps/?${path}`)
        .then(response => {
            dispatch({
                type: SWAP_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const getSwapDetails = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/swaps/${id}/fullDetail`)
        .then(response => {
            dispatch({
                type: SWAP_GET_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const updateFiltersForSwapList = (filterKey, filterValue) => {
    return ({
        type: SWAP_FILTER,
        payload: { filterKey, filterValue }
    })
}

export const clearSwapFilters = () => {
    return ({
        type: SWAP_CLEAR_FILTER
    })
}

export const sortSwaps = (orderBy, sort) => {
    return ({
        type: SWAP_SORT,
        payload: { orderBy, sort }
    })
}

export const updateRepairParts = (id, value) => async dispatch => {
    axios.patch(`${BASE_URL}/v1/swaps/${id}`, { repairDetail: { repairParts: [value] } })
        .then((response) => {
            dispatch({
                type: HIDE_CONFIRMATION_DIALOG
            })
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.swap.updatedRepairParts',
                    dialogType: 'success'
                }
            })
            dispatch({
                type: SWAP_GET_DETAILS,
                payload: response
            })
        }).catch(error => {
            handleError(error, dispatch);
        })
}

export const updateRepairCost = (id, value) => async dispatch => {
    axios.patch(`${BASE_URL}/v1/swaps/${id}`, { repairDetail: { repairCost: { amount: value } } })
        .then((response) => {
            dispatch({
                type: HIDE_CONFIRMATION_DIALOG
            })
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.swap.updatedRepairCost',
                    dialogType: 'success'
                }
            })
            dispatch({
                type: SWAP_GET_DETAILS,
                payload: response
            })
        }).catch(error => {
            handleError(error, dispatch);
        })
}

export const updateStatus = (id, event) => async dispatch => {
    axios.post(`${BASE_URL}/v1/swaps/${id}/events/${event}`, {})
        .then((response) => {
            dispatch({
                type: HIDE_CONFIRMATION_DIALOG
            })
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.swap.updatedStatus',
                    dialogType: 'success'
                }
            })
            dispatch({
                type: SWAP_GET_DETAILS,
                payload: response
            })
        }).catch(error => {
            handleError(error, dispatch);
        })
}