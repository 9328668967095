import _ from "lodash";

export const parseSearchParams = (searchParams, countryCode, languageCode) => {
    const localeCode = `${languageCode}_${countryCode}`;
    let filterFields = '';
    let filterValues = '';
    _.forEach(searchParams, (value, key) => {
        if (value.length > 0) {
                filterFields = filterFields + `filter_field[]=${key.replace('**localeCode**', localeCode)}&`
                filterValues = filterValues + `filter_value[]=${encodeURIComponent(value)}&`
        }
    })
    return (filterFields + filterValues);
}
