import React, {Component} from "react";
import { connect } from 'react-redux';
import {withTranslation} from "react-i18next";
import {getSearchResults, setSearchParamValue, unsetSearchState} from "../actions/searchActions";
import Typography from "@material-ui/core/Typography";
import {SEARCH_RESULT_KEYS} from "../constant";
import {parseSearchParams} from "../utils/searchUtils";
import TextField from "@material-ui/core/TextField";

class ElasticSearch extends Component {
    componentDidMount() {
        this.props.unsetSearchState();
        this.props.getSearchResults('article.manufacturer.name.**localeCode**.keyword', '', this.props.authentication.loggedInUser.languageCode, this.props.authentication.loggedInUser.countryCode, this.props.carrierCode)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.search.searchParams !== prevProps.search.searchParams) {
            this.props.getSearchResults(
                this.props.search.nextSearch,
                parseSearchParams(this.props.search.searchParams, this.props.authentication.loggedInUser.countryCode, this.props.authentication.loggedInUser.languageCode),
                this.props.authentication.loggedInUser.languageCode,
                this.props.authentication.loggedInUser.countryCode,
                this.props.carrierCode
            )
        }
        if (this.props.search.searchResults['article.sku.keyword'][0] !== prevProps.search.searchResults['article.sku.keyword'][0]) {
            this.props.onSkuChange(this.props.search.searchResults['article.sku.keyword'][0])
        }
    }

    handleOnChange(value, facet) {
        this.props.setSearchParamValue(facet, value.target.value, value.target.getAttribute('data-nextsearch'));
    }

    renderInputFields (t) {
        return (
            SEARCH_RESULT_KEYS.map((searchResultKey, index) => {
                return (
                    <div key={searchResultKey} className="margin-bottom-10">
                        <Typography variant="subtitle2">{t(`keys.${searchResultKey}`)}</Typography>
                        {index < SEARCH_RESULT_KEYS.length - 1 &&
                        <select name={searchResultKey}
                                key={searchResultKey}
                                data-nextsearch={SEARCH_RESULT_KEYS[index + 1]}
                                onChange={(value) => this.handleOnChange(value, searchResultKey)}
                                value={this.props.search.searchParams[searchResultKey]}
                                className="select select--small"
                                style={{width: '100%'}}
                                disabled={this.props.search.searchResults[searchResultKey].length === 0}
                        >
                            <option hidden value=''>{t('messages.selectYourOption')}</option>
                            {
                                this.props.search.searchResults[searchResultKey].map(searchResult => {
                                    return (
                                        <option value={searchResult} key={searchResult}>
                                            {searchResult}
                                        </option>
                                    )
                                })
                            }
                        </select>
                        }
                        {
                            index === SEARCH_RESULT_KEYS.length - 1 &&
                            <TextField
                                variant="outlined"
                                fullWidth
                                size="small"
                                name={searchResultKey}
                                autoComplete='off'
                                disabled
                                value={this.props.search.searchResults[searchResultKey][0] || ''}
                            />
                        }
                    </div>
                )
            })
        )
    }

    render() {
        const { t } = this.props;
        return (
            this.renderInputFields(t)
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    search: state.search,
    common: state.common
})

export default connect(mapStateToProps, {
    getSearchResults,
    setSearchParamValue,
    unsetSearchState
})(withTranslation() (ElasticSearch));