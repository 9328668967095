import React from 'react';
import {Card, CardContent} from "@material-ui/core";

const AddressCard = ({ address, countryCode }) => {

    if(!address) return null
    return (
        <Card>
            <CardContent>
                {
                    countryCode === 'IT' &&
                    <React.Fragment>
                        <span>{address.street}</span> <span>{address.number}</span><br />
                        <span>{address.zipCode}</span> <span>{address.city}</span><br />
                        <span>{address.provinceCode}</span>
                    </React.Fragment>
                }
                {
                    countryCode === 'HK' &&
                    <React.Fragment>
                        <span>{address.flat}</span> <span>{address.floor}</span><br />
                        <span>{address.building}</span><br />
                        <span>{address.number}</span> <span>{address.street}</span><br />
                        <span>{address.district}</span> <span>{address.city}</span>
                    </React.Fragment>
                }
                {
                    countryCode === 'TH' &&
                    <React.Fragment>
                        <span>{address.homeNumber}</span> <span>{address.area}</span><br />
                        <span>{address.building}</span> <span>{address.floor}</span><br />
                        <span>{address.lane}</span> <span>{address.street}</span><br />
                        <span>{address.district}</span> <span>{address.subDistrict}</span> <br />
                        <span>{address.city}</span> <span>{address.zipCode}</span>
                    </React.Fragment>
                }
                {
                    countryCode === 'SG' &&
                    <React.Fragment>
                        <span>{address.homeNumber}</span> <span>{address.block}</span><br />
                        <span>{address.street}</span> <span>{address.building}</span><br />
                        <span>{address.zipCode}</span><br />
                    </React.Fragment>
                }
            </CardContent>
        </Card>
    )
}

export default AddressCard;