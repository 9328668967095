import React from "react";
import "../../styles/custom-bootstrap.scss";
import "../../styles/custom-react-date-picker.css";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { zonedTimeToUtc, format } from 'date-fns-tz';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import {
  fullDateWithIndicator,
} from "../../utils/utils";
import { customerAddressFields } from "../../utils/fields";
import { FormControl, Grid, InputLabel, MenuItem, NativeSelect, Select, TextField, Typography } from "@material-ui/core";
import { useFormik, useFormikContext, getIn } from "formik";

const RepairInvoiceView = (props) => {
  const { extendedWarrantyClaim, userCountryCode, editableFields, serviceLimit } =
    props;


  const { t } = useTranslation();
  const customStyles = makeStyles({
    inputField: {
      marginTop: 10,
      marginBottom: 10
    },
    selectField: {
      marginBottom: 10
    },
    card: {
      width: '100%'
    },
    customStyleForSelect: {
      color: '#f44336',
      fontSize: '0.75rem',
      marginTop: '3px',
      marginLeft: '14px',
      marginRight: '14px'
    }
  })
  const classes = customStyles();

  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const handleDateSelect = (newDate, field) => {
    const formattedDate = new Date(newDate);
    const dateInUsersLocalTime = zonedTimeToUtc(formattedDate, timeZone)
    formik.setFieldValue(field, dateInUsersLocalTime);
  }

  const formik = useFormikContext();

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <i class="fa fa-history" aria-hidden="true"></i>
              <strong> {'Repair Invoice'}</strong>
            </div>
            <div className="card-body">
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <div className="">
                    <Typography variant="subtitle2">{t('Invoice Date & Time')}</Typography>

                    <DatePicker
                      selected={getIn(formik.values, 'invoice.invoiceDate') ? new Date(getIn(formik.values, 'invoice.invoiceDate')) : null}
                      onChange={(value) =>
                        handleDateSelect(value, 'invoice.invoiceDate')
                      }
                      name={`invoice.invoiceDate`}
                      className={`${classes.inputField} input-field`}
                      placeholderText={t('messages.from')}
                      timeInputLabel="Time:"
                      dateFormat="dd/MM/yyyy HH:mm"
                      showTimeInput
                      style={{ width: "100%" }}
                    />

                  </div>

                </Grid>

                <Grid item xs={4}>
                  <TextField
                    key={`invoice.totalInvoiceAmount.amount`}
                    label={t(`keys.extendedWarrantyClaim.totalInvoiceAmount`)}
                    name="invoice.totalInvoiceAmount.amount"
                    variant="filled"
                    autoComplete='off'
                    fullWidth
                    disabled
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class',
                        focused: 'custom-label-focused-class',
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={getIn(formik.values, 'invoice.totalInvoiceAmount.amount')}
                  // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                  // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                  />

                </Grid>

                <Grid item xs={4}></Grid>

                <Grid item xs={4}>
                  <TextField
                    key={`invoice.ewAmount.amount`}
                    label={t(`keys.extendedWarrantyClaim.ewAmount`)}
                    name={`invoice.ewAmount.amount`}
                    variant="filled"
                    autoComplete='off'
                    fullWidth
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class', // Example custom class
                        focused: 'custom-label-focused-class', // Example custom class for focused state
                      },
                      // Any other props you want to pass to InputLabel
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={getIn(formik.values, 'invoice.ewAmount.amount')}
                  // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                  // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                  />

                </Grid>

                <Grid item xs={4}>
                  <TextField
                    key={`invoice.inspectionFeeAmount.amount`}
                    label={t(`keys.extendedWarrantyClaim.inspectionFeeAmount`)}
                    name={`invoice.inspectionFeeAmount.amount`}
                    variant="filled"
                    autoComplete='off'
                    fullWidth
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class', // Example custom class
                        focused: 'custom-label-focused-class', // Example custom class for focused state
                      },
                      // Any other props you want to pass to InputLabel
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={getIn(formik.values, 'invoice.inspectionFeeAmount.amount')}
                  // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                  // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                  />

                </Grid>

                <Grid item xs={4}>
                  <TextField
                    key={`invoice.otherAmount.amount`}
                    label={t(`keys.extendedWarrantyClaim.otherAmount`)}
                    name={`invoice.otherAmount.amount`}
                    variant="filled"
                    autoComplete='off'
                    fullWidth
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class', // Example custom class
                        focused: 'custom-label-focused-class', // Example custom class for focused state
                      },
                      // Any other props you want to pass to InputLabel
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-top-20 margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={getIn(formik.values, 'invoice.otherAmount.amount')}
                  // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                  // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    key={`invoice.adldAmount.amount`}
                    label={t(`keys.extendedWarrantyClaim.adldAmount`)}
                    name={`invoice.adldAmount.amount`}
                    variant="filled"
                    autoComplete='off'
                    fullWidth
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class', // Example custom class
                        focused: 'custom-label-focused-class', // Example custom class for focused state
                      },
                      // Any other props you want to pass to InputLabel
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={getIn(formik.values, 'invoice.adldAmount.amount')}
                  // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                  // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                  />

                </Grid>

                <Grid item xs={4}>
                  <TextField
                    key={`invoice.replacementAmount.amount`}
                    label={t(`keys.extendedWarrantyClaim.replacementAmount`)}
                    name={`invoice.replacementAmount.amount`}
                    variant="filled"
                    autoComplete='off'
                    fullWidth
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class', // Example custom class
                        focused: 'custom-label-focused-class', // Example custom class for focused state
                      },
                      // Any other props you want to pass to InputLabel
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={getIn(formik.values, 'invoice.replacementAmount.amount')}
                  />

                </Grid>

                <Grid item xs={4}></Grid>

                <Grid item xs={12}>
                  <hr></hr>

                </Grid>
                <Grid item xs={4}>
                  <TextField
                    key={`invoice.totalApprovedAmount.amount`}
                    label={t(`keys.extendedWarrantyClaim.totalApprovedInvoiceAmount`)}
                    name={`invoice.totalApprovedAmount.amount`}
                    variant="filled"
                    autoComplete='off'
                    fullWidth
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class', // Example custom class
                        focused: 'custom-label-focused-class', // Example custom class for focused state
                      },
                      // Any other props you want to pass to InputLabel
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={getIn(formik.values, 'invoice.totalApprovedAmount.amount')}
                  // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                  // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                  />
                </Grid>

                <Grid item xs={4}>
                  <TextField
                    key={`invoice.totalRepairInvoiceAmount.amount`}
                    label={t(`keys.extendedWarrantyClaim.totalRepairInvoiceAmount`)}
                    name={`invoice.totalRepairInvoiceAmount.amount`}
                    variant="filled"
                    autoComplete='off'
                    fullWidth

                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class', // Example custom class
                        focused: 'custom-label-focused-class', // Example custom class for focused state
                      },
                      // Any other props you want to pass to InputLabel
                    }}
                    InputProps={{
                      readOnly: true,
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={getIn(formik.values, 'invoice.totalRepairInvoiceAmount.amount')}
                  // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                  // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                  />
                </Grid>

                <Grid item xs={4}>

                </Grid >

                <Grid item xs={4}>

                  <TextField
                    key={`invoice.approvedEwAmount.amount`}
                    label={t(`keys.extendedWarrantyClaim.approvedEwAmount`)}
                    name={`invoice.approvedEwAmount.amount`}
                    variant="filled"
                    autoComplete='off'
                    fullWidth
                    disabled={serviceLimit.details?.extendedWarranty?.frequencyRemaining <= 0 ||
                      serviceLimit.details?.extendedWarranty?.amountRemaining?.amount <= 0}
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class', // Example custom class
                        focused: 'custom-label-focused-class', // Example custom class for focused state
                      },
                      // Any other props you want to pass to InputLabel
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={getIn(formik.values, 'invoice.approvedEwAmount.amount')}
                  // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                  // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                  />

                </Grid>

                <Grid item xs={4}>

                  <TextField
                    key={`invoice.approvedInspectionFeeAmount.amount`}
                    label={t(`keys.extendedWarrantyClaim.approvedInspectionFeeAmount`)}
                    name={`invoice.approvedInspectionFeeAmount.amount`}
                    variant="filled"
                    autoComplete='off'
                    fullWidth
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class', // Example custom class
                        focused: 'custom-label-focused-class', // Example custom class for focused state
                      },
                      // Any other props you want to pass to InputLabel
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={getIn(formik.values, 'invoice.approvedInspectionFeeAmount.amount')}
                  // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                  // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                  />

                </Grid>

                <Grid item xs={4}>

                  <TextField
                    key={`invoice.approvedOtherAmount.amount`}
                    label={t(`keys.extendedWarrantyClaim.approvedOtherAmount`)}
                    name={`invoice.approvedOtherAmount.amount`}
                    variant="filled"
                    autoComplete='off'
                    fullWidth
                    disabled={serviceLimit.details?.otherCoverage?.frequencyRemaining <= 0 ||
                      serviceLimit.details?.otherCoverage?.amountRemaining?.amount <= 0}
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class', // Example custom class
                        focused: 'custom-label-focused-class', // Example custom class for focused state
                      },
                      // Any other props you want to pass to InputLabel
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={getIn(formik.values, 'invoice.approvedOtherAmount.amount')}
                  // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                  // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                  />

                </Grid>

                <Grid item xs={4}>

                  <TextField
                    key={`invoice.approvedAdldAmount.amount`}
                    label={t(`keys.extendedWarrantyClaim.approvedAdldAmount`)}
                    name={`invoice.approvedAdldAmount.amount`}
                    variant="filled"
                    autoComplete='off'
                    fullWidth
                    disabled={serviceLimit.details?.accidentalDamageAndLiquidDamage?.frequencyRemaining <= 0 ||
                      serviceLimit.details?.accidentalDamageAndLiquidDamage?.amountRemaining?.amount <= 0}
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class', // Example custom class
                        focused: 'custom-label-focused-class', // Example custom class for focused state
                      },
                      // Any other props you want to pass to InputLabel
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={getIn(formik.values, 'invoice.approvedAdldAmount.amount')}
                  // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                  // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                  />

                </Grid>

                <Grid item xs={4}>

                  <TextField
                    key={`invoice.approvedReplacementAmount.amount`}
                    label={t(`keys.extendedWarrantyClaim.approvedReplacementAmount`)}
                    name={`invoice.approvedReplacementAmount.amount`}
                    variant="filled"
                    autoComplete='off'
                    fullWidth
                    disabled={serviceLimit.details?.replacement?.frequencyRemaining <= 0 ||
                      serviceLimit.details?.replacement?.amountRemaining?.amount <= 0}
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class', // Example custom class
                        focused: 'custom-label-focused-class', // Example custom class for focused state
                      },
                      // Any other props you want to pass to InputLabel
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={getIn(formik.values, 'invoice.approvedReplacementAmount.amount')}
                  // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                  // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                  />

                </Grid>

                <Grid item xs={4}>

                  <TextField
                    key={`invoice.customerPayableAmount.amount`}
                    label={t(`keys.extendedWarrantyClaim.customerPayableAmount`)}
                    name={`invoice.customerPayableAmount.amount`}
                    variant="filled"
                    autoComplete='off'
                    fullWidth
                    InputLabelProps={{
                      shrink: true, // Example prop
                      classes: {
                        root: 'custom-label-root-class', // Example custom class
                        focused: 'custom-label-focused-class', // Example custom class for focused state
                      },
                    }}
                    InputProps={{
                      disableUnderline: false,
                      shrink: true
                    }}
                    className={`${classes.inputField} margin-right-10 width-40 Mui-focused`}
                    onChange={formik.handleChange}
                    value={getIn(formik.values, 'invoice.customerPayableAmount.amount')}
                  // error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                  // helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                  />

                </Grid>
              </Grid>
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default RepairInvoiceView;
