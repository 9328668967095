import {
    PICK_UP_GET_DETAILS,
    PICK_UP_ITEM_GET_LIST,
    PICK_UP_ITEM_CLEAR_FILTER,
    PICK_UP_ITEM_FILTER, PICK_UP_ITEM_SORT, PICK_UP_ITEM_UPDATE
} from "../actions/types";

const INITIAL_STATE = {
    pickUpItems: [],
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    filters: {
        imei: '',
        businessEntityCode: '',
        type: [],
        isBooked: [],
        deviceCondition: [],
        createdAt: {
            from: null,
            to: null
        }
    }
}

const pickUpItemReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PICK_UP_ITEM_GET_LIST:
            return {
                ...state,
                pickUpItems: action.payload.data
            }
        case PICK_UP_GET_DETAILS:
            return {
                ...state,
                pickUpItems: action.payload.data.pickUpItems
            }
        case PICK_UP_ITEM_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case PICK_UP_ITEM_CLEAR_FILTER:
            return {
                ...state,
                filters: {
                    imei: '',
                    businessEntityCode: '',
                    type: [],
                    isBooked: [],
                    deviceCondition: [],
                    createdAt: {
                        from: null,
                        to: null
                    }
                }
            }
        case PICK_UP_ITEM_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        case PICK_UP_ITEM_UPDATE:
            const pickUpItems = state.pickUpItems;
            state.pickUpItems.forEach(pickUpItem => {
                if (pickUpItem.id === action.payload.id) {
                    pickUpItem[action.payload.fieldName] = action.payload.fieldValue;
                }
            })
            return {
                ...state,
                pickUpItems: pickUpItems
            }
        default:
            return state;
    }
}

export default pickUpItemReducer;