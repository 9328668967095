import React from 'react';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import {Paper, TableCell} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import {fullDate} from "../utils/utils";

const HistoryTable = ({ history, countryCode }) => {
    const { t } = useTranslation();

    return (
        history ?
            <div style={{overflow: 'auto'}}>
                <Paper>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{t('keys.status')}</TableCell>
                                <TableCell>{t('keys.reason')}</TableCell>
                                <TableCell>{t('keys.createdAt')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {history.map((statusHistory) => (
                                <TableRow key={statusHistory.createdAt}>
                                    <TableCell>{t(`values.status.${statusHistory.status}`)}</TableCell>
                                    <TableCell>{statusHistory.reason}</TableCell>
                                    <TableCell>{fullDate(statusHistory.createdAt, countryCode)}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Paper>
            </div>
 : ''
    )
}

export default HistoryTable;