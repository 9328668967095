import {
    DELIVERY_BOX_CLEAR_FILTER,
    DELIVERY_BOX_FILTER, DELIVERY_BOX_SORT, DELIVERY_CREATED_BOX_ORDER,
    DELIVERY_GET,
    DELIVERY_GET_BOX_ORDER,
    DELIVERY_GET_BOX_ORDERS,
    DELIVERY_UNSET
} from "../actions/types";

const INITIAL_STATE = {
    delivery: undefined,
    boxDeliveries: [],
    logisticsBoxDeliveries: [],
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    filters: {
        salesOrderNumber: '',
        createdAt: {
            from: null,
            to: null
        }
    }
}

const logisticsReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case DELIVERY_GET:
            return {
                ...state,
                delivery: action.payload.data
            }
        case DELIVERY_GET_BOX_ORDER:
            return {
                ...state,
                boxDeliveries: [...state.boxDeliveries, action.payload.data]
            }
        case DELIVERY_GET_BOX_ORDERS:
            return {
                ...state,
                logisticsBoxDeliveries: action.payload.data
            }
        case DELIVERY_CREATED_BOX_ORDER:
            return {
                ...state,
                logisticsBoxDeliveries: [action.payload.data, ...state.logisticsBoxDeliveries]
            }
        case DELIVERY_UNSET:
            return {
                ...state,
                delivery: undefined,
                boxDeliveries: []
            }
        case DELIVERY_BOX_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case DELIVERY_BOX_CLEAR_FILTER:
            return {
                ...state,
                filters: INITIAL_STATE.filters
            }
        case DELIVERY_BOX_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        default:
            return state;
    }
}

export default logisticsReducer;