import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    TRADE_IN_TRANSACTION_GET_LIST,
    TRADE_IN_TRANSACTION_FILTER,
    TRADE_IN_TRANSACTION_CLEAR_FILTER,
    TRADE_IN_TRANSACTION_SORT,
    TRADE_IN_TRANSACTION_GET_DETAILS,
    TRADE_IN_TRANSACTION_GET_QUESTIONNAIRE,
    TRADE_IN_TRANSACTION_GET_ELIGIBLE_ARTICLES,
    TRADE_IN_DETAIL_GET_LIST,
    TRADE_IN_DETAIL_LIST_FILTER,
    TRADE_IN_DETAIL_LIST_CLEAR_FILTER,
    TRADE_IN_DETAIL_LIST_SORT,
    SHOW_SUCCESS
} from "./types";
import { handleError, showContentLoader } from "../utils/utils";

export const getTradeInTransactions = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/tradeIn/?${path}`)
        .then(response => {
            dispatch({
                type: TRADE_IN_TRANSACTION_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const getTradeInTransactionQuestionnaire = () => async dispatch => {
    showContentLoader(true, dispatch);
    await axios.get(`${BASE_URL}/v1/tradeInDiagnostic/questionnaire`)
        .then(response => {
            dispatch({
                type: TRADE_IN_TRANSACTION_GET_QUESTIONNAIRE,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const getTradeInTransactionEligibleArticles = (countryCode, carrierCode) => async dispatch => {
    showContentLoader(true, dispatch);
    await axios.get(`${BASE_URL}/v1/tradeIn/eligibleArticles/${countryCode}/${carrierCode}`)
        .then(response => {
            dispatch({
                type: TRADE_IN_TRANSACTION_GET_ELIGIBLE_ARTICLES,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const evaluateTradeInPrice = (id, payload) => async dispatch => {
    showContentLoader(true, dispatch);
    await axios.post(`${BASE_URL}/v1/tradeIn/evaluateTradeInPrice/${id}`, payload)
        .then(response => {
            dispatch({
                type: TRADE_IN_TRANSACTION_GET_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const cancelTradeInTransaction = (id, event) => async dispatch => {
    showContentLoader(true, dispatch);
    await axios.post(`${BASE_URL}/v1/tradeIn/${id}/events/${event}`)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.tradeIn.cancelSuccessful',
                    dialogType: 'success',
                    refresh: true
                }
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const confirmTradeInTransaction = (id, payload) => async dispatch => {
    showContentLoader(true, dispatch);
    await axios.post(`${BASE_URL}/v1/tradeIn/confirmedOrder/${id}`, payload)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.tradeIn.confirmSuccessful',
                    dialogType: 'success',
                    refresh: true
                }
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}


export const getTradeInTransactionDetails = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    await axios.get(`${BASE_URL}/v1/tradeIn/${id}`)
        .then(response => {
            dispatch({
                type: TRADE_IN_TRANSACTION_GET_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const updateFiltersForTradeInTransactions = (filterKey, filterValue) => {
    return ({
        type: TRADE_IN_TRANSACTION_FILTER,
        payload: { filterKey, filterValue }
    })
}

export const clearTradeInTransactionsFilters = () => {
    return ({
        type: TRADE_IN_TRANSACTION_CLEAR_FILTER
    })
}

export const sortTradeInTransactions = (orderBy, sort) => {
    return ({
        type: TRADE_IN_TRANSACTION_SORT,
        payload: { orderBy, sort }
    })
}

export const getTradeInDetailList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/tradeIn/detailList?${path}`)
        .then(response => {
            dispatch({
                type: TRADE_IN_DETAIL_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const updateFiltersForTradeInDetailList = (filterKey, filterValue) => {
    return ({
        type: TRADE_IN_DETAIL_LIST_FILTER,
        payload: { filterKey, filterValue }
    })
}

export const clearTradeInTradeInDetailList = () => {
    return ({
        type: TRADE_IN_DETAIL_LIST_CLEAR_FILTER
    })
}

export const sortTradeInDetailList = (orderBy, sort) => {
    return ({
        type: TRADE_IN_DETAIL_LIST_SORT,
        payload: { orderBy, sort }
    })
}