import axios from "./interceptor";
import { BASE_URL } from "../constant";
import {
    EXTENDED_WARRANTY_CLAIM_GET_LIST,
    EXTENDED_WARRANTY_CLAIM_GET_DETAILS,
    EXTENDED_WARRANTY_CLAIM_GET_SERVICE_LIMIT,
    EXTENDED_WARRANTY_CLAIM_FILTER,
    EXTENDED_WARRANTY_CLAIM_CLEAR_FILTER,
    EXTENDED_WARRANTY_CLAIM_SORT,
    SHOW_SUCCESS
} from "./types";
import { handleError, showContentLoader } from "../utils/utils";

export const getExtendedWarrantyClaimList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/extendedWarrantyClaims/?${path}`)
        .then(response => {
            dispatch({
                type: EXTENDED_WARRANTY_CLAIM_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const getExtendedWarrantyClaimDetails = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    await axios.get(`${BASE_URL}/v1/extendedWarrantyClaims/${id}`)
        .then(response => {
            dispatch({
                type: EXTENDED_WARRANTY_CLAIM_GET_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const getExtendedWarrantyClaimServiceLimit = (subcriptionId, extendedWarrantyId, quoteCurrencyCode) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/extendedWarrantyClaims/getAvailableExtendedWarrantyClaim/${subcriptionId}/${extendedWarrantyId}/${quoteCurrencyCode}`)
        .then(response => {
            dispatch({
                type: EXTENDED_WARRANTY_CLAIM_GET_SERVICE_LIMIT,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const getExtendedWarrantyClaimListByCustomer = (id) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/extendedWarrantyClaims/byCustomer/${id}`)
        .then(response => {
            dispatch({
                type: EXTENDED_WARRANTY_CLAIM_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const updateExtendedWarrantyClaim = (id, data) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.patch(`${BASE_URL}/v1/extendedWarrantyClaims/${id}`, data)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.extendedWarrantyClaim.updateSuccessful',
                    dialogType: 'success',
                    refresh: true
                }
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const createExtendedWarrantyClaim = (extendedWarrantyClaim) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.post(`${BASE_URL}/v1/extendedWarrantyClaims`, extendedWarrantyClaim)
        .then(response => {
            dispatch({
                type: EXTENDED_WARRANTY_CLAIM_GET_DETAILS,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const saveAndInitiateExtendedWarrantyClaim = (id, data, initiatePayload) => async dispatch => {
    showContentLoader(true, dispatch);
    await axios.patch(`${BASE_URL}/v1/extendedWarrantyClaims/${id}`, data).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
    });

    axios.patch(`${BASE_URL}/v1/extendedWarrantyClaims/${id}/initiate`, initiatePayload)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.extendedWarrantyClaim.updateSuccessful',
                    dialogType: 'success',
                    refresh: true
                }
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const saveAndUpdateExtendedWarrantyClaimStatus = (id, data, status, isSaveRequired) => async dispatch => {
    showContentLoader(true, dispatch);

    isSaveRequired && await axios.patch(`${BASE_URL}/v1/extendedWarrantyClaims/${id}`, data).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
    });

    axios.patch(`${BASE_URL}/v1/extendedWarrantyClaims/${id}/events/${status}`)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.extendedWarrantyClaim.updateSuccessful',
                    dialogType: 'success',
                    refresh: true
                }
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const extendedWarrantyClaimCustomerAcceptedQuotation = (id, data, paymentPayload) => async dispatch => {
    showContentLoader(true, dispatch);

    await axios.patch(`${BASE_URL}/v1/extendedWarrantyClaims/${id}`, data).catch(error => {
        showContentLoader(false, dispatch);
        handleError(error, dispatch);
    });

    axios.patch(`${BASE_URL}/v1/extendedWarrantyClaims/${id}/customerAcceptedQuotation`, paymentPayload)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.extendedWarrantyClaim.updateSuccessful',
                    dialogType: 'success',
                    refresh: true
                }
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const extendedWarrantyClaimUploadDocument = (id, documentType, fileData) => async dispatch => {
    showContentLoader(true, dispatch);

    axios.post(`${BASE_URL}/v1/extendedWarrantyClaims/${id}/uploadDocument/${documentType}`, fileData)
        .then(response => {
            dispatch({
                type: SHOW_SUCCESS,
                payload: {
                    message: 'messages.extendedWarrantyClaim.uploadSuccessful',
                    dialogType: 'success',
                    refresh: true
                }
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
        })
}

export const updateFiltersForExtendedWarrantyClaimList = (filterKey, filterValue) => {
    return ({
        type: EXTENDED_WARRANTY_CLAIM_FILTER,
        payload: { filterKey, filterValue }
    })
}

export const clearExtendedWarrantyClaimFilters = () => {
    return ({
        type: EXTENDED_WARRANTY_CLAIM_CLEAR_FILTER
    })
}

export const sortExtendedWarrantyClaims = (orderBy, sort) => {
    return ({
        type: EXTENDED_WARRANTY_CLAIM_SORT,
        payload: { orderBy, sort }
    })
}