import {PLAN_GET_LIST} from "../actions/types";

const INITIAL_STATE = {
    plans: []
}

const planReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PLAN_GET_LIST:
            return {
                ...state,
                plans: action.payload.data
            }
        default:
            return state;
    }
}

export default planReducer;