import React, { Component } from "react";
import "../../styles/custom-bootstrap.scss";
import { useTranslation } from "react-i18next";
import CustomerDetails from "../../pages/CustomerDetails";
import ContactDetails from "./ContactDetails";
import SubscriptionInfo from "./SubscriptionInfo";

const attributesColorCircleStyle = (color) => ({
  backgroundColor: color,
  width: `24px`,
  height: `24px`,
  borderRadius: `50%`,
});

const SubscriptionDetailsPage = ({
  subscriptions,
  countryCode,
  languageCode,
  isBlacklisted,
  onRequestExtendedWarrantyClaim
}) => {
  const { t } = useTranslation();

  // if (!article) return null;
  return (
    <div className="custom-bootstrap">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <i class="fa fa-history" aria-hidden="true"></i>
              <strong> {'Subscription Details'}</strong>
            </div>

            <div className="card-block">
              <div className="card-body">
                {subscriptions.map((subscription) => {
                  return (<SubscriptionInfo subscription={subscription} userCountryCode={countryCode} userLanguageCode={languageCode} isFromCustomerPage={true} isBlacklisted={isBlacklisted} onRequestExtendedWarrantyClaim={onRequestExtendedWarrantyClaim}></SubscriptionInfo>)
                })
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubscriptionDetailsPage;
