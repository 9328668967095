import React, {Component} from "react";
import { connect } from 'react-redux';
import DataTable from "../components/DataTable";
import PageTemplate from "../components/PageTemplate";
import {
    batchCreateBusinessEntity, clearBusinessEntityFilters,
    getBusinessEntityList, sortBusinessEntities,
    updateFilterForBusinessEntityList
} from "../actions/businessEntityActions";
import FileUpload from "../components/FileUpload";
import Box from "@material-ui/core/Box";
import {
    parseObjectToString,
    parseSortingToString,
    replaceEmptyWithNull,
    validateAcceptedFields,
    validateRequiredFields
} from "../utils/utils";
import {addressFields, fileUploadAcceptedFields, fileUploadRequiredFields} from "../utils/fields";
import {showError} from "../actions/commonActions";

class BusinessEntityList extends Component {
    componentDidMount() {
        this.props.getBusinessEntityList('orderBy=createdAt&sort=desc');
        this.props.clearBusinessEntityFilters();
        this.props.sortBusinessEntities('createdAt', 'desc');
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.common.refresh !== prevProps.common.refresh && this.props.common.refresh
        ) {
            this.props.getBusinessEntityList('orderBy=createdAt&sort=desc');
        }
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let filterState = this.props.businessEntities.filters[filterKey];
            filterState.includes(filterValue) ? filterState = filterState.filter(item => item !== filterValue) : filterState.push(filterValue);
            filterValue = filterState;
        }
        this.props.updateFilterForBusinessEntityList(filterKey, filterValue)
    }

    handleFilterReset() {
        this.props.clearBusinessEntityFilters();
        this.props.getBusinessEntityList(parseSortingToString(this.props.businessEntities.sorting));
    }

    handleSorting(orderBy, sort) {
        this.props.sortBusinessEntities(orderBy, sort)
        this.props.getBusinessEntityList(parseObjectToString(this.props.businessEntities.filters, {orderBy, sort}))
    }

    renderBusinessEntities(businessEntities, showContentLoader) {
        const fields = [
            {
                name: 'code'
            },
            {
                name: 'name'
            },
            {
                name: 'address.city'
            },
            {
                name: 'address.provinceCode'
            },
            {
                name: 'address.zipCode'
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }];

        const filterKeys = [
            {
                name: 'code'
            },
            {
                name: 'name'
            },
            {
                name: 'address.city'
            },
            {
                name: 'address.provinceCode'
            },
            {
                name: 'address.zipCode'
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            }
        ]
        return(
            <DataTable fields={fields}
                       items={businessEntities}
                       entity="businessEntities"
                       filterKeys={filterKeys}
                       filterValues={this.props.businessEntities.filters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getBusinessEntityList(parseObjectToString(this.props.businessEntities.filters, this.props.businessEntities.sorting))}
                       sorting={this.props.businessEntities.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
            />
        )
    }

    onUpload = (data) => {
        let validatedRecords = {
            valid: true,
            lineNumberWithErrors: [],
            recordLimitExceeded: false
        };
        data.forEach((datum, index) => {
            datum.address = {...datum.address, countryCode: this.props.authentication.loggedInUser.countryCode};
            datum.pickUpDays = ['wednesday'];
            const acceptedFields = fileUploadAcceptedFields['businessEntity'].concat(addressFields[this.props.authentication.loggedInUser.countryCode].map(addressField => `address.${addressField}`));
            const requiredFields = fileUploadRequiredFields['businessEntity'].concat(addressFields[this.props.authentication.loggedInUser.countryCode].map(addressField => `address.${addressField}`));
            validatedRecords.valid = validateAcceptedFields(acceptedFields, Object.keys(datum)) &&
                validateRequiredFields(requiredFields, datum)
            if (!validatedRecords.valid) {
                validatedRecords.lineNumberWithErrors.push(index + 2)
            }
            replaceEmptyWithNull(datum);
        });

        if(data.length > 5000){
            validatedRecords.recordLimitExceeded = true;
        }

        if (validatedRecords.lineNumberWithErrors.length === 0 && validatedRecords.recordLimitExceeded == false) {
            this.props.batchCreateBusinessEntity(data);
        } else {
            if(validatedRecords.recordLimitExceeded == true){
                this.props.showError({response: {data: {translationKey: `Exceeded 5000 limit of records with number of ${data.length} uploaded records. Please fix and try again.`}}});
            } else {
                this.props.showError({response: {data: {translationKey: `Error in line ${validatedRecords.lineNumberWithErrors}. Please fix and try again.`}}});
            }
        }
    }

    render() {
        const defaultFields = {
            countryCode: this.props.authentication.loggedInUser.countryCode
        }

        return(
            <PageTemplate title="messages.businessEntitiesList" createPath="businessEntitiesNew">
                <Box mb={2} mt={2}>
                    <FileUpload defaultFields={defaultFields}
                                templateFile={`template-businessEntity-${this.props.authentication.loggedInUser.countryCode}.csv`}
                                onUpload={(data) => this.onUpload(data)}/>
                </Box>
                {this.renderBusinessEntities(this.props.businessEntities.businessEntities, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    authentication: state.authentication,
    businessEntities: state.businessEntities,
    common: state.common
})

export default connect(mapStateToProps, {
    getBusinessEntityList,
    batchCreateBusinessEntity,
    updateFilterForBusinessEntityList,
    clearBusinessEntityFilters,
    sortBusinessEntities,
    showError
})(BusinessEntityList);