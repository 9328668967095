import React, {Component} from "react";
import { connect } from 'react-redux';
import * as yup from 'yup';
import PageTemplate from "../components/PageTemplate";
import {getBusinessEntityDetails, updateBusinessEntity} from "../actions/businessEntityActions";
import SectionTemplate from "../components/SectionTemplate";
import DataTableLoader from "../components/DataTableLoader";
import BusinessEntityForm from "../components/BusinessEntityForm";
import {getMerchantUserList} from "../actions/merchantUserActions";
import TableCell from "@material-ui/core/TableCell";
import Table from "@material-ui/core/Table";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import {withTranslation} from "react-i18next";
import {addressFields} from "../utils/fields";

class BusinessEntityDetails extends Component {
    componentDidMount() {
        this.props.getBusinessEntityDetails(this.props.match.params.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.businessEntities.businessEntity !== prevProps.businessEntities.businessEntity) {
            this.props.getMerchantUserList(`orderBy=createdAt&sort=desc&filterFields[]=businessEntityCode&filterValues[]=${this.props.businessEntities.businessEntity.code}`);
        }
    }

    updateBusinessEntity = (values) => {
        this.props.updateBusinessEntity(values, this.props.match.params.id)
    }

    renderDetails = (businessEntity, countryCode) => {

        const displayFields = [
            'code',
            'updatedAt',
            'createdAt'
        ];

        const formFields = [
            'name',
            'channel',
            'dealerCode',
            'aggregatedPOSCode',
            'email',
            'personInChargeName',
            'personInChargePhoneNumber',
            'description',
            'businessHours',
            'address',
            'pickUpDays'
        ];

        const editableFields = formFields.map(formField => formField)
            .reduce((obj, key) => ({ ...obj, [key]: businessEntity[key] }), {});

        const readOnlyFields = displayFields.map(displayField => displayField)
            .reduce((obj, key) => ({ ...obj, [key]: businessEntity[key] }), {});

        const validationRules = yup.object({
            name: yup.string().required('messages.fieldRequired'),
            channel: yup.string().required('messages.fieldRequired'),
            address: yup.object({
                ...addressFields[countryCode].reduce((previousValue, currentValue) => ({...previousValue, [currentValue]: yup.string().required('messages.fieldRequired')}), {}),
            })
        });

        return (
            Object.keys(businessEntity).length === 0 ? 'Loading...' :
                <BusinessEntityForm editableFields={editableFields}
                                    readOnlyFields={readOnlyFields}
                                    validationRules={validationRules}
                                    loggedInUser={this.props.authentication.loggedInUser}
                                    onFormSubmit={values => this.updateBusinessEntity(values)}
                                    updateBusinessEntity={true}
                />
        );
    }

    renderMerchantUsers = (merchantUsers, t) => {
        return(
            <Table size="small">
                <TableHead className="background-transparent">
                    <TableRow>
                        <TableCell component="th">{t('keys.displayName')}</TableCell>
                        <TableCell component="th">{t('keys.email')}</TableCell>
                        <TableCell component="th">{t('keys.phoneNumber')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        merchantUsers.map(merchantUser => {
                            return (
                                <TableRow key={merchantUser.id}>
                                    <TableCell><a href={`/users/${merchantUser.id}`}>{merchantUser.displayName}</a></TableCell>
                                    <TableCell><a href={`/users/${merchantUser.id}`}>{merchantUser.email}</a></TableCell>
                                    <TableCell><a href={`/users/${merchantUser.id}`}>{merchantUser.phoneNumber}</a></TableCell>
                                </TableRow>
                            )
                        })
                    }
                </TableBody>
            </Table>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <PageTemplate title="messages.businessEntityDetails">
                <SectionTemplate title="businessEntityHeader">
                    {this.props.common.showContentLoader ? <DataTableLoader /> : this.renderDetails(this.props.businessEntities.businessEntity, this.props.authentication.loggedInUser.countryCode)}
                </SectionTemplate>
                <SectionTemplate title="userList">
                    {this.props.common.showContentLoader ? <DataTableLoader /> : this.renderMerchantUsers(this.props.merchantUsers.merchantUsers, t)}
                </SectionTemplate>
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    businessEntities: state.businessEntities,
    merchantUsers: state.merchantUsers,
    common: state.common
})

export default connect(mapStateToProps, {
    getBusinessEntityDetails,
    updateBusinessEntity,
    getMerchantUserList
})(withTranslation() (BusinessEntityDetails));