import {
    BUSINESS_ENTITY_CHECK_EXISTENCE, BUSINESS_ENTITY_CHECK_EXISTENCE_RESULT,
    BUSINESS_ENTITY_CLEAR_FILTER,
    BUSINESS_ENTITY_FILTER,
    BUSINESS_ENTITY_GET_DETAILS,
    BUSINESS_ENTITY_GET_LIST, BUSINESS_ENTITY_RESET_EXISTENCE, BUSINESS_ENTITY_SORT
} from "../actions/types";

const INITIAL_STATE = {
    businessEntities: [],
    businessEntity: {},
    createdBusinessEntity: {},
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    businessEntityExists: null,
    businessEntityExistenceChecking: false,
    filters: {
        code: '',
        name: '',
        'address.city': '',
        'address.provinceCode': '',
        'address.zipCode': '',
        createdAt: {
            from: null,
            to: null
        }
    }
}

const businessEntityReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case BUSINESS_ENTITY_GET_LIST:
            return {
                ...state,
                businessEntities: action.payload.data
            }
        case BUSINESS_ENTITY_GET_DETAILS:
            return {
                ...state,
                businessEntity: action.payload.data,
            }
        case BUSINESS_ENTITY_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case BUSINESS_ENTITY_CLEAR_FILTER:
            return {
                ...state,
                filters: INITIAL_STATE.filters
            }
        case BUSINESS_ENTITY_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        case BUSINESS_ENTITY_CHECK_EXISTENCE:
            return {
                ...state,
                businessEntityExistenceChecking: true
            }
        case BUSINESS_ENTITY_CHECK_EXISTENCE_RESULT:
            return {
                ...state,
                businessEntityExistenceChecking: false,
                businessEntityExists: action.payload.data.length > 0
            }
        case BUSINESS_ENTITY_RESET_EXISTENCE:
            return {
                ...state,
                businessEntityExistenceChecking: false,
                businessEntityExists: null
            }
        default:
            return state;
    }
}

export default businessEntityReducer;