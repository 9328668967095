import React from 'react';
import {Accordion, AccordionDetails, AccordionSummary, Collapse, Paper, TableCell} from "@material-ui/core";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {useTranslation} from "react-i18next";
import TableBody from "@material-ui/core/TableBody";
import {fullDate} from "../utils/utils";
import Box from "@material-ui/core/Box";
import * as yup from "yup";
import {useFormik} from "formik";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Typography from "@material-ui/core/Typography";
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

const validationSchema = (t) => {
    return yup.object({
        content: yup
            .string()
            .required(t('messages.fieldRequired', {field: t(`keys.content`)}))
    });
}

const Remarks = ({ remarks, entityId, extendable, entityName, onFormSubmit, toDisabled }) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = React.useState(false);
    const [rowExpanded, setRowExpanded] = React.useState(false);

    const handleChange = () => (event, isExpanded) => {
        setExpanded(!!isExpanded);
    };

    const toggleRows = (expanded) => {
        setRowExpanded(expanded);
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            content: '',
            entityId: entityId,
            entityName: entityName
        },
        validationSchema: validationSchema(t),
        onSubmit: (values) => {
            formik.resetForm();
            onFormSubmit(values);
        },
    });

    return (
        <Paper elevation={3}>
            <Accordion expanded={expanded} onChange={handleChange()}>
                <AccordionSummary
                    expandIcon={expanded ? <RemoveCircleIcon color="secondary" /> : <AddCircleIcon color="secondary" />}
                >
                    <Typography>{t('messages.addRemark')}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <form onSubmit={formik.handleSubmit}>
                        <TextField
                            fullWidth
                            id={`content-${entityId}`}
                            name="content"
                            label={t('keys.content')}
                            variant="outlined"
                            value={formik.values.content}
                            onChange={formik.handleChange}
                            error={formik.touched.content && Boolean(formik.errors.content)}
                            helperText={formik.touched.content && formik.errors.content}
                            className="margin-bottom-20"
                            autoComplete='off'
                            inputProps={{ maxLength: 1000 }}
                            disabled={!toDisabled}
                        />
                        <Box mt={2}>
                            <Button disabled={formik.isSubmitting ||  !toDisabled} color="primary" variant="contained" type="submit" className="margin-top-10 float-right">
                                {t('messages.submit')}
                            </Button>
                        </Box>
                    </form>
                </AccordionDetails>
            </Accordion>
            {
                remarks.length > 0 &&
                <Table size="small">
                    <TableHead className="background-transparent">
                        <TableRow>
                            <TableCell component="th">{t('keys.content')}</TableCell>
                            <TableCell component="th">{t('keys.createdBy')}</TableCell>
                            <TableCell component="th">{t('keys.createdAt')}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {extendable ? (
                            <React.Fragment>
                                <TableRow key={remarks[0].id}>
                                    <TableCell className="word-break-all">{remarks[0].content}</TableCell>
                                    <TableCell>{remarks[0].creatorDisplayName}</TableCell>
                                    <TableCell>{fullDate(remarks[0].createdAt, remarks[0].countryCode)}</TableCell>
                                </TableRow>
                                {
                                    remarks.length > 1 &&
                                    <React.Fragment>
                                        <TableRow>
                                            <TableCell style={{ padding: 0 }} colSpan={3}>
                                                <Collapse in={rowExpanded} timeout="auto" unmountOnExit>
                                                    <Table size="small">
                                                        <TableBody>
                                                            {
                                                                remarks.slice(1).map(remark => (
                                                                    <TableRow key={remark.id}>
                                                                        <TableCell className="word-break-all">{remark.content}</TableCell>
                                                                        <TableCell>{remark.creatorDisplayName}</TableCell>
                                                                        <TableCell>{fullDate(remark.createdAt, remark.countryCode)}</TableCell>
                                                                    </TableRow>))
                                                            }
                                                        </TableBody>
                                                    </Table>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                        <TableRow style={{border: '2px solid #00BAC7'}}>
                                            <TableCell style={{ padding: 0, background: '#00BAC7', textAlign: 'center' }} colSpan={6}>
                                                <Button style={{ margin: 0, padding: 0, width: '100%', height: '100%' }} onClick={() => toggleRows(!rowExpanded)}>
                                                    {rowExpanded ? <KeyboardArrowUpIcon style={{fill: '#ffffff'}} /> : <KeyboardArrowDownIcon style={{fill: '#ffffff'}} />}
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    </React.Fragment>
                                }
                            </React.Fragment>
                            ) : remarks.map(remark => (
                            <TableRow key={remark.id}>
                                <TableCell className="word-break-all">{remark.content}</TableCell>
                                <TableCell>{remark.creatorDisplayName}</TableCell>
                                <TableCell>{fullDate(remark.createdAt, remark.countryCode)}</TableCell>
                            </TableRow>))}
                    </TableBody>
                </Table>
            }
        </Paper>
    )
}

export default Remarks;