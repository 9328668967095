import axios from "./interceptor";
import { BASE_URL } from "../constant";
import { handleError, showContentLoader } from "../utils/utils";
import {PLAN_GET_LIST} from "./types";

export const getPlanList = (path) => async dispatch => {
    showContentLoader(true, dispatch);
    axios.get(`${BASE_URL}/v1/plans/?${path}`)
        .then(response => {
            dispatch({
                type: PLAN_GET_LIST,
                payload: response
            })
            showContentLoader(false, dispatch);
        }).catch(error => {
            showContentLoader(false, dispatch);
            handleError(error, dispatch);
    })
}