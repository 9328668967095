import {EXPORT_FILE_CLEAR, EXPORT_FILE_GET} from "../actions/types";

const INITIAL_STATE = {
    exportFile: {
        data: undefined,
        name: undefined,
        type: undefined
    }
}

const exportReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case EXPORT_FILE_GET:
            return {
                ...state,
                exportFile: action.payload.data
            }
        case EXPORT_FILE_CLEAR:
            return {
                ...state,
                exportFile: {
                    data: undefined,
                    name: undefined,
                    type: undefined
                }
            }
        default:
            return state;
    }
}

export default exportReducer;