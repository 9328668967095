import React, {Component} from "react";
import { connect } from 'react-redux';
import DataTable from "../components/DataTable";
import PageTemplate from "../components/PageTemplate";
import {
    clearDownloadRequestFilters,
    getDownloadRequestList, sortDownloadRequests,
    updateFilterForDownloadRequestList
} from "../actions/downloadRequestsActions";
import {
    parseObjectToString,
    parseSortingToString,
} from "../utils/utils";
import {showError} from "../actions/commonActions";

class DownloadRequestList extends Component {
    componentDidMount() {
        this.props.getDownloadRequestList('orderBy=createdAt&sort=desc');
        this.props.clearDownloadRequestFilters();
        this.props.sortDownloadRequests('createdAt', 'desc');
    }

    componentDidUpdate(prevProps) {
        if (
            this.props.common.refresh !== prevProps.common.refresh && this.props.common.refresh
        ) {
            this.props.getDownloadRequestList('orderBy=createdAt&sort=desc');
        }
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let filterState = this.props.downloadRequests.filters[filterKey];
            filterState.includes(filterValue) ? filterState = filterState.filter(item => item !== filterValue) : filterState.push(filterValue);
            filterValue = filterState;
        }
        this.props.updateFilterForDownloadRequestList(filterKey, filterValue)
    }

    handleFilterReset() {
        this.props.clearDownloadRequestFilters();
        this.props.getDownloadRequestList(parseSortingToString(this.props.downloadRequests.sorting));
    }

    handleSorting(orderBy, sort) {
        this.props.sortDownloadRequests(orderBy, sort)
        this.props.getDownloadRequestList(parseObjectToString(this.props.downloadRequests.filters, {orderBy, sort}))
    }

    renderDownloadRequests(downloadRequests, showContentLoader) {
        const fields = [
            {
                name: 'name',
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
            {
                name: 'downloadUrl',
                type: 'downloadLink',
                tag: 'downloadLink'
            }
            ];

        const filterKeys = [
            {
                name: 'name',
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            }
        ]
        return(
            <DataTable fields={fields}
                       items={downloadRequests}
                       entity="downloadRequests"
                       redirectId={true}
                       filterKeys={filterKeys}
                       filterValues={this.props.downloadRequests.filters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getDownloadRequestList(parseObjectToString(this.props.downloadRequests.filters, this.props.downloadRequests.sorting))}
                       sorting={this.props.downloadRequests.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
            />
        )
    }

    render() {
        return(
            <PageTemplate title="messages.downloadRequestsList">
                {this.renderDownloadRequests(this.props.downloadRequests.downloadRequests, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    authentication: state.authentication,
    downloadRequests: state.downloadRequests,
    common: state.common
})

export default connect(mapStateToProps, {
    getDownloadRequestList,
    updateFilterForDownloadRequestList,
    clearDownloadRequestFilters,
    sortDownloadRequests,
    showError
})(DownloadRequestList);