import React, { useState } from "react";
import "../../styles/custom-bootstrap.scss";
import { useTranslation } from "react-i18next";
import DocumentUploadAndPreview from './DocumentUploadAndPreview'

const AttachedFilesView = (props) => {
  const { extendedWarrantyClaim, encodeFileAsURL, userCountryCode } =
    props;

  const { t } = useTranslation();

  const documentTypes = [
    {
      type: "salesMemo",
      list: extendedWarrantyClaim.salesMemoDocuments
    },
    {
      type: "repairInvoice",
      list: extendedWarrantyClaim.repairInvoiceDocuments
    },
    {
      type: "quotation",
      list: extendedWarrantyClaim.quotationDocuments
    },
    {
      type: "supplementDocument",
      list: extendedWarrantyClaim.supplementDocuments
    }
  ]

  return (
    <div className="card">
      <div className="card-header">
        <i className="fa fa-file-o" aria-hidden="true"></i>
        <strong>Attached Files</strong>
      </div>
      <div className="card-body">
        {
          documentTypes.map((documentType) => {
            return (
              <DocumentUploadAndPreview
                encodeFileAsURL={encodeFileAsURL}
                documentType={documentType.type}
                acceptedFileTypes={['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']}
                documents={documentType.list ? documentType.list : []}
                userCountryCode={userCountryCode}
              />
            )
          })
        }
      </div>
    </div>
  );
};

export default AttachedFilesView;
