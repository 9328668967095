import React from 'react';
import * as yup from 'yup';
import { useFormik } from 'formik';
import {Trans, useTranslation} from "react-i18next";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import Paper from "@material-ui/core/Paper";
import TextField from "@material-ui/core/TextField";
import { useHistory, Link } from "react-router-dom";
import { CircularProgress } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import logo from "../assets/images/logo_bolttech.png";

const customStyles = makeStyles({
    success: {
        color: '#4caf50'
    },
    inputFields: {
        marginBottom: 20
    }
})

const validationSchema = (t) => {
    return yup.object({
        otpCode: yup
            .string()
            .required(t('messages.fieldRequired', {field: t(`keys.otpCode`)})),
        password: yup
            .string()
            .required(t('messages.fieldRequired', {field: t(`keys.password`)})),
        newPasswordConfirmation: yup
            .string()
            .required(t('messages.fieldRequired', {field: t(`keys.newPasswordConfirmation`)}))
            .when("password", {
                is: val => (val && val.length > 0),
                then: yup.string().oneOf(
                    [yup.ref("password")],
                    t('messages.passwordsMustMatch')
                )
            })
    });
}

const PasswordReset = ({ onGetOtp, onFormSubmit, errorMessage, resetPasswordToken, otpId, requestInProgress, requestSuccess }) => {
    const { t } = useTranslation();
    const classes = customStyles();
    const [disabled, setDisabled] = React.useState(false);
    const [otpMessage, setOtpMessage] = React.useState('getOtp');
    const history = useHistory();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: '',
            otpCode: '',
            token: resetPasswordToken,
            otpId: otpId,
            newPasswordConfirmation: ''
        },
        validationSchema: validationSchema(t),
        onSubmit: (values) => {
            onSubmit(values);
        },
    });

    const getOtpButtonClick = () => {
        setDisabled(true);
        onGetOtp({token: resetPasswordToken})
        setTimeout(() => {
            setDisabled(false);
            setOtpMessage('getOtpAgain');
            }, 3000);

    }

    const onSubmit = (values) => {
        onFormSubmit(values);
    }

    if (requestSuccess) {
        setTimeout(() => { history.push('/login');}, 3000)
    }

    return (
        <div className="authentication-cover">
            <Paper className="authentication-form">
                <img src={logo} alt="bolttech" style={{width: '50%'}} />
                <p>{t('messages.otpHeadline')}</p>
                <Button color="primary" variant="contained" disabled={disabled} fullWidth className={classes.inputFields} onClick={() => getOtpButtonClick()}>
                    {t(`messages.${otpMessage}`)}
                </Button>
                <form onSubmit={formik.handleSubmit}>
                    {otpId && <p>{t('messages.otpInstructions')}</p>}
                    <TextField
                        fullWidth
                        id="otpCode"
                        name="otpCode"
                        label={t('keys.otpCode')}
                        variant="outlined"
                        value={formik.values.otpCode}
                        onChange={formik.handleChange}
                        error={formik.touched.otpCode && Boolean(formik.errors.otpCode)}
                        helperText={formik.touched.otpCode && formik.errors.otpCode}
                        className={classes.inputFields}
                        disabled={!otpId}
                    />
                    <TextField
                        fullWidth
                        id="newPassword"
                        name="password"
                        label={t('keys.password')}
                        type="password"
                        autoComplete="off"
                        variant="outlined"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={formik.touched.password && Boolean(formik.errors.password)}
                        helperText={formik.touched.password && formik.errors.password}
                        className={classes.inputFields}
                        disabled={!otpId}
                    />
                    <TextField
                        fullWidth
                        id="newPasswordConfirmation"
                        name="newPasswordConfirmation"
                        label={t('keys.newPasswordConfirmation')}
                        type="password"
                        autoComplete="off"
                        variant="outlined"
                        value={formik.values.newPasswordConfirmation}
                        onChange={formik.handleChange}
                        error={formik.touched.newPasswordConfirmation && Boolean(formik.errors.newPasswordConfirmation)}
                        helperText={formik.touched.newPasswordConfirmation && formik.errors.newPasswordConfirmation}
                        className={classes.inputFields}
                        disabled={!otpId}
                    />
                    {errorMessage !== undefined &&
                    <Paper className="authentication-error-message" elevation={3}>{t(errorMessage)}</Paper>
                    }
                    <Button color="primary"
                            variant="contained"
                            fullWidth
                            type="submit"
                            disabled={(!otpId || requestInProgress || requestSuccess)}
                    >
                        {requestInProgress && <CircularProgress />}
                        {requestSuccess && <DoneIcon className={classes.success}/>}
                        {!requestInProgress && !requestSuccess && t('messages.submit')}
                    </Button>
                    {
                        requestSuccess && <div>
                            <p>{t('messages.otpSuccess')}</p>
                            <Trans i18nKey="messages.otpRedirect">
                                If you are not redirected, please click <Link className="font-weight-bold" to="/login">here</Link>
                            </Trans>
                        </div>
                    }
                </form>
                <div className="display-flex justify-right margin-top-10">
                    <a href="/login" >{t('messages.backToLogin')}</a>
                </div>
            </Paper>
        </div>
    )
}

export default PasswordReset;