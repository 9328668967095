import {
    CUSTOMER_FILTER,
    CUSTOMER_GET_LIST,CUSTOMER_GET_DETAILS,
    CUSTOMER_CLEAR_FILTER, CUSTOMER_SORT
} from "../actions/types";

const INITIAL_STATE = {
    customers: [],
    customer: {},
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    customerFilters: {
        _id: '',
        number: '',
        "contact.lastName": '',
        "contact.firstName": '',
        "contact.ids.number": '',
        "contact.phoneNumber": '',
        "contact.email": '',
        "company.name": '',
        blacklisted: [],
        createdAt: {
            from: null,
            to: null
        }
    },
}

const customerReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case CUSTOMER_GET_LIST:
            return {
                ...state,
                customers: action.payload.data
            }
        case CUSTOMER_FILTER:
            return {
                ...state,
                customerFilters: {
                    ...state.customerFilters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case CUSTOMER_CLEAR_FILTER:
            return {
                ...state,
                customerFilters: {
                    _id: '',
                    number: '',
                    "contact.lastName": '',
                    "contact.firstName": '',
                    "contact.ids.number": '',
                    "contact.phoneNumber": '',
                    "contact.email": '',
                    "company.name": '',
                    blacklisted: [],
                    createdAt: {
                        from: null,
                        to: null
                    }
                }
            }
        case CUSTOMER_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        case CUSTOMER_GET_DETAILS:
            return {
                ...state,
                customer: action.payload.data,
            }
        default:
            return state;
    }
}

export default customerReducer;