import React from 'react';
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import * as yup from "yup";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Card, FormHelperText, IconButton, InputAdornment} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import RadioGroup from "@material-ui/core/RadioGroup";
import Radio from "@material-ui/core/Radio";
import {fullDate} from "../utils/utils";
import {SUPPORTED_LANGUAGES} from "../constant";
import Tooltip from "@material-ui/core/Tooltip";
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';

const customStyles = makeStyles({
    inputField: {
        marginTop: 10,
        marginBottom: 10
    },
    card: {
        width: '100%'
    },
    cardDanger: {
        borderColor: '#f44336'
    }
})

const UserForm = ({ editableFields, readOnlyFields, authorities, onFormSubmit, onBusinessEntityCheck, businessEntityExists, businessEntityLoading, updateUser, loggedInUser }) => {
    const { t } = useTranslation();
    const classes = customStyles();
    const [businessEntityTouched, setBusinessEntityTouched] = React.useState(false);

    const validationRules = yup.object({
        displayName: yup.string().required('messages.fieldRequired'),
        email: yup.string().email('messages.fieldInvalid').required('messages.fieldRequired'),
        secondaryEmail: yup.string().email('messages.fieldInvalid'),
        phoneNumber: yup.string().matches(/^\+[0-9]+$/, 'messages.fieldInvalid').required('messages.fieldRequired'),
        secondaryPhoneNumber: yup.string().matches(/^\+[0-9]+$/, 'messages.fieldInvalid'),
        languageCode: yup.string().required('messages.fieldRequired'),
        authorities: yup.array().min(1, 'messages.fieldRequired'),
        businessEntityCode: yup.string()
    });

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: editableFields,
        validationSchema: validationRules,
        onSubmit: (values, { setSubmitting }) => {
            values.email = values.email.toLowerCase();
            onFormSubmit(values)
            setTimeout(function(){
                setSubmitting(false)
            }, 1000);
        }
    })

    const onBusinessEntityBlur = (value) => {
        if (value.length) {
            setBusinessEntityTouched(true);
        } else {
            setBusinessEntityTouched(false);
        }
    }

    const checkBusinessEntity = (value) => {
        onBusinessEntityCheck(value);
        setBusinessEntityTouched(false);
    }

    return (
        <form onSubmit={formik.handleSubmit} className="padding-bottom-10">
            <Grid container item md={12} spacing={5}>
                <Grid item md={3}>
                    <Typography variant="h6" className="grouping-header">{t(`messages.generalInformation`)}</Typography>
                    <div className="grouping-content">
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t('keys.displayName')}
                            name="displayName"
                            autoComplete='off'
                            className={classes.inputField}
                            onChange={formik.handleChange}
                            value={formik.values['displayName']}
                            error={formik.touched['displayName'] && Boolean(formik.errors['displayName'])}
                            helperText={formik.touched['displayName'] && t(formik.errors['displayName'], {field: t('keys.displayName')})}
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t('keys.phoneNumber')}
                            name="phoneNumber"
                            autoComplete='off'
                            className={classes.inputField}
                            onChange={formik.handleChange}
                            value={formik.values['phoneNumber']}
                            error={formik.touched['phoneNumber'] && Boolean(formik.errors['phoneNumber'])}
                            helperText={formik.touched['phoneNumber'] && t(formik.errors['phoneNumber'], {field: t('keys.phoneNumber')})}
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t('keys.secondaryPhoneNumber')}
                            name="secondaryPhoneNumber"
                            autoComplete='off'
                            className={classes.inputField}
                            onChange={formik.handleChange}
                            value={formik.values['secondaryPhoneNumber']}
                            error={formik.touched['secondaryPhoneNumber'] && Boolean(formik.errors['secondaryPhoneNumber'])}
                            helperText={formik.touched['secondaryPhoneNumber'] && t(formik.errors['secondaryPhoneNumber'], {field: t('keys.secondaryPhoneNumber')})}
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t('keys.email')}
                            name="email"
                            autoComplete='off'
                            className={classes.inputField}
                            onChange={formik.handleChange}
                            value={formik.values['email']}
                            error={formik.touched['email'] && Boolean(formik.errors['email'])}
                            helperText={formik.touched['email'] && t(formik.errors['email'], {field: t('keys.email')})}
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t('keys.secondaryEmail')}
                            name="secondaryEmail"
                            autoComplete='off'
                            className={classes.inputField}
                            onChange={formik.handleChange}
                            value={formik.values['secondaryEmail']}
                            error={formik.touched['secondaryEmail'] && Boolean(formik.errors['secondaryEmail'])}
                            helperText={formik.touched['secondaryEmail'] && t(formik.errors['secondaryEmail'], {field: t('keys.secondaryEmail')})}
                        />
                    </div>
                    <Typography variant="h6" className="grouping-header">{t(`messages.other`)}</Typography>
                    <div className="grouping-content">
                        <FormControl
                            className={`${classes.inputField} width-100`}
                            error={businessEntityExists !== null}
                        >
                            <TextField
                                variant="outlined"
                                fullWidth
                                label={t('keys.businessEntityCode')}
                                name="businessEntityCode"
                                autoComplete='off'
                                onChange={formik.handleChange}
                                onBlur={() => onBusinessEntityBlur(formik.values['businessEntityCode'])}
                                value={formik.values['businessEntityCode']}
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">
                                        <IconButton size="small" onClick={() => checkBusinessEntity(formik.values['businessEntityCode'])}>
                                            {!businessEntityLoading && <Tooltip title={t('messages.check')}>
                                                <ArrowRightAltIcon />
                                            </Tooltip>}
                                        </IconButton>
                                    </InputAdornment>
                                }}
                            />
                            {
                                businessEntityExists === false && !businessEntityTouched &&
                                <FormHelperText>{t('messages.businessEntity.notFound')}</FormHelperText>
                            }
                            {
                                businessEntityTouched &&
                                <FormHelperText>{t('messages.businessEntity.notVerified')}</FormHelperText>
                            }
                            {
                                businessEntityExists && !businessEntityTouched &&
                                <FormHelperText className="color-success">{t('messages.businessEntity.found')}</FormHelperText>
                            }
                        </FormControl>
                    </div>

                </Grid>
                <Grid item md={3}>
                    <Typography variant="h6" className="grouping-header">{t(`keys.authorities`)}</Typography>
                    <div className="grouping-content">
                        <FormControl
                            className={classes.inputField}
                            error={formik.touched['authorities'] && Boolean(formik.errors['authorities'])}
                        >
                            <Card variant="outlined" className={`grouping-content ${formik.touched['authorities'] && Boolean(formik.errors['authorities']) && classes.cardDanger}`}>
                                {authorities.map(authority => {
                                    return (<FormControlLabel
                                        className="MuiTypography-root MuiTypography-subtitle2"
                                        key={`authorities.${authority}`}
                                        control={
                                            <Checkbox
                                                checked={formik.values['authorities'].includes(authority)}
                                                value={authority}
                                                onChange={formik.handleChange}
                                                name="authorities"
                                                color="primary"
                                            />
                                        }
                                        label={t(`values.authorities.${authority}`)}
                                    />)
                                })}
                            </Card>
                            {
                                formik.touched['authorities'] && Boolean(formik.errors['authorities']) &&
                                <FormHelperText>{t(formik.errors['authorities'], {field: t('keys.authorities')})}</FormHelperText>
                            }
                        </FormControl>
                    </div>
                </Grid>
                <Grid item md={3}>
                    <Typography variant="h6" className="grouping-header">{t(`keys.status`)}</Typography>
                    <div className="grouping-content">
                        {updateUser &&
                            <React.Fragment>
                                <FormControl
                                    key="locked"
                                    component="fieldset"
                                    className={`${classes.inputField} ${classes.card}`}
                                    error={formik.touched['locked'] && Boolean(formik.errors['locked'])}>
                                    <Typography variant="subtitle2">{t('keys.locked')}</Typography>
                                    <Card variant="outlined" className="grouping-content">
                                        <RadioGroup name="locked" value={formik.values['locked']}>
                                            <FormControlLabel
                                                value="true"
                                                control={<Radio />}
                                                checked={'true' === formik.values['locked'].toString()}
                                                label={t(`values.locked.true`)}
                                                onChange={formik.handleChange}
                                            />
                                            <FormControlLabel
                                                value="false"
                                                control={<Radio />}
                                                checked={'false' === formik.values['locked'].toString()}
                                                label={t(`values.locked.false`)}
                                                onChange={formik.handleChange}
                                            />
                                        </RadioGroup>
                                    </Card>
                                    <FormHelperText>{formik.touched['locked'] && formik.errors['locked']}</FormHelperText>
                                </FormControl>
                                <FormControl
                                    key="active"
                                    component="fieldset"
                                    className={`${classes.inputField} ${classes.card}`}
                                    error={formik.touched['active'] && Boolean(formik.errors['active'])}
                                >
                                    <Typography variant="subtitle2">{t('keys.active')}</Typography>
                                    <Card variant="outlined" className="grouping-content">
                                        <RadioGroup name="active" value={formik.values['active']}>
                                            <FormControlLabel
                                                value="true"
                                                control={<Radio />}
                                                checked={'true' === formik.values['active'].toString()}
                                                label={t(`values.active.true`)}
                                                onChange={formik.handleChange}
                                            />
                                            <FormControlLabel
                                                value="false"
                                                control={<Radio />}
                                                checked={'false' === formik.values['active'].toString()}
                                                label={t(`values.active.false`)}
                                                onChange={formik.handleChange}
                                            />
                                        </RadioGroup>
                                    </Card>
                                    <FormHelperText>{formik.touched['active'] && formik.errors['active']}</FormHelperText>
                                </FormControl>
                            </React.Fragment>
                        }
                        <FormControl
                            key="languageCode"
                            component="fieldset"
                            className={`${classes.inputField} ${classes.card}`}
                            error={formik.touched['languageCode'] && Boolean(formik.errors['languageCode'])}
                        >
                            <Typography variant="subtitle2">{t('keys.languageCode')}</Typography>
                            <Card variant="outlined" className={`grouping-content ${formik.touched['languageCode'] && Boolean(formik.errors['languageCode']) && classes.cardDanger}`}>
                                <RadioGroup name="languageCode" value={formik.values['languageCode']}>
                                    {SUPPORTED_LANGUAGES.map(supportedLanguage => {
                                        return (<FormControlLabel
                                            key={supportedLanguage}
                                            value={supportedLanguage}
                                            control={<Radio />}
                                            checked={supportedLanguage === formik.values['languageCode'].toString()}
                                            label={t(`values.languageCode.${supportedLanguage}`)}
                                            onChange={formik.handleChange}
                                        />)
                                    })}
                                </RadioGroup>
                            </Card>
                            {
                                formik.touched['languageCode'] && Boolean(formik.errors['languageCode']) &&
                                <FormHelperText>{t(formik.errors['languageCode'], {field: t('keys.languageCode')})}</FormHelperText>
                            }
                        </FormControl>
                    </div>
                </Grid>
                <Grid item md={3}>
                    {
                        updateUser && <React.Fragment>
                            <Typography variant="h6" className="grouping-header">{t(`messages.additionalInformation`)}</Typography>
                            <div className="grouping-content">
                                <Box mb={2}>
                                    <Typography variant="subtitle2">{t('keys.failedLoginAttempts')}</Typography>
                                    <p className="display-value">{readOnlyFields['failedLoginAttempts']}</p>
                                </Box>
                                <Box mb={2}>
                                    <Typography variant="subtitle2">{t('keys.lastLoginAt')}</Typography>
                                    <p className="display-value">{fullDate(readOnlyFields['lastLoginAt'], loggedInUser.countryCode)}</p>
                                </Box>
                                <Box mb={2}>
                                    <Typography variant="subtitle2">{t('keys.createdBy')}</Typography>
                                    <p className="display-value">{readOnlyFields['createdBy']}</p>
                                </Box>
                                <Box mb={2}>
                                    <Typography variant="subtitle2">{t('keys.createdAt')}</Typography>
                                    <p className="display-value">{fullDate(readOnlyFields['createdAt'], loggedInUser.countryCode)}</p>
                                </Box>
                                <Box mb={2}>
                                    <Typography variant="subtitle2">{t('keys.updatedAt')}</Typography>
                                    <p className="display-value">{fullDate(readOnlyFields['updatedAt'], loggedInUser.countryCode)}</p>
                                </Box>

                            </div>
                        </React.Fragment>
                    }
                </Grid>
            </Grid>
            <div className="margin-top-10 text-right">
                <Button disabled={formik.isSubmitting || (businessEntityTouched || businessEntityExists === false) } variant="contained" color="primary" type="submit">
                    {t('messages.submit')}
                </Button>
            </div>
        </form>

    )
}

export default UserForm;