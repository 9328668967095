import {
    HIDE_CONFIRMATION_DIALOG,
    SHOW_CONFIRMATION_DIALOG,
    SHOW_ERROR,
    SIMPLE_DIALOG_CLOSE
} from "./types";

export const simpleDialogClose = () => {
    return({
        type: SIMPLE_DIALOG_CLOSE
    })
}

export const showError = (error) => {
    return ({
        type: SHOW_ERROR,
        payload: error.response
    })
}

export const showConfirmationDialog = (id, previousValue, newValue, entity, field, action, valueType, onAccept) => {
    return({
        type: SHOW_CONFIRMATION_DIALOG,
        payload: {
            entity: entity,
            entityId: id,
            field: field,
            action: action,
            newValue: newValue,
            previousValue: previousValue,
            valueType: valueType,
            onAccept: onAccept
        }
    })
}

export const hideConfirmationDialog = () => {
    return ({
        type: HIDE_CONFIRMATION_DIALOG
    })
}