import React from "react";
import "../../styles/custom-bootstrap.scss";
import { useTranslation } from "react-i18next";
import {fullDate} from "../../utils/utils"

const DocumentUploadAndPreview = (props) => {
  const {
    acceptedFileTypes,
    documentType,
    encodeFileAsURL,
    documents,
    userCountryCode
  } =
    props;

  const { t } = useTranslation();

  return (
    <div className="row">
      <div className="col-sm-12">
        <label>{t(`keys.extendedWarrantyClaim.attachedFiles.${documentType}.title`)}</label>
        <div className="row">
          <div class="col-sm-12">
            <div class="form-group">
              <input
                type="file"
                id={documentType}
                class="btn btn-primary just"
                accept={acceptedFileTypes}
                onChange={(event) => encodeFileAsURL({ 'filesSelected': event.target.files, 'documentType': documentType, 'acceptedFileTypes': acceptedFileTypes })}
              />
            </div>
          </div>
        </div>
        <div className="row">
          {documents.map((document) => {
            return (
              <div className="col-sm-4">
                <div className="attachment-container">
                  <div className="row">
                    <div className="col-sm-12 card">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>File Name</label><br />
                            <label className="font-weight-bold">{document.filename}</label><br />
                            <a className="btn btn-sm btn-primary download-btn" href={document.base64EncryptedData} download={document.filename}>Download</a>
                          </div>
                          <div className="form-group">
                            {
                              document.mimeType == 'application/pdf' ? (
                                <label>
                                  <i className="fa fa-file-pdf-o"></i>
                                  <span>Preview Not Available</span>
                                </label>
                              ) : (
                                <img className="img-fluid img-thumbnail" style={{ width: "50%" }} src={document.thumbnail ? document.thumbnail : document.base64EncryptedData} />
                              )
                            }
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <label>Created By</label><br />
                            <label className="font-weight-bold">{document.user.displayName}</label>
                          </div>
                          <div className="form-group">
                            <label>File type</label><br />
                            <label className="font-weight-bold">{document.mimeType}</label>
                          </div>
                          <label className="form-group">
                            <label>Created At</label><br />
                            <label className="font-weight-bold">{fullDate(document.createdAt, userCountryCode)}</label>
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>
    </div>
  );
};

export default DocumentUploadAndPreview;
