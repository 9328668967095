import React, {Component} from "react";
import { connect } from 'react-redux';
import PageTemplate from "../components/PageTemplate";
import {
    getMerchantUserDetails, resetMerchantUserPassword, updateMerchantUser
} from "../actions/merchantUserActions";
import {checkIfBusinessEntityExists, resetBusinessEntityExistence} from "../actions/businessEntityActions";
import DataTableLoader from "../components/DataTableLoader";
import UserForm from "../components/UserForm";
import SectionTemplate from "../components/SectionTemplate";
import Button from "@material-ui/core/Button";
import {withTranslation} from "react-i18next";
import {showConfirmationDialog} from "../actions/commonActions";

class UserDetails extends Component {
    componentDidMount() {
        this.props.getMerchantUserDetails(this.props.match.params.id);
        this.props.resetBusinessEntityExistence();
    }

    updateUser = (values) => {
        this.props.updateMerchantUser(values, this.props.match.params.id);
    }

    checkBusinessEntity = (value) => {
        this.props.checkIfBusinessEntityExists(value)
    }

    renderDetails = (user) => {

        const displayFields = [
            'failedLoginAttempts',
            'lastLoginAt',
            'createdBy',
            'updatedAt',
            'createdAt'
        ];

        const formFields = [
            'displayName',
            'email',
            'secondaryEmail',
            'phoneNumber',
            'secondaryPhoneNumber',
            'active',
            'locked',
            'languageCode',
            'authorities',
            'businessEntityCode'
        ];

        const editableFields = formFields.map(formField => formField)
            .reduce((obj, key) => ({ ...obj, [key]: user[key] }), {});

        const readOnlyFields = displayFields.map(displayField => displayField)
            .reduce((obj, key) => ({ ...obj, [key]: user[key] }), {});

        return (
            Object.keys(user).length === 0 ? 'Loading...' :
                <UserForm editableFields={editableFields}
                          readOnlyFields={readOnlyFields}
                          loggedInUser={this.props.authentication.loggedInUser}
                          authorities={this.props.authentication.merchantProduct.authorities}
                          onFormSubmit={values => this.updateUser(values)}
                          onBusinessEntityCheck={value => this.checkBusinessEntity(value)}
                          businessEntityExists={this.props.businessEntities.businessEntityExists}
                          businessEntityLoading={this.props.businessEntities.businessEntityExistenceChecking}
                          updateUser={true}
                />
        );
    }

    handlePasswordReset = () => {
        this.props.showConfirmationDialog(
            this.props.match.params.id,
            null,
            null,
            'merchantUser',
            'password',
            'resetPassword',
            null,
            'resetMerchantUserPassword'
            )
    }

    render() {
        const { t } = this.props;
        return (
            <PageTemplate title="messages.userDetails">
                {this.props.common.showContentLoader ? <DataTableLoader /> :
                    <React.Fragment>
                        {this.renderDetails(this.props.merchantUser.merchantUser)}
                        <SectionTemplate title="passwordReset">
                            <Button color="primary"
                                    variant="contained"
                                    onClick={() => this.handlePasswordReset()}
                            >{t('messages.passwordReset')}</Button>
                        </SectionTemplate>
                    </React.Fragment>
                    }
            </PageTemplate>

        )
    }
}

const mapStateToProps = state => ({
    merchantUser: state.merchantUsers,
    authentication: state.authentication,
    businessEntities: state.businessEntities,
    common: state.common
})

export default connect(mapStateToProps, {
    getMerchantUserDetails,
    updateMerchantUser,
    resetMerchantUserPassword,
    showConfirmationDialog,
    checkIfBusinessEntityExists,
    resetBusinessEntityExistence
})(withTranslation() (UserDetails));