import React, { Component } from "react";
import "../../styles/custom-bootstrap.scss";
import { useTranslation } from "react-i18next";

const ExtendedWarrantyClaimService = ({
  extendedWarrantyClaims
}) => {
  const { t } = useTranslation();

  return (
    <div className="custom-bootstrap">
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <i className="fa fa-exchange" aria-hidden="true"></i>
              <strong>{'EW Claim Service'}</strong>
            </div>
            <div className="card-body">
              {extendedWarrantyClaims?.map((extendedWarrantyClaim) => {
                return (
                  <div className="card card-body">
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>{'EW Claim Id'}</label>
                          <br />
                          <strong>
                            <a href={`/extendedWarrantyClaims/${extendedWarrantyClaim.id}`}>{extendedWarrantyClaim.id}</a>
                          </strong>
                        </div>
                        <div className="form-group">
                          <label>{'EW Claim Status'}</label>
                          <br />
                          <strong>{extendedWarrantyClaim.status}</strong>
                        </div>
                        <div className="form-group">
                          <label>{'EW Claim Number'}</label>
                          <br />
                          <strong>{extendedWarrantyClaim.number}</strong>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label>{'EW Claim Date'}</label>
                          <br />
                          <strong>{extendedWarrantyClaim.createdAt}</strong>
                        </div>
                        <div className="form-group">
                          <label>{'Beneficiary Detail - Partner External Id'}</label>
                          <br />
                          <strong>{extendedWarrantyClaim.beneficiary?.externalId}</strong>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExtendedWarrantyClaimService;
