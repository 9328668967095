import React, { Component } from 'react';
import { connect } from 'react-redux';
import Button from "@material-ui/core/Button";
import { withTranslation } from "react-i18next";
import DataTable from "../components/DataTable";
import PageTemplate from "../components/PageTemplate";
import {clearPickUpFilters, getPickUpList, sortPickUps, updateFilterForPickUpList} from "../actions/pickUpActions";
import {parseObjectToString, validateAuthority} from "../utils/utils";
import {AUTHORITY_PICK_UP_MANAGER, AUTHORITY_READ_ONLY, AUTHORITY_VIDEO_CONTROLLER} from "../constant";
import { exportFile } from "../actions/exportActions";
import {pickUpsExportParams} from "../utils/exportUtils";

class PickUpList extends Component {
    componentDidMount() {
        this.props.getPickUpList('orderBy=createdAt&sort=desc');
        this.props.clearPickUpFilters();
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let filterState = this.props.pickUps.filters[filterKey];
            filterState.includes(filterValue) ? filterState = filterState.filter(item => item !== filterValue) : filterState.push(filterValue);
            filterValue = filterState;
        }
        this.props.updateFilterForPickUpList(filterKey, filterValue)
    }

    handleFilterReset() {
        this.props.clearPickUpFilters();
        this.props.getPickUpList('orderBy=createdAt&sort=desc');
    }

    handleSorting(orderBy, sort) {
        this.props.sortPickUps(orderBy, sort);
        this.props.getPickUpList(parseObjectToString(this.props.pickUps.filters, {orderBy, sort}))
    }

    renderPickUps(pickUps, showContentLoader) {
        const fields = [
            {
                name: 'number'
            },
            {
                name: 'status',
                type: 'translatedString'
            },
            {
                name: 'pickUpDate',
                type: 'shortDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }];

        const filterKeys = [
            {
                name: 'number'
            },
            {
                name: 'status',
                type: 'checkBoxes',
                options: ['new', 'scheduled', 'notScheduled', 'transmitted', 'picked' ,'delivering', 'delivered']
            },
            {
                name: 'pickUpDate',
                type: 'dateRangePicker'
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            }
        ]

        if (validateAuthority(AUTHORITY_VIDEO_CONTROLLER, this.props.authentication.loggedInUser.authorities)) {
            filterKeys.splice(1, 1,
                {
                    name: 'status',
                    type: 'checkBoxes',
                    options: ['transmitted', 'delivering', 'delivered', 'picked', 'scheduled']
                })
        }

        if (validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_VIDEO_CONTROLLER, AUTHORITY_READ_ONLY], this.props.authentication.loggedInUser.authorities)) {
            fields.splice(1, 0, {
                name: 'businessEntityCode'
            })
            fields.splice(3,0,
                {
                    name: 'parcelCondition',
                    type: 'translatedString'
                }
            )
            filterKeys.splice(1, 0, {
                name: 'businessEntityCode'
            })
            filterKeys.splice(3, 0,
                {
                    name: 'parcelCondition',
                    type: 'checkBoxes',
                    options: ['accepted', 'unknown', 'lost', 'packOpen']
                })
        }

        return(
            <DataTable fields={fields}
                       items={pickUps}
                       entity="pickUps"
                       filterKeys={filterKeys}
                       filterValues={this.props.pickUps.filters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getPickUpList(parseObjectToString(this.props.pickUps.filters))}
                       sorting={this.props.pickUps.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={false}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
            />
        )
    }

    onDocumentDownload() {
        this.props.exportFile(pickUpsExportParams(this.props.authentication.loggedInUser.authorities));
    }

    render() {
        const { t } = this.props;
        return(
            <PageTemplate title="messages.pickUpList">
                <div className="display-flex justify-space-between align-items-center margin-bottom-10">
                    <p>{t('messages.pickUpListSubHeader')}</p>
                    {
                        !validateAuthority([AUTHORITY_READ_ONLY], this.props.authentication.loggedInUser.authorities) &&
                        <Button variant="contained" size="small" color="secondary" onClick={() => this.onDocumentDownload()}>download</Button>
                    }
                </div>
                {this.renderPickUps(this.props.pickUps.pickUps, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    pickUps: state.pickUps,
    authentication: state.authentication,
    common: state.common,
    export: state.export
})

export default connect(mapStateToProps, {
    getPickUpList,
    clearPickUpFilters,
    updateFilterForPickUpList,
    sortPickUps,
    exportFile
})(withTranslation() (PickUpList));