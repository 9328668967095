import React from 'react';
import makeStyles from "@material-ui/core/styles/makeStyles";
import {useTranslation} from "react-i18next";
import {getIn, useFormik} from "formik";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Box from "@material-ui/core/Box";
import {fullDate} from "../utils/utils";
import {addressFields} from "../utils/fields";
import {MERCHANT_USER_CHANNEL_OPTIONS, PICK_UP_DAYS} from "../constant";

const customStyles = makeStyles({
    inputField: {
        marginTop: 10,
        marginBottom: 10
    },
    selectField: {
        marginBottom: 10
    },
    card: {
        width: '100%'
    },
    customStyleForSelect: {
        color: '#f44336',
        fontSize: '0.75rem',
        marginTop: '3px',
        marginLeft: '14px',
        marginRight: '14px'
    }
})

const BusinessEntityForm = ({ editableFields, readOnlyFields, validationRules, onFormSubmit, updateBusinessEntity, loggedInUser }) => {
    const { t } = useTranslation();
    const classes = customStyles();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: editableFields,
        validationSchema: validationRules,
        onSubmit: (values, { setSubmitting }) => {
            onFormSubmit(values)
            setTimeout(function(){
                setSubmitting(false)
            }, 1000);
        }
    })

    const [numberOfPickUpDays, setNumberOfPickUpDays] = React.useState(formik.values['pickUpDays'] === undefined || formik.values['pickUpDays'].length === 0 ? 1 : formik.values['pickUpDays'].length);

    const removePickUpDay = () => {
        if (numberOfPickUpDays > 1) {
            formik.values['pickUpDays'].pop();
            setNumberOfPickUpDays(numberOfPickUpDays - 1);
        }
    }

    return (
        <form onSubmit={formik.handleSubmit} className="padding-bottom-10">
            <Grid container item md={12} spacing={5}>
                <Grid item md={3}>
                    <Typography variant="h6" className="grouping-header">{t(`messages.generalInformation`)}</Typography>
                    <div className="grouping-content">
                        {updateBusinessEntity ?
                            <Box mb={2}>
                                <Typography variant="subtitle2">{t('keys.code')}</Typography>
                                <p className="display-value">{readOnlyFields['code']}</p>
                            </Box> :
                            <TextField
                                variant="outlined"
                                fullWidth
                                label={t('keys.code')}
                                name="code"
                                autoComplete='off'
                                className={classes.inputField}
                                onChange={formik.handleChange}
                                value={formik.values['code']}
                                error={formik.touched['code'] && Boolean(formik.errors['code'])}
                                helperText={formik.touched['code'] && t(formik.errors['code'], {field: t('keys.code')})}
                            />
                        }
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t('keys.name')}
                            name="name"
                            autoComplete='off'
                            className={classes.inputField}
                            onChange={formik.handleChange}
                            value={formik.values['name']}
                            error={formik.touched['name'] && Boolean(formik.errors['name'])}
                            helperText={formik.touched['name'] && t(formik.errors['name'], {field: t('keys.name')})}
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t('keys.dealerCode')}
                            name="dealerCode"
                            autoComplete='off'
                            className={classes.inputField}
                            onChange={formik.handleChange}
                            value={formik.values['dealerCode']}
                            error={formik.touched['dealerCode'] && Boolean(formik.errors['dealerCode'])}
                            helperText={formik.touched['dealerCode'] && t(formik.errors['dealerCode'], {field: t('keys.dealerCode')})}
                        />
                        {
                            MERCHANT_USER_CHANNEL_OPTIONS[loggedInUser['productCode']] !== undefined && MERCHANT_USER_CHANNEL_OPTIONS[loggedInUser['productCode']].length ?
                                <div className={classes.selectField}>
                                    <Typography variant="subtitle2">{t('keys.channel')}</Typography>
                                    <select name="channel" onChange={formik.handleChange} value={formik.values['channel']} className="select" style={{width: '100%', border: formik.touched['channel'] && Boolean(formik.errors['channel']) ? '1px solid red' : ''}}>
                                        <option hidden value=''>{t('messages.selectYourOption')}</option>
                                        {
                                            MERCHANT_USER_CHANNEL_OPTIONS[loggedInUser['productCode']].map(productCode => {
                                                return(
                                                    <option value={productCode}
                                                            key={productCode}
                                                    >{productCode}</option>
                                                )
                                            })
                                        }
                                        {
                                            !MERCHANT_USER_CHANNEL_OPTIONS[loggedInUser['productCode']].includes(formik.values['channel']) && formik.values['channel'] !== '' &&
                                            <option value={formik.values['channel']}>{formik.values['channel']}</option>
                                        }
                                    </select>
                                    {formik.touched['channel'] && t(formik.errors['channel'], {field: t('keys.channel')}) ? (
                                        <div className={classes.customStyleForSelect}>{ t(formik.errors['channel'], {field: t('keys.channel')})}</div>
                                    ) : null}
                                </div> :
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    label={t('keys.channel')}
                                    name="channel"
                                    autoComplete='off'
                                    className={classes.inputField}
                                    onChange={formik.handleChange}
                                    value={formik.values['channel']}
                                    error={formik.touched['channel'] && Boolean(formik.errors['channel'])}
                                    helperText={formik.touched['channel'] && t(formik.errors['channel'], {field: t('keys.channel')})}
                                />
                        }
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t('keys.aggregatedPOSCode')}
                            name="aggregatedPOSCode"
                            autoComplete='off'
                            className={classes.inputField}
                            onChange={formik.handleChange}
                            value={formik.values['aggregatedPOSCode']}
                            error={formik.touched['aggregatedPOSCode'] && Boolean(formik.errors['aggregatedPOSCode'])}
                            helperText={formik.touched['aggregatedPOSCode'] && t(formik.errors['aggregatedPOSCode'], {field: t('keys.aggregatedPOSCode')})}
                        />
                        <TextField
                            variant="outlined"
                            fullWidth
                            label={t('keys.email')}
                            name="email"
                            autoComplete='off'
                            className={classes.inputField}
                            onChange={formik.handleChange}
                            value={formik.values['email']}
                            error={formik.touched['email'] && Boolean(formik.errors['email'])}
                            helperText={formik.touched['email'] && t(formik.errors['email'], {field: t('keys.email')})}
                        />

                    </div>
                </Grid>
                <Grid item md={3}>
                    <Typography variant="h6" className="grouping-header">{t(`messages.shopInformation`)}</Typography>
                    <TextField
                        variant="outlined"
                        fullWidth
                        label={t('keys.personInChargeName')}
                        name="personInChargeName"
                        autoComplete='off'
                        className={classes.inputField}
                        onChange={formik.handleChange}
                        value={formik.values['personInChargeName']}
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        label={t('keys.personInChargePhoneNumber')}
                        name="personInChargePhoneNumber"
                        autoComplete='off'
                        className={classes.inputField}
                        onChange={formik.handleChange}
                        value={formik.values['personInChargePhoneNumber']}
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        label={t('keys.businessHours')}
                        name="businessHours"
                        autoComplete='off'
                        className={classes.inputField}
                        onChange={formik.handleChange}
                        value={formik.values['businessHours']}
                    />
                    <TextField
                        variant="outlined"
                        fullWidth
                        label={t('keys.description')}
                        name="description"
                        autoComplete='off'
                        className={classes.inputField}
                        onChange={formik.handleChange}
                        value={formik.values['description']}
                    />

                    {PICK_UP_DAYS[loggedInUser.countryCode].length > 0 &&
                        <div className={classes.selectField}>
                            <Typography variant="subtitle2">{t('keys.pickUpDays')}</Typography>
                            {[...Array(numberOfPickUpDays)].map((x, i) => {
                                return (
                                    <select key={`pickUpDays[${i}]`} name={`pickUpDays[${i}]`}
                                            onChange={formik.handleChange}
                                            value={formik.values['pickUpDays'][i]} className="select margin-bottom-10"
                                            style={{width: '100%'}}>
                                        <option hidden value=''>{t('messages.selectYourOption')}</option>
                                        <option
                                            disabled={formik.values['pickUpDays'][i] !== 'monday' && formik.values['pickUpDays'].includes('monday')}
                                            value='monday'>{t('values.weekday.monday')}</option>
                                        <option
                                            disabled={formik.values['pickUpDays'][i] !== 'tuesday' && formik.values['pickUpDays'].includes('tuesday')}
                                            value='tuesday'>{t('values.weekday.tuesday')}</option>
                                        <option
                                            disabled={formik.values['pickUpDays'][i] !== 'wednesday' && formik.values['pickUpDays'].includes('wednesday')}
                                            value='wednesday'>{t('values.weekday.wednesday')}</option>
                                        <option
                                            disabled={formik.values['pickUpDays'][i] !== 'thursday' && formik.values['pickUpDays'].includes('thursday')}
                                            value='thursday'>{t('values.weekday.thursday')}</option>
                                        <option
                                            disabled={formik.values['pickUpDays'][i] !== 'friday' && formik.values['pickUpDays'].includes('friday')}
                                            value='friday'>{t('values.weekday.friday')}</option>
                                    </select>
                                )
                            })}
                            <Button
                                onClick={() => setNumberOfPickUpDays(numberOfPickUpDays < 5 ? numberOfPickUpDays + 1 : numberOfPickUpDays)}>{t('messages.addMore')}</Button>
                            <Button onClick={() => removePickUpDay()}>{t('messages.remove')}</Button>
                        </div>
                    }

                </Grid>
                <Grid item md={6}>
                    <Typography variant="h6" className="grouping-header">{t(`messages.address`)}</Typography>
                    <div className="grouping-content">
                        <Grid container>
                            {
                                addressFields[loggedInUser.countryCode].map(addressField => {
                                    return <TextField
                                            key={`address.${addressField}`}
                                            variant="outlined"
                                            label={t(`keys.address.${addressField}`)}
                                            name={`address.${addressField}`}
                                            autoComplete='off'
                                            className={`${classes.inputField} margin-right-10 width-40`}
                                            onChange={formik.handleChange}
                                            value={formik.values['address'][addressField]}
                                            error={getIn(formik.touched, ['address'][addressField]) && Boolean(getIn(formik.errors, `address.[${addressField}]`))}
                                            helperText={getIn(formik.touched, ['address'][addressField]) && t(getIn(formik.errors, `address.[${addressField}]`), {field: t(`keys.address.${addressField}`)})}
                                        />
                                })
                            }
                        </Grid>
                    </div>
                </Grid>
                <Grid item md={3}>
                    {updateBusinessEntity &&
                        <React.Fragment>
                            <Typography variant="h6" className="grouping-header">{t(`messages.additionalInformation`)}</Typography>
                            <div className="grouping-content">
                                <Box mb={2}>
                                    <Typography variant="subtitle2">{t('keys.createdAt')}</Typography>
                                    <p className="display-value">{fullDate(readOnlyFields['createdAt'], loggedInUser.countryCode)}</p>
                                </Box>
                                <Box mb={2}>
                                    <Typography variant="subtitle2">{t('keys.updatedAt')}</Typography>
                                    <p className="display-value">{fullDate(readOnlyFields['updatedAt'], loggedInUser.countryCode)}</p>
                                </Box>
                            </div>
                        </React.Fragment>
                    }
                </Grid>
            </Grid>
            <div className="margin-top-10 text-right">
                <Button disabled={!formik.isValid || formik.isSubmitting} variant="contained" color="primary" type="submit" seleniumselector="businessEntityFormSubmit">
                    {t('messages.submit')}
                </Button>
            </div>
        </form>
    )
}

export default BusinessEntityForm;