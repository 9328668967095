import {SEARCH_GET_RESULT, SEARCH_SET_FACET_PARAM_VALUE, SEARCH_UNSET_STATE} from "../actions/types";
import {SEARCH_RESULT_KEYS} from "../constant";

const INITIAL_STATE = {
    searchResults: {
        'article.manufacturer.name.**localeCode**.keyword': [],
        'article.attributes.series.value.**localeCode**.keyword': [],
        'article.attributes.color.value.**localeCode**.keyword': [],
        'article.attributes.storageSize.value.keyword': [],
        'article.attributes.networkTechnology.value.keyword': [],
        'article.sku.keyword': []
    },
    searchParams: {
        'article.manufacturer.name.**localeCode**.keyword': '',
        'article.attributes.series.value.**localeCode**.keyword': '',
        'article.attributes.color.value.**localeCode**.keyword': '',
        'article.attributes.storageSize.value.keyword': '',
        'article.attributes.networkTechnology.value.keyword': '',
        'article.sku.keyword': ''
    },
    nextSearch: 'article.manufacturer.name.**localeCode**.keyword'
}

let resultKeysToBeUpdated;

const searchReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SEARCH_GET_RESULT:
            resultKeysToBeUpdated = SEARCH_RESULT_KEYS.slice(SEARCH_RESULT_KEYS.indexOf(action.payload.searchKey) + 1)
            return {
                ...state,
                searchResults: {
                    ...state.searchResults,
                    [action.payload.searchKey]: action.payload.searchResult['facets'][0]['facetValues'].map(facetValue => { return facetValue.value }),
                    ...Object.fromEntries(
                        resultKeysToBeUpdated.map(key => [key, []])
                    ),
                }
            }
        case SEARCH_SET_FACET_PARAM_VALUE:
            resultKeysToBeUpdated = SEARCH_RESULT_KEYS.slice(SEARCH_RESULT_KEYS.indexOf(action.payload.searchKey) + 1)
            return {
                ...state,
                nextSearch: action.payload.nextSearch,
                searchParams: {
                    ...state.searchParams,
                    [action.payload.searchKey]: action.payload.searchParamValue,
                    ...Object.fromEntries(
                        resultKeysToBeUpdated.map(key => [key, ''])
                    )
                }
            }
        case SEARCH_UNSET_STATE:
            return {
                ...state,
                searchResults: INITIAL_STATE.searchResults,
                searchParams: INITIAL_STATE.searchParams,
                nextSearch: INITIAL_STATE.nextSearch
            }
        default:
            return state;
    }
}

export default searchReducer;