import axios from "./interceptor";
import { BASE_URL } from "../constant";
import { REMARK_CREATE, REMARK_GET_LIST } from "./types";
import { handleError } from "../utils/utils";

export const getRemarks = (entityName, entityId) =>  async dispatch =>  {
    const filters = `entityName=${entityName}&entityId=${entityId}`;
    const sortAndOrder = 'orderBy=createdAt&sort=desc';
    axios.get(`${BASE_URL}/v1/remarks/?${filters}&${sortAndOrder}`)
        .then(response => {
            dispatch({
                type: REMARK_GET_LIST,
                payload: {response, entityId}
            })
        }).catch(error => {
        handleError(error, dispatch);
    })
}

export const createdRemark = (data, entityId) => async dispatch => {
    axios.post(`${BASE_URL}/v1/remarks`, data)
        .then(response => {
            dispatch({
                type: REMARK_CREATE,
                payload: {response, entityId}
            })
        }).catch(error => {
            handleError(error, dispatch)
    })
}