import React, { Component } from "react";
import { connect } from 'react-redux';
import * as yup from 'yup';
import PageTemplate from "../components/PageTemplate";
import { getBusinessEntityDetails, updateBusinessEntity } from "../actions/businessEntityActions";
import { getMerchantUserList } from "../actions/merchantUserActions";
import { withTranslation } from "react-i18next";
import { addressFields } from "../utils/fields";
import ExtendedWarrantyDetailsView from "../components/extendedWarranty/ExtendedWarrantyDetailsView";
import CustomerDetailsView from "../components/customer/CustomerDetailsView";
import SubscriptionInfo from "../components/customer/SubscriptionInfo"
import { getSubscriptionDetails } from "../actions/subscriptionActions";

class SubscriptionDetails extends Component {
    componentDidMount() {
        this.props.getSubscriptionDetails(this.props.match.params.id);
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        // if (this.props.businessEntities.businessEntity !== prevProps.businessEntities.businessEntity) {
        //     this.props.getMerchantUserList(`orderBy=createdAt&sort=desc&filterFields[]=businessEntityCode&filterValues[]=${this.props.businessEntities.businessEntity.code}`);
        // }
    }

    updateBusinessEntity = (values) => {
        this.props.updateBusinessEntity(values, this.props.match.params.id)
    }

    renderDetails = (subscription, countryCode, languageCode) => {
        return (
            <div className="custom-bootstrap">
                <SubscriptionInfo subscription={subscription} userCountryCode={countryCode} userLanguageCode={languageCode} isFromCustomerPage={false}></SubscriptionInfo>

            </div>
        )
    }

    render() {
        const { t } = this.props;
        return (
            <PageTemplate title="Subscriptions Details">
                {
                    this.renderDetails(this.props.subscriptions.subscription, this.props.authentication.loggedInUser.countryCode, this.props.authentication.loggedInUser.languageCode)

                }
            </PageTemplate>
        )
    }
}

const mapStateToProps = state => ({
    authentication: state.authentication,
    businessEntities: state.businessEntities,
    subscriptions: state.subscriptions,
    merchantUsers: state.merchantUsers,
    common: state.common
})

export default connect(mapStateToProps, {
    getBusinessEntityDetails,
    updateBusinessEntity,
    getMerchantUserList,
    getSubscriptionDetails
})(withTranslation()(SubscriptionDetails));