import React, {Component} from "react";
import { connect } from 'react-redux';
import {
    clearExtendedWarrantyClaimFilters,
    getExtendedWarrantyClaimList, sortExtendedWarrantyClaims,
    updateFiltersForExtendedWarrantyClaimList
} from '../actions/extendedWarrantyClaimActions';
import DataTable from "../components/DataTable";
import PageTemplate from "../components/PageTemplate";
import {
    parseObjectToString,
    validateAcceptedFields,
    validateDisableAuthority,
    validateEmail,
    validatePhoneNumber,
    validateRequiredFields
} from "../utils/utils";
import Box from "@material-ui/core/Box";
import FileUpload from "../components/FileUpload";
import {showError} from "../actions/commonActions";
import {fileUploadAcceptedFields, fileUploadRequiredFields} from "../utils/fields";
import { AUTHORITY_PARTNER_AGENT } from "../constant";

class ExtendedWarrantyClaimList extends Component {
    componentDidMount() {
        this.fetchData()
        this.props.clearExtendedWarrantyClaimFilters();
        this.props.sortExtendedWarrantyClaims('createdAt', 'desc');
    }

    handleFilterUpdate(filterKey, filterValue, multipleSelection) {
        if (multipleSelection) {
            let extendedWarrantyClaimFilterState = this.props.extendedWarrantyClaims.extendedWarrantyClaimFilters[filterKey];
            extendedWarrantyClaimFilterState.includes(filterValue) ? extendedWarrantyClaimFilterState = extendedWarrantyClaimFilterState.filter(item => item !== filterValue) : extendedWarrantyClaimFilterState.push(filterValue);
            filterValue = extendedWarrantyClaimFilterState;
        }
        this.props.updateFiltersForExtendedWarrantyClaimList(filterKey, filterValue)
    }

    fetchData(path) {
        let urlPath = path ? path : 'orderBy=createdAt&sort=desc'
        urlPath = validateDisableAuthority(AUTHORITY_PARTNER_AGENT, this.props.authentication.loggedInUser.authorities) ? urlPath : `${urlPath}&filterFields[]=channel&filterValues[]=merchant_portal` ;
        this.props.getExtendedWarrantyClaimList(urlPath);
    }

    handleFilterReset() {
        this.props.clearExtendedWarrantyClaimFilters();
        this.fetchData();
    }

    handleSorting(orderBy, sort) {
        this.props.sortExtendedWarrantyClaims(orderBy, sort)
        this.fetchData(parseObjectToString(this.props.extendedWarrantyClaims.filters, {orderBy, sort}))
    }

    renderExtendedWarrantyClaims(extendedWarrantyClaims, showContentLoader) {
        const fields = [
            {
                name: 'id',
                label: 'extendedWarrantyClaim.id'
            },
            {
                name: 'number',
                label: 'extendedWarrantyClaim.number'
            },
            {
                name: 'carrierCode',
                label: 'extendedWarrantyClaim.carrierCode'
            },
            {
                name: 'subscriptionId',
                label: 'extendedWarrantyClaim.subscriptionId'
            },
            {
                name: 'beneficiary.externalId',
                label: 'extendedWarrantyClaim.beneficiary.externalId'
            },
            {
                name: 'status',
                label: 'extendedWarrantyClaim.status'
            },
            {
                name: 'repairCost.amount',
                label: 'extendedWarrantyClaim.repairCost.amount'
            }, 
            {
                name: 'creator.displayName',
                label: 'extendedWarrantyClaim.user'
            },
            {
                name: 'quoteCurrencyCode',
                label: 'extendedWarrantyClaim.quoteCurrencyCode'
            },
            {
                name: 'beneficiary.paymentMethod',
                label: 'extendedWarrantyClaim.beneficiary.paymentMethod'
            },
            {
                name: 'invoice.totalApprovedAmount.amount',
                label: 'extendedWarrantyClaim.invoice.totalApprovedAmount.amount'
            },
            {
                name: 'payoutId',
                label: 'extendedWarrantyClaim.payoutId'
            },
            {
                name: 'createdAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            },
            {
                name: 'updatedAt',
                type: 'fullDate',
                countryCode: this.props.authentication.loggedInUser.countryCode
            }];

        const filterKeys = [
            {
                name: '_id',
                type: 'textField',
                label: 'extendedWarrantyClaim.id'
            },
            {
                name: 'number',
                type: 'textField',
                label: 'extendedWarrantyClaim.number'
            },
            {
                name: 'creator.displayName',
                type: 'textField',
                label: 'extendedWarrantyClaim.user'
            },
            {
                name: 'createdAt',
                type: 'dateRangePicker'
            },
            {
                name: 'updatedAt',
                type: 'dateRangePicker'
            }   
        ];

        return(
            <DataTable fields={fields}
                       items={extendedWarrantyClaims}
                       entity="extendedWarrantyClaims"
                       filterKeys={filterKeys}
                       filterValues={this.props.extendedWarrantyClaims.extendedWarrantyClaimFilters}
                       onInputChange={(filterKey, filterValue, multipleSelection) => this.handleFilterUpdate(filterKey, filterValue, multipleSelection)}
                       onValueSubmit={() => this.props.getExtendedWarrantyClaimList(parseObjectToString(this.props.extendedWarrantyClaims.extendedWarrantyClaimFilters))}
                       sorting={this.props.extendedWarrantyClaims.sorting}
                       onSort={(columnName, directionToSort) => this.handleSorting(columnName, directionToSort)}
                       sortingAvailable={true}
                       onCancel={() => this.handleFilterReset()}
                       showContentLoader={showContentLoader}
            />
        )
    }

    render() {

        return(
            <PageTemplate title="messages.extendedWarrantyClaimList">
                {this.renderExtendedWarrantyClaims(this.props.extendedWarrantyClaims.extendedWarrantyClaims, this.props.common.showContentLoader)}
            </PageTemplate>
        )
    }

}

const mapStateToProps = state => ({
    extendedWarrantyClaims: state.extendedWarrantyClaims,
    authentication: state.authentication,
    common: state.common
})

export default connect(mapStateToProps, {
    getExtendedWarrantyClaimList,
    updateFiltersForExtendedWarrantyClaimList,
    clearExtendedWarrantyClaimFilters,
    sortExtendedWarrantyClaims,
    showError
})(ExtendedWarrantyClaimList);