import React from 'react';
import { useTranslation } from "react-i18next";
import {validateAuthority} from "../../utils/utils";
import {AUTHORITY_PICK_UP_MANAGER, AUTHORITY_PICK_UP_OFFICER} from "../../constant";

const ReloadPlusPickup = ({authorities}) => {
    const { t } = useTranslation();
    if (validateAuthority([AUTHORITY_PICK_UP_MANAGER, AUTHORITY_PICK_UP_OFFICER], authorities)) {
        return (
            <div>
                <p>{t('messages.dashboard.reloadPlusPickup.title1')}</p>
                <p>{t('messages.dashboard.reloadPlusPickup.title2')}</p>
                <p>{t('messages.dashboard.reloadPlusPickup.optionTitle')}</p>
                <ol>
                    <li dangerouslySetInnerHTML={{__html: t('messages.dashboard.reloadPlusPickup.option1')}}/>
                    <li dangerouslySetInnerHTML={{__html: t('messages.dashboard.reloadPlusPickup.option2')}}/>
                    <li dangerouslySetInnerHTML={{__html: t('messages.dashboard.reloadPlusPickup.option3')}}/>
                    <li dangerouslySetInnerHTML={{__html: t('messages.dashboard.reloadPlusPickup.option4')}}/>
                </ol>
                <p>{t('messages.dashboard.reloadPlusPickup.questionTitle')}</p>
                <ol>
                    <li dangerouslySetInnerHTML={{__html: t('messages.dashboard.reloadPlusPickup.question1')}}/>
                    <li dangerouslySetInnerHTML={{__html: t('messages.dashboard.reloadPlusPickup.question2')}}/>
                    <li dangerouslySetInnerHTML={{__html: t('messages.dashboard.reloadPlusPickup.question3')}}/>
                    <li dangerouslySetInnerHTML={{__html: t('messages.dashboard.reloadPlusPickup.question4')}}/>
                    <li dangerouslySetInnerHTML={{__html: t('messages.dashboard.reloadPlusPickup.question5')}}/>
                </ol>
                <p>{t('messages.dashboard.reloadPlusPickup.forFurtherQuestions')}</p>
            </div>
        )
    }
    return (
        <div/>
    )
}

export default ReloadPlusPickup;