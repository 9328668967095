import React, { useState } from "react";
import "../../styles/custom-bootstrap.scss";
import { Grid, TextField, Typography } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useTranslation } from "react-i18next";
import { useFormikContext, getIn } from "formik";
import { zonedTimeToUtc } from 'date-fns-tz';
import DatePicker from 'react-datepicker';
import ContactDetails from "./ContactDetails";

const PickUpDetailsView = (props) => {
  const { extendedWarrantyClaim, editableNewAddressFields } =
    props;

  const { t } = useTranslation();

  const checkPickUpSameAsDeliveryAddress = () => {
    if (extendedWarrantyClaim?.pickUpDetails?.addresses && extendedWarrantyClaim?.deliveryDetails?.addresses) {
      var sameValue = true
      Object.keys(extendedWarrantyClaim?.pickUpDetails?.addresses[0]).map((field) => {
        if (sameValue && extendedWarrantyClaim?.pickUpDetails?.addresses[0][field] != extendedWarrantyClaim?.deliveryDetails?.addresses[0][field]) sameValue = false
      })
      return sameValue

    } else {
      return false;
    }
  }

  return (
    <>
      <div className="row">
        <div className="col-sm-12">
          <div className="card">
            <div className="card-header">
              <i class="fa fa-truck" aria-hidden="true"></i>
              <strong> {'Pick Up Details'}</strong>
            </div>
            <ContactDetails
              extendedWarrantyClaim={extendedWarrantyClaim}
              entity="pickUpDetails"
              editableNewAddressFields={editableNewAddressFields}
              readOnlyFields={extendedWarrantyClaim?.deliveryDetailsReadOnlyFields ? extendedWarrantyClaim?.deliveryDetailsReadOnlyFields : ""}
              addAddressEnabled={false}
              selectAddressEnabled={extendedWarrantyClaim?.status == 'new'}
              sameAddressEnabled={true}
              isSameAddress={checkPickUpSameAsDeliveryAddress()}
            />
          </div>
        </div>
      </div>

    </>
  );
};

export default PickUpDetailsView;
