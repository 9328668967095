import {
    SWAP_CLEAR_FILTER,
    SWAP_FILTER,
    SWAP_GET_DETAILS,
    SWAP_GET_LIST, SWAP_SORT,
    SWAP_UPDATE,
} from "../actions/types";

const INITIAL_STATE = {
    swaps: {},
    swapObject: {},
    sorting: {
        orderBy: 'createdAt',
        sort: 'desc'
    },
    filters: {
        number: '',
        status: [],
        'coveredDevice.imei': '',
        createdAt: {
            from: null,
            to: null
        },
        updatedAt: {
            from: null,
            to: null
        }
    }
}

const swapReducer = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SWAP_GET_LIST:
            return {
                ...state,
                swaps: action.payload.data
            }
        case SWAP_GET_DETAILS:
            return {
                ...state,
                swapObject: action.payload.data
        }
        case SWAP_UPDATE:
            return {
                ...state,
                swapObject: action.payload.data
            }
        case SWAP_FILTER:
            return {
                ...state,
                filters: {
                    ...state.filters,
                    [action.payload.filterKey]: action.payload.filterValue
                }
            }
        case SWAP_CLEAR_FILTER:
            return {
                ...state,
                filters: {
                    number: '',
                    status: [],
                    'coveredDevice.imei': '',
                    createdAt: {
                        from: null,
                        to: null
                    },
                    updatedAt: {
                        from: null,
                        to: null
                    }
                }
            }
        case SWAP_SORT:
            return {
                ...state,
                sorting: action.payload
            }
        default:
            return state;
    }
}

export default swapReducer;