import _, { isArray } from 'lodash';
import {
    TIME_FORMAT_LONG,
    TIME_FORMAT_SHORT,
    TIME_FORMAT_LONG_WITH_INDICATOR,
    TIME_ZONES
} from "../constant";
import format from 'date-fns/format';
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import isValid from 'date-fns/isValid';
import {parseISO} from "date-fns";
import {LOG_OUT, SHOW_CONTENT_LOADER, SHOW_ERROR} from "../actions/types";

export const parseObjectToString = (objectsToBeParsed, sorting) => {
    let filterFields = '';
    let filterValues = '';
    let parsedSorting = parseSortingToString(sorting);
    _.forEach(objectsToBeParsed, (value, key) => {
        if (value.length > 0) {
            if (Array.isArray(value)) {
                value.forEach(valueElement => {
                    filterFields = filterFields + `filterFields[]=${key}&`
                    filterValues = filterValues + `filterValues[]=${valueElement}&`

                })
            } else {
                filterFields = filterFields + `filterFields[]=${key}&`
                if (key === 'contact.phoneNumber' || key === 'phoneNumber'|| key === 'email' || key === 'contact.email') {
                    filterValues = filterValues + `filterValues[]=${encodeURIComponent(value)}&`
                } else {
                    filterValues = filterValues + `filterValues[]=${value}&`
                }
            }
        }

        if (key === 'createdAt' || key === 'pickUpDate' || key === 'activationDate' || key === 'updatedAt') {
            if (value['from'] !== null) {
                filterFields = filterFields + `filterFields[]=${key}.from&`
                filterValues = filterValues + `filterValues[]=${encodeURIComponent(value['from'])}&`
            }
            if (value['to'] !== null) {
                filterFields = filterFields + `filterFields[]=${key}.to&`
                filterValues = filterValues + `filterValues[]=${encodeURIComponent(value['to'])}&`
            }
        }

    })
    return (filterFields + filterValues + parsedSorting);
}

export const parseSortingToString = (sorting) => {
    let parsedSorting = '';
    if (sorting && sorting.orderBy && sorting.sort) {
        parsedSorting = `orderBy=${sorting.orderBy}&sort=${sorting.sort}`;
    }

    return parsedSorting;
}

export const showContentLoader = (showLoading, dispatch) => {
    dispatch({
        type: SHOW_CONTENT_LOADER,
        payload: showLoading
    })
}

export const handleError = (error, dispatch) => {
    if (error.response.data.errorCode === 401) {
        dispatch({
            type: LOG_OUT,
            payload: error.response
        })
    } else {
        dispatch({
            type: SHOW_ERROR,
            payload: error.response
        })
    }
}

export const fallbackValue = {
    pickUpDate: "selectPickUpDate"
}

export const parseDate = (dateValue, timeZone) => {
    const valueToDate = new Date(dateValue);
    const dateInUsersLocalTime = zonedTimeToUtc(valueToDate, timeZone || 'Etc/UTC')
    return format(dateInUsersLocalTime, "yyyy-MM-dd'T'HH:mm:ss.SSSxx");
}

export const fullDate = (date, countryCode) => {
    return isValid(parseISO(date)) ? format(utcToZonedTime(date, TIME_ZONES[countryCode]), TIME_FORMAT_LONG) : '';
}

export const fullDateWithIndicator = (date, countryCode) => {
    return isValid(parseISO(date)) ? format(utcToZonedTime(date, TIME_ZONES[countryCode]), TIME_FORMAT_LONG_WITH_INDICATOR) : '';
}

export const shortDate = (date, countryCode) => {
    return isValid(parseISO(date)) ? format(utcToZonedTime(date, TIME_ZONES[countryCode]), TIME_FORMAT_SHORT) : '';
}

export const calculateDayDifference = (initialDate, endDate) => {
    initialDate = new Date(initialDate);
    endDate = new Date(endDate);
    const differenceInMilliseconds = Math.abs(endDate - initialDate);
    const differenceInDays = Math.ceil(differenceInMilliseconds / (1000 * 60 * 60 * 24));

    return differenceInDays;
}

export const validateAuthority = (permittedAuthorities, userAuthorities) => {
    if(isArray(permittedAuthorities)) {
        return userAuthorities.some(userAuthority=> permittedAuthorities.includes(userAuthority));
    } else {
        return userAuthorities.includes(permittedAuthorities);
    }
}

export const validateDisableAuthority = (permittedAuthorities, userAuthorities) => {
    if(isArray(permittedAuthorities)) {
        return !userAuthorities.some(userAuthority=> permittedAuthorities.includes(userAuthority));
    } else {
        return !userAuthorities.includes(permittedAuthorities);
    }
}

export const validateEmail = (email) => {
    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return emailRegex.test(String(email).toLowerCase())
}

export const validatePhoneNumber = (phoneNumber) => {
    const phoneNumberRegex = /^\+[0-9]+$/;
    return phoneNumberRegex.test(String(phoneNumber));
}

export const validateAcceptedFields = (validFields, dataFields) => {
    return dataFields.some(dataField => validFields.includes(dataField));
}

export const validateRequiredFields = (requiredFields, data) => {
    let result = true;
    requiredFields.forEach(requiredField => {
        result = result && (_.has(data, requiredField)) && (_.get(data, requiredField).length > 0) ;
    })
    return result;
}

export const trimData = (data) => {
    console.log('trimming...', data);
    return data.forEach(datum => {
        Object.keys(datum).forEach(fieldName => datum[fieldName] = datum[fieldName].trim());
    })
}

export const replaceEmptyWithNull = (data) => {
    Object.keys(data).map(function (key, index) {
        if (typeof data[key] === 'string' && data[key] === "") {
            data[key] = null;
        }
    });
}

export const formatCurrency = (amount) => {
    return amount.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
}

export const getPropertyByString = (object, path) => {
    if (!path || !object) return object;

    var childNode;
    var pathList = path.split('.');

    for(var i = 0; i < pathList.length - 1; i++) {
        childNode = pathList[i];
        var parentNode = object[childNode];
        if (parentNode) {
            object = parentNode;
        } else {
            break;
        }
    }

    return object[pathList[i]];
}

export const parseDotNotation = (str, val, obj) => {
    var currentObj = obj,
        keys = str.split("."),
        i, l = Math.max(1, keys.length - 1),
        key;

    for (i = 0; i < l; ++i) {
        key = keys[i];
        currentObj[key] = currentObj[key] || {};
        currentObj = currentObj[key];
    }

    currentObj[keys[i]] = val;
    delete obj[str];
}

export const convertToDimensional = (map) => {
    for (var key in map) {
        if (map.hasOwnProperty(key)) {
            if (key.indexOf(".") !== -1)
            {
                parseDotNotation(key, map[key], map);
            }
        }
    }
    return map;
}