import React, { useEffect } from 'react';
import {CircularProgress, Paper} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import * as yup from "yup";
import {useFormik} from "formik";
import TextField from "@material-ui/core/TextField";
import makeStyles from "@material-ui/core/styles/makeStyles";
import Button from "@material-ui/core/Button";
import DoneIcon from '@material-ui/icons/Done';
import logo from "../assets/images/logo_bolttech.png";
import useIsMount from "../hooks/use-is-mount";

const customStyles = makeStyles({
    success: {
        color: '#4caf50'
    },
    inputFields: {
        marginBottom: 20
    }
})

const validationSchema = (t) => {
    return yup.object({
        email: yup
            .string()
            .email(t('messages.fieldInvalid', {field: t(`keys.email`)}))
            .required(t('messages.fieldRequired', {field: t(`keys.email`)}))
    });
}

const getCaptchaValidatedKey = () => {
    return window.grecaptcha.getResponse();
}
const resetCaptcha = () => {
    window.grecaptcha.reset();
}

const ForgotPassword = ({ onFormSubmit, errorMessage, requestInProgress, requestSuccess }) => {
    const { t, i18n } = useTranslation();
    const classes = customStyles();
    const isMount = useIsMount();

    const formik = useFormik({
        initialValues: {
            email: '',
            captchaToken: '',
        },
        validationSchema: validationSchema(t),
        onSubmit: (values) => {
            values['captchaToken'] = getCaptchaValidatedKey();
            onFormSubmit(values);
            resetCaptcha();
        },
    });

    useEffect(() => {
        if (isMount && typeof window !== 'undefined' && window.grecaptcha) {
            window.grecaptcha.ready(function () {
                window.grecaptcha.render('g-recaptcha', {
                  'sitekey' : process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY,
                  'hl': i18n.language,
                });
            });
        }
    }, [i18n, isMount]);

    return (
        <div className="authentication-cover">
            <Paper className="authentication-form">
                <img src={logo} alt="bolttech"  style={{width: '50%'}} />
                <p>Forgot Password</p>
                <form onSubmit={formik.handleSubmit}>
                    <TextField
                        fullWidth
                        id="emailForgotPassword"
                        name="email"
                        label={t('keys.email')}
                        variant="outlined"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                        disabled={requestInProgress || requestSuccess}
                        className={classes.inputFields}
                    />
                    <div id="g-recaptcha" className="authentication-g-recaptcha"></div>
                    {errorMessage !== undefined &&
                    <Paper className="authentication-error-message" elevation={3}>{t(errorMessage)}</Paper>
                    }
                    <Button disabled={requestInProgress || requestSuccess} color="primary" variant="contained" fullWidth type="submit">
                        {requestInProgress && <CircularProgress />}
                        {requestSuccess && <DoneIcon className={classes.success}/>}
                        {!requestInProgress && !requestSuccess && t('messages.submit')}
                    </Button>
                    {requestSuccess && <p>Please check your email and follow the instructions</p>}
                </form>
            </Paper>
        </div>
    )
}

export default ForgotPassword;